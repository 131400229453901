import React, { useContext, useState } from "react";
import CardSlider from "../CardSlider/CardSlider";
import "./BestDealsNearMe.scss";
import { useNavigate } from "react-router-dom";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import { Context } from "../../contexts/AppContext";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import BestDealItemCard from "../BestDealItemCard/BestDealItemCard";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
// import { generateFormattedItemArray } from "../../utils/commonFunctions";

const BestDealsNearMe = () => {
  const { bestDealProducts } = useContext(Context);
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  const EventTriggerAndNavigation = (screenName) => {
    navigate(`/${screenName}`);
    googleAnalyticsFunc(
      `${screenName}_Tab_clicked`,
      `BestDealsNearMe_${screenName}_Tab_clicked`,
      `BestDealsNearMe`
    );
  };

  let newDeals = {
    ...bestDealProducts,
    items: bestDealProducts.store,
  };
  // let formattedItems = generateFormattedItemArray(newDeals);

  //One Product from each store
  const uniqueProducts = [];
  const storeIds = new Set();

  bestDealProducts?.store?.forEach((product) => {
    if (!storeIds.has(product.storDetails.store_id)) {
      storeIds.add(product.storDetails.store_id);
      uniqueProducts.push(product);
    }
  });
  return (
    <>
      {bestDealProducts.length !== 0 && (
        <div className="deals-container">
          <div className="row-container ">
            <div className="title-container">
              <h1 className="product-list-title">{bestDealProducts?.title}</h1>
              <a
                className="explore-btn"
                onClick={() => EventTriggerAndNavigation(`best-deals`)}
              >
                Explore All
              </a>
            </div>
            <CardSlider
              cards={uniqueProducts?.map((product) => (
                <BestDealItemCard key={product.itemId} product={product} />
              ))}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BestDealsNearMe;
