import { logEvent } from "@firebase/analytics";
import { analytics } from "../firebase";
import { AppConstants } from "../constants/AppContants";

export const formatOrderHistoryDate = (orderDate) => {
  const [datePart] = orderDate.split(" ");
  const [day, month, year] = datePart.split("-");

  const date = new Date(`${year}-${month}-${day}`);

  return date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const getCategoriesForFilter = (products) => {

  if (products.length > 0) {
    const categories = products.map((item) => {
      return {
        category_displayname: item.category_displayname,
        category_id: item.category_id,
        category_image: item.category_image,
        domain: item.domain,
      };
    });
    const uniqueCategories = [...new Map(categories.map((item) => [item.category_id, item])).values()]
    return uniqueCategories;
  }
}


export const generateFormattedItemArray = (data) => {
  const categoryMap = {};
  const formattedItems = [];
  let serialNumber = 1;
  // 1. Parent Id wise Items with variants
  const groupedItems = data?.items?.reduce((acc, item) => {
    const parentId = item?.parent_item_id || item?.itemId;
    if (!acc[parentId]) {
      acc[parentId] = {
        ...item,
        variations: [item],
      };
    } else {
      acc[parentId].variations.push(item);
    }
    return acc;
  }, {});
  // 2. Setting categoryMap
  // 2.1 Sub Category wise Items with varients
  groupedItems &&
    Object?.values(groupedItems)?.forEach((item, index) => {
      const { category_displayname, category_image, priority } = item;
      if (category_displayname && category_displayname !== '') {
        if (categoryMap[category_displayname]) {
          categoryMap[category_displayname].items.push(item);
        } else {
          categoryMap[category_displayname] = {
            category_displayname: category_displayname,
            category_image: category_image,
            items: [item],
            sr_no: serialNumber++,
            priority: priority || 0, // priority of the 1st product of that category
          };
        }
      }
      // 2.2 All items with varients
      if (categoryMap['All']) {
        categoryMap['All'].items.push(item);
      } else {
        categoryMap['All'] = {
          category_displayname: 'All',
          category_image: '',
          items: [item],
          sr_no: 0,
          priority: -1,
        };
      }
    });
  // 3. Setting formatted Items clubbed according to its category
  for (const key in categoryMap) {
    formattedItems.push(categoryMap[key]);
  }
  //4. Items of "All" category sorted according the priority of its subcategories
  const allCategory = formattedItems.find(
    category => category.category_displayname === 'All',
  );
  if (allCategory) {
    allCategory.items.sort((a, b) => {
      const categoryA = formattedItems.find(
        category => category.category_displayname === a.category_displayname,
      );
      const categoryB = formattedItems.find(
        category => category.category_displayname === b.category_displayname,
      );
      return (categoryA?.priority || 0) - (categoryB?.priority || 0);
    });
  }
  //5. Other items sorted according the priority of its subcategories
  return formattedItems.sort((a, b) => a.priority - b.priority) || [];
}

export const getItemCount = (input1, input2) => {
  if (input2?.parent_item_id) {
    let itmCount = 0;
    const parentId = input2?.parent_item_id;
    if (input1?.items?.length > 0) {
      for (const listItem of input1?.items) {
        if (listItem?.parentItemId === parentId) {
          itmCount = itmCount + Number(listItem?.count);
        }
      }
    }
    return Number(itmCount);
  } else {
    const itemId2 = input2.itemId;
    if (input1?.items?.length > 0) {
      for (const item of input1?.items) {
        if (item.itemId === itemId2) {
          return Number(item.count);
        }
      }
    }
  }
  return 0;
}

const getCurrentTime = () => {
  const now = new Date();
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
  hours = String(hours).padStart(2, '0'); // Add leading zero for single-digit hours
  return `${hours}:${minutes}:${seconds} ${ampm}`;
};

export const googleAnalyticsFunc = (eventName, message, screen) => {
  logEvent(analytics, eventName, {
    agent_code: AppConstants.AID || '',
    screen: screen,
    message: message,
    event_date: getCurrentTime(),
    event_time: getCurrentTime()
  });
}
