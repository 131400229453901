import "./App.scss";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import CategoryProductList from "./pages/CategoryProductList/CategoryProductList";
import StoreProductList from "./pages/StoreProductList/StoreProductList";
import SingleProduct from "./pages/SingleProduct/SingleProduct";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AppContext from "./contexts/AppContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart";
import { Login } from "./pages/Login/Login";
import { Register } from "./pages/Register/Register";
import { Checkout } from "./pages/Checkout/Checkout";
import { Provider } from "react-redux";
import store from "./redux/store";
import Dashboard from "./pages/Dashboard/Dashboard";
import OrderPlaced from "./pages/OrderPlaced/OrderPlaced";
import Profile from "./components/Profile/Profile";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import AddressList from "./pages/AddressList/AddressList";
import AddAddress from "./pages/AddAddress/AddAddress";
import ProtectedRoutes from "./utilities/ProtectedRoutes";
import RaiseTicket from "./pages/RaiseTicket/RaiseTicket";
import TicketList from "./pages/TicketList/TicketList";
import TicketDetails from "./pages/TicketDetails/TicketDetails";
import AllIssues from "./pages/TicketDetails/TicketDetails";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import DealsProductList from "./pages/DealsProductList/DealsProductList";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundOrderCancellationPolicy from "./pages/RefundOrderCancellationPolicy/RefundOrderCancellationPolicy";
import ReturnPolicy from "./pages/ReturnPolicy/ReturnPolicy";
import SellerAppPolicy from "./pages/SellerAppPolicy/SellerAppPolicy";
import ShippingAndDeliveryPolicies from "./pages/ShippingAndDeliveryPolicies/ShippingAndDeliveryPolicies";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition";
import FestivalOffers from "./pages/FestivalOffers/FestivalOffers";
import FestiveCategory from "./pages/FestiveCategory/FestiveCategory";
import AboutUs from "./pages/AboutUs/AboutUs";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <AppContext>
          <Provider store={store}>
            <Header />
            <div className="page-wrapper">
              <Routes>
                {/*Public Routes*/}
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Home />} />
                <Route path="/category/:id" element={<CategoryProductList />} />
                <Route
                  path="/store-products/:id"
                  element={<StoreProductList />}
                />
                <Route path="/event/:eventName" element={<FestivalOffers />} />
                <Route path="/event/:eventName/products" element={<FestiveCategory />} />

                <Route path="/best-deals" element={<DealsProductList />} />
                <Route path="/event/:eventName" element={<FestivalOffers />} />
                <Route path="/event/:eventName/products" element={<FestiveCategory />} />

                <Route
                  path="/product/:id/seller/:sid"
                  element={<SingleProduct />}
                />

                {/*Private Routes*/}
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoutes>
                      <Navigate to="dashboard" />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoutes>
                      <Profile />
                    </ProtectedRoutes>
                  }
                >
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="order-history" element={<OrderHistory />} />
                  <Route path="order-history/:id" element={<OrderDetails />} />
                  <Route path="address-list" element={<AddressList />} />
                  <Route path="add-address" element={<AddAddress />} />
                  <Route path="edit-address" element={<AddAddress />} />
                  <Route
                    path="tickets/raise-ticket/:id"
                    element={<RaiseTicket />}
                  />
                  <Route path="tickets" element={<TicketList />} />
                  <Route path="tickets/:id" element={<TicketDetails />} />
                </Route>
                <Route
                  path="/cart"
                  element={
                    <ProtectedRoutes>
                      <ShoppingCart />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    <ProtectedRoutes>
                      <Checkout />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/order-placed"
                  element={
                    // <ProtectedRoutes>
                    <OrderPlaced />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/privacyPolicy"
                  element={
                    // <ProtectedRoutes>
                    <PrivacyPolicy />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/RefundOrderCancellationPolicy"
                  element={
                    // <ProtectedRoutes>
                    <RefundOrderCancellationPolicy />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/ReturnPolicy"
                  element={
                    // <ProtectedRoutes>
                    <ReturnPolicy />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/SellerAppPolicy"
                  element={
                    // <ProtectedRoutes>
                    <SellerAppPolicy />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/ShippingAndDeliveryPolicies"
                  element={
                    // <ProtectedRoutes>
                    <ShippingAndDeliveryPolicies />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/TermsAndCondition"
                  element={
                    // <ProtectedRoutes>
                    <TermsAndCondition />
                    // </ProtectedRoutes>
                  }
                />
                <Route
                  path="/AboutUs"
                  element={
                    <AboutUs />
                  }
                />
                {/* <Route path="/access-denied" element={<AccessDenied />} /> */}
                {/* <Route path="*" element={<AccessDenied />} /> */}
              </Routes>
            </div>
            <Footer />
            <ToastContainer />
          </Provider>
        </AppContext>
      </BrowserRouter>
    </div>
  );
};

export default App;
