import "./SingleProduct.scss";
import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
  useMemo,
} from "react";
import "react-slideshow-image/dist/styles.css";
import { Context } from "../../contexts/AppContext";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
  // useSearchParams,
} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import cartBagRed from "../../assets/icons/cart-bag-red.svg";
import cartBag from "../../assets/icons/cart-bag.svg";
import nonCancellable from "../../assets/icons/non-cancellable.svg";
import arrowUp from "../../../src/assets/images/arrow-up.svg";
import arrowDown from "../../../src/assets/images/arrow-down.svg";
import { AppConstants, generateId } from "../../constants/AppContants";
import Modal from "@mui/material/Modal";
import cross from "../../assets/icons/cross-for-close.svg";
import { toast } from "react-toastify";
import { search_productDtl_by_id } from "../../services/services";
import Spinner from "../../components/Loader/Spinner";
// import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import ReplaceProductPopup from "../../components/ReplaceProductPopup/ReplaceProductPopup";
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { Duration } from "luxon";
import { getDecryptedQueryData } from "../../utils/encryption";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const SingleProduct = () => {
  const navigate = useNavigate();
  const { id, sid } = useParams();
  const [searchParams] = useSearchParams();
  const queryParamData = getDecryptedQueryData(searchParams.get("pd"));
  const location = useLocation();
  const {
    userLocation,
    cartData,
    handleAddToCart,
    eachCartItemCount,
    reduceCartItems,
    selectedCategory,
    setSelectedCategory,
  } = useContext(Context);
  const [productDetails, setProductDetails] = useState({});
  const [activeTab, setActiveTab] = useState("tab1");
  const [activeTabMobile, setActiveTabMobile] = useState("tab1");
  const [isInCart, setIsInCart] = useState(false);
  const [replaceCartModalVisible, setReplaceCartModalVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [imageError, setImageError] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };

  const [renderContent, setRenderContent] = useState(false);

  const [outOfStock, setOutOfStock] = useState(false);
  const [isMaxOrderQuantityReached, setIsMaxOrderQuantityReached] =
    useState(false);
  const [isMaxAvailableQantityReached, setIsMaxAvailableQantityReached] =
    useState(false);
  const toastId = useRef(null);
  const [images, setImages] = useState([]);
  const [showProductDetails, setShowProductDetails] = useState({});

  const autoplayInterval = 5000;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, autoplayInterval);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  // 1. Calling a Product Seller
  // const { data, error } = useFetch(
  //   `/productsellers?populate=*&[filters][product_id][product_id]=${id}&[filters][store_details][store_name]=${sid}`,
  // );

  const getItemDetailsById = async () => {
    // if (queryParamData) {
    //   var domain =
    //     (queryParamData.domain && queryParamData.domain.toLowerCase().replace(/\s+/g, "")) ||
    //     "all";
    //   if (domain === "beautyandpersonalcare") {
    //       domain = "bpc";
    //   }
    //   var city = queryParamData.city.toLowerCase().replace(/\s+/g, "");
    // }

    // if (store) {
    //   var domain =
    //     (store.domain && store.domain.toLowerCase().replace(/\s+/g, "")) ||
    //     "all";
    //   var city = store.city.toLowerCase().replace(/\s+/g, "");
    // }
    try {
      const payload = {
        DATA: {
          city: queryParamData.city,
          providerId: sid,
          itemId: id,
          domain: queryParamData.domain,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          userId: AppConstants.mobile,
          versioNo: "",
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        },
      };

      const res = await search_productDtl_by_id(payload);

      if (res.RESP_CODE === 300) {
        setProductDetails(res.DATA);
        setShowProductDetails(res.DATA.store[0].items[0])
        setImages(res.DATA.store[0].items[0].itemImagesArray);
        if (parseInt(res.DATA.store?.[0]?.items?.[0]?.available) === 0) {
          setOutOfStock(true);
        }
      } else {
        setProductDetails([]);
        setImages([]);
        toast.error(res.RESP_MSG);
      }
    } catch (error) {
      setProductDetails([]);
      setImages([]);
      console.error("Error fetching search results:", error);
    } finally {
      setRenderContent(true);
    }
  };

  useEffect(() => {
    // setSelectedCategory(queryParamData && queryParamData?.domain ? queryParamData?.domain : ""); why??
    getItemDetailsById();
  }, [id]);

  // 2. Adding item to cart
  const addToCartOrIncrement = () => {
    const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

    if (!isLogin || !isLogin.isLogin) {
      sessionStorage.setItem(
        "activityRedirectionInfo",
        JSON.stringify({ path: location.pathname + location.search, activity: "addToCart" }),
      );
      navigate("/login");
      return;
    }

    if (Object.keys(cartData).length !== 0) {
      if (productDetails?.store[0]?.store_id !== cartData.storeId) {
        setReplaceCartModalVisible(true);
        return;
      }
    }

    if (isMaxAvailableQantityReached) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "You have this item in your cart with the maximum available quantity.",
        );
      }
      return;
    }

    if (isMaxOrderQuantityReached) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "You have reached maximum item quantity limit for this order.",
        );
      }
      return;
    }

    const payload = {
      DATA: {
        userId: AppConstants.mobile,
        screenName: AppConstants.screenName,
        UDID: AppConstants.screenName,
        agentCode: AppConstants.AID,
        DATA: [
          {
            areaCode: "",
            bppId: showProductDetails?.storDetails?.bpp_id,
            bppUrl:
              showProductDetails?.storDetails?.bpp_uri.endsWith(
                "/",
              )
                ? showProductDetails?.storDetails?.bpp_uri
                : `${showProductDetails?.storDetails?.bpp_uri}/`,
            categories: [{}],
            city: productDetails?.store?.[0]?.city,
            gps: `${userLocation.latitude}, ${userLocation.longitude}`,
            locationId:
              showProductDetails?.storDetails
                ?.store_location_id,
            messageId: Math.floor(new Date().getTime() / 1000.0),
            transactionId: cartData?.transactionId || generateId(),
            store_area_code: productDetails?.store?.[0]?.store_area_code,
            store_city: productDetails?.store?.[0]?.store_city,
            store_email: productDetails?.store?.[0]?.store_email,
            store_end: productDetails?.store?.[0]?.store_end,
            storeId: productDetails?.store?.[0]?.store_id,
            store_image: productDetails?.store?.[0]?.store_image?.[0],
            store_name: productDetails?.store?.[0]?.store_name,
            store_phone: productDetails?.store?.[0]?.store_phone,
            store_start: productDetails?.store?.[0]?.store_start,
            store_state: productDetails?.store?.[0]?.store_state,
            store_street: productDetails?.store?.[0]?.store_street,
            domainName: showProductDetails?.domain,
            items: [
              {
                availableQuantity:
                  showProductDetails?.available,
                bppId:
                  showProductDetails?.storDetails?.bpp_id,
                bppUrl:
                  showProductDetails?.storDetails?.bpp_uri,
                cancellable:
                  showProductDetails?.cancellable,
                contact_details_consumer_care:
                  showProductDetails
                    ?.contact_details_consumer_care,
                count: "1",
                itemId: showProductDetails?.itemId,
                item_image: showProductDetails?.item_image,
                itemImagesArray:
                  showProductDetails?.itemImagesArray,
                item_long_desc:
                  showProductDetails?.item_long_desc,
                item_name: showProductDetails?.item_name,
                item_short_desc:
                  showProductDetails?.item_short_desc,
                item_symbol:
                  showProductDetails?.item_symbol,
                categoryName:
                  showProductDetails?.category_id,
                max_price: showProductDetails?.max_price,
                parent_item_id: showProductDetails?.parent_item_id,
                unitized: showProductDetails?.unitized,
                offer: {
                  item_capacity: [],
                },
                maxQuantity:
                  showProductDetails?.max_available,
                // ondcreturnwindow:
                //   showProductDetails?.ondc_return_window,
                // return_window:
                //   showProductDetails?.return_window,
                price: showProductDetails?.price,
                returnable: showProductDetails?.returnable,
                ...(showProductDetails?.returnable
                  ? {
                      return_window:
                        showProductDetails
                          ?.ondc_return_window,
                      ondcreturnwindow:
                        showProductDetails
                          ?.ondc_return_window,
                    }
                  : {}),
              },
            ],
          },
        ],
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: "",
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    handleAddToCart(payload);
    googleAnalyticsFunc(
      `AddToCartOrIncrement_Btn_Clicked`,
      `SingleProduct_AddToCartOrIncrement_Btn_Clicked`,
      `SingleProduct`
    );
  };

  // 3. Setting Item in Cart and Cart item count
  useEffect(() => {
    if (
      Object.keys(cartData).length !== 0 &&
      Object.keys(productDetails).length !== 0
      // &&
      // showProductDetails
    ) {
      const item = cartData?.items?.find(
        (item) => item.itemId === showProductDetails.itemId,
      );
      if (item) {
        setCurrentCount(item?.count);
        setIsInCart(true);
        setIsMaxAvailableQantityReached(item.count === item.availableQuantity);
        setIsMaxOrderQuantityReached(item.count === item.maxQuantity);
      } else {
        setCurrentCount(0);
        setIsInCart(false);
        setIsMaxAvailableQantityReached(false);
        setIsMaxOrderQuantityReached(false);
      }
    } else {
      setCurrentCount(0);
      setIsInCart(false);
      setIsMaxAvailableQantityReached(false);
      setIsMaxOrderQuantityReached(false);
    }
  }, [cartData, showProductDetails]);

  // 4. Clear cart
  useEffect(() => {
    if (replaceCartModalVisible && Object.keys(cartData).length === 0) {
      addToCartOrIncrement();
      setReplaceCartModalVisible(false);
    }
  }, [cartData]);

  // 5. Get return duration
  const returnDuration = useMemo(() => {
    if (Object.keys(productDetails).length !== 0) {
      const isoCode = showProductDetails?.ondc_return_window;
      try {
        const duration = Duration.fromISO(isoCode);
        const days = duration.as("days");
        return `${Math.floor(days)} days`;
      } catch (error) {
        console.error("Invalid ISO code:", error);
        return "N/A";
      }
    }
  }, [productDetails]);

  // 6. handle add to cart when deep linking us used
  useEffect(() => {
    if (Object.keys(productDetails).length !== 0) {
      const activityRedirectionInfo = JSON.parse(
        sessionStorage.getItem("activityRedirectionInfo"),
      );
      // shorten this code
      if (activityRedirectionInfo) {
        if (activityRedirectionInfo.activity === "addToCart") {
          addToCartOrIncrement();
          sessionStorage.removeItem("activityRedirectionInfo");
        }
      }
    }
  }, [cartData, productDetails]);

  // let no_items = true;

  // useEffect(() => {
  //   if (data) {
  //     const product = data?.data?.[0]?.attributes;
  //     // const sellers = product?.sellers?.data;
  //     const sellers = product?.productseller?.data;
  //     // const initialSelectedSellerName = sellers?.[0]?.attributes?.seller_name;
  //     const initialSelectedSellerName = sellers?.[0]?.attributes?.store_details.store_name;
  //     setSelectedSeller(initialSelectedSellerName);
  //     fetchSellerData(product, initialSelectedSellerName);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   // if (!data || loading) return;

  //   const images = productDetails?.item_details?.itemImagesArray || [];
  //   const loadImage = async (url) => {
  //     try {
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch image");
  //       }
  //       const blob = await response.blob();
  //       return blob;
  //     } catch (error) {
  //       throw error;
  //     }
  //   };

  //   const compareImages = async () => {
  //     const uniqueImagesArray = [];
  //     for (let i = 0; i < images.length; i++) {
  //       const imageUrl1 = images[i];
  //       let isUnique = true;
  //       for (let j = i + 1; j < images.length; j++) {
  //         const imageUrl2 = images[j];
  //         try {
  //           const blob1 = await loadImage(imageUrl1);
  //           const blob2 = await loadImage(imageUrl2);
  //           // Compare the blobs using resemble
  //           const data = await new Promise((resolve) => {
  //             resemble(blob1)
  //               .compareTo(blob2)
  //               .onComplete((data) => resolve(data));
  //           });
  //           const diffImage = data.misMatchPercentage;
  //           // If images match 100%, set isUnique to false
  //           if (diffImage == 0.0) {
  //             isUnique = false;
  //             break;
  //           }
  //         } catch (error) {
  //           console.error("Error loading image:", error);
  //         }
  //       }
  //       // If the image is unique, add it to the uniqueImagesArray
  //       if (isUnique) {
  //         uniqueImagesArray.push(imageUrl1);
  //       }
  //     }
  //     // Update the state with unique images
  //     setUniqueImages(uniqueImagesArray);
  //   };

  //   compareImages();
  // }, [data,
  //   // loading,
  //   productDetails]);

  // useEffect(() => {
  // }, [eachCartItemSeller]);

  // const fetchSellerData = async (product, sellerName) => {
  //   const productTitle = product.product_id;

  //   try {
  //     const res = await fetchDataFromApi(
  //       `https://strapibackend.easypay.co.in/api/productsellers?populate=*&[filters][product_id][product_id]=${productTitle}&[filters][store_details][store_name]=${sellerName}`,
  //     );
  //     setproductDetails(res?.data?.[0]?.attributes);
  //     if (res?.data?.[0]?.attributes?.seller?.data[0]) {
  //       setEachCartItemSeller((prev) => ({
  //         ...prev,
  //         [product.product_id]: res?.data?.[0]?.attributes?.seller?.data[0],
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching seller data:", error);
  //   }
  // };

  // const decrement = () => {
  //   if (quantity > 1) {
  //     setQuantity(quantity - 1);
  //     handleCartProductQuantity("dec", data?.data?.[0]?.attributes);
  //   }
  // };

  // const decrement = () => {
  //   if (quantity > 1) {
  //     setQuantity(quantity - 1); // Decrease local quantity state
  //     handleCartProductQuantity("dec", product); // Decrease cart item count
  //   }
  //   // else if (eachCartItemCount[product.product_id] === 1) {
  //   //   // If quantity is 1 and item is in cart, remove it from cart
  //   //   handleRemoveFromCart(product);
  //   // }
  // };

  // const increment = () => {
  //   if (quantity < productDetails.item_details.max_available) {
  //     setQuantity(quantity + 1);
  //     handleCartProductQuantity("inc", data?.data?.[0]?.attributes);
  //   }
  // };

  // const handleSellerClick = (sellerName) => {
  //   setSelectedSeller(sellerName);
  //   fetchSellerData(data?.data?.[0]?.attributes, sellerName);
  // };

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  // if (!data || loading) {
  //   return <div>Loading...</div>;
  // }

  // const product = data?.data?.[0]?.attributes;
  // // const sellers = product?.sellers?.data || [];
  // const sellers = product?.productseller?.data || [];
  // const isProductInCart = eachCartItemCount[product?.product_id] > 0;
  // const addToCartOrIncrement = () => {
  //   if (cartItems.some((item) => item.product_id === product.product_id)) {
  //     increment();
  //   } else {
  //     // handleAddToCart(product, quantity, product.sellers.data[0]);
  //     handleAddToCart(product, quantity, product.productseller.data[0]);
  //   }
  // };
  return (
    <Fragment>
      {/* {loading && <Spinner />} */}
      {renderContent ? (
        Object.keys(productDetails).length !== 0 ? (
          <div className="single-product row-container">
            <Breadcrumb
              category={selectedCategory}
              prodName={showProductDetails?.item_name}
            />
            <div className="main-content">
              <div className="left">
                {images.length > 0 ? (
                  <div className="image-slider">
                    <button
                      onClick={() => {
                        handlePrevious();
                        googleAnalyticsFunc(
                          `HandlePrevious_Btn_Clicked`,
                          `SingleProduct_HandlePrevious_Btn_Clicked`,
                          `SingleProduct`
                        );
                      }}
                      className="nav-button left"
                    >
                      &#8249;
                    </button>
                    <img
                      src={
                        imageError || !images[currentIndex]
                          ? itemImageLoader
                          : images[currentIndex]
                      }
                      alt={`Product ${currentIndex + 1}`}
                      className={
                        imageError || !images[currentIndex]
                          ? "main-img loader-img"
                          : "main-img"
                      }
                      onError={handleImageError}
                      // main-img loader-img
                    />
                    <button onClick={() => {
                      handleNext();
                      googleAnalyticsFunc(
                        `HandleNext_Btn_Clicked`,
                        `SingleProduct_HandleNext_Btn_Clicked`,
                        `SingleProduct`
                      );
                    }} className="nav-button right">
                      &#8250;
                    </button>
                    <div className="current-index">
                      {" "}
                      {`${currentIndex + 1} / ${images.length}`}
                    </div>
                  </div>
                ) : (
                  <img
                    src={itemImageLoader}
                    alt="Default Image"
                    className="main-img loader-img"
                  />
                )}

                {/* <Slide>
              {uniqueImages.map((image, index) => (
                <div key={index} >
                  <img src={image} alt={`Slide ${index}`} className="sub-img" />
                </div>
              ))}
            </Slide> */}
              </div>
              <div className="right">
                <div className="head-container">
                  <div className="product-title-container">
                    <h6 className="title">
                      {showProductDetails?.item_name}
                    </h6>
                    <p style={{ color: "#777" }}>
                      {showProductDetails?.unitized}
                    </p>
                    <p
                      className="product-desc"
                      data-full-text={
                        showProductDetails?.item_short_desc
                      }
                    >
                      {showProductDetails?.item_short_desc}
                    </p>
                    {/* <p className="sub-title">
                    <u>{productDetails?.store_details.store_name}</u>
                  </p> */}
                  </div>
                  {/* <div className="head-btns">
                  <p className="head-btn">
                    <img src={bookmark} alt="bookmark" />
                  </p>
                  <p className="head-btn">
                    <img src={share} alt="share" />
                  </p>
                </div> */}
                </div>
                <hr className="product-line" />
                <div className="price-review">
                  <div className="price-container">
                    <p className="price">
                      &#8377;{showProductDetails?.price}
                    </p>
                    {showProductDetails?.price !==
                      showProductDetails?.max_price && (
                      <p className="mrp">
                        MRP{" "}
                        <span className="mrp-rupees">
                          &#8377;{showProductDetails?.max_price}
                        </span>
                      </p>
                    )}
                  </div>
                  {/* <div className="review-container">
                  <div className="review-tabs">
                    <div className="review-tab">
                      <img src={reviewPointsStar} alt="review-points-star" />
                      <span>4.2</span>
                    </div>
                    <div className="review-tab">
                      <img src={review} alt="review" />
                      <span>67 Reviews</span>
                    </div>
                  </div>
                  <p className="recommend-text">
                    <span>93%</span>&nbsp;of buyers have recommended this.
                  </p>
                </div> */}
                </div>
                <hr className="product-line" />
                {/* <div className="price-review">
                  <div className="show-versions">
                      {productDetails.store[0]?.items?.map(data => 
                        <p className={`change-product-details ${showProductDetails.itemId === data.itemId ? "active-product" : ""}`}
                          onClick={() => {
                            setShowProductDetails(data)
                            setImages(data.itemImagesArray);
                          }}
                        >
                          {data.unitized}
                        </p>
                      )}
                  </div>
                </div>
                <hr className="product-line" /> */}
                {/* <div className="weight-container">
                <div className="offer-headline">Weight</div>
                <div className="weights">
                  <p className="weight selected-weight">
                    {productDetails?.item_details.unitized}
                  </p>
                </div>
              </div> */}
                {/* <hr className="product-line" /> */}
                {outOfStock ? (
                  <div className="cart-buttons">
                    <p className="out-of-stock">Currently unavailable</p>
                  </div>
                ) : (
                  <div className="cart-buttons">
                    {isInCart || currentCount ? (
                      <div className="quantity-buttons">
                        <button
                          type="button"
                          className="cart-button"
                          onClick={() => {
                            reduceCartItems(
                              false,
                              showProductDetails?.itemId,
                            );
                            googleAnalyticsFunc(
                              `ReduceCartItems_Btn_Clicked`,
                              `SingleProduct_ReduceCartItems_Btn_Clicked`,
                              `SingleProduct`
                            );
                          }}>
                          <img
                            alt=""
                            className="cart-button-img"
                            src="/minus-sign.png"
                          />
                        </button>
                        <p className="selected-quantity">
                          {/* {cartData?.items?.find(
                        (item) =>
                          item.itemId === productDetails.item_details.itemId
                      )?.count || 0} */}
                          {currentCount}
                        </p>
                        <button
                          type="button"
                          className="cart-button"
                          onClick={addToCartOrIncrement}
                        >
                          <img
                            alt=""
                            className="cart-button-img"
                            src="/Vector.png"
                          />
                        </button>
                      </div>
                    ) : (
                      <button
                        className="add-to-cart-button"
                        onClick={addToCartOrIncrement}
                      >
                        <img src={cartBag} alt="" />
                        Add To Cart
                      </button>
                    )}
                    {currentCount >= 1 && (
                      <button
                        className="go-to-cart-button"
                        onClick={() => {
                          navigate("/cart");
                          googleAnalyticsFunc(
                            `Go_To_Cart_Btn_Clicked`,
                            `SingleProduct_Go_To_Cart_Btn_Clicked`,
                            `SingleProduct`
                          );
                        }}>
                        <img
                          src="/white-cart.svg"
                          alt=""
                          className="go-cart-icon"
                        />
                        Go To Cart
                      </button>
                    )}
                  </div>
                )}

                <div className="delivery-details">
                  {/* <div className="delivery-card">
                  <div className="free-delivery-img-container">
                    <img src={bus} alt="" className="free-delivery-img" />
                  </div>
                  <div className="delivery-info">
                    <p className="delivery-title">Free Delivery</p>
                    <p className="info">
                      Enter your Postal code for Delivery Availability
                    </p>
                  </div>
                  </div> */}
                  {/* <hr className="product-line" /> */}
                  <div className="delivery-card">
                    <div className="free-delivery-img-container">
                      <img
                        src={cartBagRed}
                        alt=""
                        className="free-delivery-img"
                      />
                    </div>
                    {showProductDetails?.returnable ===
                      "true" ||
                      showProductDetails?.returnable === true ? (
                      <div className="delivery-info">
                        <p className="delivery-title">Returnable</p>
                        <p className="info">
                          {`Free ${returnDuration} delivery return`}
                        </p>
                      </div>
                    ) : (
                      <div className="delivery-info">
                        <p className="delivery-title">Non-Returnable</p>
                        <p className="info">No delivery return</p>
                      </div>
                    )}
                  </div>
                  <hr className="product-line" />
                  <div className="delivery-card">
                    <div className="free-delivery-img-container">
                      <img
                        src={nonCancellable}
                        alt=""
                        className="free-delivery-img"
                      />
                    </div>
                    {showProductDetails?.cancellable ===
                      "true" ||
                      showProductDetails?.cancellable === true ? (
                      <div className="delivery-info">
                        <p className="delivery-title">Cancellable</p>
                      </div>
                    ) : (
                      <div className="delivery-info">
                        <p className="delivery-title">Non-cancellable</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Fragment>
              <div className="tabs-details">
                <div className="tab-container">
                  <ul className="tabs">
                    <li
                      className={`tab ${activeTab === "tab1" ? "active" : ""}`}
                      onClick={() => {
                        setActiveTab("tab1")
                        googleAnalyticsFunc(
                          `Product_Details_Btn_Clicked`,
                          `SingleProduct_Product_Details_Btn_Clicked`,
                          `SingleProduct`
                        );
                      }}>
                      Product Details
                    </li>
                    <li
                      className={`tab ${activeTab === "tab2" ? "active" : ""}`}
                      onClick={() => {
                        setActiveTab("tab2")
                        googleAnalyticsFunc(
                          `Description_Btn_Clicked`,
                          `SingleProduct_Description_Btn_Clicked`,
                          `SingleProduct`
                        );
                      }}>
                      Description
                    </li>
                    <li
                      className={`tab ${activeTab === "tab3" ? "active" : ""}`}
                      onClick={() => {
                        setActiveTab("tab3")
                        googleAnalyticsFunc(
                          `Store_Details_Btn_Clicked`,
                          `SingleProduct_Store_Details_Btn_Clicked`,
                          `SingleProduct`
                        );
                      }}>
                      Store Details
                    </li>
                    {/* <li
                  className={`tab ${activeTab === "tab4" ? "active" : ""}`}
                  onClick={() => setActiveTab("tab4")}
                >
                  Review
                </li> */}
                  </ul>
                </div>
                <div className="details-container">
                  {activeTab === "tab1" ? (
                    <table className="details-table">
                      <tbody>
                        <tr>
                          <td>Quantity :</td>
                          <td>
                            {showProductDetails?.unitized}
                          </td>
                        </tr>
                        <tr>
                          <td>Brand :</td>
                          <td>
                            {showProductDetails?.tags
                              ?.brand || "N/A"}
                          </td>
                        </tr>
                        {/* <tr>
                      <td>Diet Type :</td>
                      <td>{productDetails?.item_details.tags.type || "-"}</td>
                    </tr> */}
                        <tr>
                          <td>Cancellation Available :</td>
                          <td>
                            {showProductDetails?.cancellable ===
                              "true" ||
                              showProductDetails?.cancellable ===
                              true
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Return Available :</td>
                          <td>
                            {showProductDetails?.returnable ===
                              "true" ||
                              showProductDetails?.returnable ===
                              true
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                  {activeTab === "tab2" ? (
                    <div className="description">
                      {showProductDetails?.item_long_desc}
                    </div>
                  ) : (
                    ""
                  )}
                  {activeTab === "tab3" ? (
                    <table className="details-table">
                      <tbody>
                        <tr>
                          <td>Store Name:</td>
                          <td>{productDetails?.store[0]?.short_desc}</td>
                        </tr>
                        <tr>
                          <td>Store Address :</td>
                          <td>
                            {productDetails?.store[0]?.store_street},
                            {productDetails?.store[0]?.store_city},
                            {productDetails?.store[0]?.store_state},
                            {productDetails?.store[0]?.store_area_code}
                          </td>
                        </tr>
                        <tr>
                          <td>Store Email :</td>
                          <td>{productDetails?.store[0]?.store_email}</td>
                        </tr>
                        <tr>
                          <td>Store Phone :</td>
                          <td>{productDetails?.store[0]?.store_phone}</td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                  {activeTab === "tab4" ? (
                    <div className="reviews-content">No reviews</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="tabs-details-mobile">
                <div className="tab-container-mobile">
                  {/* Product Details Tab */}
                  <div
                    className={`tab-mobile ${
                      activeTabMobile === "tab1" ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTabMobile(
                        activeTabMobile === "tab1" ? null : "tab1",
                      );
                      googleAnalyticsFunc(
                        `Product_Details_Tab_Clicked`,
                        `SingleProduct_Product_Details_Tab_Clicked`,
                        `SingleProduct`
                      );
                    }}>
                    <span>Product Details</span>
                    <span className="arrow-mobile">
                      {activeTabMobile === "tab1" ? (
                        <img src={arrowUp} alt="Arrow Up" />
                      ) : (
                        <img src={arrowDown} alt="Arrow Down" />
                      )}
                    </span>
                  </div>
                  {activeTabMobile === "tab1" && (
                    <div className="details-container-mobile">
                      <table className="details-table-mobile">
                        <tbody>
                          <tr>
                            <td>Quantity :</td>
                            <td>
                              {showProductDetails?.unitized}
                            </td>
                          </tr>
                          <tr>
                            <td>Brand :</td>
                            <td>
                              {showProductDetails?.tags
                                ?.brand || "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>Cancellation Available :</td>
                            <td>
                              {showProductDetails
                                ?.cancellable === "true" ||
                                showProductDetails
                                ?.cancellable === true
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Return Available :</td>
                            <td>
                              {showProductDetails
                                ?.cancellable === "true" ||
                                showProductDetails?.returnable ===
                                true
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  {/* Description Tab */}
                  <div
                    className={`tab-mobile ${
                      activeTabMobile === "tab2" ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTabMobile(
                        activeTabMobile === "tab2" ? null : "tab2",
                      );
                      googleAnalyticsFunc(
                        `Description_Tab_Clicked`,
                        `SingleProduct_Description_Tab_Clicked`,
                        `SingleProduct`
                      );
                    }}>
                    <span>Description</span>
                    <span className="arrow-mobile">
                      {activeTabMobile === "tab2" ? (
                        <img src={arrowUp} alt="Arrow Up" />
                      ) : (
                        <img src={arrowDown} alt="Arrow Down" />
                      )}
                    </span>
                  </div>
                  {activeTabMobile === "tab2" && (
                    <div className="details-container-mobile">
                      <div>
                        {showProductDetails?.item_long_desc}
                      </div>
                    </div>
                  )}

                  {/* Store Details Tab */}
                  <div
                    className={`tab-mobile ${
                      activeTabMobile === "tab3" ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTabMobile(
                        activeTabMobile === "tab3" ? null : "tab3",
                      );
                      googleAnalyticsFunc(
                        `Store_Details_Tab_Clicked`,
                        `SingleProduct_Store_Details_Tab_Clicked`,
                        `SingleProduct`
                      );
                    }}>
                    <span>Store Details</span>
                    <span className="arrow-mobile">
                      {activeTabMobile === "tab3" ? (
                        <img src={arrowUp} alt="Arrow Up" />
                      ) : (
                        <img src={arrowDown} alt="Arrow Down" />
                      )}
                    </span>
                  </div>
                  {activeTabMobile === "tab3" && (
                    <div className="details-container-mobile">
                      <table className="details-table-mobile">
                        <tbody>
                          <tr>
                            <td>Store Name:</td>
                            <td>{productDetails?.store[0]?.short_desc}</td>
                          </tr>
                          <tr>
                            <td>Store Address :</td>
                            <td>
                              {productDetails?.store[0]?.store_street},{" "}
                              {productDetails?.store[0]?.store_city},{" "}
                              {productDetails?.store[0]?.store_state},{" "}
                              {productDetails?.store[0]?.store_area_code}
                            </td>
                          </tr>
                          <tr>
                            <td>Store Email :</td>
                            <td>{productDetails?.store[0]?.store_email}</td>
                          </tr>
                          <tr>
                            <td>Store Phone :</td>
                            <td>{productDetails?.store[0]?.store_phone}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
            {/* <div className="similar-products">
        <h4 className="similar-products-title">
          Similar Items You Might Also Like
        </h4>
        <div className="product-row">
          {sellers.map((seller) => {
            if (
              seller.attributes.store_details.store_name !== selectedSeller
            ) {
              no_items = false;
              return (
                <ProductCard
                  key={product.product_id}
                  data={product}
                  sellerName={seller.attributes.store_details.store_name}
                  id={product.product_id}
                />
              );
            }
          })}
          {no_items ? <p>No similar items available</p> : console.log("")}
        </div>
      </div> */}
          </div>
        ) : (
          <ErrorScreen
            message="No product details available"
            isShowAddAddress={false}
          />
        )
      ) : (
        <div style={{ minHeight: "100vh" }}></div>
      )}
      {productDetails?.store?.[0] && (
        <ReplaceProductPopup
          isVisible={replaceCartModalVisible}
          onClose={() => setReplaceCartModalVisible(false)}
          cartStoreName={cartData?.store_name}
          newItemName={showProductDetails?.item_name}
          newStoreName={productDetails?.store[0]?.store_name}
          onReplace={() => reduceCartItems(true)}
        />
      )}
      {/* <Modal
        open={replaceCartModalVisible}
        onClose={() => setReplaceCartModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="popup-box">
          <img
            src={cross}
            alt="close"
            className="close-cross"
            onClick={() => setReplaceCartModalVisible(false)}
          />
          <h3 className="popup-title">Replace cart item?</h3>
          <p className="popup-content">
            Your cart contains products from <b>{cartData.store_name}</b>. Do
            you want to discard the selection and add{" "}
            {showProductDetails && (
              <>
                <b>{productDetails.store[0].items[0].item_name}</b> from{" "}
                <b>{productDetails.store[0].store_name}</b>?
              </>
            )}
          </p>

          <div className="popup-button-group">
            <button
              className="popup-button popup-reject"
              onClick={() => setReplaceCartModalVisible(false)}
            >
              No
            </button>
            <button
              className="popup-button popup-accept"
              onClick={() => reduceCartItems(true)}
            >
              Replace
            </button>
          </div>
        </div>
      </Modal> */}
    </Fragment>
  );
};

export default SingleProduct;
