import React, { Fragment, useContext, useEffect, useState } from "react";
import "./TicketDetails.scss";
import checkGreen from "../../../src/assets/images/succes-tick.svg";
import thumpsUp from "../../assets/icons/ThumpsUp.png";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { getIssueList, provideFeedbackApi } from "../../redux/actions/Action";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Loader/Spinner";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const TicketDetails = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [issueList, setissueList] = useState(null);
  const [issueWithId, setissueWithId] = useState(null);
  const navigate = useNavigate();
  const [feedbackProvided, setFeedbackProvided] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [issueimageError, setissueImageError] = useState(false);

    
  const handleImageError = () => {
      setImageError(true);
  };
  const handleIssueImgErr=()=>{
    setissueImageError(true)
  }

  const fetchIssues = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(getIssueList());
      if (response.payload.RESP_CODE === 300) {
        setissueList(response.payload.DATA);
        const filteredIssue = response.payload.DATA.find(
          (issue) => issue.issueId === id
        );
        setissueWithId(filteredIssue);
      } else {
        console.error("Error:", response.payload.RESP_MSG);
      }
    } catch (error) {
      console.error("Error fetching issues:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const callprovideFeedbackApi = async (id, rating) => {
    try {
      googleAnalyticsFunc(
        `Provide_Your_Feedback_${rating == 'THUMBS-UP' ? 'THUMBS_UP' : 'THUMBS_DOWN'}}_Tab_Clicked`,
        `TicketDetails_Provide_Your_Feedback_${rating == 'THUMBS-UP' ? 'THUMBS_UP' : 'THUMBS_DOWN'}}_Tab_Clicked`,
        `TicketDetails`
      );
      setIsLoading(true);
      const response = await dispatch(provideFeedbackApi({ id, rating }));
      if (response.payload.RESP_CODE === 300) {
        setFeedbackProvided(true);
        setIsLoading(true);

        await fetchIssues()
      } else {
        console.error("Error:", response.payload.RESP_MSG);
      }
    } catch (error) {
      console.error("Error providing feedback:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchIssues();
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  const handleBackToIssues = () => {
    navigate(`/profile/tickets`, { replace: true });
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
      <>    
      <div className="main-container">
        {/* Header */}
        <div className="header">
          <div className="header-left"> Issue Details</div>
          <div className="header-middle">{issueWithId?.issueStatus}</div>
          <div className="header-right">
            <a onClick={() => {
              handleBackToIssues();
              googleAnalyticsFunc(
                `Back_To_All_Issues_Tab_Clicked`,
                `TicketDetails_Back_To_All_Issues_Tab_Clicked`,
                `TicketDetails`
              );
            }}>Back to all issues</a>
          </div>
        </div>
        <div className="divider"></div>
        <div className="issue-details">
          {/* Success Message */}
          <div className="success-message">
            <div className="title-container">
              <img src={checkGreen} alt="check-green" className="icon-green" />
              <p className="success-title">
                Issue has been reported successfully
              </p>
            </div>
            <div className="id-date-container">
              <p className="issue-id">
                <span className="issue-head-label">Issue ID: </span>
                {id}
              </p>
              <p className="date">
                <span className="issue-head-label">Date: </span>
                {issueWithId?.issueDate}
              </p>
            </div>

            <p className="success-info">
              {issueWithId?.issueStatus?.toLowerCase() === "processing" ||
              issueWithId?.issueStatus?.toLowerCase() === "open"
                ? `Your issued ticket is under ${issueWithId?.issueStatus}, it usually takes 1 to 2 working days. You can reach us on 7486874868 for other queries. Thank you.`
                : `Your issued ticket is ${issueWithId?.issueStatus}. Please check resolved details below.`}
            </p>
          </div>

          {/* Issue Details */}
          <div className="reason-container">
            <div className="reason-details">
              <div className="issue-info">
                <h3 className="issue-label">Category: </h3>
                <p className="issue-desc">{issueWithId?.subCategoryName}</p>
              </div>
              <div className="issue-info">
                <h3 className="issue-label">Issue: </h3>
                <p className="issue-desc issue-short">{issueWithId?.shortDescription}</p>
                <p className="issue-desc">{issueWithId?.longDescription}</p>
                </div>

            </div>
            <div className="issue-photo">
              <h3 className="title">Issue Photo </h3>
              <div className="issues-pic-container">
                <img
                  src={
                    issueimageError || !issueWithId?.issueImage
                      ? itemImageLoader
                      : issueWithId?.issueImage
                  }
                  alt="Issue"
                  className="issues-pic"
                  onError={handleIssueImgErr}
                />
              </div>
            </div>
          </div>

          {/* Product List */}
          <div className="product-list">
            <h3>Products</h3>
            {issueWithId?.itemArray?.map((item, index) => (
              <div className="product-item" key={index}>
                <div className="product-container">
                  <img
                    src={
                      imageError || !item.itemImage
                        ? itemImageLoader
                        : item.itemImage
                    }
                    alt="Product"
                    onError={handleImageError}

                  />
                  <div className="product-qty">
                    <p className="qty-name">{item.itemName}</p>
                    <p className="qty">Qty: {item.itemQuantity}</p>
                  </div>
                  <span className="price-value">₹{item.itemPrice}</span>

                </div>
                {/* <div className="product-price">
                  <span className="price-value">₹{item.itemPrice}</span>
                </div> */}
              </div>
            ))}
          </div>

          {/* Resolver Details */}
          {(issueWithId?.issueStatus?.toLowerCase() === "resolved" ||
            issueWithId?.issueStatus?.toLowerCase() === "closed") && (
            <div className="resolver-details">
              <h3>Resolver Details</h3>
              <div className="resolver-contact">
                <div className="issue-info">
                  <p className="issue-label">Resolver Phone:</p>
                  <p className="issue-desc">{issueWithId?.resolverPhone}</p>
                </div>
                <div className="issue-info">
                  <p className="issue-label">Resolver Email:</p>
                  <p className="issue-desc">{issueWithId?.resolverEmail}</p>
                </div>
                <div className="issue-info">
                  <p className="issue-label">Resolver Short Description:</p>
                  <p className="issue-desc">
                    {issueWithId?.resolutionShortDescription}
                  </p>
                </div>
              </div>

              <div className="issue-info issue-long">
                <p className="issue-label">Resolver Long Description:</p>
                <p className="issue-desc">
                  {issueWithId?.resolutionLongDescription}
                </p>
              </div>

              <div className="resolver-action">
              <div className="issue-info">
                  <p className="issue-label">Resolution Action:</p>
                  <p className="issue-desc">{issueWithId?.resolutionAction}</p>
                </div>
                {issueWithId?.refundAmount && (
                  <div className="issue-info">
                    <p className="issue-label">Refund Amount:</p>
                    <p className="issue-desc">{issueWithId?.refundAmount}</p>
                  </div>
                )}
                  <div className="issue-info">
                  <p className="issue-label">Chat Link:</p>
                  <p className="issue-desc">
                    <a className="sub-link" href={issueWithId?.chatLink}>
                      Open Link
                    </a>
                  </p>
                </div>
              </div>

              {/* <div className="resolver-action">
                <div className="issue-info">
                  <p className="issue-label">Resolution Action:</p>
                  <p className="issue-desc">{issueWithId?.resolutionAction}</p>
                </div>

                {issueWithId?.refundAmount && (
                  <div className="issue-info">
                    <p className="issue-label">Refund Amount:</p>
                    <p className="issue-desc">{issueWithId?.refundAmount}</p>
                  </div>
                )}

                <div className="issue-info">
                  <p className="issue-label">Chat Link:</p>
                  <p className="issue-desc">
                    <a className="sub-link" href={issueWithId?.chatLink}>
                      Open Link
                    </a>
                  </p>
                </div>
              </div> */}
            </div>
          )}

          {/* Feedback */}
          {issueWithId?.issueStatus?.toLowerCase() === "resolved" &&
          !feedbackProvided ? (
            <div className="feedback">
              <h3>Provide your feedback</h3>
              <div className="feedback-buttons">
                <button
                  className="thumbs-up"
                  onClick={() => callprovideFeedbackApi(id, "THUMBS-UP")}
                >
                  <img src={thumpsUp} alt="thumbs-up" />
                </button>
                <button
                  className="thumbs-down"
                  onClick={() => callprovideFeedbackApi(id, "THUMBS-DOWN")}
                >
                  <img src={thumpsUp} alt="thumbs-down" />
                </button>
              </div>
            </div>
          ) : feedbackProvided ? (
            <div className="thank-you">
              <div>Thank you for your feedback!</div>
            </div>
          ) : null}
        </div>
      </div>
      </>
      )}
    </Fragment>
  );
};

export default TicketDetails;
