import React from 'react'
import './SearchSuggestionStore.scss'

function SearchSuggestionStore({ store }) {
    return (
        <div className="search-sugg-store">
            <img src={store.store_image} alt="store-image" className="store-img" />
            <div className="store-info">
                <div className="store-name-row">
                    <h6 className="store-name">{store.store_name}</h6>
                    <div className="store-distance"></div>
                </div>
                <div className="store-profile">Seller</div>
            </div>
        </div>
    )
}

export default SearchSuggestionStore