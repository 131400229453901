import React, { Fragment, useEffect, useState } from 'react';
import './TicketList.scss';
import { useNavigate } from 'react-router-dom';
import arrowRight from "../../assets/icons/arrow-right.svg";
import notfoundicon from "../../assets/images/not-found-icon.png"
import { useAppDispatch } from '../../hooks/useReduxHooks';
import { getIssueList } from '../../redux/actions/Action';
import Spinner from "../../components/Loader/Spinner";
import ErrorScreen from '../../components/ErrorScreen/ErrorScreen';
import { googleAnalyticsFunc } from '../../utils/commonFunctions';

function TicketList() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('All'); // Default to 'All' tab
  const [tickets, setTickets] = useState([]); // State to hold ticket data
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(true);

  const fetchIssues = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(getIssueList());
      if (response.payload.RESP_CODE === 300) {
        const issueData = response.payload.DATA || [];
        const formattedTickets = issueData.map(issue => ({
          id: issue.issueId,
          category: issue.categoryId,
          issue: issue.subCategoryName,
          status: issue.issueStatus,
          date: new Date(issue.issueDate).toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }),
          image: issue.issueImage,
        }));
        setTickets(formattedTickets);
      } else {
        console.error('Error:', response.payload.RESP_MSG);
      }
    } catch (error) {
      console.error('Error fetching issues:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchIssues();
    }, 0);
    return () => clearTimeout(timer);
  }, []);


  const handleTicketDetails = (id) => {
    navigate(`/profile/tickets/${id}`);
  };

  const tabs = ['All', 'Open', 'Resolved', 'Closed'];

  const filterTickets = () => {
    switch (activeTab) {
      case 'All':
        return tickets;
      case 'Open':
        return tickets.filter(ticket =>
          ticket.status.toLowerCase() === 'open' || ticket.status.toLowerCase() === 'processing'
        );
      case 'Resolved':
        return tickets.filter(ticket =>
          ticket.status.toLowerCase() === 'resolved'
        );
      case 'Closed':
        return tickets.filter(ticket =>
          ticket.status.toLowerCase() === 'closed'
        );
      default:
        return tickets;
    }
  };


  const filteredTickets = filterTickets();

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : tickets.length > 0 ? (
        <>
          <div className="ticket-list-container">
            <div className="header">
              <h2>All Issues</h2>
            </div>
            <div className="ticketlist-main-content">
              <div className="tabs">
                {tabs.map((tab) => (
                  <div
                    key={tab}
                    className={`tab ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => {
                      setActiveTab(tab);
                      googleAnalyticsFunc(
                        `Tabs_Switching`,
                        `TicketList_Tabs_Switching`,
                        `TicketList`
                      );
                    }}>
                    {tab}
                  </div>
                ))}
              </div>

              {filteredTickets.length > 0 ? (
                <div className="issue-list">
                  {filteredTickets.map((ticket) => (
                    <div
                      className="issue-card"
                      key={ticket.id}
                      onClick={() => {
                        handleTicketDetails(ticket.id);
                        googleAnalyticsFunc(
                          `Ticket_Details_Clicked`,
                          `TicketList_Ticket_Details_Clicked`,
                          `TicketList`
                        );
                      }}>
                      <div className="issue-id-row"><span className="issue-head-label">ISSUE ID: </span> {ticket.id}</div>
                      <div className="image-row">
                          <img className="issue-image" src={ticket.image} alt="Issue" />
                        <div className="text-column">
                          <p className="issue-category">
                            Category: <span>{ticket.category}</span>
                          </p>
                          <p className="issue-issue">
                            Issue: <span>{ticket.issue}</span>
                          </p>
                        </div>
                        <div className="issue-status">
                          <span className="status-text">{ticket.status}</span>
                          <span className="issue-date">| {ticket.date}</span>
                          <img src={arrowRight} alt="Arrow Icon" className="arrow-icon" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                // <div className="no-issues-tab">
                //   <img src={notfoundicon} alt="No Issues Icon" />
                //   <p>No issues found</p>
                // </div>
                <ErrorScreen message="No issues found"  isShowAddAddress={false}  />

              )}

            </div>
          </div>

          {/* <div className="issue-container">
        <div className="header">
          <h2>All Issues</h2>
        </div>
        <div className="issue-list-container">
          
              <div className="tabs">
                {tabs.map((tab) => (
                  <div
                    key={tab}
                    className={`tab ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
              <div className="divider" />

              {filteredTickets.length > 0 ? (
                <div className="issue-list">
                  {filteredTickets.map((ticket) => (
                    <div
                      className="issue-card"
                      key={ticket.id}
                      onClick={() => handleTicketDetails(ticket.id)}
                    >
                      <div className="issue-id-row">ISSUE ID: {ticket.id}</div>
                      <div className="image-row">
                        <div className="issue-image">
                          <img src={ticket.image} alt="Issue" />
                        </div>
                        <div className="text-column">
                          <p className="issue-category">
                            Category: <span>{ticket.category}</span>
                          </p>
                          <p className="issue-issue">
                            Issue: <span>{ticket.issue}</span>
                          </p>
                        </div>
                        <div className="issue-status">
                          <span className="status-text">{ticket.status}</span>
                          <span className="issue-date">| {ticket.date}</span>
                          <img src={arrowRight} alt="Arrow Icon" className="arrow-icon" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-issues-tab">
                  <img src={notfoundicon} alt="No Issues Icon" />
                  <p>No issues found</p>
                </div>
              )}
           
        </div>
      </div> */}
        </>
      ) : (
        <div className="no-issues">
          <img src={notfoundicon} alt="No Issues Icon" />
          <p>No Issues Found</p>
        </div>
      )}
    </Fragment>
  );
}

export default TicketList;