import { combineReducers } from "redux";
import DeviceInfoReducer from "./AppReducer";
import SendOtpSlice from "./SendOtpSlice";
import GetOrderHistorySlice from "./GetOrderHistorySlice";
import SendRequestForStatusSlice from "./SendRequestForStatusSlice";
import GetAddressBuyerSlice from "./GetAddressBuyerReducer";

const appReducer = combineReducers({
  DeviceInfoReducer,
  SendOtpSlice,
  GetOrderHistory: GetOrderHistorySlice,
  SendRequestForStatus: SendRequestForStatusSlice,
  GetAddressBuyerSlice: GetAddressBuyerSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
