import React from "react";
import "./ImageHeader.scss";
// import HeaderImage from "../../assets/images/homepage/loantap.png";
import HeaderImage from "../../assets/images/homepage/login-banner.jpg";
import HeaderImageMobile from "../../assets/images/homepage/login-banner-mobile.jpg";
import HomeIcon from "../../assets/icons/home.png";
import VectorIcon from "../../assets/icons/vector_gray.png";
import { useNavigate } from "react-router-dom";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const ImageHeader = ({ secondText = "" }) => {
  const navigate = useNavigate();  
  const EventTriggerAndNavigation = () => {
    navigate("/");
    googleAnalyticsFunc(
      `homeIconImage_clicked`,
      `ImageHeader_homeIconImage_clicked`,
      `ImageHeader`
    );
  }
  return (
    <div className="page-heading-banner">
      <div>
        <picture>
          <source
            media="(min-width: 651px)"
            srcSet={HeaderImage}
            className="headerImage"
          />
          <source
            media="(max-width: 650px)"
            srcSet={HeaderImageMobile}
            className="headerImage"
          />
          <img
            src={HeaderImage}
            className="headerImage"
            alt='HeaderImage'
          />
        </picture>
      </div>
      <div className="BreadCumbNavigate">
        <div className="homenav">
          <img
            src={HomeIcon}
            className="homeIconImage"
            alt=""
            onClick={() => EventTriggerAndNavigation()}
          />

          <img src={VectorIcon} className="vectorImage" alt="" />
          <p className="checkOutText">{secondText}</p>
        </div>
      </div>
    </div>
  );
};

export default ImageHeader;
