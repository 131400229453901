import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./CategoryProductList.scss";
import Filter from "../../components/Filter/Filter";
import CardSlider from "../../components/CardSlider/CardSlider";
import ProductCard from "../../components/ProductCard/ProductCard";
import Pagination from "../../components/Pagination/Pagination";
import Spinner, { SmallLoader } from "../../components/Loader/Spinner";
import { Context } from "../../contexts/AppContext";
import { fetchDataFromApi, fetchDataFromStrapi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/ErrorScreen/ErrorScreen";
import { toast } from "react-toastify";
import SelectElement from "../../components/SelectElement/SelectElement";
import IconsContainer from "../../components/IconsContainer/IconsContainer";
import CategoriesDropdown from "../../components/CategoriesDropdown/CategoriesDropdown";
import Offers from "../../components/Offers/Offers";
import searchLens from "../../assets/images/homepage/search-lens.svg";
import locationIcon from "../../assets/icons/locationIcon.png";
import currentlocationIcon from "../../assets/icons/Group.png";
import locationIcon2 from "../../assets/icons/navigate.png";
import { search_Category_With_Pagination } from "../../services/services";
import { AppConstants } from "../../constants/AppContants";
import { categoryImage } from "../../assets/images/category-product-list/grocery_banner.jpg";
import {
  // generateFormattedItemArray,
  getCategoriesForFilter
} from "../../utils/commonFunctions";

const CategoryProductList = () => {
  const pageSize = 50;
  const [searchParams] = useSearchParams();
  const categoryObj = JSON.parse(decodeURIComponent(searchParams.get("cat")));
  const categoryName = categoryObj?.name;
  const { selectedCategory, setSelectedCategory, setCurrentPage } =
    useContext(Context);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryWiseProdutList, setcategoryWiseProdutList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [categoryWiseFilteredProducts, setcategoryWiseFilteredProducts] =
    useState([]);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const observerRef = useRef(null);
  const lastProductRef = useRef(null);
  const toastId = useRef(null);
  const catBannerRef = useRef(null);

  const [renderContent, setRenderContent] = useState(false);
  const [categoriesForFilter, setCategoriesForFilter] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // 1. On category change, URI is changed, URI value used to set category
  useEffect(() => {
    if (categoryName) {
      setRenderContent(false);
      setSelectedCategory(categoryName);
      setCurrentPage(1);
      setcategoryWiseProdutList([]);
      setcategoryWiseFilteredProducts([]);
    }
  }, [categoryName]);

  useEffect(() => {
    if (selectedCategory) {
      setRenderContent(false);
      setCurrentPage(1);
      setcategoryWiseProdutList([]);
      setcategoryWiseFilteredProducts([]);
      getCategoryProductList(1, true);
    }
  }, [selectedCategory]);

  const getCategoryProductList = async (
    page,
    isLoadingReq,
    cityOverride = null,
    previousCityOverride = null,
  ) => {
    try {
      if (toast.isActive(toastId.current)) {
        toast.dismiss(toastId.current);
      }
      if (isLoadingReq) {
        setIsLoading(true);
        setIsFetchingMore(false);
      }

      setIsFetchingMore(true);
      const userLocation = JSON.parse(localStorage.getItem("userLocation"));
      const latitude = userLocation.latitude;
      const longitude = userLocation.longitude;
      let city = cityOverride || userLocation.city?.toLowerCase() || "IND";
      let previousCity = previousCityOverride || "";

      const payload = {
        DATA: {
          page_no: page,
          city: city || "IND",
          gps: latitude + "," + longitude,
          domainName:
            categoryObj.key.toLowerCase().replace(/\s+/g, "") ===
            "beautyandpersonalcare"
              ? "bpc"
              : categoryObj.key.toLowerCase().replace(/\s+/g, ""),
          previous_city: previousCity,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          userId: AppConstants.mobile,
          versioNo: "",
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        },
      };

      const res = await search_Category_With_Pagination(payload);

      if (res.RESP_CODE === 300) {
        if (res.DATA.onSearchData.length === 0) {
          setIsFetchingMore(false);
          if (city !== "IND") {
            await getCategoryProductList(page, isLoadingReq, "IND", city);
          }
          return;
        }
        // catBannerRef.current.style.backgroundImage = `url("../../assets/images/category-product-list/grocery_banner.jpg")`;
        // if (process.env.REACT_APP_ENV !== "production") {
        //   catBannerRef.current.style.backgroundImage = `url(${res.DATA.categoryImage})`;
        // }

        const allItems = res.DATA.onSearchData.reduce((acc, searchData) => {
          const storeItems = searchData.store.reduce((storeAcc, store) => {
            return storeAcc.concat(store.items);
          }, []);
          return acc.concat(storeItems);
        }, []);

        setcategoryWiseProdutList((prevItems) => [...prevItems, ...allItems]);
        setcategoryWiseFilteredProducts((prevItems) => [
          ...prevItems,
          ...allItems,
        ]);
        setRenderContent(true);
      } else {
        setRenderContent(true);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(res.RESP_MSG);
          // onClose: () => {
          //   toastId.current = null;
          // },
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
      setIsFetchingMore(false);
      setRenderContent(true);
    }
  };

  useEffect(() => {
    if (categoryWiseProdutList.length > 0) {
      const uniqueCategories = getCategoriesForFilter(categoryWiseProdutList);
      setCategoriesForFilter(uniqueCategories);
    }
  }, [categoryWiseProdutList]);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      const filteredProducts = categoryWiseFilteredProducts.filter((item) => {
        return selectedCategories.includes(item.category_id);
      });
      setcategoryWiseFilteredProducts(filteredProducts);
    } else {
      setcategoryWiseFilteredProducts(categoryWiseProdutList);
    }
  }, [selectedCategories, categoryWiseProdutList]);

  const updateSelectedCategories = (arr) => {
    setSelectedCategories(arr);
  };


  // 3. page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 4. URL encoding of special characters for API endpoint
  const encodeCategory = (category) => {
    const specialChars = /[&=?]/;
    if (specialChars.test(category)) {
      return encodeURIComponent(category);
    }
    return category;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 1) {
      const filtered = categoryWiseProdutList.filter((product) =>
        product.item_name.toLowerCase().includes(value.toLowerCase()),
      );
      setcategoryWiseFilteredProducts(filtered);
    } else {
      setcategoryWiseFilteredProducts(categoryWiseProdutList);
    }
  };

  useEffect(() => {
    if (categoryWiseFilteredProducts.length !== 0 && lastProductRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setCurrentPage((prevPage) => {
              const nextPage = prevPage + 1;
              getCategoryProductList(nextPage, false);
              return nextPage;
            });
            observer.unobserve(entries[0].target);
          }
        },
        { rootMargin: "330px", threshold: 0 },
      );

      observer.observe(lastProductRef.current);
      observerRef.current = observer;

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }
  }, [categoryWiseFilteredProducts]);

  return (
    <Fragment>
      {loading && <Spinner />}

      <div className="category-product-page">
        <div className="category-banner" ref={catBannerRef}>
          <div className="category-banner-content">
            <h1>{categoryName}</h1>
            {/* <p className="banner-breadcrumb">
              Home{" "}
              <span>
                <img src="/right-arrow.png" className="rightArrow" alt="" />
              </span>{" "}
              {categoryName}{" "}
            </p> */}
          </div>
        </div>
        {renderContent ? (
          <section className="product-list-section">
            <div className="search-sort-row">
              <IconsContainer handleSearchChange={handleSearchChange} />
            </div>
            <hr className="hracpl" />
            <div className="filter-and-product-list">
              <div className="left-content">
                {/* <Filter
                  categories={categoriesForFilter}
                  selectedCategories={selectedCategories}
                  sendSelectedCategories={updateSelectedCategories}
                /> */}
              </div>
              {categoryWiseFilteredProducts.length !== 0 ? (
                <div className="right-content">
                  <div className="selected-filters-row">
                    {["Ober-ear headphones", "headphones"].map(
                      (selectedFilter, index) => (
                        <div className="selected-filter" key={index}>
                          {selectedFilter}
                        </div>
                      ),
                    )}
                  </div>
                  <div className="cardsContainer">
                    {categoryWiseFilteredProducts.map((product, index) => (
                      <ProductCard
                        key={index}
                        data={product}
                        isFromCatrgory={true}
                        ref={
                          index === categoryWiseFilteredProducts.length - 1
                            ? lastProductRef
                            : null
                        }
                      />
                    ))}
                    {isFetchingMore && (
                      <div className="loaderContainer">
                        <SmallLoader />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <ErrorMessage message="No products found" />
              )}
            </div>

            {/* <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                /> */}

            {/* <Offers /> */}
          </section>
        ) : (
          <div 
          style={{ minHeight: "100vh" }}
          ></div>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <img src={locationIcon} width={100} height={100} alt="" />
            <h3>Select a location</h3>

            <div className="search-wrapper ">
              <input
                className="search-input"
                type="text"
                placeholder="Search for your location"
              />
              <img src={searchLens} className="search-lens" alt="search-lens" />
            </div>

            <div className="currentLocation">
              <div className="currentIcon">
                <img src={currentlocationIcon} width={15} alt="" />
                <p>Use your current location</p>
              </div>
              <div className="currentIcon">
                <img src={locationIcon2} width={20} alt="" />
                <p>Locate on Map</p>
              </div>
            </div>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26924.34774929709!2d72.809635638464!3d21.23000080350025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04eeb5c1077c1%3A0xf3db03f8cc74c359!2sKatargam%2C%20Surat%2C%20Gujarat!5e1!3m2!1sen!2sin!4v1730125490968!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ borderRadius: "10px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CategoryProductList;
