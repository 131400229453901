import React from "react";
import SelectElement from "../SelectElement/SelectElement";
import "./IconsContainer.scss";
import filterIcon from "../../assets/icons/FilterIcon.png";

const IconsContainer = ({ handleSearchChange }) => {
  let arr = [
    {
      id: 1,
      key: "Eggs, Meat & Fish",
      name: "Eggs, Meat & Fish",
    },
    {
      id: 2,
      key: "Masala & Seasoning",
      name: "Masala & Seasoning",
    },
    {
      id: 3,
      key: "Snacks, Dry Fruits, Nuts",
      name: "Snacks, Dry Fruits, Nuts",
    },
    {
      id: 4,
      key: "Dals and Pulses",
      name: "Dals and Pulses",
    },
    {
      id: 5,
      key: "Snacks and Namkeen",
      name: "Snacks and Namkeen",
    },
    {
      id: 6,
      key: "Atta, Flours and Sooji",
      name: "Atta, Flours and Sooji",
    },
    {
      id: 7,
      key: "Rice and Rice Products",
      name: "Rice and Rice Products",
    },
    {
      id: 8,
      key: "Cooking and Baking Needs",
      name: "Cooking and Baking Needs",
    },
    {
      id: 9,
      key: "Salt, Sugar and Jaggery",
      name: "Salt, Sugar and Jaggery",
    },
    {
      id: 10,
      key: "Fruits and Vegetables",
      name: "Fruits and Vegetables",
    },
    {
      id: 11,
      key: "Sauces, Spreads and Dips",
      name: "Sauces, Spreads and Dips",
    },
    {
      id: 12,
      key: "Cleaning & Household",
      name: "Cleaning & Household",
    },
    {
      id: 13,
      key: "Fruit Juices and Fruit Drinks",
      name: "Fruit Juices and Fruit Drinks",
    },
    {
      id: 14,
      key: "Chocolates and Biscuits",
      name: "Chocolates and Biscuits",
    },
    {
      id: 15,
      key: "Pasta, Soup and Noodles",
      name: "Pasta, Soup and Noodles",
    },
    {
      id: 16,
      key: "Bakery, Cakes & Dairy",
      name: "Bakery, Cakes & Dairy",
    },
    {
      id: 17,
      key: "Dairy and Cheese",
      name: "Dairy and Cheese",
    },
    {
      id: 18,
      key: "Frozen Snacks",
      name: "Frozen Snacks",
    },
    {
      id: 19,
      key: "Cereals and Breakfast",
      name: "Cereals and Breakfast",
    },
    {
      id: 20,
      key: "Ready to Cook and Eat",
      name: "Ready to Cook and Eat",
    },
    {
      id: 21,
      key: "Oil & Ghee",
      name: "Oil & Ghee",
    },
    {
      id: 22,
      key: "Tea and Coffee",
      name: "Tea and Coffee",
    },
    {
      id: 23,
      key: "Energy and Soft Drinks",
      name: "Energy and Soft Drinks",
    },
    {
      id: 24,
      key: "Pickles and Chutney",
      name: "Pickles and Chutney",
    },
    {
      id: 25,
      key: "Indian Sweets",
      name: "Indian Sweets",
    },
  ];

  return (
    <div className="searchMainDiv">
      <div className="search-container">
        <img src="/search.png" className="searchIcon" alt="" />
        <input
          type="search"
          className="searchInputs"
          placeholder="Search Products...."
          onChange={handleSearchChange}
        />
      </div>
      {/* <div className="filterIcon">
        <img src={filterIcon} alt="filterIcon" />
      </div>
      <div className="manuIcon">
        <img src="/menu_2.png" className="menuIcons" alt="" />
      </div>
      <div className="manuIcon">
        <img src="/menu.png" className="menuIcons" alt="" />
      </div>
      <div className="manuIcon">
        <img src="/list.png" className="menuIcons" alt="" />
      </div>
      <div className="manuIcon">
        <img src="/dashboard.png" className="menuIcons" alt="" />
      </div>
      <SelectElement
        id={`category-search`}
        selectElementOptionList={arr}
        handleOptionSelect={(option) => console.log(option)}
        selectedValue={""}
        placeholder={`Sort By`}
      /> */}
    </div>
  );
};

export default IconsContainer;
