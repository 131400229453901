import { encode } from "base-64";
import CryptoJS from "crypto-js";

export const getRandomNumber = () => {
  const randomStringLength = 100;
  let randomNumberString = "";
  while (randomNumberString.length < randomStringLength) {
    randomNumberString += Math.floor(Math.random() * 10);
  }
  return randomNumberString;
};

export const getKeyFromRandomNumber = (num) => {
  return num?.slice?.(0, 4) + num?.slice?.(num?.length - 4, num?.length);
};

export const sendData = (data) => {
  let num1 = getRandomNumber();
  let num2 = getRandomNumber();
  let key = getKeyFromRandomNumber(num1) + getKeyFromRandomNumber(num2);
  const encoded = encode(key);
  let dataString = JSON.stringify(data);
  let encodedKey = CryptoJS.enc.Base64.parse(encoded);
  let encrypted = CryptoJS.AES.encrypt(dataString, encodedKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return num1 + ":" + encrypted + ":" + num2;
};

export const getData = (encryptedData) => {
  let { key, content } = getKey(encryptedData);
  let decrypted = CryptoJS.AES.decrypt(
    content,
    CryptoJS.enc.Base64.parse(encode(key)),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const getKey = (data) => {
  let dataArr = data?.split(":");
  let firstKeyNumber = getKeyFromRandomNumber(dataArr[0]);
  let secondKeyNumber = getKeyFromRandomNumber(dataArr[2]);
  let key = firstKeyNumber + secondKeyNumber;
  let content = dataArr[1];
  return { key, content };
};

export const formatOrderDate = (orderDate) => {
  const [datePart] = orderDate.split(" ");
  const [day, month, year] = datePart.split("-");

  const date = new Date(`${year}-${month}-${day}`);

  return date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

export const getQueryParamURIComponent = (object) => {
  const secretKey = "mySecretKey";
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(object), secretKey).toString();
  return encodeURIComponent(encryptedData);
} 

export const getDecryptedQueryData = (queryParamURIComponent) => {
  const secretKey = "mySecretKey";
  const decodedData = decodeURIComponent(queryParamURIComponent);
const decryptedData = CryptoJS.AES.decrypt(decodedData, secretKey).toString(CryptoJS.enc.Utf8);
return JSON.parse(decryptedData);
}
