import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./FestiveCategory.scss";
import { useLocation, useParams } from "react-router-dom";
import { AppConstants } from "../../constants/AppContants";
import { Context } from "../../contexts/AppContext";
import { get_festive_data } from "../../services/services";
import { toast } from "react-toastify";
import Spinner from "../../components/Loader/Spinner";
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";
// import Filter from "../CategoryProductList/Filter/Filter";
import IconsContainer from "../../components/IconsContainer/IconsContainer";
import ProductCard from "../../components/ProductCard/ProductCard";
// import { generateFormattedItemArray } from "../../utils/commonFunctions";

const FestiveCategory = () => {
  const { userLocation } = useContext(Context);
  const catBannerRef = useRef(null);
  const { festiveid, catid } = useParams();
  const [loading, setIsLoading] = useState(true);
  const [categoryData, setCategoryData] = useState(null);
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [itemwiseFilteredProducts, setitemwiseFilteredProducts] = useState([]);
  const location = useLocation();
  const { eventName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get("dataWithcatgid");

  let parsedData = {};

  const decodedData = decodeURIComponent(encodedData);
  parsedData = JSON.parse(decodedData);

  useEffect(() => {
    getFestiveseasonDataAsPercategory();
  }, [festiveid, catid]);

  const getFestiveseasonDataAsPercategory = async () => {
    try {
      setIsLoading(true);
      const payload = {
        DATA: {
          gps: `${userLocation.latitude}, ${userLocation.longitude}`,
          festival_name: parsedData.festiveId,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          userId: AppConstants.mobile,
          versioNo: "",
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        },
      };

      const res = await get_festive_data(payload);

      if (res.RESP_CODE === 300 && res.DATA) {
        const filteredItems = res.DATA.items.filter(
          (item) => item.category_id?.toLowerCase() === parsedData.catid?.toLowerCase()
        );

        if (filteredItems.length > 0) {
          setCategoryData({
            name: filteredItems[0].category_displayname,
            image: filteredItems[0].category_image,
          });
          setItems(filteredItems);
          let festiveData = {
            items: filteredItems
          }
          // let formattedItems = generateFormattedItemArray(festiveData)
          setitemwiseFilteredProducts(filteredItems);
        }
      } else {
        toast.error(res.RESP_MSG);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (catBannerRef.current && categoryData) {
      catBannerRef.current.style.backgroundImage = `url(${categoryData.image})`;
    }
  }, [categoryData]);
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 1) {
      const filtered = items.filter((product) =>
        product.item_name.toLowerCase().includes(value.toLowerCase())
      );
      setitemwiseFilteredProducts(filtered);
    } else {
      setitemwiseFilteredProducts(itemwiseFilteredProducts);
    }
  };

  return (
    <Fragment>
      {loading && <Spinner />}
      <div className="festive-product-page">
        {/* <div className="festive-banner" ref={catBannerRef}> */}
        <div className="festive-banner">
          <div className="festive-banner-content">
            <h1>{categoryData ? categoryData.name : parsedData.catid}</h1>
          </div>
        </div>
        <section className="festive-list-section">
          <div className="search-sort-row">
            <IconsContainer handleSearchChange={handleSearchChange} />
          </div>
          <hr className="hracpl" />
          <div className="filter-and-product-list">
            <div className="left-content">
              {/* <Filter /> */}
            </div>
            {itemwiseFilteredProducts.length !== 0 ? (
              <div className="right-content">
                <div className="selected-filters-row">
                  {["Ober-ear headphones", "headphones"].map(
                    (selectedFilter, index) => (
                      <div className="selected-filter" key={index}>
                        {selectedFilter}
                      </div>
                    )
                  )}
                </div>
                <div className="cardsContainer">
                  {itemwiseFilteredProducts.map((product, index) => (
                    <ProductCard
                      key={index}
                      data={product}
                      isFromCatrgory={true}
                      ref={null}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <ErrorScreen message="No products found" />
            )}
          </div>

          {/* <Offers /> */}
        </section>
      </div>
    </Fragment>
  );
};

export default FestiveCategory;
