import React, { Fragment, useEffect, useRef, useState } from "react";
import "./OrderHistory.scss";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { GetOrderHistory } from "../../redux/actions/Action";
import { formatOrderDate } from "../../utils/encryption";
import { useNavigate } from "react-router-dom";
import { formatOrderHistoryDate, googleAnalyticsFunc } from "../../utils/commonFunctions";
import OrderHistoryCard from '../../components/OrderHistoryCard/OrderHistoryCard';
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";
import Spinner from "../../components/Loader/Spinner";

function OrderHistory({ usedAt }) {
  const observerRef = useRef(null);
  const desktopLastOrderRef = useRef(null);
  const mobileLastOrderRef = useRef(null);
  const wrapperRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchOrders = async (page) => {
    if (!hasMore) return;
    try {
      setIsLoading(true);
      const response = await dispatch(GetOrderHistory(page));
      const fetchedOrders = response?.payload?.DATA?.orderDetails;

      if (fetchedOrders?.length > 0) {
        if (usedAt === 'dashboard') {
          setHasMore(false);
          setOrders(fetchedOrders.slice(0, 5));
          return;
        }

        setOrders((prevOrders) => [...prevOrders, ...fetchedOrders]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
      // setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (orders.length !== 0 && (desktopLastOrderRef.current || mobileLastOrderRef.current)) {

      let observer;

      if (!observerRef.current) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting && hasMore) {
                setCurrentPage((prevPage) => prevPage + 1);
                observer.unobserve(entry.target);
              }

            })
          },
          {
            root: wrapperRef.current,
            rootMargin: "0px",
            threshold: 0,
          }
        );
        observerRef.current = observer;
      } else {
        observer = observerRef.current;
      }

      // Observe both elements
      if (desktopLastOrderRef.current) {
        observer.observe(desktopLastOrderRef.current);
      }
      if (mobileLastOrderRef.current) {
        observer.observe(mobileLastOrderRef.current);
      }

      return () => {
        // Cleanup
        if (desktopLastOrderRef.current) {
          observer.unobserve(desktopLastOrderRef.current);
        }
        if (mobileLastOrderRef.current) {
          observer.unobserve(mobileLastOrderRef.current);
        }
        observer.disconnect();
      };
    }

  }, [orders]);

  return (
    <Fragment>
  {usedAt !== "dashboard" && isLoading && <Spinner />}

    <div className="order-history">
      {usedAt === 'dashboard' ?
        <div className="order-header">
          <h1>Recent Order History</h1>
          <p
            href="#"
            className="view-all"
            onClick={
              () => {
                navigate("/profile/order-history");
                googleAnalyticsFunc(
                  `View_All_Btn_Clicked`,
                  `OrderHistory_View_All_Btn_Clicked`,
                  `OrderHistory`
                );
            }}
          >
            View All
          </p>
        </div>
        :
        <div className="order-header">
          <h1>Order History</h1>
        </div>
      }
      {orders.length !== 0 ?
        <div className="history-table-wrapper" ref={wrapperRef}>
          <div className="grid-wrapper">
            {orders.length !== 0 &&
              orders?.map((order, index) => (
                <OrderHistoryCard
                  order={order}
                  cardRef={index === orders.length - 1 ? mobileLastOrderRef : null}
                  key={order.orderReferenceNumber}
                />
              ))
            }
          </div>
          <table className="history-table">
            <thead>
              <tr className="history-head-row">
                <th>ORDER ID</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.length !== 0 &&
                orders?.map((order, index) => (
                  <tr
                    key={order.orderReferenceNumber}
                    className="history-body-row"
                    ref={(index === orders.length - 1) && usedAt !== 'dashboard' ? desktopLastOrderRef : null}
                  >
                    <td>{order.orderReferenceNumber}</td>
                    <td>{formatOrderHistoryDate(order.orderDate)}</td>
                    <td>
                      <div className="order-products">
                        <span className="order-amount">₹{order.quote.totalPrice.toFixed(2)}</span>
                        <span className="order-quantity">{" "}(
                          {order.itemArray.reduce(
                            (sum, item) => sum + item.itemQuantity,
                            0
                          )}{" "}
                          Product)</span>
                      </div>
                    </td>
                    <td>
                      {
                        order.fulfillmentStatus ?
                          order.fulfillmentStatus
                            .toLowerCase()
                            .replace(/\b\w/g, (char) => char.toUpperCase())
                          :
                          "Pending"
                      }
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/profile/order-history/${order.orderReferenceNumber}`
                        );
                        googleAnalyticsFunc(
                          `View_Details_Btn_Clicked`,
                          `OrderHistory_View_Details_Btn_Clicked`,
                          `OrderHistory`
                        );
                      }}
                      className="view-details"
                    >
                      View Details
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {hasMore && <p className="loading-text">loading more orders...</p>}
        </div>
        :
        <ErrorScreen message="No Order History Found" isShowAddAddress={false} />
      }

    </div>
    </Fragment>
  );
}

export default OrderHistory;
