import React from "react";
import "./Bredcrumb.scss";
import { useNavigate } from "react-router-dom";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const Breadcrumb = ({ category, prodName }) => {
  const navigate = useNavigate();

  const EventTriggerAndNavigation = () => {
    navigate("/");
    googleAnalyticsFunc(
      `Home_Tab_clicked`,
      `Breadcrumb_Home_Tab_clicked`,
      `Breadcrumb`
    );
  }

  return (
    <div className="breadcrumb">
      {category ? (
        <span onClick={() => EventTriggerAndNavigation()}>
          {/* Category should redirect to category page */}
          <u>Home</u><img className="rightArrow" src="/right-arrow.png" alt="" />{category}<img className="rightArrow" src="/right-arrow.png" alt="" /> 
        </span>
      ) : (
        <span onClick={() => EventTriggerAndNavigation()}><u>Home</u><img className="rightArrow" src="/right-arrow.png" alt="" /></span>
      )}
      <span className="product-name">{prodName}</span>
    </div>
  );
};

export default Breadcrumb;
