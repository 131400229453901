import { HTTPS } from "./HTTPS";

const BASE_URL = process.env.REACT_APP_BACKEND_ENCRYPT_MODULE_PATH;
const NO_ENCRYPTION_BASE_URL = process.env.REACT_APP_BACKEND_NO_ENCRYPT_MODULE_PATH;

// 1. OTP
export const send_otp = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/consumerGenrateOtp`, PAYLOAD);
};

export const verify_otp = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/consumerVerifyOtp`, PAYLOAD);
};

// 2. Registration
export const save_details = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/consumerSaveDetails`, PAYLOAD);
};

// 3. ADDRESS
export const get_address_buyer = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/getAddressBuyer`, PAYLOAD);
};

export const add_address = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/updateAddressBuyer`, PAYLOAD);
};

export const request_for_update_and_deleteaddress = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/updateAndDeleteAddress`, PAYLOAD);
};

// 4. CART
export const add_to_cart = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/cosumerAddEntryInCart`, PAYLOAD);
};

export const remove_cart = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/removeEntryFromCart`, PAYLOAD);
};

export const get_cart = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/getCartEntry`, PAYLOAD);
};

// 5. ORDERS
export const get_order_history = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/orderedHistoryWithPagination`, PAYLOAD);
};

export const get_order_details = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/orderedListHistory`, PAYLOAD);
};

// 6. ORDER DETAILS
export const send_request_for_status = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/sendRequestForStatusV2`, PAYLOAD);
};

export const send_request_for_cancel = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/sendRequestForCancelV2`, PAYLOAD);
};

// 7. CHECKOUT
export const send_request_for_select = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/sendRequestForSelectV2`, PAYLOAD);
};

export const send_request_for_init = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/sendRequestForInitV2`, PAYLOAD);
};

export const send_request_for_confirm = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/processPaymentReqPayU`, PAYLOAD);
};

export const check_payment_status = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/payu_status_check`, PAYLOAD);
};

export const get_issue_category = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/getCategoryListForIssue`, PAYLOAD);
};


export const send_Request_For_Submit_Issue = (PAYLOAD) => {
  return HTTPS(
    `${NO_ENCRYPTION_BASE_URL}/sendRequestForIssueV2`,
    PAYLOAD,
    false,
  ); // `false` for no encryption
};
export const get_issue_list = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/getIssueList`, PAYLOAD);
};

export const get_google_address_predictions = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/apiAutocomplete`, PAYLOAD);
}

export const get_google_address_from_place_id = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/apiPlaceDetail`, PAYLOAD);
}

export const get_google_address_form_lat_long = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/apiGeocode`, PAYLOAD);
}
export const get_cancellation_reasons = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/getCancellationReasons`, PAYLOAD);
};
export const provide_feedback_api = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/sendRequestToCloseIssueRetail`, PAYLOAD);
};

export const get_search_results = (PAYLOAD) => {
  return HTTPS(`${NO_ENCRYPTION_BASE_URL}/searchByItemAndStroreWithPagination`, PAYLOAD, false);
};

export const get_categories = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/getAllProductSubCategoriesV2`, PAYLOAD);
};
export const search_store_by_provider_id = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/searchStoreByProviderId`, PAYLOAD);
}
export const search_Category_With_Pagination = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/searchByDomainNameWithPagination`, PAYLOAD);
}
export const search_productDtl_by_id = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/searchByItemId`, PAYLOAD);
}
export const get_promotion_banner = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/get-promotion-banner`, PAYLOAD);
}
export const get_festive_data = (PAYLOAD) => {
  return HTTPS(`${BASE_URL}/fetchTheFestiveSeasonData`, PAYLOAD);
}
