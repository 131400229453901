import React, { useRef, useState, useEffect } from "react";
import "./CategoryBrandSection.scss";
import buttonnext from "../../assets/icons/button-next.svg";
import buttoneback from "../../assets/icons/buttone-back.svg";
import { useNavigate } from "react-router-dom";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
import { getQueryParamURIComponent } from "../../utils/encryption";

const CategoryBrandSection = ({ categories, brands, heading, isTopDeal }) => {
  const categoryScrollRef = useRef(null);
  const brandScrollRef = useRef(null);
  const [cardWidth, setCardWidth] = useState();
  const [rotatedBrands, setRotatedBrands] = useState(brands || []);
  const [isPaused, setIsPaused] = useState(false);
  // const scrollSpeed = 1;
  const [visibleForButton, setVisibleForButton] = useState(false);
  const [visibleBackButton, setVisibleBackButton] = useState(false);

  const navigate = useNavigate();

  //Visibility of scroll button
  const updateButtonVisibility = () => {
    if (!categoryScrollRef.current) return;
    const scrollLeft = categoryScrollRef.current.scrollLeft;
    const scrollWidth = categoryScrollRef.current.scrollWidth;
    const clientWidth = categoryScrollRef.current.clientWidth;

    setVisibleBackButton(scrollLeft > 1);
    setVisibleForButton(scrollLeft + clientWidth < scrollWidth - 1);
  };

  useEffect(() => {
    if (categories?.length > 0) {
      const scrollWidth = categoryScrollRef.current.scrollWidth;
      const clientWidth = categoryScrollRef.current.clientWidth;
      setVisibleForButton(scrollWidth > clientWidth);
    }

    const ref = categoryScrollRef.current;
    ref.addEventListener("scroll", updateButtonVisibility);
    return () => ref.removeEventListener("scroll", updateButtonVisibility);
  }, [categories]);

  useEffect(() => {
    setRotatedBrands(brands || []);
  }, [brands]);

  useEffect(() => {
    const updateCardWidth = () => {
      if (categoryScrollRef.current) {
        const card =
          categoryScrollRef.current.querySelector(".category-banner");
        if (card) {
          setCardWidth(card.offsetWidth);
        }
      }
    };

    updateCardWidth();
    window.addEventListener("resize", updateCardWidth);
    return () => window.removeEventListener("resize", updateCardWidth);
  }, []);

  // Auto-rotation
  // useEffect(() => {
  //   let animationFrameId;

  //   const autoScroll = () => {
  //     if (brandScrollRef.current && !isPaused) {
  //       brandScrollRef.current.scrollLeft += scrollSpeed;
  //       if (
  //         brandScrollRef.current.scrollLeft >=
  //         brandScrollRef.current.scrollWidth -
  //           brandScrollRef.current.clientWidth
  //       ) {
  //         brandScrollRef.current.scrollLeft = 0;
  //       }
  //     }
  //     animationFrameId = requestAnimationFrame(autoScroll);
  //   };

  //   animationFrameId = requestAnimationFrame(autoScroll);
  //   return () => cancelAnimationFrame(animationFrameId);
  // }, [isPaused]);

  const scrollBtnHandler = (ref, direction) => {
    if (!ref.current) return;

    const scrollAmount = cardWidth;
    if (direction === "left") {
      ref.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    } else {
      ref.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const redirectToStore = (imageData) => {
    googleAnalyticsFunc(
      `redirectToStore_Tab_clicked`,
      `TopRatedStore_redirectToStore_Tab_clicked`,
      `TopRatedStore`
    );

    const storeDetails =
      process.env.REACT_APP_ENV === "production"
        ? {
            bpp_id: imageData.ACTION,
            city: "IND",
            domain: "All",
          }
        : {
            bpp_id: "sellerpreprod.easypay.co.in",
            city: "Pune",
            domain: "grocery",
          };
    const queryParamValue = getQueryParamURIComponent(storeDetails);
    const store_id =
      process.env.REACT_APP_ENV === "production" ? imageData.REDIRECT_URL : 101;
    navigate("/store-products/" + store_id + `?sd=${queryParamValue}`);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateButtonVisibility);
    return () => window.removeEventListener("scroll", updateButtonVisibility);
  }, [categories]);

  useEffect(() => {
    if (brandScrollRef.current) {
      const brandWidth = brandScrollRef.current.scrollWidth;

      const duration = brandWidth / 100 + "s";

      brandScrollRef.current.style.animationDuration = duration;
    }
  }, [rotatedBrands]);

  return (
    <div className="row-container category-brand-section">
      <h1 className="category-title">{heading}</h1>
      <div className="category-scroll-wrapper">
        {visibleBackButton && (
          <button
            className="scroll-btn left"
            onClick={() => scrollBtnHandler(categoryScrollRef, "left")}
          >
            <img src={buttoneback} alt="next" />
          </button>
        )}
        <div className="category-scroll" ref={categoryScrollRef}>
          {categories.map((category) => (
            <img
              src={category.URL}
              alt="Banner 1"
              className={`category-banner ${
                isTopDeal ? "top-deal-banner" : ""
              }`}
              onClick={() => redirectToStore(category)}
            />
          ))}
        </div>
        {visibleForButton && (
          <button
            className="scroll-btn right"
            onClick={() => scrollBtnHandler(categoryScrollRef, "right")}
          >
            <img src={buttonnext} alt="next" />
          </button>
        )}
      </div>

      <div
        className="brand-scroll-wrapper"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <div
          className={`brand-scroll ${isPaused ? "paused" : ""}`}
          ref={brandScrollRef}
        >
          {[
            ...rotatedBrands,
            ...rotatedBrands,
            ...rotatedBrands,
            ...rotatedBrands,
            ...rotatedBrands,
            ...rotatedBrands,
          ].map((brand, index) => (
            <img
              key={index}
              src={brand.URL}
              alt={brand.alt}
              className="brand-logo"
              onClick={() => redirectToStore(brand)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryBrandSection;
