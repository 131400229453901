import { createSlice } from '@reduxjs/toolkit';
import { GetAddressBuyer } from '../actions/Action';

const initialState = {
    RESPONSE: false,
    RESP_MSG: "",
    data: [],
};

const GetAddressBuyerSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAddressBuyer.pending, (state) => {
                state.RESPONSE = true;
            })
            .addCase(GetAddressBuyer.fulfilled, (state, { payload }) => {
                state.RESPONSE = false;
                state.RESP_MSG = payload.RESP_MSG;
                state.data = payload.DATA;
            })
            .addCase(GetAddressBuyer.rejected, (state, { payload }) => {
                state.RESPONSE = false;
                state.RESP_MSG = payload.RESP_MSG;
            });
    },
});

export default GetAddressBuyerSlice.reducer;
