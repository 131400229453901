import React from "react";
import Modal from "@mui/material/Modal";
import "./LocationInstruction.scss";
import cross from "../../assets/icons/cross-for-close.svg";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";


const LocationInstruction = ({ open, onAccept, title, content }) => {
  const EventTriggerAndNavigation = (btnName) => {
    onAccept();
    googleAnalyticsFunc(
      `${btnName}_button_clicked`,
      `LocationInstruction_${btnName}_button_clicked`,
      `LocationInstruction`
    );
  }
  return (
    <Modal open={open}
    >
      <div className="popup-box">
      <img
            src={cross}
            alt="close"
            className="close-cross"
            onClick={() => EventTriggerAndNavigation('Close')}
          />
        <h2 className="popup-title">{title}</h2>
        <div className="popup-content">{content}</div>
        <div className="popup-button-group">
          <button className="popup-button popup-accept" onClick={() => EventTriggerAndNavigation('Okay')}>
            Okay
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LocationInstruction;
