import React, { Fragment, useContext, useEffect, useState } from "react";
import "../Checkout/Checkout.scss";
import {
  GetAddressBuyer,
  sendRequestForConfirm,
  sendRequestForInit,
  sendRequestForSelect,
} from "../../redux/actions/Action";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { Context } from "../../contexts/AppContext";
import { toast } from "react-toastify";
import {
  AppConstants,
  generateId,
  generateIdWithoutHyphens,
} from "../../constants/AppContants";
import { useNavigate } from "react-router-dom";
import AddAddress from "../AddAddress/AddAddress";
import { useJsApiLoader } from "@react-google-maps/api";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const librariesArray = ["places", "geometry"];

export const Checkout = () => {
  const navigate = useNavigate();
  const { cartData, setLoading } = useContext(Context);
  const dispatch = useDispatch();
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [shipping, setShipping] = useState(null);
  const [tax, setTax] = useState(null);
  const [packingCharges, setPackingCharges] = useState(null);
  const [discountCharges, setDiscountCharges] = useState(null);
  const [miscCharges, setMiscCharges] = useState(null);
  const [totalItemPrice, setTotalPrice] = useState(null);
  const [init, setInit] = useState(null);
  const [select, setSelect] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addConfirmOrder, setAddConfirmOrder] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [canPlaceOrder, setCanPlaceOrder] = useState(false);
  const handleImageError = () => {
    setImageError(true);
  };

  // Why??
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: librariesArray,
  });
  const { data } = useAppSelector((state) => state.GetAddressBuyerSlice);

  useEffect(() => {
    dispatch(GetAddressBuyer());
  }, [dispatch, addressList]);

  const handleAddressSelection = async (index, address) => {
    setCanPlaceOrder(false);
    setSelectedAddressId(index);
    setSelectedAddress(address);
    setLoading(true);
    let gpsAdd = address?.latitude + "," + address?.longitude;
    const selectPayload = {
      transactionId: cartData?.transactionId,
      bppId: cartData?.bppId,
      bppUrl: cartData?.bppUrl,
      city: cartData?.city,
      storeId: cartData?.storeId,
      locationId: cartData?.locationId,
      gps: gpsAdd,
      store_area_code: cartData?.store_area_code,
      domain: cartData?.domain,
      items:
        cartData?.items?.map((value) => ({
          itemId: value.itemId,
          count: value.count,
        })) || [],
    };
    const resSelect = await dispatch(sendRequestForSelect(selectPayload));
    if (
      resSelect?.payload?.RESP_CODE !== 300 &&
      (resSelect?.payload?.RESPONSE === "FAILED" ||
        resSelect?.payload?.RESPONSE === "Response not received")
    ) {
      setLoading(false);
      toast.error(resSelect?.payload?.RESP_MSG || "Address selection failed.");
      navigate("/checkout"); // why??
    } else if (resSelect?.payload?.RESP_CODE === 300) {
      setSelect(resSelect?.payload?.DATA);
      const initPayload = {
        domain: cartData?.domain,
        transactionId: resSelect.payload?.DATA?.transactionId,
        bppId: cartData?.bppId,
        bppUrl: cartData?.bppUrl?.endsWith("/")
          ? cartData.bppUrl
          : `${cartData?.bppUrl}/`,
        city: cartData?.city,
        storeId: cartData?.storeId,
        providerId: resSelect.payload?.DATA?.provider?.id,
        locationId: resSelect.payload?.DATA?.provider?.location,
        gps: gpsAdd.trim(),
        store_area_code: address?.pincode,
        customer_name: address?.name,
        customer_phone: address?.mobile,

        items:
          cartData?.items?.map((value) => ({
            itemId: value.itemId,
            count: value.count,
          })) || [],
        fulfillment_id: resSelect.payload?.DATA?.fulfillments?.[0]?.id,
        fulfillment_type:
          resSelect.payload?.DATA?.fulfillments?.[0]?.categoryName,
        fulfillment_tracking:
          resSelect.payload?.DATA?.fulfillments?.[0]?.tracking,
        fulfillment_gps: gpsAdd,
        customerAddress: {
          addressId: address?.addressId,
          door: address?.houseNo,
          name: address?.addressOne,
          locality: address?.area,
          city: address?.city,
          state: address?.state,
          country: "India",
          area_code: address?.pincode,
        },
      };
      setLoading(true);

      //Make init call
      try {
        const secondApiResponse = await dispatch(
          sendRequestForInit(initPayload),
        );

        if (secondApiResponse?.payload?.RESP_CODE === 300) {
          setInit(secondApiResponse?.payload?.DATA);
          const breakUp =
            secondApiResponse?.payload?.DATA?.message?.quote?.break_up || [];
          const totalPrice =
            secondApiResponse?.payload?.DATA?.message?.quote?.total_price;
          setTotalPrice(totalPrice);
          const deliveryCharges =
            breakUp.find((item) => item.title_type === "delivery")?.price || 0;
          const miscCharges =
            breakUp.find((item) => item.title_type === "misc")?.price || 0;
          const packingTotal =
            breakUp.find((item) => item.title_type === "packing")?.price || 0;
          const discountCharges =
            breakUp.find((item) => item.title_type === "discount")?.price || 0;
          const taxCharges =
            breakUp.find((item) => item.title_type === "tax")?.price || 0;
          setCanPlaceOrder(true);
          const shippingCharges = parseFloat(deliveryCharges);
          const tax = parseFloat(taxCharges);
          const packing = parseFloat(packingTotal);
          const discount = parseFloat(discountCharges);
          const misc = parseFloat(miscCharges);

          setShipping(shippingCharges);
          setTax(tax);
          setPackingCharges(packing);
          setDiscountCharges(discount);
          setMiscCharges(misc);
          setLoading(false);
        } else {
          toast.error(
            secondApiResponse?.payload?.RESP_MSG ||
              "Fail to fetch shipping cost",
          );
          navigate("/checkout");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error during the second API call" || error);
        navigate("/checkout");
      }
    } else {
      setLoading(false);
      toast.error(
        resSelect?.payload?.RESP_MSG || "Failed to process address selection.",
      );
      navigate("/checkout");
    }
  };
  const handlePayNow = async () => {
    googleAnalyticsFunc(
      `Place_Order_Btn_Clicked`,
      `Checkout_Place_Order_Btn_Clicked`,
      `Checkout`
    );
    try {
      setLoading(true);
      const msgID = generateId();
      const refID = generateIdWithoutHyphens();
      const dataObj = {
        ORDER_ID: refID,
        imei: "",
        UDID: AppConstants.UDID,
        mobileip: AppConstants.IP_ADDRESS,
        macAddress: AppConstants.MAC_ADDRESS,
        devicedtl: AppConstants.devicedtl,
        deviceOS: "WEB",
        mobileNumber: AppConstants.mobile,
        CN: "ONDC",
        BILLER_REF_NO: refID,
        transactionId: init?.context?.transaction_id,
        messageId: msgID,
        itemArray: [
          {
            bppId: cartData?.bppId,
            bppUrl: cartData?.bppUrl,
            domain: cartData?.domain,
            created_at: init?.message?.created_at,
            updated_at: init?.message?.updated_at,
            city: cartData?.city,
            state: cartData?.store_state,
            providerId: select?.provider?.id,
            locationId: select?.provider?.location,
            customer_name: AppConstants.NAME,
            customer_email: AppConstants.EMAIL,
            customer_phone: AppConstants.mobile,
            sellerId: cartData?.storeId,
            sellerCode: cartData?.store_area_code,
            orderState: "Serviceable",
            categoryName: "",
            storeName: cartData?.store_name,
            expStart: cartData?.store_start,
            expEnd: cartData?.store_end,
            totalItemsQuantity: cartData?.items?.length,
            items: cartData?.items?.map((item, index) => ({
              id: item?.itemId,
              fulfillment_id: select?.fulfillments[0]?.id,
              count: item?.count,
              itemName: item?.item_name,
              itemImage: item?.item_image,
              itemPrice: item?.price,
              returnable: item?.returnable,
              cancellable: item?.cancellable,
              returnWindow: item?.return_window,
              ondcreturnwindow: item?.ondcreturnwindow,
            })),
            customer_billing_address: {
              addressId: selectedAddress?.addressId,
              door: init?.message?.customer_billing_address?.building,
              ...init?.message?.customer_billing_address,
            },
            customer_shipping_address: {
              addressId: selectedAddress.addressId,
              door: init?.message?.fulfillments?.[0]?.customer_shipping_address
                ?.building,
              ...init?.message?.fulfillments?.[0]?.customer_shipping_address,
            },
            fulfillments: [
              {
                fulfillment_id:
                  init?.message?.fulfillments?.[0]?.fulfillment_id,
                fulfillment_type:
                  init?.message?.fulfillments?.[0]?.fulfillment_type,
                fulfillment_tracking:
                  init?.message?.fulfillments?.[0]?.fulfillment_tracking,
                fulfillment_provider_id:
                  init?.message?.fulfillments?.[0]?.fulfillment_provider_id,
                "@ondc/org/TAT": select?.fulfillments[0]?.["@ondc/org/TAT"],
                fulfillment_email: AppConstants.EMAIL,
                fulfillment_phone:
                  init?.message?.fulfillments?.[0]?.fulfillment_phone,
                logisticProviderName: "",
                logisticProviderId: "",
                deliveryCharges: "",
                deliveryMode: "",
                state: {
                  code: select?.fulfillments[0]?.state,
                  name: init?.message?.fulfillments?.[0]
                    ?.fulfillment_provider_id,
                },
                start: {
                  location: {
                    id: select?.provider?.id,
                    gps: cartData?.gps,
                    descriptor: {
                      code: cartData?.storeId,
                      name: cartData?.store_name,
                    },
                    address: {
                      name: cartData?.store_name,
                      building: "",
                      door: "",
                      locality: "",
                      city: cartData?.city,
                      state: cartData?.store_state,
                      country: "India",
                      area_code: cartData?.store_area_code,
                    },
                  },
                  time: {
                    range: {
                      start: cartData?.store_start,
                      end: cartData?.store_end,
                    },
                  },
                  instructions: {
                    name: "Ready to ship",
                    short_desc: " ",
                  },
                  contact: {
                    phone: cartData?.store_phone,
                    email: cartData?.store_email,
                  },
                },
                end: {
                  location: {
                    gps: init?.message?.fulfillments?.[0]
                      ?.customer_shipping_address?.gps,
                    address: {
                      door: init?.message?.fulfillments?.[0]
                        ?.customer_shipping_address?.building,
                      ...init?.message?.fulfillments?.[0]
                        ?.customer_shipping_address,
                    },
                  },
                  time: {
                    range: {
                      start: cartData?.store_start,
                      end: cartData?.store_end,
                    },
                  },
                  instructions: {
                    name: "Ready to ship",
                    short_desc: " ",
                  },
                  contact: {
                    phone: AppConstants.mobile,
                    email: AppConstants.EMAIL,
                  },
                },
              },
            ],
            quote: {
              totalPrice: init?.message?.quote?.total_price,
              originalPrice: init?.message?.quote?.originalPrice,
              deliveryCharges: shipping,
              packingCharges: packingCharges,
              orderTotalTax: tax,
              orderDiscount: discountCharges,
              convenienceCharges: miscCharges,
              totalCharges: shipping + packingCharges + tax + discountCharges + miscCharges,
              breakUp: init?.message?.quote?.break_up.map((item) => {
                return {
                  itemId: item?.item_id,
                  count: item?.count || "",
                  titleType: item?.title_type,
                  title: item?.title,
                  price: item?.price,
                  item: {
                    price: item?.item?.price || {},
                    quantity: {
                      available: {},
                      maximum: {},
                    },
                  },
                };
              }),
              ttl: init?.message?.quote?.ttl,
            },
            payment: {
              uri: "",
              tl_method: "",
              transaction_id: init.context?.transaction_id,
              transaction_status: "SUCCESS",
              amount: init?.message?.quote?.total_price,
              status: init?.message?.payment?.status,
              settlement_basis: init?.message?.payment?.settlement_basis,
              settlement_window: init?.message?.payment?.settlement_window,
              withholding_amount: init?.message?.payment?.withholding_amount,
              type: init?.message?.payment?.type,
              collected_by: "BAP",
              buyer_app_finder_fee_type:
                init?.message?.payment?.buyer_app_finder_fee_type,
              buyer_app_finder_fee_amount:
                init?.message?.payment?.buyer_app_finder_fee_amount,
              settlement_details: init?.message?.payment?.settlement_details,
            },
            tags: init?.message?.tags,
          },
        ],
        orderId: refID?.toString(),
        amount: init?.message?.quote?.total_price,
        phone: AppConstants.mobile,
        agentCode: AppConstants.AID,
        platformFees: "0",
        agentId: AppConstants.AID,
        // deviceOS: Platform.OS.toUpperCase(),
        // targetApp: appUrl,
        type: "UPI_INTENT",
        name: AppConstants.NAME,
        paymentType: "GateWay",
        surl: process.env.REACT_APP_FRONTEND_URL + '/order-placed',
        furl: process.env.REACT_APP_FRONTEND_URL + '/checkout'
      };

      dispatch(sendRequestForConfirm(dataObj))
        .then((res) => {
          if (res.payload.RESP_CODE === 300) {
            setLoading(false);
            setAddConfirmOrder((prev) => [res?.payload?.DATA, ...prev]);

            const formForPayU = `
  <form id="payuform" action=${res.payload.DATA.action} name="payuform" method="POST">
    <input type="hidden" id="key" name="key" value=${res.payload.DATA.key}>
    <input type="hidden" name="hash" value=${res.payload.DATA.hash}>
    <input type="hidden" name="txnid" value=${res.payload.DATA.txnid}>
    <input type="hidden" name="amount" value=${res.payload.DATA.amount}>
    <input type="hidden" name="email" value=${res.payload.DATA.email}>
    <input type="hidden" name="phone" value=${res.payload.DATA.phone}>
    <input type="hidden" name="productinfo" value=${res.payload.DATA.productinfo}>
    <input type="hidden" name="surl" value=${res.payload.DATA.surl}>
    <input type="hidden" name="furl" value=${res.payload.DATA.furl}>
    <input type="hidden" name="service_provider" value=${res.payload.DATA.service_provider}>
    <input type="hidden" name="lastname">
    <input type="hidden" name="curl">
    <input type="hidden" name="address1">
    <input type="hidden" name="address2">
    <input type="hidden" name="city">
    <input type="hidden" name="state">
    <input type="hidden" name="country">
    <input type="hidden" name="zipcode">
    <input type="hidden" name="udf1" value=${res.payload.DATA.udf1}>
    <input type="hidden" name="udf2" value=${res.payload.DATA.udf2}>
    <input type="hidden" name="udf3" value=${res.payload.DATA.udf3}>
    <input type="hidden" name="udf4">
    <input type="hidden" name="udf5">
    <input type="hidden" name="pg">
  </form>`;
            const container = document.createElement("div");
            container.innerHTML = formForPayU;
            const formElement = container.firstElementChild;

            // Append the form to the body
            document.body.appendChild(formElement);

            // Programmatically submit the form
            formElement.submit();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err || "Order is not confirmed,try after sometime!");
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddNewAddress = () => {
    setShowForm(true);
    googleAnalyticsFunc(
      `AddNewAddress_Btn_clicked`,
      `Checkout_AddNewAddress_Btn_clicked`,
      `Checkout`
    );
  };

  const calculateSubtotal = (items) => {
    return items?.reduce((total, product) => {
      const price = parseFloat(product.price);
      const count = parseInt(product.count, 10);
      return total + price * count;
    }, 0);
  };

  const backToCheckout = (usedFor = "") => {
    if (usedFor === "checkout") {
      setShowForm(false);
      dispatch(GetAddressBuyer());
      // move to checkout after address added
    } else {
      if (data?.length > 0) {
        setShowForm(false);
        // Discard and move to checkout
      } else {
        // Address screen remains to add 1st new address
      }
    }
  };
  return (
    <Fragment>
      <div className="checkout-banner">
        <h2>Checkout</h2>
      </div>
      <div className="row-container headerTag">
        <p onClick={() => {
          navigate("/");
          googleAnalyticsFunc(
            `Home_HeaderText_clicked`,
            `Checkout_Home_HeaderText_clicked`,
            `Checkout`
          );
        }} className="headerText">
          <u>Home</u>
        </p>
        <img className="rightArrow" src="/right-arrow.png" alt="" />
        <p onClick={() => {
          navigate("/cart");
          googleAnalyticsFunc(
            `Cart_HeaderText_clicked`,
            `Checkout_Cart_HeaderText_clicked`,
            `Checkout`
          );
        }} className="headerText">
          <u>Cart</u>
        </p>
        <img className="rightArrow" src="/right-arrow.png" alt="" />
        <p>Checkout</p>
      </div>
      <div className="row-container checkout">
        <div className="left-checkout">
          {data?.length > 0 && !showForm ? (
            <Fragment>
              <div className="checkout-header">
                <h4>Choose Address</h4>
                <div onClick={handleAddNewAddress} className="add-address-btn">
                  <span className="add-address-btn-text1">Add New Address</span>
                  <span className="add-address-btn-text2">Add Address</span>
                </div>
              </div>
              <div
                className={`address-grid ${
                  data?.length > 6 ? "scrollable-container" : ""
                }`}
              >
                {data?.map((address, index) => (
                  <div
                    className={`address-card ${
                      selectedAddressId === index ? "selected" : ""
                    }`}
                    key={address.addressId}
                    onClick={() => {
                      handleAddressSelection(index, address);
                      setSelectedAddressId(index);
                      googleAnalyticsFunc(
                        `Address_Selection`,
                        `Checkout_Address_Selection`,
                        `Checkout`
                      );
                    }}
                  >
                    <h3>{address?.name}</h3>
                    <p className="address-text">
                      {[
                        address?.houseNo,
                        address?.addressOne
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                    <div className="email-div">
                      {address?.mobile && (
                        <>
                          {" "}
                          <p>PHONE</p> <span>{address?.mobile}</span>
                        </>
                      )}
                    </div>

                    <label className="addressCheckDiv">
                      <input
                        type="checkbox"
                        checked={selectedAddressId === index}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleAddressSelection(index, address)}
                        className="checkboxInput"
                      />
                      <p className="checkboxText">Select address</p>
                    </label>
                  </div>
                ))}
              </div>
            </Fragment>
          ) : (
            isLoaded && (
              <AddAddress
                usedFor={"checkout"}
                callFromParent={backToCheckout}
                showDiscardBtn={data.length > 0}
              />
            )
          )}
        </div>

        <div className="right-checkout">
          <h3 className="summryTitle">Order summary</h3>
          <div
            className={` ${
              cartData?.items?.length > 4 ? "scrollable-container" : ""
            }`}
          >
            {cartData?.items?.map((product) => (
              <div className="summary-card" key={product.itemId}>
                <div className="summary-card-left">
                  <img
                    src={
                      imageError || !product.item_image
                        ? itemImageLoader
                        : product.item_image
                    }
                    className="imageSummery"
                    alt=""
                    onError={handleImageError}
                  />
                  <div className="sum-prod-details">
                    <p className="sum-prod-name">{product.item_name}</p>
                    <span className="sum-prod-quant">
                      Quantity: {product.count}
                    </span>
                  </div>
                </div>
                <div className="summary-card-right">
                  <p>₹{parseFloat(product.price * product.count).toFixed(2)}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="summryChildMian">
            <div className="summryChild">
              <p className="childColor">Subtotal</p>
              <p>₹{calculateSubtotal(cartData?.items)?.toFixed(2)}</p>
            </div>
            {shipping !== null && (
              <>
                <div className="summryChild">
                  <p className="childColor">Shipping Cost </p>
                  <p>₹{shipping}</p>
                </div>
                <div className="summryChild">
                  <p className="childColor">Tax</p>
                  <p>₹{tax}</p>
                </div>
                <div className="summryChild">
                  <p>Total</p>
                  <p>₹{totalItemPrice}</p>
                </div>
              </>
            )}
          </div>
          <div className="buttonMainDivs">
            <button
              className="Proceed-button"
              onClick={handlePayNow}
              disabled={!canPlaceOrder}
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
