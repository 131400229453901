import React, { useContext, useEffect, useRef, useState } from "react";
import "./Login.scss";
import viewEye from "../../assets/icons/view_eye.png";
import hideEye from "../../assets/icons/hide_eye.png";
import ImageHeader from "../../components/ImageHeader/ImageHeader";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { sendOtp, verifyOtp } from "../../redux/actions/Action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { setLocalStorageData } from "../../utilities/Storage";
import { getUserInfoAndSetConstants } from "../../constants/AppContants";
import { Is_Login } from "../../redux/actions";
import { Context } from "../../contexts/AppContext";
import Spinner from "../../components/Loader/Spinner";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

export const Login = () => {
  const toastId = useRef(null);
  const { setSelectedCategory } = useContext(Context);
  const [mobileNumber, setMobileNumber] = useState("");
  const [focusedInputIndex, setFocusedInputIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  let time = 120;
  const [timer, setTimer] = useState(time);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const textInputRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (otp.length === 6) {
      // Remove focus from all input fields
      const inputElements = document.querySelectorAll('.otp');
      inputElements.forEach((input) => {
        input.blur();
      });
    }
  }, [otp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let interval = null;
    if (isTimerRunning && step === 2) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsTimerRunning(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isTimerRunning, step]);

  const validateMobileNumber = (number) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(number);
  };

  const callGenerateOtp = () => {
    if (validateMobileNumber(mobileNumber)) {
      setIsLoading(true);
      dispatch(sendOtp(mobileNumber))
        .then((res) => {
          if (res.payload.RESP_CODE == 300) {
            if (!toast.isActive(toastId.current)) {
              toast.success(res.payload.RESP_MSG);
            }
            setStep(2);
          } else {
            if (!toast.isActive(toastId.current)) {
              toast.error(res.payload.RESP_MSG);
            }
          }
        })
        .catch((error) => {
          console.log(toast.error(error));
        })
        .finally(() => setIsLoading(false));
    } else {
      if (!toast.isActive(toastId.current)) {
        toast.error("Please enter valid mobile number");
      }
    }
  };

  const verifyOtpNumber = () => {
    if (otp.length === 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Enter code properly.");
      }
    } else if (otp.length !== 6) {
      if (!toast.isActive(toastId.current)) {
        toast.error("Enter all the six digits.");
      }
    } else {
      setIsLoading(true);
      dispatch(verifyOtp({ code: otp, mobileNumber }))
        .then(async (res) => {
          if (res.payload.RESP_CODE === 300) {
            if (res.payload.DATA.AGENT_EXIST === "Y") {
              dispatch(
                Is_Login({
                  isLogin: true,
                  isFirstTime: false,
                }),
              );
              await setLocalStorageData(
                "IsLogin",
                JSON.stringify({ isLogin: true }),
              );
              await setLocalStorageData(
                "userInfo",
                JSON.stringify({ userInfo: res.payload.DATA }),
              );
              await getUserInfoAndSetConstants();
              if (!toast.isActive(toastId.current)) {
                toast.success(res.payload.RESP_MSG);
              }
              const activityRedirectionInfo = JSON.parse(sessionStorage.getItem("activityRedirectionInfo"));
              if (activityRedirectionInfo) {
                navigate(activityRedirectionInfo.path);
              } else {
                navigate("/");
              }
            } else {
              navigate("/register", { state: { mobileNumber } });
            }
          } else {
            if (!toast.isActive(toastId.current)) {
              toast.error(res.payload.RESP_MSG);
              setOtp("");
            }
          }
        })
        .catch((e) => console.log("err", e))
        .finally(() => setIsLoading(false));
    }
  };

  const handleOtpChange = (value) => {
    setOtp(value);

    if (value.length < otp.length) { 
      setFocusedInputIndex(Math.max(value.length - 1, 0)); 
    }
  };

  return (
    <>
      {isLoading && <Spinner />}
      <ImageHeader  secondText="Login" />
      <div className="login-container">
        <div className="login-form-container">
          {step === 1 ? (
            <>
              <h2 className="login-title">Sign In</h2>
              <div className="login-form">
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  className="login-input"
                  value={mobileNumber}
                  maxLength={10}
                  onChange={(e) =>
                    setMobileNumber(e.target.value?.replace(/[^0-9]/g, ""))
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      callGenerateOtp();
                    }
                  }}
                />
                <button
                  type="submit"
                  className="login-button"
                  onClick={() => {
                    callGenerateOtp();
                    googleAnalyticsFunc(
                      `Send_OTP_Btn_Clicked`,
                      `Login_Send_OTP_Btn_Clicked`,
                      `Login`
                    );
                  }}>
                  Send OTP
                </button>
                {/* <p className="register-text">
                  Don’t have an account? <Link to="/register">Register</Link>
                </p> */}
              </div>
            </>
          ) : (
            <>
              <h2 className="login-title">OTP Verification</h2>
              <div className="login-form">
                <OTPInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  shouldAutoFocus
                  inputType="tel"
                  inputStyle={{ width: "40px" }}
                  renderInput={(props) => (
                    <input 
                      required 
                      {...props} 
                      className={`otp ${focusedInputIndex === props.index ? 'focused' : ''}`}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          verifyOtpNumber();
                        }
                      }}
                    />
                  )}
                />
                <button
                  type="submit"
                  className="login-button login-button-verify"
                  onClick={() => {
                    verifyOtpNumber();
                    googleAnalyticsFunc(
                      `Verify_OTP_Btn_Clicked`,
                      `Login_Verify_OTP_Btn_Clicked`,
                      `Login`
                    );
                  }}>
                  Verify OTP
                </button>
                <p
                  className={`time-running ${
                    isTimerRunning ? "time-disable" : "time-enable"
                  }`}
                  onClick={() => {
                    if (!isTimerRunning) {
                      callGenerateOtp();
                      setTimer(time);
                      setIsTimerRunning(true);
                      googleAnalyticsFunc(
                        `Resend_OTP_Btn_Clicked`,
                        `Login_Resend_OTP_Btn_Clicked`,
                        `Login`
                      );
                    }
                  }}
                >
                  {isTimerRunning
                    ? timer > 59
                      ? `Resend OTP in ${Math.floor(timer / 60)} mins ${
                          timer % (Math.floor(timer / 60) * 60)
                        }s`
                      : `Resend OTP in ${timer}s`
                    : "Resend OTP"}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
