import React, { Fragment, useContext, useRef } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import DashboardIcon from "../../assets/icons/dashboard 2.png";
import orderHistory from "../../assets/icons/dashboard 2 (1).png";
import dashboardIssues from "../../assets/icons/DashboardIssues.png";
import Wishlist from "../../assets/icons/dashboard 2 (2).png";
import Addresses from "../../assets/icons/Group (1).png";
import Settings from "../../assets/icons/dashboard 2 (3).png";
import Logout from "../../assets/icons/dashboard 2 (4).png";
import allissueic from "../../assets/icons/all-issue-ic.svg";

import { Context } from "../../contexts/AppContext";
import { getUserInfoAndSetConstants } from "../../constants/AppContants";
import "./ProfileMenu.scss";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

function ProfileMenu() {
  const { getCart, setCartData } = useContext(Context);
  const navigate = useNavigate();
  const profileMenuRef = useRef(null);

  const logOutHandler = async () => {
    localStorage.removeItem("IsLogin");
    localStorage.removeItem("userInfo");
    sessionStorage.clear();
    setCartData({});
    await getUserInfoAndSetConstants();
    getCart();
    navigate("/");
  };

  const toggleMenu = () => {
    if (profileMenuRef.current) {
      const menu = profileMenuRef.current;
      const menuHeight = profileMenuRef.current.style.height; // Get the current color
      if (menuHeight === "0px" || menuHeight === "") {
        profileMenuRef.current.style.height = `${menu.scrollHeight}px`;
      } else {
        profileMenuRef.current.style.height = "0px";
        profileMenuRef.current.style.border = "0";
      }
    }
  };

  const NavigationToTitle = (ProfileMenuNavigationTitle) => {
    googleAnalyticsFunc(
      `${ProfileMenuNavigationTitle}_clicked`,
      `ProfileMenu_${ProfileMenuNavigationTitle}_clicked`,
      `ProfileMenu`
    );
  }

  return (
    <Fragment>
      <div className="sidebar sidebar-desktop">
        <div className="nav-ham">
          <h4>My account</h4>
        </div>

        <ul className="profile-menus">
          <li onClick={() => NavigationToTitle('Dashboard')}>
            <NavLink className="nav-link" to={"/profile/dashboard"}>
              <img src={DashboardIcon} width={25} alt="" />
              Dashboard
            </NavLink>
          </li>
          <li onClick={() => NavigationToTitle('Order_History')}>
            <NavLink className="nav-link" to={"/profile/order-history"}>
              <img src={orderHistory} width={25} alt="" />
              Order History
            </NavLink>
          </li>
          <li>
            {/* <NavLink className='nav-link' to={'/profile/wishlist'}>
                                <img src={Wishlist} width={25} alt="" />
                                Wishlist
                            </NavLink> */}
          </li>
          <li onClick={() => NavigationToTitle('Addresses')}>
            <NavLink className="nav-link" to={"/profile/address-list"}>
              <img src={Addresses} width={25} alt="" />
              Addresses
            </NavLink>
          </li>
          {/* <li>
          <NavLink className="nav-link" to={"/profile/tickets"}>
            <img src={dashboardIssues} width={25} alt="" />
            All Issues
          </NavLink>
        </li> */}
          <li onClick={() => NavigationToTitle('All_Issues')}>
            <NavLink className="nav-link" to={"/profile/tickets"}>
              <img src={allissueic} width={25} alt="" />
              All Issues
            </NavLink>
          </li>
          <li>
            {/* <NavLink className='nav-link' to={'/profile/settings'}>
                                <img src={Settings} width={25} alt="" /> Settings
                            </NavLink> */}
          </li>
          <li onClick={() => {
            logOutHandler();
            NavigationToTitle('Logout');
          }}>
            <NavLink className="nav-link" to={"/profile/logout"}>
              <img src={Logout} width={25} alt="" /> Log-out
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="sidebar sidebar-mobile">
        <div className="nav-ham">
          <h4>My account</h4>
          <div className="ham-button" onClick={() => {
            toggleMenu();
            NavigationToTitle('hamBurgerBtn');
          }}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <ul className="profile-menus" ref={profileMenuRef}>
          <li onClick={() => {
            toggleMenu();
            NavigationToTitle('Dashboard');
          }}>
            <NavLink className="nav-link" to={"/profile/dashboard"}>
              <img src={DashboardIcon} width={25} alt="" />
              Dashboard
            </NavLink>
          </li>
          <li onClick={() => {
            toggleMenu();
            NavigationToTitle('Order_History');
          }}>
            <NavLink className="nav-link" to={"/profile/order-history"}>
              <img src={orderHistory} width={25} alt="" />
              Order History
            </NavLink>
          </li>
          <li onClick={() => {
            toggleMenu();
          }}>
            {/* <NavLink className='nav-link' to={'/profile/wishlist'}>
                              <img src={Wishlist} width={25} alt="" />
                              Wishlist
                          </NavLink> */}
          </li>
          <li onClick={() => {
            toggleMenu();
            NavigationToTitle('Addresses');
          }}>
            <NavLink className="nav-link" to={"/profile/address-list"}>
              <img src={Addresses} width={25} alt="" />
              Addresses
            </NavLink>
          </li>
          {/* <li>
        <NavLink className="nav-link" to={"/profile/tickets"}>
          <img src={dashboardIssues} width={25} alt="" />
          All Issues
        </NavLink>
      </li> */}
          <li onClick={() => {
            toggleMenu();
            NavigationToTitle('All_Issues');
          }}>
            <NavLink className="nav-link" to={"/profile/tickets"}>
              <img src={allissueic} width={25} alt="" />
              All Issues
            </NavLink>
          </li>
          <li>
            {/* <NavLink className='nav-link' to={'/profile/settings'}>
                              <img src={Settings} width={25} alt="" /> Settings
                          </NavLink> */}
          </li>
          <li
            onClick={() => {
              toggleMenu();
              logOutHandler();
              NavigationToTitle('LogOut');
            }}
          >
            <NavLink className="nav-link" to={"/profile/logout"}>
              <img src={Logout} width={25} alt="" /> Log-out
            </NavLink>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}

export default ProfileMenu;
