import React, { useContext, useEffect, useRef, useState } from "react";
import "./StoreItemCard.scss";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { Context } from "../../contexts/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConstants, generateId } from "../../constants/AppContants";
import { toast } from "react-toastify";
import ReplaceProductPopup from "../ReplaceProductPopup/ReplaceProductPopup";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
// import { getItemCount } from "../../utils/commonFunctions";


const StoreItemCard = ({ onCardClick, cardData }) => {
  const [imageError, setImageError] = useState(false);

  const {
    reduceCartItems,
    handleAddToCart,
    eachCartItemCount,
    handleCartProductQuantity,
    cartData,
    userLocation,
  } = useContext(Context);
  const [isInCart, setIsInCart] = useState(false);
  const navigate = useNavigate();
  const toastId = useRef(null);

  const [replaceCartModalVisible, setReplaceCartModalVisible] = useState(false);
  const location = useLocation();

  // 1. check whether item is already in cart
  useEffect(() => {
    if (Object.keys(cartData).length !== 0) {
      const inCart = cartData.items.some(
        (item) => item.itemId === cardData.attributes.item_details.itemId
      );
      setIsInCart(inCart);
    } else {
      setIsInCart(false);
    }
  }, [cartData]);

  // 2. Replace modal close and item added to recently emptied cart
  useEffect(() => {
    if (replaceCartModalVisible && Object.keys(cartData).length === 0) {
      addToCartOrIncrement();
      setReplaceCartModalVisible(false);
    }
  }, [cartData]);
  const addToCartOrIncrement = () => {
    googleAnalyticsFunc(
      `addToCartOrIncrement_clicked`,
      `StoreItemCard_addToCartOrIncrement_clicked`,
      `StoreItemCard`
    );
    const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

    if (!isLogin || !isLogin.isLogin) {
      sessionStorage.setItem(
        "activityRedirectionInfo",
        JSON.stringify({ path: location.pathname + location.search, activity: "addToCart", productId: cardData.attributes.item_details.itemId }),
      );
      navigate("/login");
      return;
    }

    if (Object.keys(cartData).length !== 0) {
      if (cardData.attributes.item_details.storDetails.store_id !== cartData.storeId) {
        setReplaceCartModalVisible(true);
        return;
      }
      const item = cartData?.items?.find(
        (cartItem) => cartItem.itemId === cardData.itemId
      );

      if (item) {
        if (item.count === item.availableQuantity) {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(
              "You have this item in your cart with the maximum available quantity."
            );
          }
          return;
        }

        if (item.count === item.maxQuantity) {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(
              "You have reached maximum item quantity limit for this order."
            );
          }
          return;
        }
      }
    }
    const payload = {
      DATA: {
        userId: AppConstants.mobile,
        screenName: AppConstants.screenName,
        UDID: AppConstants.UDID,
        agentCode: AppConstants.AID,
        DATA: [
          {
            areaCode: "",
            bppId: cardData.attributes.item_details.storDetails.bpp_id,
            bppUrl: cardData.attributes.item_details.storDetails?.bpp_uri,
            domainName: cardData.attributes.item_details.domain,
            categories: [{}],
            city: cardData.attributes.item_details.storDetails.city,
            gps: `${userLocation.latitude}, ${userLocation.longitude}`,
            locationId: cardData.attributes.item_details.storDetails.store_location_id,
            messageId: Math.floor(new Date().getTime() / 1000.0),
            transactionId: cartData?.transactionId || generateId(),
            store_area_code: cardData.attributes.item_details.storDetails.store_area_code,
            store_city: cardData.attributes.item_details.storDetails.store_city,
            store_email: cardData.attributes.item_details.storDetails.store_email,
            store_end: cardData.attributes.item_details.storDetails.store_end,
            storeId: cardData.attributes.item_details.storDetails.store_id,
            store_image: cardData.attributes.item_details.storDetails.store_image,
            store_name: cardData.attributes.item_details.storDetails.store_name,
            store_phone: cardData.attributes.item_details.storDetails.store_phone,
            store_start: cardData.attributes.item_details.storDetails.store_start,
            store_state: cardData.attributes.item_details.storDetails.store_state,
            store_street: cardData.attributes.item_details.storDetails.store_street,
            items: [
              {
                availableQuantity: cardData.attributes.item_details.available,
                bppId: cardData.attributes.item_details.storDetails.bpp_id,
                bppUrl: cardData.attributes.item_details.storDetails?.bpp_uri,
                cancellable: cardData.attributes.item_details.cancellable,
                contact_details_consumer_care:
                  cardData.attributes.item_details.contact_details_consumer_care,
                count: "1",
                itemId: cardData.attributes.item_details.itemId,
                item_image: cardData.attributes.item_details.item_image,
                itemImagesArray: cardData.attributes.item_details.itemImagesArray,
                item_long_desc: cardData.attributes.item_details.item_long_desc,
                item_name: cardData.attributes.item_details.item_name,
                item_short_desc: cardData.attributes.item_details.item_short_desc,
                item_symbol: cardData.attributes.item_details.item_symbol,
                categoryName: cardData.attributes.item_details.category_id,
                max_price: cardData.attributes.item_details.max_price,
                unitized: cardData.attributes.item_details.unitized,
                offer: {
                  item_capacity: [],
                },
                maxQuantity: cardData.attributes.item_details.max_available,
                ondcreturnwindow: cardData.attributes.item_details.ondc_return_window,
                return_window: cardData.attributes.item_details.return_window,
                price: cardData.attributes.item_details.price,
                returnable: cardData.attributes.item_details.returnable,
                parent_item_id: cardData.attributes.item_details?.parent_item_id,
              },
            ],
          },
        ],
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: "",
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    handleAddToCart(payload);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const imageUrl = cardData.attributes.image_url;

  // 6. handle add to cart when deep linking us used
  useEffect(() => {
    if (Object.keys(cardData).length !== 0) {
      const activityRedirectionInfo = JSON.parse(
        sessionStorage.getItem("activityRedirectionInfo"),
      );
      // shorten this code
      if (activityRedirectionInfo) {
        if (activityRedirectionInfo.productId === cardData.attributes.item_details.itemId && activityRedirectionInfo.activity === "addToCart") {
          addToCartOrIncrement();
          sessionStorage.removeItem("activityRedirectionInfo");
        }
      }
    }
  }, [cardData]);

  const EventTriggerAndNavigation = (btnName) => {
    onCardClick();
    googleAnalyticsFunc(
      `${btnName}_clicked`,
      `StoreItemCard_${btnName}_clicked`,
      `StoreItemCard`
    );
  }
  // let variationsData = cardData?.attributes?.item_details?.variations

  return (
    <div key={cardData.id} className="store-item-card" >
      <div className="product-img-container" onClick={() => EventTriggerAndNavigation('item_image')}>
        <img
          src={
            imageError || !imageUrl
              ? itemImageLoader
              : imageUrl
          }
          alt="deal-1-img"
          className="deal-img"
          onError={handleImageError}
        />
      </div>
      <div className="product-content">
        <div onClick={() => EventTriggerAndNavigation('item_name')}>
          <p className="product-name">
            {cardData.attributes.item_details.item_name}
          </p>
          <p
            className="product-desc"
            data-full-text={cardData.attributes.item_details.item_short_desc}
          >
            {cardData.attributes.item_details.item_short_desc}
          </p>
          {/* <div className="star-review">
                    <div className="five-stars">
                        <div className="star shining"></div>
                        <div className="star shining"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                        <div className="star"></div>
                    </div>
                    <span className="review-sum">Reviews&nbsp;(4)</span>
                </div> */}
          <div className="price-container-home">
            <p className="price-home">₹{cardData.attributes.item_details.price}</p>
            {cardData.attributes.item_details.price !== cardData.attributes.item_details.max_price && (
              <p className="max-price-home">₹{cardData.attributes.item_details.max_price}</p>
            )}
          </div>
          {/* <button type="button" className="buy-button">
          buy now
        </button> */}
        </div>
        {cardData.attributes.item_details?.available > 0 && (
          <div className="addButton">
            {isInCart ? (
              <div className="quantityControls">
                <button
                  className="decrement"
                  onClick={() => {
                    reduceCartItems(false, cardData.attributes.item_details?.itemId);
                    googleAnalyticsFunc(
                      `reduceCartItems_clicked`,
                      `StoreItemCard_reduceCartItems_clicked`,
                      `StoreItemCard`
                    );
                  }}
                >
                  -
                </button>
                <span className="quantity">
                  {cartData?.items?.find(
                    (item) => item.itemId === cardData.attributes.item_details?.itemId
                  )?.count || 0}
                </span>
                <button
                  className="increment"
                  onClick={() => addToCartOrIncrement()}
                >
                  +
                </button>
              </div>
            ) : (
              <button
                className="addonlyButton"
                onClick={() => addToCartOrIncrement()}
              >
                Add
              </button>
            )}
          </div>
        )}
      </div>
      <ReplaceProductPopup
        isVisible={replaceCartModalVisible}
        onClose={() => setReplaceCartModalVisible(false)}
        cartStoreName={cartData?.store_name}
        newItemName={cardData.attributes.item_details?.item_name}
        newStoreName={cardData.attributes.item_details?.storDetails?.store_name}
        onReplace={() => reduceCartItems(true)}
      />
    </div>
  );
};

export default StoreItemCard;
