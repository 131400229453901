import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
        Welcome to OpenKart, OpenKart is a service offered by Easy Pay Limited (Easy Pay) !
        </p>
        <p>
        At OpenKart ("we," "us," or "our"), we are committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our mobile application or website (collectively, "Platform"), which is based on the ONDC Protocol.
        </p>
        <p>
        By using any of OpenKart’s Services, or by dealing with a merchant using OpenKart and Easy Pay’s Services, you are agreeing to the terms of this Privacy Policy and, as applicable, the Terms of Service. If you do not agree, please do not use the Platform.
        </p>
      </section>

      <section>
        <h2>Information from Merchants</h2>
        <p>
          Privacy matters! If you are a merchant, your customers need to understand how you (and how OpenKart) collects and processes their personal information. Accordingly, if you use the Services, you agree to post an up-to-date and accurate privacy policy on your storefront that complies with the laws applicable to your business. You also agree to obtain consent from your customers for the use and access to their personal information by OpenKart and other third parties. Additionally, if you are collecting any sensitive personal information from your customers (including information relating to medical or health conditions, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership or sexuality), you agree to obtain affirmative, express consent from your customers for the use and access of sensitive personal information by OpenKart and other third parties. 
        </p>
        <h3>Information do we collect from merchants and why?</h3>
        <p>
        We collect your name, company name, address, email address, phone number(s) and payment details.
        </p>
        <p>
        We use this information to provide you with our Services; for example, to confirm your identity, contact you, provide you with advertising and marketing, and invoice you. We also use this information to make sure that we comply with legal requirements.
        </p>
        <p>
        We collect data about the OpenKart websites that you visit. We also collect data about how and when you access your account and the OpenKart platform, including information about the device and browser you use, your network connection, your IP address, and information about how you browse through the OpenKart interface.
        </p>
        <p>
        We use this information to give you access to and improve our Services; for example, to make our platform interface easier to use and we may use this information to provide you with advertising or marketing.
        </p>
        <p>
        Upon completing the subscription process for OpenKart or Easy Pay, we collect your business address, business type, business ID number, date of birth (if you are an individual business owner), bank account information and government-issued identification information.
        </p>
        <p>
        We use this information to provide you with OpenKart payments services, including fraud and risk monitoring, and to comply with applicable legal and regulatory requirements.
        </p>
        <p>
        We collect personal information about your customers that you share with us or that customers provide while shopping or during checkout.
        </p>
        <p>
        We use this information to provide you with our Services and so that you can process orders and better serve your customers..
        </p>
        <p>
        We use some of the personal information you provide us to conduct some level of automated decision-making- for example, we use the certain personal information to help us screen accounts for risk or fraud concerns.
        </p>
        <p>
        We will also use personal information in other cases where you have given us your express permission.
        </p>
      </section>

      <section>
        <h3>When do we collect this information?</h3>
        <p>
        We collect personal information when you sign up for our services when you access our services or otherwise provide us with the information.
        </p>
        <p>
        We also partner with third parties who provide us information about merchants or prospective merchants, for example, to help us screen out merchants associated with fraud.
        </p>
        </section>

<section>
  <h3>When and why do we share this information with third parties?</h3>
  <p>
  OpenKart works with a variety of third parties and service providers to help provide you with our services and we may share personal information with them to support these efforts.
  </p>
  <p>
  <strong>We may also share your information in the following circumstances:</strong>
  </p>
  <p>
  to prevent, investigate, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Service or any other agreement related to the Services, or as otherwise required by law. 
  </p>
  <p>
  to help us conduct marketing and/or advertising campaigns. to conform to legal requirements, or to respond to lawful court orders, subpoenas, warrants, or other requests by public authorities (including to meet national security or law enforcement requirements).
  </p>
  <p>
  Personal information may also be shared with a company that acquires our business, whether through merger, acquisition, bankruptcy, dissolution, reorganization, or other similar transaction or proceeding. If this happens, we will post a notice on our homepage.
  </p>
  </section>

      <section>
        <p>
        <h2>Information from Customers</h2>
        </p>
        <h3>What Information We Collect?</h3>
        <p>We may collect the following types of information:</p>
          <p>
            <strong>Personal Information:</strong> This includes your name, email address, phone number, delivery addresses, payment details, and other data that can identify you.
          </p>
          <p>
            <strong>Usage Data:</strong> We collect data about how you interact with the Platform, including browsing behavior, search history, and preferences.
          </p>
          <p>
            <strong>Device Information:</strong> Information about the device you use to access OpenKart, including hardware model, operating system, and IP address.
          </p>
          <p>
            <strong>Location Data:</strong> We may collect your location information when you use location-based services or make location-specific purchases.
          </p>
        <h3>How We Use Your Information</h3>
        <p>We use your information for the following purposes:</p>
        <p><strong>To Provide Services:</strong> Processing orders, delivering products, and facilitating transactions.</p>
        <p><strong>Customer Support:</strong> Responding to your queries, complaints, or issues with our services.</p>
        <p><strong>Communication:</strong> Sending you notifications, promotional offers, updates about your orders, and changes to our policies.</p>
        <p><strong>Analytics:</strong> Improving our services by analyzing how users interact with the Platform.</p>


        <h3>Sharing of Your Information</h3>
        <p>We may share your information with:</p>
        <p><strong>Vendors and Service Providers:</strong> Third-party vendors who assist in providing services such as payment processing, delivery, and customer support.</p>
        <p><strong>ONDC Protocol Partners:</strong> As OpenKart operates on the ONDC Protocol, we may share necessary data with ONDC-participating sellers and buyers.</p>
        <p><strong>Legal Obligations:</strong> We may disclose information to comply with legal requirements or respond to legal requests from public authorities.</p>
        <p><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your data may be transferred as part of the business deal.</p>
      </section>

      <section>
        <h2>Data Retention</h2>
        <p>
          We will retain your personal information for as long as needed to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, or enforce agreements.
        </p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>You have the following rights concerning your personal information:</p>
        <p><strong>Access:</strong> Request access to the personal information we hold about you.</p>
        <p><strong>Correction:</strong> Request correction of inaccuracies in your personal data.</p>
        <p><strong>Deletion:</strong> Request the deletion of your personal information, subject to legal obligations.</p>
      </section>

      <section>
        <h2>Security Measures</h2>
        <p>
          We implement industry-standard security measures to protect your personal information against unauthorized access, loss, or misuse. However, no data transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
        </p>
      </section>
      <section>
        <h2>Cookies and Tracking Technologies</h2>
        <p>
        We use cookies and similar technologies to enhance your experience on our Platform. You can manage your cookie preferences through your browser settings.
        </p>
      </section>
      <section>
        <h2>Third-Party Links</h2>
        <p>
        Our Platform may contain links to third-party websites or services. We are not responsible for the privacy practices of those third parties, and we encourage you to review their privacy policies.
        </p>
      </section>
      <section>
        <h2>Changes to This Policy</h2>
        <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this page periodically to stay informed about how we protect your information.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at:
        </p>
        <p>Email: contact@openkart.co.in</p>
        <p className="lastUpdated">Last updated: Oct 01, 2024</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;