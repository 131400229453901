import axios from 'axios';
import {getData, sendData} from '../utils/encryption';

const HOST = process.env.REACT_APP_BACKEND_ENCRYPT_BASE_URL;
const NO_ENCRYPTION_HOST = process.env.REACT_APP_BACKEND_NO_ENCRYPT_BASE_URL;

export const HTTPS = async (URL, PAYLOAD, encryption = true) => {
  if (encryption) {
    try {
      const formData = new FormData();
      formData.append('request', sendData(PAYLOAD));
      const API = HOST + URL;
      const response = await axios.post(API, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return JSON.parse(getData(response.data));
      
    } catch (error) {
      throw error;
    }
  } else {
    try {
      const APIURL = NO_ENCRYPTION_HOST + URL;
      const response = await axios.post(APIURL, PAYLOAD, {
        headers: {
          'Content-Type': URL.includes('sendRequestForIssueV2') 
          ? 'multipart/form-data' 
          : 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};
