import React from 'react';
import './ErrorScreen.scss';
import notfoundicon from "../../assets/images/not-found-icon.png"
import { useNavigate } from 'react-router-dom';
import { googleAnalyticsFunc } from '../../utils/commonFunctions';


const ErrorScreen = ({ message,isShowAddAddress }) => {
const navigate = useNavigate();
const EventTriggerAndNavigation = () => {
  googleAnalyticsFunc(
    `AddNewAddress_Tab_clicked`,
    `ErrorScreen_AddNewAddress_Tab_clicked`,
    `ErrorScreen`
  );
  navigate("/profile/add-address");
}
  
    return (
        <div className="error-screen">
        <img src={notfoundicon} alt="No Address Found" />

         <p>{message}</p>
         {isShowAddAddress &&
        <div
          className="error-screen-add-address"
          onClick={() => EventTriggerAndNavigation()}
        >
          Add New Address
        </div>
        }
      </div>
    );
};

export default ErrorScreen;