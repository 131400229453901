import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCSSoCL4XDihuhC7t-Ek9c5RqJL9r6M5zY",
  authDomain: "openkart-web.firebaseapp.com",
  projectId: "openkart-web",
  storageBucket: "openkart-web.firebasestorage.app",
  messagingSenderId: "308686181757",
  appId: "1:308686181757:web:dc0d1db07e69f1b5d7e814",
  measurementId: "G-R1QZMQ35KP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
