import { createSlice } from '@reduxjs/toolkit';
import { sendOtp } from '../actions/Action';

const initialState = {
  RESPONSE: false,
  RESP_MSG: '',
  AUTH_RESP: false,
  data: {},
  error: 'false',
};

const SendOtpSlice = createSlice({
  name: 'SendOtpSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.RESPONSE = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, { payload }) => {
      state.RESPONSE = false;
      state.RESP_MSG = payload.RESP_MSG;
      state.data = payload.DATA;
    });
    builder.addCase(sendOtp.rejected, (state, { payload }) => {
      state.RESPONSE = false;
      state.RESP_MSG = payload.RESP_MSG;
    });
  },
});

export default SendOtpSlice.reducer;
