import './Spinner.scss';
import loader from '../../assets/images/loader_easy_pay1.gif'
import { Lotties } from '../../constants/LottieJson';
import Lottie from 'react-lottie';

export const SmallLoader = ({ loaderText }) => {
    return (
        <div className="small-loader">
            <span className="small-spinner"></span>
            {loaderText}
        </div>
    )
}

const Spinner = ()=> {
     const mainLoader = {
        loop: true,
        autoplay: true,
        animationData: Lotties.okLoader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
    return (
        <div className="spinner-container">
            <div className='loader'>
            <Lottie options={mainLoader} height={150} width={150} />
            </div>
            {/* <div className="progress"></div> */}
        </div>
        
    );
}

export default Spinner;