import React, { useEffect } from "react";
import "./AboutUs.scss";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  }, [location]);

  return (
    <div className="about-us">
      <section id="hero">
        <img id="hero-img" src="/ondc/images/hero_cover.png" alt="hero_img" />
        <div id="hero-content">
          <h1 className="tagline">
            Bring your <span>business online</span> with <span>ONDC</span>{" "}
            network - a platform to democratize <br /> e-commerce in India.
          </h1>
          <a
            href="http://uat-baas.easypay.co.in/baas/registration"
            target="_blank"
            rel="noopener noreferrer"
            className="sell-link"
            //   style={{ display: "none" }}
          >
            <button className="ondc-button">
              Register as Seller
              <img
                src="/ondc/icons/arrow-circle.svg"
                alt="arrow-circle"
                style={{ marginLeft: 5 }}
              />
            </button>
          </a>
        </div>
      </section>
      {/* About ONDC Network Section */}
      <section id="about-ondc">
        <h1 className="headline">About ONDC network</h1>
        <div className="content">
          <img
            className="desc-img"
            src="/ondc/images/ondc_logo.png"
            alt="ondc_logo"
          />
          <div className="content-text">
            ONDC (Open Network for Digital Commerce) allows sellers to showcase
            products on various buyers' platforms. Buyers and sellers can
            transact regardless of the platform as long as they are connected to
            the ONDC network.
          </div>
        </div>
      </section>

      {/* Easy Pay Section */}
      <section id="easypay-ondc">
        <div className="content">
          <div className="img-container">
            <img
              className="desc-img"
              src="/ondc/images/easypay_ondc.png"
              alt="easypay_ondc"
            />
          </div>
          <div className="content-text">
            <h1 className="tabletline headline">
              Easy Pay <span>- An ONDC network Participant</span>
            </h1>
            Easy Pay, one of India’s leading digital payment platforms, has
            taken a significant step in enhancing the online experience with its
            latest integration called the Open Network for Digital Commerce
            (ONDC).
            <br />
            <br />
            This revolutionary feature is designed to streamline various
            services within the Easy Pay app, making it easier than ever for
            users to access a multitude of offerings.
          </div>
        </div>
      </section>

      {/* Seller Work Section */}
      <section id="seller-work">
        <h1 className="headline">How ONDC network works for sellers?</h1>
        <div className="content">
          <div className="gif-container">
            <img
              className="desc-img"
              src="/ondc/images/ondc_flow.png"
              alt="ondc_gif"
            />
            <img
              className="desc-img"
              src="/ondc/images/ondc_gif_v1.gif"
              alt="ondc_gif"
            />
          </div>
          <div className="content-text">
            <div className="work">
              <img src="/ondc/icons/document-icon.svg" alt="document-icon" />
              <p>List your products on a single platform</p>
            </div>
            <div className="work">
              <img src="/ondc/icons/apps-icon.svg" alt="apps-icon" />
              <p>Get discovered on multiple buyer apps</p>
            </div>
            <div className="work">
              <img src="/ondc/icons/orders-icon.svg" alt="orders-icon" />
              <p>Get orders</p>
            </div>
            <div className="work">
              <img src="/ondc/icons/delivery-icon.svg" alt="delivery-icon" />
              <p>Deliver through multiple partners</p>
            </div>
            <div className="work">
              <img src="/ondc/icons/details-icon.svg" alt="details-icon" />
              <p>Get customer details</p>
            </div>
          </div>
        </div>
        <a
          href="https://business.easypay.in/"
          target="_blank"
          class="sell-link"
        >
          <button class="ondc-button" id="ondc-contact-us">
            Sell Now
          </button>
        </a>
      </section>

      {/* Benefits Section */}
      <section id="benefits">
        <h1 className="headline">
          Benefits of onboarding to ONDC network via Easy Pay
        </h1>
        <div className="card-container">
          <div className="card">
            <h3 className="head">One-click onboarding onto ONDC network</h3>
            <div className="content-text">
              Pre-register for ONDC network through quick and hassle-free
              single-click onboarding on Easy Pay App and become visible across
              all ONDC network partners.
            </div>
          </div>
          <div className="card">
            <h3 className="head">Multiple value-added services</h3>
            <div className="content-text">
              Register with Easy Pay and connect with more than 100+ services
              for credit, loans, digital payments, delivery, and more.
            </div>
          </div>
          <div className="card">
            <h3 className="head">One-stop solution</h3>
            <div className="content-text">
              Get complete digital business solution: billing, catalog creation,
              B2B buying & selling, pay-in, payouts, business reports, and more.
            </div>
          </div>
        </div>
      </section>

      {/* Sell on ONDC Section */}
      <section id="sell-on-ondc">
        <h1 className="headline">Sell On ONDC network with Easy Pay</h1>
        <div className="content-text">
          With Easy Pay, you can register as a seller and upload your catalog.
          You will have a dedicated store for your products that you can market
          to your buyers. Your catalog will also appear on ONDC network through
          other buyer apps like PayTm, PhonePe, WhatsApp, etc. Easy Pay provides
          a complete catalog panel to manage your products, orders, payments,
          and payouts.
        </div>
        <div className="img-steps-container">
          <img
            src="/ondc/images/sell-on-ondc.jpg"
            className="sell-main-img"
            alt="sell-on-ondc"
          />
          <div className="steps-container">
            {[...Array(5)].map((_, i) => (
              <div className="step-container" key={i}>
                <img
                  src={`/ondc/icons/sell-${
                    ["account", "list", "get-order", "delivery", "payment"][i]
                  }.png`}
                  alt={`sell-step-${i + 1}`}
                  className="sell-icon"
                />
                <div className="step-row">
                  <span className="number">{i + 1}</span>
                  <div className="step-info">
                    <span className="step-headline">
                      {
                        [
                          "Create your account",
                          "List your products",
                          "Get orders",
                          "Order fulfillment",
                          "Get payment",
                        ][i]
                      }
                    </span>
                    <span className="step-details">
                      {
                        [
                          "Basic Information, Profession",
                          "Upload catalog or add products individually",
                          "Receive orders from all over India",
                          "Collaborate with delivery partner",
                          "Hassle-free transactions",
                        ][i]
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="sms-adv">
        <div id="adv-background">
          <h1 className="headline">
            Seller Management Solutions for Wholesalers & Brands
          </h1>
          <div className="content-text">
            Enterprise seller management solutions provide wholesalers and
            brands with ready-to-use technology implementation needed to start
            selling through the ONDC network. Unlock growth for your brand with
            customizable, pre-built infrastructure to connect and manage your
            seller/dealer/distributor network on ONDC.
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdamCC1BMqFBBJUsnc4DvghP_NYIb07gXdPoz6Jbf5TWxGoAw/viewform"
            target="_self"
            className="sell-link"
          >
            <button className="ondc-button" id="ondc-contact-us">
              Book a Demo
            </button>
          </a>
        </div>
      </section>

      {/* Seller Management Solution Section */}
      <section id="sms">
        <h1 className="headline">Seller Management Solution</h1>
        <div className="content-text">
          Easy-to-use and feature-rich solutions to manage different sets of
          sellers or dealers for your brand with Easy Pay. Easily manage your
          seller networks while enabling them to directly sell via ONDC. Make
          selling easy for your sellers and be in complete control of your
          seller network.
        </div>
        <div className="image-benefits-container">
          <div className="img-container">
            <img
              className="sms-img"
              src="/ondc/images/laptop-person.png"
              alt="laptop-person"
            />
          </div>
          <ul className="benefits-container">
            <li className="benefit">Manage Sellers, Products and Orders</li>
            <li className="benefit">
              Set and manage common settings (like time to ship, Pin code
              serviceability etc.) for sellers
            </li>
            <li className="benefit">
              Set and manage Time Schedule (timings, holiday schedules etc.)
            </li>
            <li className="benefit">Manage Pay-in and payouts for sellers</li>
            <li className="benefit">
              Manage returnable, cancellable, invoice settings etc. for your
              seller network
            </li>
          </ul>
        </div>
      </section>

      {/* Invitation Section */}
      <section id="invitation">
        <h1 className="headline">Make the Transition to Open Networks</h1>
        <div className="content-text">
          Every day, new Seller, Buyer and Logistics nodes are coming up on ONDC
          network. This allows you to expand your reach and make your network
          more resilient and vibrant by forming newer business partnerships
          rapidly.
        </div>
      </section>

      {/* Sellers Image Container Section */}
      <section id="sellers-img-container">
        <picture>
          <source
            className="sellers-img"
            media="(min-width: 768px)"
            srcSet="/ondc/images/sellers-onboarding-desktop.png"
          />
          <source
            className="sellers-img"
            media="(min-width: 425px)"
            srcSet="/ondc/images/sellers-onboarding-tablet.png"
          />
          <img
            className="sellers-img"
            src="/ondc/images/sellers-onboarding-mobile.png"
            alt="sellers-onboarding-mobile"
          />
        </picture>
      </section>
    </div>
  );
};

export default AboutUs;
