import React, { Fragment, useState, useEffect, useRef } from "react";
import './SelectElement.scss';
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
// import onClickOutside from 'react-onclickoutside';

// selectElementOptionList: {id: int, key: string, name: string}
const SelectElement = ({
  id,
  selectElementOptionList,
  handleOptionSelect,
  selectedValue,
  placeholder,
  disabled = false,
  className = "",
  marginRight10 = ""
}) => {

  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  const toggleDropdown = (e) => {
    googleAnalyticsFunc(
      `toggleDropdown_Clicked`,
      `SelectElement_toggleDropdown_Clicked`,
      `SelectElement`
    );
    e.preventDefault();
    setOpen(!open);
  };

  const handleOption = (option) => {
    googleAnalyticsFunc(
      `handleOption_Clicked`,
      `SelectElement_handleOption_Clicked`,
      `SelectElement`
    );
    setOpen(false);
    handleOptionSelect(option);
  }

  // SelectElement.handleClickOutside = () => setOpen(false);

  const outsideClickDropdownClose = (event) => {
    // Listeniing in git?
    if(!selectRef.current) return; //Why? When? - Ans : When unmounting javascript couldnt trace element
    if (selectRef.current.contains(event.target)) return;
    setOpen(false);
  }

  const listenForOutsideClicks = () => {
    ["click", "touchstart"].forEach((type) => {
      document.addEventListener(type, outsideClickDropdownClose);
    })
  }

  useEffect(() => {
    listenForOutsideClicks();
    return () => {
      ["click", "touchstart"].forEach((type) => {
        document.removeEventListener(type, outsideClickDropdownClose);
      })
    }
  }, []);

  return (
    <Fragment>
      <div className={`custom-select ${open ? "active" : ""} ${marginRight10}`}
      ref={selectRef}
      >
        <button
          id={id}
          className={`buyer-form-control dropdown-button ${className}`}
          onClick={toggleDropdown}
          disabled={disabled}
        >
          <span className="select-text">{selectedValue ? selectedValue : placeholder}</span>
        </button>
        <div className="options-container">
          <ul className="dropdown-options">
            {selectElementOptionList?.map((option) => (
              <li
                className="dropdown-option"
                key={option.id}
                onClick={() => handleOption(option)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>

      </div>
    </Fragment>
  );
}

// const clickOutsideConfig = {
//   handleClickOutside: () => SelectElement.handleClickOutside,
// }
// export default onClickOutside(SelectElement, clickOutsideConfig);
export default SelectElement;
