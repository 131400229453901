import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAppFirstTime: true
};

const AppReducer = createSlice({
    name: "app",
    initialState,
    reducers: {
        setIsAppFirstTime(state, action) {
            state.isAppFirstTime = action.payload;
        },
    }
});

export const { setIsAppFirstTime } = AppReducer.actions;
export default AppReducer.reducer;
