import React from "react";
import "./SellerAppPolicy.scss";

const SellerAppPolicy = () => {
  return (
    <div className="seller-app-policy-container">
      <h1>Easy Pay’s Seller App Policy for Buyer Apps</h1>

      <section>
        <p>
          As a Seller App on the ONDC Network, Easy Pay believes in fair,
          transparent, and equal treatment of all Buyer Apps.
        </p>
        <p>
          We, as a Seller Network Participant on the ONDC Network, hereby
          declare our commitment to uphold the principle of:
        </p>
        <ol className="outerList">
          <li>
            Equal and non-differential treatment of all Buyer Apps in terms of -
          </li>
          <ol className="innerList">
          <li>Responding to the search requests raised by the Buyer Apps;</li>
          <li>
            Providing the Buyer Apps with the same transaction terms provided by
            the Sellers to us; and
          </li>
          <li>
            Providing the Buyer Apps with the same catalogs or features provided
            by the Sellers to us;
          </li>
          </ol>
          <li>
            Upholding transparency, fairness, and unbiased interactions, as our
            foremost priority.
          </li>
        </ol>
      </section>

      <section>
        <h2>Whitelisting</h2>
        <p>
          The new buyer apps will have to go through a whitelisting process
          before they start sellers' catalog from Easy Pay seller app. The
          process is very simple. They will have to share their bap id with Easy
          Pay team and Easy Pay team will observe their requests for 2-3 days
          before enabling them.
        </p>
      </section>

      <section>
        <p>
          The declaration provided herein may be subject to change based on
          certain technical factors which includes but is not limited to changes
          introduced by the ONDC Network.
        </p>
      </section>
    </div>
  );
};

export default SellerAppPolicy;
