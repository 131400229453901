import React from "react";
import { useNavigate } from "react-router-dom";
import "./OrderHistoryCard.scss";
import { formatOrderDate } from "../../utils/encryption";
import { formatOrderHistoryDate, googleAnalyticsFunc } from "../../utils/commonFunctions";

const OrderHistoryCard = ({ order, cardRef }) => {
  const navigate = useNavigate();

  return (
    <div key={order.orderReferenceNumber} className="mobile-order-card" ref={cardRef}>
      <div className="order-detail">
        <div className="order-product">
          <div className="order-id">{order.orderReferenceNumber}</div>
          <div className="total-status">
            ₹ {order.quote.totalPrice.toFixed(2)}
            <div className="bracket-content">
              (
              {order.itemArray.reduce(
                (sum, item) => sum + item.itemQuantity,
                0
              )}
              Product)
            </div>
          </div>
        </div>
        <div className="order-status">
          {
            order.fulfillmentStatus ?
              order.fulfillmentStatus
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase())
              :
              "Pending"
          }
        </div>
      </div>
      <div className="delivery-detail">
        <div className="order-date">
          {formatOrderHistoryDate(order.orderDate)}
        </div>
        <div className="date-view">
          <span
            onClick={() => {
              googleAnalyticsFunc(
                `View_Details_clicked`,
                `OrderHistoryCard_View_Details_clicked`,
                `OrderHistoryCard`
              );
              navigate(
                `/profile/order-history/${order.orderReferenceNumber}`
              )
            }}
            className="view-details"
          >
            View Details<span className="arrow-icon">&gt;</span>
          </span>
        </div>
      </div>
    </div>
  );

}
export default OrderHistoryCard;
