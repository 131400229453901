import React, { useState } from "react";
import "./Register.scss";
import ImageHeader from "../../components/ImageHeader/ImageHeader";
import { Is_Login } from "../../redux/actions";
import { setLocalStorageData } from "../../utilities/Storage";
import { getUserInfoAndSetConstants } from "../../constants/AppContants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { saveDetails } from "../../redux/actions/Action";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

export const Register = () => {
  const location = useLocation();
  const [registrationData, setRegistrationData] = useState({
    fullName: "",
    email: "",
    mobile: location.state?.mobileNumber || "",
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData((prevData) => ({
      ...prevData,
      [name]: name === "mobile" ? value.replace(/[^0-9]/g, "") : value,
    }));
  };

  const register = () => {
    const { fullName, email } = registrationData;

    if (!fullName) {
      toast.error("Full Name is required.");
      return;
    }

    if (fullName.length < 3) {
      toast.error("Full Name should contain at least 3 characters.");
      return;
    }

    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!nameRegex.test(fullName)) {
      toast.error("Full Name should not contain special characters.");
      return;
    }

    if (!email) {
      toast.error("Email is required.");
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error("Enter a valid email.");
      return;
    }

    if (!acceptTerms) {
      toast.error("You must accept the terms & conditions.");
      return;
    }

    setIsLoading(true);
    googleAnalyticsFunc(
      `Create_Account_Btn_Clicked`,
      `Register_Create_Account_Btn_Clicked`,
      `Register`
    );
    dispatch(saveDetails(registrationData))
      .then(async (res) => {
        if (res.payload.RESP_CODE === 300) {
          dispatch(
            Is_Login({
              isLogin: true,
              isFirstTime: false,
            })
          );
          await setLocalStorageData(
            "IsLogin",
            JSON.stringify({ isLogin: true })
          );
          await setLocalStorageData(
            "userInfo",
            JSON.stringify({ userInfo: res.payload.DATA })
          );
          getUserInfoAndSetConstants();
          navigate("/");
        } else {
          toast.error(res?.payload?.RESP_MSG);
        }
      })
      .catch((e) => console.log("err", e))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <ImageHeader firstText="Account" secondText="Login" />
      <div className="register-container">
        <div className="register-form-container">
          <h2 className="register-title">Create Account</h2>
          <div className="register-form">
            <input
              type="text"
              name="fullName"
              placeholder="Enter Full Name"
              className="register-input"
              value={registrationData.fullName}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Enter Email"
              className="register-input"
              value={registrationData.email}
              onChange={handleChange}
            />
            {/* <input
              type="text"
              name="mobile"
              placeholder="Enter Mobile Number"
              className="register-input"
              maxLength={10}
              value={registrationData.mobile}
              onChange={handleChange}
            /> */}
            <div className="register-options">
              <label className="label-with-checkbox">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                />
                <p className="remember">
                  <a
                    href="https://easypay.in/privacypolicies.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="terms-link"
                  >
                    Accept all Terms & Conditions
                  </a>
                </p>
              </label>
            </div>

            <button
              type="submit"
              className="register-button"
              onClick={register}
            >
              Create Account
            </button>
            <p className="register-text">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
