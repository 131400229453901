import { v4 as uuidv4 } from "uuid";
import { getLocalStorageData } from "../utilities/Storage";

let deviceInfo = {
  AID: "",
  APP_VERSION: "",
  DEVICE_BRAND: "",
  DEVICE_NAME: "",
  DEVICE_VERSION: "",
  MOBILE: "",
  MID: "",
  UDID: "",
  IP_ADDRESS: "",
  MAC_ADDRESS: "",
};

export const AppConstants = {
  screenName: "test",
  mobile: "Dummy AID",
  AID: "Dummy AID",
  EMAIL: "android@easypay.in",
  NAME: "Easy Pay",
  fcm_token: "",
  APP_VERSION: "3.0.0.68",
  UDID: "02e104dde613ccb8",
  IP_ADDRESS: deviceInfo.IP_ADDRESS,
  MAC_ADDRESS: deviceInfo.MAC_ADDRESS,
  devicedtl: deviceInfo.DEVICE_NAME,
  REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
  bppId: "",
  bppUrl: "",
};

export const generateId = () => {
  try {
    return uuidv4();
  } catch (error) {
    console.error("Error generating transaction ID", error);
    return "";
  }
};

export const generateIdWithoutHyphens = () => {
  try {
    return uuidv4().replace(/-/g, "");
  } catch (error) {
    console.error("Error generating ID", error);
    return "";
  }
};

export const getUserInfoAndSetConstants = async () => {
  try {
    const userInfoString = await getLocalStorageData("userInfo");
    if (userInfoString !== null) {
      const userInfo = JSON.parse(userInfoString);

      AppConstants.mobile =
        userInfo?.userInfo?.AGENT_CODE || AppConstants.mobile;
      AppConstants.AID = userInfo?.userInfo?.AGENT_CODE || AppConstants.AID;
      AppConstants.NAME = userInfo?.userInfo?.NAME || AppConstants.NAME;
      AppConstants.EMAIL = userInfo?.userInfo?.EMAIL || AppConstants.EMAIL;
    } else {
      AppConstants.mobile = "Dummy AID";
      AppConstants.AID = "Dummy AID";
      AppConstants.NAME = "EasyPay";
      AppConstants.EMAIL = "android@easypay.in";
    }
  } catch (error) {
    console.error("Error retrieving user info from AsyncStorage", error);
  }
};

getUserInfoAndSetConstants();

export const validateForm = (addressData) => {
  const newErrors = {};

  if (!addressData?.name?.trim()) {
    newErrors.name = "Full name cannot be blank";
  } else if (addressData.name.trim().length < 3) {
    newErrors.name = "Full name must be at least 3 characters long";
  } else if (!/^[a-zA-Z\s]+$/.test(addressData.name)) {
    newErrors.name = "Full name cannot contain special characters";
  }

  if (!addressData?.mobile.trim()) {
    newErrors.mobile = "Mobile number cannot be blank";
  } else if (!/^[6-9]\d{9}$/.test(addressData.mobile)) {
    newErrors.mobile =
      "Mobile number must be 10 digits and start with 6, 7, 8, or 9";
  }

  if (
    addressData?.email?.trim() &&
    !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addressData.email)
  ) {
    newErrors.email = "Please enter a valid email address";
  }

  if (!addressData?.houseNo.trim()) {
    newErrors.houseNo = "Please fill this field";
  }

  if (!addressData?.addressTwo.trim()) {
    newErrors.addressTwo = "Please fill this field";
  }

  if (!addressData?.addressType) {
    newErrors.addressType = "Please select a type for 'Save as'";
  }

  return newErrors;
};
