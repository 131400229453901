import BuyerCarousel from "../../../components/BuyerCarousel/BuyerCarousel";
import "../../../assets/styles/global.scss";
import "./Banner.scss";
import React, { useEffect, useState } from "react";
// import bannerDesk1 from "../../../assets/images/homepage/carousel-banner/desk-banner-1.jpg";
// import bannerDesk2 from "../../../assets/images/homepage/carousel-banner/desk-banner-2.jpg";
// import bannerDesk3 from "../../../assets/images/homepage/carousel-banner/desk-banner-3.jpg";
// import bannerMob1 from "../../../assets/images/homepage/carousel-banner/mob-banner-1.jpg";
// import bannerMob2 from "../../../assets/images/homepage/carousel-banner/mob-banner-2.jpg";
// import bannerMob3 from "../../../assets/images/homepage/carousel-banner/mob-banner-3.jpg";
// import loanTapBanner from "../../../assets/images/homepage/loantap.png";
import { get_promotion_banner } from "../../../services/services";
import { AppConstants } from "../../../constants/AppContants";

const Banner = ({ setBannerData }) => {
  const [imagesData, setImagesData] = useState([
    // { desktop: bannerDesk1, mobile: bannerMob1 },
    // { desktop: bannerDesk2, mobile: bannerMob2 },
    // { desktop: bannerDesk3, mobile: bannerMob3 },
  ]);
  // const imagesData = [
  //   { desktop: loanTapBanner, mobile: loanTapBanner },
  //   { desktop: loanTapBanner, mobile: loanTapBanner },
  //   { desktop: loanTapBanner, mobile: loanTapBanner },
  // ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesData.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getPromotionBanners = async () => {
    try {
      const payload = {
        DATA: {
          CATEGORY: "All",
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: "3.0.0.68",
          CUSTOMER_CHARGE: 0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
          TXN_AMOUNT: 0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: "",
          epPartnerId: "",
          mobile: "",
          sellerCode: "",
          totalPrice: 0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      const res = await get_promotion_banner(payload);
      console.log(res, "res");
      setBannerData(res?.DATA);
      const images = res.DATA.filter(
        (imageData) =>
          imageData.CATEGORY === "WEB" || imageData.CATEGORY === "MOBILE"
      );
      setImagesData(images);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getPromotionBanners();
  }, []);

  return (
    <>
      <BuyerCarousel imagesData={imagesData} />
    </>
  );
};

export default Banner;
