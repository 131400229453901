import React, { Fragment, useContext, useEffect, useState } from "react";
import "./OrderPlaced.scss";
import graffiti from "../../assets/images/thank_you_graffiti.svg";
import Spinner, { SmallLoader } from "../../components/Loader/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPaymentStatus } from "../../redux/actions/Action";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import { Lotties } from "../../constants/LottieJson";
import { Context } from "../../contexts/AppContext";
import {
  AppConstants,
  getUserInfoAndSetConstants,
} from "../../constants/AppContants";

function OrderPlaced() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [flag, setFlag] = useState(null);
  const {  reduceCartItems, cartData, getCart } =
    useContext(Context);
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const orderId = queryParams.get("txnId");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initializeCartAndFetchStatus = async () => {
      try {
        await getUserInfoAndSetConstants();
        await getCart();

        if (!orderId) return;

        setLoading(true);
        const response = await dispatch(checkPaymentStatus(orderId));

        if (
          response?.payload?.DATA?.gateWayStatus &&
          response?.payload?.DATA?.status === "SUCCESS"
        ) {
          setFlag(true);
          try {
            const cartResponse = await reduceCartItems(
              true,
              "",
              cartData?.transactionId
            );
            setTimeout(() => {
              navigate(`/profile/order-history/${orderId}`);
            }, 2000);
          } catch (cartError) {
            console.error("Error in reduceCartItems:", cartError);
          }
        } else {
          setFlag(false);
          setTimeout(() => {
            navigate(`/profile/order-history/${orderId}`);
          }, 2000);
        }
      } catch (error) {
        console.error(
          "Error initializing data or fetching payment status:",
          error
        );
        navigate("/cart");
      } finally{
        setLoading(false);
      }
    };

    initializeCartAndFetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, dispatch, navigate, cartData?.transactionId]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Lotties.redCross,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    const initializeCart = async () => {
      await getUserInfoAndSetConstants();
      getCart();
    };

    if (AppConstants.AID) {
      initializeCart();
    }
  }, []);

  return (
    <Fragment>
      {loading && <Spinner />}
      <div className="order-placed">
        <div>
          {flag === null ? (
            <div></div>
          ) : flag ? (
            <>
              <img className="graffiti" src={graffiti} alt="Thank you" />
              <h1 className="text">Your order has been placed</h1>
              <div className="sub-text">
                <SmallLoader /> Redirecting to order details...
              </div>
            </>
          ) : (
            <>
              <Lottie options={defaultOptions} height={300} width={400} />
              <h1 className="text">Your payment failed!</h1>
              <div className="sub-text">
                <SmallLoader /> Redirecting to order details...
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default OrderPlaced;
