import React from 'react';
import './RefundOrderCancellationPolicy.scss';

const RefundOrderCancellationPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h1>Refund and Order Cancellation Policy</h1>

      <section>
        <h2>Cancellation Policy</h2>
        <p>
          The cancellation policy for orders placed on OpenKart may vary depending on the seller. 
        </p>
        <p>
          For ONDC Network sellers, some products are non-cancellable & non-returnable, and this information will be clearly indicated on the product page.
        </p>
        <p>
          For orders that are eligible for cancellation, customers can cancel them anytime before they are shipped. Once an order has been shipped, it can no longer be cancelled.
        </p>
        <p>
          The cancellation window varies by category, and orders cannot be cancelled once the specified time has elapsed or the status has changed.
        </p>
        <p>
          In some cases, the customer may not be allowed to cancel the order for free, post the specified time or the status has changed, and a cancellation fee will be charged. 
          The details about the time window mentioned on the product page or order confirmation page will be considered final.
        </p>
        <p>
          In case of any cancellation from the seller due to unforeseen circumstances, a full refund will be initiated for prepaid orders.
        </p>
        <p>
          OpenKart reserves the right to accept the cancellation of any order. OpenKart also reserves the right to waive off or modify the time window or cancellation fee from time to time.
        </p>
      </section>

      <section>
        <h2>Refund Policy</h2>
        <p>
          The refund terms may vary depending on the product category and the seller’s specific policies. The details outlined on the product page will take precedence over any general refund policy.
        </p>
        <p><strong>Eligibility for Refunds</strong> Refund eligibility is determined by the following conditions:</p>
        <ul>
          <li>
            <strong>Non-Cancellable Items:</strong> Certain products are non-cancellable once ordered. These items will be clearly marked on the product page. 
            If a refund is applicable, it will be processed only under specific conditions, such as if the product was not delivered or if there was a seller-initiated cancellation due to unforeseen circumstances.
          </li>
          <li>
            <strong>Cancellable Items:</strong> For items that can be cancelled, refunds will be processed if the order is cancelled before shipment. Once the order is shipped, cancellation is not possible, and a refund will not be initiated.
          </li>
          <li>
            <strong>Seller Cancellations:</strong> If the seller cancels the order due to stock unavailability or other reasons, a full refund will be issued to the customer for prepaid orders.
          </li>
        </ul>
        <h3>Refund Process</h3>
        <ul>
          <li>
            <strong>Processing Time:</strong> Refunds will be initiated once the cancellation is confirmed or the issue has been validated. 
            The time taken for the refund to reflect in the customer's account may vary depending on the payment method used. 
            <p>Refunds generally take 5 to 7 business days to reflect in the customer's bank account after the refund has been processed.</p>
          </li>
          <li>
            <strong>Refund Method:</strong> Refunds will be processed back to the original payment method used during purchase.
          </li>
        </ul>
        <h3>General Conditions</h3>
        <ul>
          <li>
            OpenKart reserves the right to modify or waive off the refund conditions at its discretion.
          </li>
          <li>
            In case of disputes or issues related to refunds, OpenKart’s decision will be considered final.
          </li>
        </ul>
        <p>
          For any specific inquiries or concerns regarding refunds, customers are encouraged to refer to the product page for detailed information or contact OpenKart’s customer support team.
        </p>
      </section>
    </div>
  );
};

export default RefundOrderCancellationPolicy;
