import React, { useEffect, useContext, useState, Fragment } from "react";
import axios from "axios";
import "./Home.scss";
import CardSlider from "../../components/CardSlider/CardSlider";
import { fetchDataFromApi, fetchDataFromStrapi } from "../../services/api";
import { Context } from "../../contexts/AppContext";
import Banner from "./Banner/Banner";
import advHome from "../../../src/assets/images/homepage/ad-home-1.png";
import brand1 from "../../../src/assets/images/homepage/brand-1.png";
import brand2 from "../../../src/assets/images/homepage/brand-2.png";
import brand3 from "../../../src/assets/images/homepage/brand-3.png";
import brand4 from "../../../src/assets/images/homepage/brand-4.png";
import brand5 from "../../../src/assets/images/homepage/brand-5.png";
import brand6 from "../../../src/assets/images/homepage/brand-6.png";
import brand7 from "../../../src/assets/images/homepage/brand-7.png";
import deal from "../../assets/images/homepage/vector-18@2x.png";
import Spinner from "../../components/Loader/Spinner";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import { useNavigate } from "react-router-dom";
import DealCard from "../../components/DealCard/DealCard";
import StoreItemCard from "../../components/StoreItemCard/StoreItemCard";
import NearByStoreList from "../../components/NearByStoreList/NearByStoreList";
import BestDealsNearMe from "../../components/BestDealsNearMe/BestDealsNearMe";
import { getQueryParamURIComponent } from "../../utils/encryption";
// import { generateFormattedItemArray } from "../../utils/commonFunctions";
import CategoryBrandSection from "../../components/CategoryBrandSection/CategoryBrandSection";

const Home = () => {
  const {
    userLocation,
    // setUserLocation,
    sellers,
    setSellers,
    nearestSeller,
    setNearestSeller,
    setLoading,
    getGeolocation,
    handleErrorMsg,
    categoriesInfo,
    setSelectedCategory,
    setSearchCategory,
    getUrlFriendlyCategoryName,
    setcategoryActiveTab,
  } = useContext(Context);

  const [categoryCards, setCategoryCards] = useState([]);
  const [nearestSeller2, setNearestSeller2] = useState({});
  const [bannerData, setBannerData] = useState([]);
  // const [dealsData, setDealsData] = useState([
  //   {
  //     id: 1,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 2,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 3,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 4,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 5,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 6,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 7,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  //   {
  //     id: 8,
  //     image_url: deal,
  //     discount: "15%",
  //     productName: "Lorem Epsum",
  //     price: "150",
  //     deliveryTime: 1,
  //   },
  // ]);
  const [dealCards, setDealCards] = useState([]);
  const [nearestStoreItemCards, setNearestStoreItemCards] = useState([]);
  const [nearestStore2ItemCards, setNearestStore2ItemCards] = useState([]);
  const [topDealData, setTopDealData] = useState({
    category: [],
    brands: [],
    heading: "",
  });
  //For BPC
  const [bpcData, setBpcData] = useState({
    category: [],
    brands: [],
    heading: "",
  });
  const [fashionData, setFashionData] = useState({
    category: [],
    brands: [],
    heading: "",
  });

  //Electronics
  const [electronicsData, setElectronicsData] = useState({
    category: [],
    brands: [],
    heading: "",
  });

  //Home Decor
  const [homeDecorData, setHomeDecorData] = useState({
    category: [],
    brands: [],
    heading: "",
  });

  const [groceryData, setGroceryData] = useState({
    category: [],
    brands: [],
    heading: "",
  });

  const [appliancesData, setAppliancesData] = useState({
    category: [],
    brands: [],
    heading: "",
  });
  const [healthData, setHealthData] = useState({
    category: [],
    brands: [],
    heading: "",
  });
  // const [electonicsStoreItemData, setElectonicsStoreItemData] = useState([
  //   {
  //     id: 31307,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31308,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31309,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31310,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31311,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31312,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31313,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  //   {
  //     id: 31314,
  //     attributes: {
  //       price: 6999,
  //       image_url: deal,
  //       item_details: {
  //         itemId:
  //           "92189044752737273062C3C825B2333F_65f28d8efbe9c712d7e6e8a0_default",
  //         item_name: "Tymo Airflow",
  //       },
  //     },
  //   },
  // ]);
  const [electonicsStoreItemCards, setElectonicsStoreItemCards] = useState([]);
  const [nearestSellerData, setNearestSellersData] = useState([]);
  const navigate = useNavigate();

  const navigateToCategoryList = (category) => {
    setSelectedCategory(category.categoryDisplayName);
    setSearchCategory(category.categoryDisplayName);
    const friendlyCategoryName = getUrlFriendlyCategoryName(
      category.categoryDisplayName
    );
    const categoryObj = {
      // id: index,
      key: category.category,
      name: category.categoryDisplayName,
    };
    navigate(
      `/category/${friendlyCategoryName}?cat=${encodeURIComponent(
        JSON.stringify(categoryObj)
      )}`
    );
  };

  useEffect(() => {
    setSearchCategory("All");
    if (categoriesInfo) {
      let cards = [];
      for (let categoryDetails of categoriesInfo) {
        cards.push(
          <CategoryCard
            key={categoryDetails.category}
            cardData={categoryDetails}
            onCardClick={() => {
              navigateToCategoryList(categoryDetails);
            }}
          />
        );
      }
      setCategoryCards(cards);
    }
  }, [categoriesInfo]);

  // useEffect(() => {
  //   let cards = [];
  //   for (let dealDetails of dealsData) {
  //     cards.push(<DealCard key={dealDetails.id} cardData={dealDetails} />);
  //   }
  //   setDealCards(cards);
  // }, [dealsData]);

  // 1. Getting sellers within a given radius
  useEffect(() => {
    const topStores = sessionStorage.getItem("topStores");
    if (topStores) {
      setNearestSellersData(JSON.parse(topStores));
    } else {
      getSellersInRadius();
    }
  }, [userLocation.latitude]);

  const getSellersInRadius = async () => {
    try {
      setLoading(true);
      let allSellers = [];
      let hasMoreData = true;
      let start = 0;
      const limit = 20;

      while (hasMoreData) {
        const userLocation = JSON.parse(localStorage.getItem("userLocation"));
        const latitude = userLocation?.latitude || null;
        const longitude = userLocation?.longitude || null;

        const data = await fetchDataFromStrapi(
          // `/get-nearby-sellers?lat=${latitude}&long=${longitude}&radius=1000&domainName=all&pagination[start]=10&pagination[limit]=2`
          `/get-nearby-v1-sellers?domainName=all&pagination[start]=10&pagination[limit]=2`
        );

        const sellersData = data;

        const sellerIds = sellersData.map((seller) => seller.seller_id);
        allSellers = [...allSellers, ...sellerIds];

        start += limit;
        hasMoreData = sellersData.length === limit;
      }
      setSellers(allSellers);

      if (allSellers.length > 0) {
        // const sellerIds = allSellers.map((seller) => seller.seller_id);
        await fetchProductSellerData(allSellers);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchProductSellerData = async (sellerId1, sellerId2) => {
  //   try {
  //     setLoading(true);
  //     const data = await fetchDataFromApi(
  //       process.env.REACT_APP_STRIPE_APP_DEV_URL +
  //         `/sellers?populate=*&filters[seller_id][$in][0]=${sellerId1}&filters[seller_id][$in][1]=${sellerId2}`
  //     );
  //     setNearestSeller(data?.data[0]);
  //     setNearestSeller2(data?.data[1]);
  //   } catch (error) {
  //     handleErrorMsg(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchProductSellerData = async (sellerIds) => {
    try {
      setLoading(true);

      const sellerFilters = sellerIds
        .map((id, index) => `filters[seller_id][$in][${index}]=${id}`)
        .join("&");

      const data = await fetchDataFromStrapi(
        `/sellers?populate=*&${sellerFilters}`
      );
      if (data?.data?.length) {
        setNearestSellersData(data.data);
        sessionStorage.setItem("topStores", JSON.stringify(data.data));
      } else {
        setNearestSellersData([]);
      }
    } catch (error) {
      handleErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const fetchProductSellerData = async (sellerIds) => {
  //   console.log(sellerIds, "fetchs");
  //   try {
  //     const data = await fetchDataFromApi(
  //       `${
  //         process.env.REACT_APP_STRIPE_APP_DEV_URL
  //       }/sellers?populate=*&filters[seller_id][$in]=${sellerIds.join(",")}`
  //     );
  //     console.log(data, "resp");
  //     return data?.data || [];
  //   } catch (error) {
  //     handleErrorMsg(error.message);
  //     return [];
  //   }
  // };
  const handleStoreItemCardClick = (storeItemDetails) => {
    const itemDetails = storeItemDetails.attributes.item_details;
    const storeDetails = {
      city: itemDetails.storDetails.city.toLowerCase().replace(/\s+/g, ""),
      domain: itemDetails.domain.toLowerCase().replace(/\s+/g, ""),
    };
    const queryParamValue = getQueryParamURIComponent(storeDetails);

    navigate(
      `/product/${itemDetails.itemId}/seller/${itemDetails.storDetails.store_id}?pd=${queryParamValue}`
    );
  };

  useEffect(() => {
    if (nearestSellerData?.length > 0) {
      const allCards = nearestSellerData.map((seller) => {
        // Create store cards for each seller
        const cards = seller.attributes.productseller.data.map(
          (storeItemDetails) => (
            <StoreItemCard
              key={storeItemDetails.id}
              cardData={storeItemDetails}
              onCardClick={() => handleStoreItemCardClick(storeItemDetails)}
            />
          )
        );

        return {
          seller_id: seller.attributes.seller_id,
          sellerName: seller.attributes.seller_name,
          storeImage: seller.attributes.seller_info.store_image,
          domain: seller.attributes.domain,
          cards, // Cards for this seller
        };
      });

      // Set the cards for all sellers
      setNearestStoreItemCards(allCards);
    }
  }, [nearestSellerData]);

  // useEffect(() => {
  //   if (Object.keys(nearestSeller).length !== 0) {
  //     const cards = [];
  //     for (let storeItemDetails of nearestSeller?.attributes.productseller
  //       .data) {
  //       cards.push(
  //         <StoreItemCard
  //           key={storeItemDetails.id}
  //           cardData={storeItemDetails}
  //           onCardClick={() => {
  //             navigate(
  //               `/product/${storeItemDetails.attributes.item_details.itemId}/seller/${storeItemDetails.attributes.store_details.store_name}`
  //             );
  //           }}
  //         />
  //       );
  //     }
  //     setNearestStoreItemCards(cards);
  //     // setNearestStore2ItemCards(cards);
  //   }
  // }, [nearestSeller]);

  // useEffect(() => {
  //   if (Object.keys(nearestSeller2).length !== 0) {
  //     const cards = [];
  //     for (let storeItemDetails of nearestSeller2?.attributes.productseller
  //       .data) {
  //       cards.push(
  //         <StoreItemCard
  //           key={storeItemDetails.id}
  //           cardData={storeItemDetails}
  //           onCardClick={() => {
  //             navigate(
  //               `/product/${storeItemDetails.attributes.item_details.itemId}/seller/${storeItemDetails.attributes.store_details.store_name}`
  //             );
  //           }}
  //         />
  //       );
  //     }
  //     setNearestStore2ItemCards(cards);
  //   }
  // }, [nearestSeller2]);

  // useEffect(() => {
  //   if (Object.keys(nearestSeller).length !== 0) {
  //     const cards = [];
  //     for (let storeItemDetails of electonicsStoreItemData) {
  //       cards.push(
  //         <StoreItemCard
  //           key={storeItemDetails.id}
  //           cardData={storeItemDetails}
  //         />
  //       );
  //     }
  //     setElectonicsStoreItemCards(cards);
  //   }
  // }, [nearestSeller]);
  // const storeClickHandler=(seller)=>{
  //   navigate(`/storeProductList/${seller.seller_id}`)
  // }

  //Handling top brands data

  useEffect(() => {
    if (bannerData) {
      //Top Brands
      const categories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_TOP_STORE"
      );

      const brands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_TOP_BRNAD"
      );

      setTopDealData({
        category: categories,
        brands: brands,
        heading: "Top Deal By Brands",
      });

      //For BPC
      const bpcCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_BPC_TOP_STORE"
      );
      const bpcBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_BPC_TOP_BRNAD"
      );

      setBpcData({
        category: bpcCategories,
        brands: bpcBrands,
        heading: "Beauty and Personal Care",
      });

      //Fashion
      const fashionCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_FASHION_TOP_STORE"
      );
      const fashionBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_FASHION_TOP_BRNAD"
      );

      setFashionData({
        category: fashionCategories,
        brands: fashionBrands,
        heading: "Fashion",
      });

      //electronics
      const electronicsCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_ELECTRONICS_TOP_STORE"
      );
      const electronicsBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_ELECTRONICS_TOP_BRNAD"
      );

      setElectronicsData({
        category: electronicsCategories,
        brands: electronicsBrands,
        heading: "Electronics",
      });

      //Home decor
      const homeDecorCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_HOMEDECORE_TOP_STORE"
      );
      const homeDecorBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_HOMEDECORE_TOP_BRNAD"
      );
      setHomeDecorData({
        category: homeDecorCategories,
        brands: homeDecorBrands,
        heading: "Furnishing & Styling",
      });

      //Grocery
      const groceryCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_GROCERY_TOP_STORE"
      );
      const groceryBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_GROCERY_TOP_BRNAD"
      );
      setGroceryData({
        category: groceryCategories,
        brands: groceryBrands,
        heading: "Grocery",
      });

      //Appliances
      const appliancesCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_APPLIANCES_TOP_STORE"
      );
      const appliancesBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_APPLIANCES_TOP_BRNAD"
      );
      setAppliancesData({
        category: appliancesCategories,
        brands: appliancesBrands,
        heading: "Appliances",
      });

      //Health
      const healthCategories = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_HEALTH_TOP_STORE"
      );
      const healthBrands = bannerData?.filter(
        (item) => item.CATEGORY === "WEB_HEALTH_TOP_BRNAD"
      );
      setHealthData({
        category: healthCategories,
        brands: healthBrands,
        heading: "Health And Wellness",
      });
    }
  }, [bannerData]);

  console.log(bannerData, "bannerData");

  return (
    <Fragment>
      <div className="home-page">
        <Banner setBannerData={setBannerData} />
        <section className="row-container">
          <div className="title-container">
            <h1 className="product-list-title">Shop By Category</h1>
            {/* <a className="explore-btn">explore all</a> */}
          </div>
          {categoriesInfo?.data?.length !== 0 && (
            <CardSlider cards={categoryCards} />
          )}
        </section>
        <BestDealsNearMe />

        {/* Top Deal */}
        {topDealData?.category?.length > 0 &&
          topDealData?.brands?.length > 0 && (
            <CategoryBrandSection
              categories={topDealData?.category}
              brands={topDealData?.brands}
              heading={topDealData?.heading}
              isTopDeal={true}
            />
          )}

        {/* ELECTRONICS */}
        {electronicsData?.category?.length > 0 &&
          electronicsData?.brands?.length > 0 && (
            <CategoryBrandSection
              categories={electronicsData?.category}
              brands={electronicsData?.brands}
              heading={electronicsData?.heading}
            />
          )}

        {/* Bpc Data */}
        {bpcData?.category?.length > 0 && bpcData?.brands?.length > 0 && (
          <CategoryBrandSection
            categories={bpcData?.category}
            brands={bpcData?.brands}
            heading={bpcData?.heading}
          />
        )}

        {/* fashion */}
        {fashionData?.category?.length > 0 &&
          fashionData?.brands?.length > 0 && (
            <CategoryBrandSection
              categories={fashionData?.category}
              brands={fashionData?.brands}
              heading={fashionData?.heading}
            />
          )}
        {/* Grocery */}
        {groceryData?.category?.length > 0 &&
          groceryData?.brands?.length > 0 && (
            <CategoryBrandSection
              categories={groceryData?.category}
              brands={groceryData?.brands}
              heading={groceryData?.heading}
            />
          )}

        {/* Home decor */}
        {homeDecorData?.category?.length > 0 &&
          homeDecorData?.brands?.length > 0 && (
            <CategoryBrandSection
              categories={homeDecorData?.category}
              brands={homeDecorData?.brands}
              heading={homeDecorData?.heading}
            />
          )}
        {/* Appliances */}
        {appliancesData?.category?.length > 0 &&
          appliancesData?.brands?.length > 0 && (
            <CategoryBrandSection
              categories={appliancesData?.category}
              brands={appliancesData?.brands}
              heading={appliancesData?.heading}
            />
          )}
        {/* Health and Wellness */}
        {healthData?.category?.length > 0 && healthData?.brands?.length > 0 && (
          <CategoryBrandSection
            categories={healthData?.category}
            brands={healthData?.brands}
            heading={healthData?.heading}
          />
        )}

        {/* <NearByStoreList nearestStoreItemCards={nearestStoreItemCards} /> */}
        {/* <section className="row-container">
          <div className="title-container">
            <h1 className="product-list-title">Shop by category</h1>
            <a className="explore-btn">explore all</a>
          </div>
          {categoriesInfo?.data?.length !== 0 && (
            <CardSlider cards={categoryCards} />
          )}
        </section> */}
        {/* <div className="row-container deals-container">
          <div className="title-container">
            <h1 className="product-list-title">Best Deals Near you</h1>
            <a className="explore-btn">explore all</a>
          </div>
          <CardSlider cards={dealCards} />
        </div> */}
        {/* <div className="row-container">
          <div className="title-container">
            <h1 className="product-list-title">Top Store Near you</h1>
          </div>
          <div className={sellers.length > 0 ? "cards-grid" : ""}>
            {sellers.length != 0 ? (
              sellers.filter((seller, index) => index < 6).map((seller) => (
                <div
                  key={seller.id}
                  className="store-card"
                  onClick={() => navigate(`/storeProductList/${seller.seller_id}`)}
                >
                  <img
                    src={seller.seller_info.store_image}
                    alt=""
                    className="store-img"
                  />
                  <p className="store-name">{seller.seller_name}</p>
                </div>
              ))
            ) : (
              <ErrorMessage message="Enable Location to view nearby sellers" />
            )}
          </div>
        </div> */}
        {/* <div className="adv-container">
          <img src={advHome} alt="" className="adv-home" />
        </div> */}
        {/* <div className="row-container deals-container"> */}
        {/* <div className="title-container"> */}
        {/* <h1 className="product-list-title">Automotive</h1> */}
        {/* <a className="explore-btn">explore all</a> */}
        {/* </div> */}
        {/* {nearestStoreItemCards.length !== 0 ? (
            <div className="adv-cards-row">
              <div
                className="store-adv-card"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
                    url(${nearestSeller?.attributes?.seller_info.store_image})`,
                }}
              >
                <div className="store-adv-content">
                  <button className="offer-button">Upto 20% Off</button>
                  <p className="adv-store-name">
                    {nearestSeller?.attributes?.seller_name}
                  </p>
                  <a href="" className="adv-product-link">
                    See All Products
                  </a>
                </div>
              </div>
              <CardSlider cards={nearestStoreItemCards} />
            </div>
          ) : (
            <ErrorMessage message="Enable Location to view nearby seller's products" />
          )} */}
        {/* {nearestStoreItemCards.length !== 0 ? (
            nearestStoreItemCards.map((seller, index) => (
              <div key={index} className="adv-cards-row">
                <div
                  className="store-adv-card"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
            url(${seller.storeImage})`,
                  }}
                >
                  <div className="store-adv-content">
                  <p className="adv-store-name" >{seller.sellerName}</p> 
                  onClick={() => navigate(`/storeProductList/${seller.seller_id}`)}
                  </div>
                </div>
                <CardSlider cards={seller.cards} />
              </div>
            ))
          ) : (
            <ErrorMessage message="Enable Location to view nearby seller's products" />
          )}
        </div> */}
        {/* <div className="row-container deals-container">
          <div className="title-container">
            <h1 className="product-list-title">Automotive</h1>
            <a className="explore-btn">explore all</a>
          </div>
          {nearestStore2ItemCards.length !== 0 ? (
            <div className="adv-cards-row">
              <div
                className="store-adv-card"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
                  url(${nearestSeller2?.attributes?.seller_info.store_image})`,
                }}
              >
                <div className="store-adv-content">
                  <button className="offer-button">Upto 20% Off</button>
                  <p className="adv-store-name">
                    {nearestSeller2?.attributes?.seller_name}
                  </p>
                  <a href="" className="adv-product-link">
                    See All Products
                  </a>
                </div>
              </div>
              <CardSlider cards={nearestStore2ItemCards} />
            </div>
          ) : (
            <ErrorMessage message="Enable Location to view nearby seller's products" />
          )}
        </div> */}
        {/*<div className="row-container deals-container">
          <div className="title-container">
            <h1 className="product-list-title">Electonics</h1>
            <a className="explore-btn">explore all</a>
          </div>
          {electonicsStoreItemCards.length !== 0 ? (
            <div className="adv-cards-row">
              <div className="store-adv-card">
                <div className="store-adv-content">
                  <button className="offer-button">Upto 20% Off</button>
                  <p className="adv-store-name">Lorem Ipsum</p>
                  <a href="" className="adv-product-link">
                    See All Products
                  </a>
                </div>
              </div>
              <CardSlider cards={electonicsStoreItemCards} />
            </div>
          ) : (
            <ErrorMessage message="Enable Location to view nearby seller's products" />
          )}
        </div> */}
        {/* <div className="sell-on-ondc-container">
          <div className="text-overlay">
            <div className="title">
              Sell on&nbsp;
              <span className="ondc">ONDC?</span>
            </div>
            <div className="description">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <button className="connect-now">CONNECT NOW</button>
          </div>
        </div> */}
        {/* <div className="row-container features-home">
          <div className="feature-home-item">
            <img src="" alt=""></img>
            <div className="title">Instant Delivery</div>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euis
            </p>
          </div>
          <div className="feature-home-item">
            <img src="" alt=""></img>
            <div className="title">Verified by ONDC</div>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euis
            </p>
          </div>
          <div className="feature-home-item">
            <img src="" alt=""></img>
            <div className="title">100% Assurance</div>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euis
            </p>
          </div>
        </div> */}
        {/* <div className="row-container brand">
          <img src={brand1}></img>
          <img src={brand2}></img>
          <img src={brand3}></img>
          <img src={brand4}></img>
          <img src={brand5}></img>
          <img src={brand6}></img>
          <img src={brand7}></img>
        </div> */}
        {/* <div className="row-container">
          <div className="feedback-container">
            <div className="total-feedback">
              <div className="open-quotes">‘’</div>
              <div className="feedback">
                <p>
                  My first order arrived today in perfect condition. From the
                  time I sent a question about the item to making the purchase,
                  to the shipping and now the delivery, your company, Tecs, has
                  stayed in touch. Such great service. I look forward to
                  shopping on your site in the future and would highly recommend
                  it.
                </p>
                <div className="cust-name">- Tama Brown</div>
              </div>
            </div>
            <div className="four-circles">
              <div className="circle1"> </div>
              <div className="circle2"> </div>
              <div className="circle2"> </div>
              <div className="circle2"> </div>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default Home;
