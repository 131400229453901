import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import "./StoreProductList.scss";
import ProductCard from "../../components/ProductCard/ProductCard";
import Pagination from "../../components/Pagination/Pagination";
import { Context } from "../../contexts/AppContext";
import ErrorMessage from "../../components/ErrorScreen/ErrorScreen";
import IconsContainer from "../../components/IconsContainer/IconsContainer";
import CategoriesDropdown from "../../components/CategoriesDropdown/CategoriesDropdown";
import Offers from "../../components/Offers/Offers";
import Spinner from "../../components/Loader/Spinner";
import { searchStoreByProviderId } from "../../redux/actions/Action";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { toast } from "react-toastify";
import { search_store_by_provider_id } from "../../services/services";
import { AppConstants } from "../../constants/AppContants";
import { getDecryptedQueryData } from "../../utils/encryption";
import {
  // generateFormattedItemArray,
  getCategoriesForFilter
} from "../../utils/commonFunctions";
import Filter from "../../components/Filter/Filter";
import timeIcon from "../../assets/icons/time-ic.svg";
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";

const StoreProductList = () => {
  const pageSize = 50;
  const { id } = useParams();
  const location = useLocation();
  const { store } = location.state || "";
  const [searchParams] = useSearchParams();
  const [queryParamsData] = useState(
    getDecryptedQueryData(searchParams.get("sd")),
  );

  const { setCurrentPage } = useContext(Context);
  const [loading, setIsLoading] = useState(true);
  const [city, setCity] = useState("IND");
  const [bppId, setBppId] = useState("");
  const [providerId, setProviderId] = useState("");
  const [domainName, setDomainName] = useState("all");
  const [storeDetails, setstoreDetails] = useState("");
  const [storeProductList, setstoreProductList] = useState("");
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categoriesForFilter, setCategoriesForFilter] = useState([]);
  const [storeClosed, setStoreClosed] = useState(false);
  const [closingSoon, setClosingSoon] = useState(null);
  const ApiForsearchStoreByProviderId = async (
    city,
    bppId,
    providerId,
    domainName,
  ) => {
    try {
      setIsLoading(true);
      const payload = {
        DATA: {
          city: city,
          ...(bppId && { bppId: bppId }),
          providerId: providerId,
          domainName: domainName,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          UDID: AppConstants.UDID,
          mobile: AppConstants.mobile,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          userId: AppConstants.mobile,
          versioNo: "",
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        },
      };

      const res = await search_store_by_provider_id(payload);

      if (res.RESP_CODE === 300) {
        setstoreDetails(res.DATA.onSearchData[0].store[0]);
        const items = res.DATA.onSearchData[0].store[0].items;
        setstoreProductList(items);
        setFilteredProducts(items);
      } else {
        toast.error(res.RESP_MSG);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkStoreStatus = (storeStart, storeEnd) => {
    try {
      const currentDeviceTime = new Date();
      const currentDeviceTimeIST = new Date(
        currentDeviceTime.getTime() + 5.5 * 60 * 60 * 1000
      );

      const storeStartTimeInIST = new Date(storeStart);
      const storeEndTimeInIST = new Date(storeEnd);

      if (
        currentDeviceTimeIST >= storeStartTimeInIST &&
        currentDeviceTimeIST <= storeEndTimeInIST
      ) {
        // Store is open
        const timeDiff =
          (storeEndTimeInIST - currentDeviceTimeIST) / (1000 * 60); // in minutes
        return {
          storeClosed: false,
          closingSoon: timeDiff <= 60 ? Math.round(timeDiff) : null, // Returns remaining minutes if <= 60
        };
      } else {
        // Store is closed
        return { storeClosed: true, closingSoon: null };
      }
    } catch (e) {
      console.log(e);
      return { storeClosed: false, closingSoon: null };
    }
  };


  useEffect(() => {
    if (storeProductList.length > 0) {
      const uniqueCategories = getCategoriesForFilter(storeProductList);
      setCategoriesForFilter(uniqueCategories);
    }
  }, [storeProductList]);

  useEffect(() => {
    setProviderId(id);
    if (id && queryParamsData) {
      setCity(queryParamsData?.city);
      setBppId(queryParamsData?.bpp_id);
      setDomainName(queryParamsData?.domain?.toLowerCase().replace(/\s+/g, ""));
    }
  }, [id, queryParamsData]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (city && providerId && domainName) {
        ApiForsearchStoreByProviderId(city, bppId, providerId, domainName);
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [city, bppId, providerId, domainName]);

  useEffect(() => {
    if (id) {
      setCurrentPage(1);
    }
  }, [id]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const calculateDistBetweenStoreNdUser = () => {
    const userLocation = JSON.parse(localStorage.getItem("userLocation"));
    if (userLocation && userLocation?.latitude !== null) {
      const userLatitude = userLocation.latitude;
      const userLongitude = userLocation.longitude;
      const storeLatitudeLongitude = storeDetails.store_location_gps;
      if (!storeLatitudeLongitude) {
        // console.error("Store location is not available.");
        return { distanceKm: 0, timeFormatted: "N/A" };
      }
      const [storeLatitude, storeLongitude] = storeLatitudeLongitude
        .split(",")
        .map((coord) => parseFloat(coord.trim()));
      if (isNaN(storeLatitude) || isNaN(storeLongitude)) {
        console.error("Invalid store location coordinates.");
        return { distanceKm: 0, timeFormatted: "N/A" };
      }
      const toRad = (value) => (value * Math.PI) / 180;
      const R = 6371;
      const lat1 = toRad(userLatitude);
      const lon1 = toRad(userLongitude);
      const lat2 = toRad(storeLatitude);
      const lon2 = toRad(storeLongitude);
      const deltaLat = lat2 - lat1;
      const deltaLon = lon2 - lon1;
      const a =
        Math.sin(deltaLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distanceKm = R * c;
      const userSpeed = 29.9;
      const timeInHours = distanceKm / userSpeed;
      let timeInMinutes = timeInHours * 60;
      let timeFormatted = "";

      if (timeInMinutes >= 1440) {
        const timeInDays = Math.floor(timeInMinutes / 1440);
        timeFormatted = `${timeInDays} day${timeInDays > 1 ? "s" : ""}`;
      } else if (timeInMinutes >= 60) {
        const timeInActualHours = Math.floor(timeInMinutes / 60);
        const remainingMinutes = Math.floor(timeInMinutes % 60);
        timeFormatted = `${timeInActualHours} hour${
          timeInActualHours > 1 ? "s" : ""
        } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
      } else {
        timeFormatted = `${Math.floor(timeInMinutes)} minute${
          timeInMinutes !== 1 ? "s" : ""
        }`;
      }

      return {
        distanceKm,
        timeFormatted,
      };
    } else {
      return "";
    }
  };

  const result = calculateDistBetweenStoreNdUser();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 1) {
      const filtered = storeProductList.filter((product) =>
        product.item_name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(storeProductList);
    }
  };
  function toCamelCase(text) {
    if (!text) return "";
    return text.replace(
      /\b\w+/g,
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* <div className="HeaderLinear"></div>
          <div className="row-container filterContainers">
            <div className="HeaderLinearChild">
              <div className="filterFlexdiv">
                <div>
                  <img
                    src={storeDetails.store_image || "/image_food.png"}
                    className="HeaderLinearChildImg"
                    alt=""
                  />
                </div>
                <div>
                  <h2 className="superMakerth">{storeDetails.store_name}</h2>
                  <div className="superMakerths">
                  <p>
                  <img
                        src="/location.png"
                        className="locationIcon"
                        alt=""
                      />
                      {storeDetails.store_street &&
                        toCamelCase(storeDetails.store_street)+", "}
                      {storeDetails.store_city &&
                        toCamelCase(storeDetails.store_city)+", "}
                      {storeDetails.store_area_code &&
                        toCamelCase(storeDetails.store_area_code)}
                    </p>
                    <p>
                    <img src="/vehicle.png" className="locationIcon" alt="" />
                      {result.distanceKm.toFixed(2)} km {result.timeFormatted}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {storeDetails ?(
            <Fragment>
          <div className="store-background">
            <div className="row-container">
              <div className="store-content">
                <img
                  src={storeDetails.store_image || "/image_food.png"}
                  className="store-image"
                  alt=""
                />

                <div className="share-button">
                  {/* <img className="share-icon" src="/share.png" alt="" /> */}
                </div>
              </div>
              <div className="store-details">
                <h1 className="store-name">{storeDetails.store_name}</h1>
                <div className="store-add-dtls">
                  <p className="store-adds">
                    <img src="/location.png" className="location-icon" alt="" />
                    <span className="store-add-text">
                      {storeDetails.store_street &&
                        toCamelCase(storeDetails.store_street) + ", "}
                      {storeDetails.store_city &&
                        toCamelCase(storeDetails.store_city) + ", "}
                      {storeDetails.store_area_code &&
                        toCamelCase(storeDetails.store_area_code)}
                      {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Magnam deserunt consectetur corrupti recusandae, ad ea,
                      accusantium officia, perferendis sint iste mollitia. Alias
                      saepe culpa, nam velit consequatur maxime nulla iste!
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nisi, neque. Voluptatibus quidem possimus placeat aperiam
                      soluta in animi totam recusandae. */}
                    </span>
                  </p>
                  {result && (
                    <p className="store-time">
                      <img
                        src="/vehicle.png"
                        className="location-icon"
                        alt=""
                      />
                      {result.distanceKm.toFixed(2)} km {result.timeFormatted}{" "}
                    </p>
                  )}
                  {storeClosed ? (
                    <p className="store-closed">
                      <img src={timeIcon} className="location-icon" alt="" />
                      Closed
                    </p>
                  ) : closingSoon !== null ? (
                    <p className="store-closed-in">
                      <img src={timeIcon} className="location-icon" alt="" />
                      Closes in {closingSoon} min
                    </p>
                  ) : (
                    <p className="store-open">
                      <img src={timeIcon} className="location-icon" alt="" />
                      Open
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row-container filterContainer">
            <div className="fullWidthSection">
              <div className="mainComponent">
                <div className="headerChanges">
                  {/* <Filter categories={categoriesForFilter} /> */}
                  <IconsContainer handleSearchChange={handleSearchChange} />
                </div>
                {filteredProducts.length !== 0 ? (
                  <div className="cardsContainer">
                    {filteredProducts.map((product) => (
                      <ProductCard
                        key={null}
                        data={product}
                        isFromCatrgory={false}
                        isStoreClosed={storeClosed}
                        ref={null}
                      />
                    ))}
                  </div>
                ) : (
                  <ErrorMessage message="No products found" />
                )}
                {/* <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              /> */}
              </div>
            </div>
            {/* <Offers /> */}
          </div>
          </Fragment>
          ):(
            <ErrorScreen message="Store details not found"/>
          )}
        </>
      )}
    </Fragment>
  );
};

export default StoreProductList;
