import React, { Fragment, useEffect, useState } from "react";
import "./AddressList.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import {
  GetAddressBuyer,
  requestForUpdateAndDeleteAddress,
} from "../../redux/actions/Action";
import homeIcon from "../../assets/icons/home1.png";
import officeIcon from "../../assets/icons/basket.png";
import Navigate from "../../assets/icons/navigate.png";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import cross from "../../assets/icons/cross-for-close.svg";
import notfoundicon from "../../assets/images/not-found-icon.png"
import Spinner from "../../components/Loader/Spinner";
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

function AddressList() {
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [removeAddressModalVisible, setRemoveAddressModalVisible] =
    useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const getAddressList = () => {
    try{
    setIsLoading(true);
    dispatch(GetAddressBuyer()).then((res) => {
      if (res.payload?.RESP_CODE === 300) {
        setAddressList(res.payload.DATA);
      }
    });
  } catch (error) {
    console.error("Error fetching address:", error);
  } finally {
    setIsLoading(false);

  }
  };

  useEffect(() => {
    getAddressList();
  }, []);

  const deleteAddress = async () => {
    try {
      setIsLoading(true);
      const res = await dispatch(
        requestForUpdateAndDeleteAddress({
          addressId: selectedAddress?.addressId,
          addressOne: selectedAddress?.addressOne,
          addressTwo: selectedAddress?.addressTwo,
          addressTypeName: selectedAddress?.addressTypeName,
          area: selectedAddress?.area,
          city: selectedAddress?.city,
          houseNo: selectedAddress?.houseNo,
          isDefault: selectedAddress?.isDefault,
          landmark: selectedAddress?.landmark,
          latitude: selectedAddress?.latitude,
          longitude: selectedAddress?.longitude,
          pincode: selectedAddress?.pincode,
          state: selectedAddress?.state,
          name: selectedAddress?.name,
          mobile: selectedAddress?.mobile,
          addressType: selectedAddress?.addressTypeName.toUpperCase(),
          updateFlag: "N",
        })
      );
      if (res?.payload?.RESP_CODE === 300) {
        setRemoveAddressModalVisible(false);
        toast.success(res.payload?.RESP_MSG);
        setAddressList(res.payload?.DATA);
      } else {
        toast.error(res.payload?.RESP_MSG);
      }
    } catch (error) {
      // console.error('Error sending status:', error);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Spinner />
      ) : addressList.length !== 0 ?  (
        <>
        <div className="address-container">
          <div className="header">
            <h2>My Address</h2>
            <div
              className="add-address"
              onClick={() => {
                navigate("/profile/add-address");
                googleAnalyticsFunc(
                  `Add_New_Address_clicked`,
                  `AddressList_Add_New_Address_clicked`,
                  `AddressList`
                );
              }}
            >
              Add New Address
            </div>
          </div>
          <div className="address-grid">
            {addressList
              ?.map((address, index) => (
                <div
                  className={
                    address?.addressId === selectedAddress?.addressId &&
                    removeAddressModalVisible
                      ? "address-card selected-add"
                      : "address-card"
                  }
                  key={address?.addressId}
                >
                  <h3 className="name">{address?.name}</h3>
                  <p className="address">
                    {[
                      address?.houseNo,
                      address?.addressOne
                      // address?.addressTwo, // not showing to avoid customer's confusion
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                  {address?.mobile && (
                    <div className="add-contact">
                      <p>PHONE</p>
                      <p>{address?.mobile}</p>
                    </div>
                  )}
                  <div className="actions">
                    <button
                      className="remove-btn"
                      onClick={() => {
                        setSelectedAddress(address);
                        setRemoveAddressModalVisible(true);
                        googleAnalyticsFunc(
                          `Remove_Btn_clicked`,
                          `AddressList_Remove_Btn_clicked`,
                          `AddressList`
                        );
                      }}
                    >
                      Remove
                    </button>
                    <button
                      className="edit-btn"
                      onClick={() => {
                        navigate("/profile/edit-address", { state: address });
                        googleAnalyticsFunc(
                          `Edit_Btn_clicked`,
                          `AddressList_Edit_Btn_clicked`,
                          `AddressList`
                        );
                      }}>
                      Edit
                    </button>
                    <span
                      className={`type ${address?.addressTypeName?.toLowerCase()}`}
                    >
                      <img
                        src={
                          address?.addressTypeName === "HOME"
                            ? homeIcon
                            : address?.addressTypeName === "OFFICE"
                            ? officeIcon
                            : Navigate
                        }
                        width={30}
                        alt=""
                      />
                      {address?.addressTypeName?.charAt(0).toUpperCase() +
                        address?.addressTypeName?.slice(1).toLowerCase()}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        </>
     ) : (

        <ErrorScreen message="No Address Found"  isShowAddAddress={true}  />

    )}

      <Modal
        open={removeAddressModalVisible}
        onClose={() => setRemoveAddressModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="popup-box">
          <img
            src={cross}
            alt="close"
            className="close-cross"
            onClick={() => {
              setRemoveAddressModalVisible(false);
              googleAnalyticsFunc(
                `DeleteAddressPopup_CloseIcon_clicked`,
                `AddressList_DeleteAddressPopup_CloseIcon_clicked`,
                `AddressList`
              );
            }}
          />
          <h3 className="popup-title">Are You Sure?</h3>
          <p className="popup-content">
            Kindly confirm if you want to delete the address.
          </p>

          <div className="popup-button-group">
            <button
              className="popup-button popup-reject"
              onClick={() => {
                setRemoveAddressModalVisible(false);
                googleAnalyticsFunc(
                  `DeleteAddressPopup_No_Btn_clicked`,
                  `AddressList_DeleteAddressPopup_No_Btn_clicked`,
                  `AddressList`
                );
              }}
            >
              No
            </button>
            <button
              className="popup-button popup-accept"
              onClick={() => {
                deleteAddress();
                googleAnalyticsFunc(
                  `DeleteAddressPopup_Yes_Btn_clicked`,
                  `AddressList_DeleteAddressPopup_Yes_Btn_clicked`,
                  `AddressList`
                );
              }}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default AddressList;
