"use client";
import { createSlice } from "@reduxjs/toolkit";
import { sendRequestForStatus } from "../actions/Action";

const initialState = {
  RESPONSE: false,
  RESP_MSG: "",
  data: {},
};

const SendRequestForStatusSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendRequestForStatus.pending, (state) => {
        state.RESPONSE = true;
      })
      .addCase(sendRequestForStatus.fulfilled, (state, { payload }) => {
        state.RESPONSE = false;
        state.RESP_MSG = payload.RESP_MSG;
        state.data = payload.DATA;
      })
      .addCase(sendRequestForStatus.rejected, (state, { payload }) => {
        state.RESPONSE = false;
        state.RESP_MSG = payload.RESP_MSG;
      });
  },
});

export default SendRequestForStatusSlice.reducer;
