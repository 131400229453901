import { useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import "./RaiseTicket.scss";
import image from "../../../src/assets/images/Image.png";
import uploadIcon from "../../../src/assets/images/uploadIcon.svg";
import arrowUp from "../../../src/assets/images/arrow-up.svg";
import arrowDown from "../../../src/assets/images/arrow-down.svg";
import {
  AppConstants,
  generateId,
  generateIdWithoutHyphens,
} from "../../constants/AppContants";
import { get_order_details } from "../../services/services";
import {
  getIssueCategory,
  SendRequestForSubmitIssue,
  submitIssueDetails,
} from "../../redux/actions/Action";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { toast } from "react-toastify";
import Spinner from "../../components/Loader/Spinner";
import IssueProductCard from "../../components/IssueProductCard/IssueProductCard";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

function RaiseTicket() {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [issueImageUrl, setIssueImageUrl] = useState("");
  const [issueCategories, setIssueCategories] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const dispatch = useAppDispatch();
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedCategoryDetails, setSelectedCategoryDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const issueId = generateId();
  const navigate = useNavigate();

  const handleSubcategorySelection = (categoryId, subcategoryCode) => {
    const selectedCategory = issueCategories.find(
      (category) => category.id === categoryId
    );
    const selectedSubCategory = selectedCategory?.subCategory.find(
      (sub) => sub.subCategoryCode === subcategoryCode
    );

    if (selectedCategory && selectedSubCategory) {
      setSelectedCategoryDetails({
        category: selectedCategory.category,
        subcategoryEnum: selectedSubCategory.enum,
      });
    }

    setSelectedSubcategory({ categoryId, subcategoryCode });
  };

  const getOrderDetails = async () => {
    setIsLoading(true)
    let payload = {
      DATA: {
        screenName: AppConstants.screenName,
        ORDER_ID: id,
      },
      HEADER: {
        AGENT_ID: "",
        AID: AppConstants.AID,
        APP_VERSION: AppConstants.APP_VERSION,
        CUSTOMER_CHARGE: 0.0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0.0,
        REQUEST_ID: AppConstants.REQUEST_ID,
        TXN_AMOUNT: 0.0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: generateIdWithoutHyphens(),
        sellerCode: "",
        totalPrice: 0.0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };
    const data = await get_order_details(payload);

    if (data.RESP_CODE === 300) {
      setOrderDetails(data.DATA.orderDetails[0]);
      setIsLoading(false)
    } else {
      setIsLoading(false)
      toast.error(data.RESP_MSG)
    }
  };
  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) =>
      prev.find((selectedItem) => selectedItem.itemId === item.itemId)
        ? prev.filter((selectedItem) => selectedItem.itemId !== item.itemId)
        : [...prev, item]
    );
  };

  useEffect(() => {
  }, [selectedItems]);

  useEffect(() => {
    getOrderDetails();
    callApiGetIssuesCategory();
  }, []);
  useEffect(() => {
  }, [issueCategories]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
  
    if (file) {
      if (file.size > 2 * 1024 * 1024) { 
        toast.error("File size should not exceed 2 MB.");
        return;
      }
  
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setIssueImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
        setSelectedImage(file);
      } else {
        toast.error("Please select a valid image file.");
      }
    }
  };
  const callApiGetIssuesCategory = async () => {
    try {
      // setIsLoading(true);

      dispatch(getIssueCategory())
        .then((res) => {
          const { RESP_CODE, DATA } = res.payload;

          if (res.payload.RESP_CODE == 300) {
            const categoriesWithId = DATA.map((category, index) => ({
              ...category,
              id: generateIdWithoutHyphens(
                category.category || `category-${index}`
              ),
            }));
            setIssueCategories(categoriesWithId);
          } else {
            toast.error(res.payload.RESP_MSG)
          }
        })
        .catch((err) => console.log("eeerrr", err));
    } catch (error) {
      console.error("Error fetching issues:", error);
    } finally {
      // setIsLoading(false);
    }
  };
  const toggleCategory = (categoryId) => {
    // If the clicked category is already expanded, collapse it
    if (expandedCategory === categoryId) {
      setExpandedCategory(null);
    } else {
      // Otherwise, expand the clicked category and collapse others
      setExpandedCategory(categoryId);
    }
  };

  const submitHandler = async () => {
    const {
      transactionId,
      fulfillmentid,
      fulfillmentStatus,
      network_participant_id,
      itemArray,
      shippingAddress,
    } = orderDetails;

    const updatedItems = selectedItems.map((item) => {
      return {
        ...item,
        item_id: item.itemId,
        count: item.itemQuantity,
        itemId: undefined,
        itemQuantity: undefined,
      };
    });

    const details = {
      DATA: {
        screenName: "test",
        transaction_id: transactionId,
        message_id: generateId(),
        bpp_id: itemArray[0]?.bppId,
        bpp_uri: itemArray[0]?.bppUrl,
        city: shippingAddress?.city,
        issue_id: issueId,
        category: selectedCategoryDetails.category,
        sub_category: selectedCategoryDetails.subcategoryEnum,
        customer_name: AppConstants.NAME,
        customer_phone: AppConstants.mobile,
        customer_email: AppConstants.EMAIL,
        order_id: id,
        state: "OPEN",
        fulfillment_id: fulfillmentid,
        fulfillment_state: fulfillmentStatus,
        provider_id: orderDetails.sellerId,
        items: updatedItems,
        short_desc: shortDescription,
        long_desc: longDescription,
        additional_desc_file:
          "https://interfac-app/igm/additonal-desc/user/desc.txt",
        network_participant_id: network_participant_id,
        status: "OPEN",
        complainant_action: "OPEN",
        complainant_short_desc: shortDescription,
      },
      HEADER: {
        AGENT_ID: "",
        AID: AppConstants.AID,
        CUSTOMER_CHARGE: 0.0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0.0,
        REQUEST_ID: 0,
        TXN_AMOUNT: 0.0,
        TXN_DT: "",
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: id,
        sellerCode: "",
        totalPrice: 0.0,
        userId: "",
        versioNo: "",
      },
    };

    // Optionally append file if selected
    // formData.append('file', selectedImage);

    try {
      setIsLoading(true);
      const result = await submitIssueDetails(details, selectedImage);

      if (result.RESP_CODE === 300) {
        toast.success(result.RESP_MSG); // Logs the success message
        navigate(`/profile/tickets/${issueId}`, { replace: true });
        // navigate(-1, { replace: true });
      } else {
        toast.error(result.RESP_MSG);
        console.warn(
          "Unexpected response:",
          result.RESP_MSG || "No message provided"
        );
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.message);

    } finally {
      setIsLoading(false);
    }
  };
  const handleBackToOrderDtl = () => {
    navigate(-1, { replace: true });
  };

  const handleSubmitButton = () => {
    if (selectedItems.length === 0) {
      toast.error("Please select at least one product.");
      return;
    }
  
    const shortDescription = document
      .querySelector(".short-description textarea")
      .value.trim();
    const validDescriptionRegex = /^[a-zA-Z0-9.,\s]+$/;
  
    if (!shortDescription || shortDescription.length < 3) {
      toast.error("Short description must have at least 3 characters.");
      return;
    }
    if (shortDescription.length > 300) {
      toast.error("Short description must be less than 300 characters.");
      return;
    }
    if (!validDescriptionRegex.test(shortDescription)) {
      toast.error("Special characters are not allowed in short description.");
      return;
    }
  
    const longDescription = document
      .querySelector(".long-description textarea")
      .value.trim();

    if (!longDescription || longDescription.length < 3) {
      toast.error("Long description must have at least 3 characters.");
      return;
    }
    if (longDescription.length > 300) {
      toast.error("Long description must be less than 300 characters.");
      return;
    }
    if (!validDescriptionRegex.test(longDescription)) {
      toast.error("Special characters are not allowed in long description.");
      return;
    }
  
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (
      !selectedSubcategory ||
      !selectedSubcategory.categoryId ||
      !selectedSubcategory.subcategoryCode
    ) {
      toast.error("Please select an issue category.");
      return;
    }
    submitHandler();
    googleAnalyticsFunc(
      `Submit_Btn_Clicked`,
      `RaiseTicket_Submit_Btn_Clicked`,
      `RaiseTicket`
    );
  };
  

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="ticket-container">
            <div className="header">
              <h2>Problem with the Order</h2>
              <div className="back-to-orders" onClick={() => {
                handleBackToOrderDtl();
                googleAnalyticsFunc(
                  `Back_To_Order_Btn_Clicked`,
                  `RaiseTicket_Back_To_Order_Btn_Clicked`,
                  `RaiseTicket`
                );
      }}>
                Back to Order
              </div>
            </div>
            <div className="main-content">
              <p className="tell-us-text">
                Tell us about the problem you are facing with the order
              </p>

              <h5 className="main-title">Select Products*</h5>
              <IssueProductCard
                orderDetails={orderDetails}
                handleCheckboxChange={handleCheckboxChange}
                selectedItems={selectedItems}
              />
              <h5 className="main-title">Describe the issue*</h5>
              <div className="issue-description">
                <div className="short-description">
                  <textarea
                    placeholder="Short Description"
                    value={shortDescription}
                    maxLength={300}
                    onChange={(e) => setShortDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="long-description">
                  <textarea
                    placeholder="Long Description"
                    value={longDescription}
                    maxLength={300}
                    onChange={(e) => setLongDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="file-upload">
                <h5 className="main-title">Upload an Image*</h5>
                <div
                  className="upload-box"
                  onClick={() => {
                    document.getElementById("file-input").click();
                    googleAnalyticsFunc(
                      `${selectedImage ? 'Preview' : 'Upload'}_An_Image`,
                      `RaiseTicket_${selectedImage ? 'Preview' : 'Upload'}_An_Image`,
                      `RaiseTicket`
                    );
                  }}>
                  {selectedImage ? (
                    <img
                      id="upload-preview"
                      className="upload-preview"
                      src={issueImageUrl}
                      alt="Uploaded Preview"
                    />
                  ) : (
                    <img
                      id="upload-icon"
                      className="upload-icon"
                      src={uploadIcon}
                      alt="Upload Icon"
                    />
                  )}
              {selectedImage && (
                  <button className="replace-button">Replace</button>
                )}
                  <span id="upload-text" className="upload-text">
                    {selectedImage
                      ? "Click here or drag image to replace"
                      : "Drag your file here or click to browse"}
                  </span>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
              <h5 className="main-title">Select the Appropriate issue*</h5>
              <div className="issue-categories">
                {issueCategories.length > 0 ? (
                  issueCategories.map((category) => (
                    <div key={category.id} className="issue-category">
                      <div
                        className="category-header"
                        onClick={() => {
                          toggleCategory(category.id);
                          googleAnalyticsFunc(
                            `Select_Appropriate_issue_Category`,
                            `RaiseTicket_Select_Appropriate_issue_Category`,
                            `RaiseTicket`
                          );
                        }}
                      >
                        <span className="category-title">
                          {category.category}
                        </span>
                        <span className="arrow-icon">
                          <span className="arrow-icon">
                            <img
                              src={
                                expandedCategory === category.id
                                  ? arrowUp
                                  : arrowDown
                              }
                              alt={
                                expandedCategory === category.id
                                  ? "Arrow Up"
                                  : "Arrow Down"
                              }
                            />
                          </span>
                        </span>
                      </div>
                      {expandedCategory === category.id &&
                        category.subCategory?.length > 0 && (
                          <div className="subcategory-list">
                            {category.subCategory.map((subCategory) => (
                              <div
                                key={subCategory.subCategoryCode}
                                className="subcategory-item"
                              >
                                <input
                                  type="radio"
                                  id={`subCategory${subCategory.subCategoryCode}`}
                                  name="issue" // Use a global radio group name
                                  value={subCategory.subCategoryCode}
                                  checked={
                                    selectedSubcategory?.categoryId ===
                                    category.id &&
                                    selectedSubcategory?.subcategoryCode ===
                                    subCategory.subCategoryCode
                                  }
                                  onChange={() =>
                                    handleSubcategorySelection(
                                      category.id,
                                      subCategory.subCategoryCode
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`subCategory${subCategory.subCategoryCode}`}
                                >
                                  {subCategory.subCategoryName}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  ))
                ) : (
                  <p>Loading categories...</p>
                )}
              </div>
              <div className="submit-issue-btn" onClick={handleSubmitButton}>Submit</div>
            </div>


            {/* 

        <h5 className="main-title-no-line">Select Products*</h5>

        <section className="order-form">
        <IssueProductCard
              orderDetails={orderDetails}
              handleCheckboxChange={handleCheckboxChange}
              selectedItems={selectedItems}
            />

          <h5 className="main-title">Describe the issue*</h5>

          <div className="issue-description">
          <div className="short-description">
      <textarea
        placeholder="Short Description"
        value={shortDescription}
        maxLength={300}
        onChange={(e) => setShortDescription(e.target.value)}
      ></textarea>
    </div>
    <div className="long-description">
      <textarea
        placeholder="Long Description"
        value={longDescription}
        maxLength={300}
        onChange={(e) => setLongDescription(e.target.value)}
      ></textarea>
    </div>
          </div>

          <div className="file-upload">
            <h5 className="main-title">Upload an Image*</h5>
            <div
              className="upload-box"
              onClick={() => document.getElementById("file-input").click()}
            >
              {selectedImage ? (
                <img
                  id="upload-preview"
                  className="upload-preview"
                  src={issueImageUrl}
                  alt="Uploaded Preview"
                />
              ) : (
                <img
                  id="upload-icon"
                  className="upload-icon"
                  src={uploadIcon}
                  alt="Upload Icon"
                />
              )}
              <span id="upload-text" className="upload-text">
                {selectedImage
                  ? "Click here or drag image to replace"
                  : "Drag your file here or click to browse"}
              </span>
              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleFileUpload}
              />
            </div>
          </div>
          <h5 className="main-title">Select the Appropriate issue*</h5>
          <div className="issue-categories">
  {issueCategories.length > 0 ? (
    issueCategories.map((category) => (
      <div key={category.id} className="issue-category">
        <div
          className="category-header"
          onClick={() => toggleCategory(category.id)}
        >
          <span className="category-title">{category.category}</span>
          <span className="arrow-icon">
          <span className="arrow-icon">
  <img
    src={expandedCategory === category.id ? arrowUp : arrowDown}
    alt={expandedCategory === category.id ? "Arrow Up" : "Arrow Down"}
  />
</span>          
</span>
        </div>
        {expandedCategory === category.id && category.subCategory?.length > 0 && (
          <div className="subcategory-list">
            {category.subCategory.map((subCategory) => (
              <div key={subCategory.subCategoryCode} className="subcategory-item">
                <input
                  type="radio"
                  id={`subCategory${subCategory.subCategoryCode}`}
                  name="issue" // Use a global radio group name
                  value={subCategory.subCategoryCode}
                  checked={
                    selectedSubcategory?.categoryId === category.id &&
                    selectedSubcategory?.subcategoryCode === subCategory.subCategoryCode
                  }
                  onChange={() =>
                    handleSubcategorySelection(category.id, subCategory.subCategoryCode)
                  }
                />
                <label htmlFor={`subCategory${subCategory.subCategoryCode}`}>
                  {subCategory.subCategoryName}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    ))
  ) : (
    <p>Loading categories...</p>
  )}
          </div>
        </section>
           <div className="submit-issue-btn" onClick={handleSubmitButton}>Submit</div> */}
          </div>
        </>
      )}
    </Fragment>
  );
}
// Works code

export default RaiseTicket;
