import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Modal from "@mui/material/Modal";
import "./SelectLocation.scss";
import cross from "../../assets/icons/cross-for-close.svg";
import mapImage from "../../assets/images/mapImage.svg";
import currentLocation from "../../assets/icons/currentLocation.svg";
import searchLens from "../../assets/images/homepage/search-lens.svg";
import {
  get_google_address_from_place_id,
  get_google_address_predictions,
} from "../../services/services";
import { toast } from "react-toastify";
import { FetchingAddressUsingLatLong } from "../../utilities/FetchingAddressUsingLatLong";
import { AppConstants } from "../../constants/AppContants";
import AppContext, { Context } from "../../contexts/AppContext";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GoogleMapComponent from "../../utilities/GoogleMapComponent";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const SelectLocation = ({ open }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [selectFromMapClicked, setselectFromMapClicked] = useState(false);
  const [currentLocationClick, setcurrentLocationClick] = useState(false);
  const wrapperRef = useRef(null);

  const librariesArray = ["places", "geometry"];
  const mapStyles = {
    height: "200px",
    width: "100%",
    borderRadius: "10px",
  };
  const cityName = "";
  const {
    setUserLocation,
    selectLocationPopUp,
    setSelectLocationPopUp,
    setLoading,
  } = useContext(Context);

  const [marker, setMarker] = useState({
    lat: 0,
    lng: 0,
  });
  const initialAddressData = {
    addressOne: "",
    addressTwo: "",
    addressType: "",
    area: "",
    city: "",
    houseNo: "",
    latitude: "",
    longitude: "",
    pincode: "",
    state: "",
    landmark: "",
    isDefault: false,
    addressId: "",
    updateFlag: "N",
    name: "",
    mobile: "",
  };
  const [addressData, setAddressData] = useState(initialAddressData);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length !== 0) {
      getgooglePredictionsOnSearch(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    googleAnalyticsFunc(
      `handleSuggestion_Clicked`,
      `SelectLocation_handleSuggestion_Clicked`,
      `SelectLocation`
    );
    setQuery(suggestion.description);
    getGoogleLatLongFromPlaceId(suggestion.place_id);
    setSuggestions([]);

    if (!showMap) {
      setQuery("");
      setShowMap(false);
      setSelectLocationPopUp(false);
    }
  };

  const getgooglePredictionsOnSearch = async (searchText) => {
    const payload = {
      DATA: {
        input: searchText,
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: AppConstants.APP_VERSION,
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: AppConstants.REQUEST_ID,
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    const res = await get_google_address_predictions(payload);

    if (res.RESP_CODE === 300) {
      const predictionArray = res.DATA?.predictions;
      setSuggestions(predictionArray);
    } else {
      toast.error(res.RESP_MSG);
    }
  };

  const getGoogleLatLongFromPlaceId = async (place_id) => {
    const payload = {
      DATA: {
        input: place_id,
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: AppConstants.APP_VERSION,
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: AppConstants.REQUEST_ID,
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    const res = await get_google_address_from_place_id(payload);

    if (res.RESP_CODE === 300) {
      const { lat, lng } = res.DATA.result.geometry.location;
      const addressComponents = res.DATA.result.address_components;

      const addressComp = addressComponents.find(
        (component) => component.types[0] === "locality"
      );

      getAddressFromLatLong(Number(lat), Number(lng));
      setMarker({
        lat: lat,
        lng: lng,
      });
      // Saved user location in local when user search directly
      if (!showMap) {
        setUserLocation((prev) => ({
          ...prev,
          latitude: lat,
          longitude: lng,
          city: addressComp.long_name,
        }));
      }
    } else {
      toast.error(res.RESP_MSG);
    }
  };

  const getAddressFromLatLong = useCallback(
    async (latitude, longitude) => {
      try {
        const approxAddress = await FetchingAddressUsingLatLong(
          latitude,
          longitude
        );
        setAddressData({
          ...addressData,
          addressOne: approxAddress?.googleAddress,
          area: approxAddress?.area,
          landmark: approxAddress?.landmark,
          pincode: approxAddress?.pincode,
          city: approxAddress?.city,
          latitude: latitude,
          longitude: longitude,
          state: approxAddress?.state,
        });
      } catch (error) {
        console.error("Error fetching address details:", error);
      }
    },
    [addressData]
  );
  // const localityObject = addressComponents.find(component => component.types[0] === 'locality');

  const getCurrentPosition = (actionType) => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          setMarker({
            lat: latitude,
            lng: longitude,
          });

          const currentLocationCity = await FetchingAddressUsingLatLong(
            latitude,
            longitude
          );
          setAddressData({
            ...addressData,
            addressOne: currentLocationCity?.googleAddress,
            area: currentLocationCity?.area,
            landmark: currentLocationCity?.landmark,
            pincode: currentLocationCity?.pincode,
            city: currentLocationCity?.city,
            latitude: latitude,
            longitude: longitude,
            state: currentLocationCity?.state,
          });
          // Saved user location in local when clicked Use Current Location
          if (actionType === "currentLocation") {
            setUserLocation((prev) => ({
              ...prev,
              latitude: latitude,
              longitude: longitude,
              city: currentLocationCity?.city,
            }));
          }

          if (actionType === "selectFromMap") {
            setShowMap(true);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching address details:", error);
          setLoading(false);
        }
      },
      (error) => {
        console.error("Error getting location:", error);
        setLoading(false);
      }
    );
  };

  const handleMarkerDragEnd = (markerVal) => {
    // const newLat = event.latLng.lat();
    // const newLng = event.latLng.lng();
    const newLat = markerVal.lat;
    const newLng = markerVal.lng;
    setMarker({ lat: newLat, lng: newLng });
    getAddressFromLatLong(newLat, newLng);
  };

  const handleSelectFromMap = () => {
    // setselectFromMapClicked(true);
    getCurrentPosition("selectFromMap");
    googleAnalyticsFunc(
      `handleSelectFromMap_Clicked`,
      `SelectLocation_handleSelectFromMap_Clicked`,
      `SelectLocation`
    );
  };

  const handleCurrentLocation = () => {
    googleAnalyticsFunc(
      `handleCurrentLocation_Clicked`,
      `SelectLocation_handleCurrentLocation_Clicked`,
      `SelectLocation`
    );
    setcurrentLocationClick(true);
    setQuery("");
    setShowMap(false);
    setSelectLocationPopUp(false);
    getCurrentPosition("currentLocation");
  };
  const handleMapConfirm = () => {
    // Saved user location in local when clicked confirm from map visible (Locate on Map)
    setUserLocation((prev) => ({
      ...prev,
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      city: addressData.city,
    }));
    setQuery("");
    setShowMap(false);
    setSelectLocationPopUp(false);
    // toast.success("Location confirmed successfully!");
    googleAnalyticsFunc(
      `handleMapConfirm_Clicked`,
      `SelectLocation_handleMapConfirm_Clicked`,
      `SelectLocation`
    );
  };
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSuggestions([]);
      // setQuery("")
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCloseSelectLocationPopup = () => {
    googleAnalyticsFunc(
      `closeCross_Clicked`,
      `SelectLocation_CloseCross_Clicked`,
      `SelectLocation`
    );
    setQuery("");
    setShowMap(false);
    setSelectLocationPopUp(false);
  };

  return (
    <Modal open={open}>
      <div className="popup-box-selectLocation">
        <img
          src={cross}
          alt="close"
          className="close-cross-selectLocation"
          onClick={handleCloseSelectLocationPopup}
        />

        {!showMap && (
          <div className="popup-map-selectLocation">
            <img
              src={mapImage}
              alt="map"
              className="map-image-selectLocation"
            />
          </div>
        )}

        <h2 className="popup-title-selectLocation">Select a location</h2>

        {/* <div className="popup-search-selectLocation">
          <input
            type="text"
            placeholder="Search for your location"
            className="popup-input-selectLocation"
          />
        </div> */}
        <div className="popup-search-selectLocation" ref={wrapperRef}>
          <input
            className={`popup-input-selectLocation ${
              query.trim() && suggestions.length > 0 ? "with-suggestions" : ""
            }`}
            type="text"
            placeholder="Search for your location"
            value={query}
            onChange={handleSearchInputChange}
            maxLength={100}
          />
          <img
            src={searchLens}
            className="g-search-lens-selecetLocation"
            alt="search-lens"
          />
          {query.trim() && suggestions.length > 0 && (
            <div className="suggestions-dropdown-selectLocation">
              <div className="suggestion-list">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item-selectLocation"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="popup-options-selectLocation">
          <div
            className="option-selectLocation"
            onClick={handleCurrentLocation}
          >
            <img src={currentLocation} alt="location" className="icons" />
            <p>Use your current location</p>
          </div>
          {!showMap && (
            <div
              className="option-selectLocation"
              onClick={handleSelectFromMap}
            >
              <img alt="" src="/group.svg" className="icons" />
              <p>Locate on Map</p>
            </div>
          )}
        </div>
        {showMap && (
          <>
            {/* <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              libraries={librariesArray}
            > */}
            <GoogleMapComponent
            mapContainerStyle={mapStyles}
            propLat={marker.lat} 
            propLng={marker.lng} 
            onDragEnd={handleMarkerDragEnd}
            />
              {/* <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={marker}
              >
                <Marker
                  position={marker}
                  draggable
                  onDragEnd={handleMarkerDragEnd}
                />
              </GoogleMap> */}
            {/* </LoadScript> */}
            <div className="map-address-section">
              <p className="mapAddress">{`${addressData.addressOne}`}</p>
              <button
                className="popup-accept-selectLocation"
                onClick={handleMapConfirm}
              >
                Confirm
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SelectLocation;
