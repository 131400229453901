import { Link, useNavigate } from "react-router-dom";
import "./../../assets/styles/global.scss";
import "./Footer.scss";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../contexts/AppContext";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const Footer = () => {
  const navigate = useNavigate();
  const { 
    categoriesInfo,
    setSearchCategory,
    setSelectedCategory,
    getUrlFriendlyCategoryName
   } = useContext(Context);
   const [categorySearchOptions, setCategorySearchOptions] = useState([]);

  useEffect(() => {
    const list = categoriesInfo?.map((categoryData, index) => ({
      id: index,
      key: categoryData.category,
      name: categoryData.category,
    }));
    setCategorySearchOptions(list);
  }, [categoriesInfo]);

  const navigateToCategoryList = (categoryObj) => {
    setSelectedCategory(categoryObj.name);
    setSearchCategory(categoryObj.name);
    const friendlyCategoryName = getUrlFriendlyCategoryName(categoryObj.name);
    navigate(`/category/${friendlyCategoryName}?cat=${encodeURIComponent(JSON.stringify(categoryObj))}`);
    googleAnalyticsFunc(
      `navigateToCategoryList_Tab_clicked`,
      `Footer_navigateToCategoryList_Tab_click`,
      `Footer`
    );
  };

  const EventTriggerAndNavigation = (screenName) => {
    navigate(`/${screenName}`);
    googleAnalyticsFunc(
      `${screenName}_Tab_clicked`,
      `Footer_${screenName}_Tab_clicked`,
      `Footer`
    );
  }

  return (
    <>
      {/* <section className="frame-parent component-16-wrapper">
        <div className="component-161">
          <div className="component-16-item" />
          <div className="instance-parent2">
            <div className="frame-parent34">
              <div className="support-wrapper">
                <img className="support-icon3" alt="" src="/support-1.svg" />
              </div>
              <div className="account-types">
                <div className="account-names">
                  <b className="product-support1">Product Support</b>
                </div>
                <div className="up-to-31">
                  Up to 3 years on-site warranty available for your peace of
                  mind.
                </div>
              </div>
            </div>
            <div className="frame-parent34">
              <div className="support-wrapper">
                <img className="support-icon3" alt="" src="/account-1.svg" />
              </div>
              <div className="account-types">
                <div className="account-names">
                  <b className="product-support1">Personal Account</b>
                </div>
                <div className="up-to-31">
                  With big discounts, free delivery and a dedicated support
                  specialist.
                </div>
              </div>
            </div>
            <div className="frame-parent34">
              <div className="support-wrapper">
                <img className="support-icon3" alt="" src="/saving-1.svg" />
              </div>
              <div className="account-types">
                <div className="account-names">
                  <b className="product-support1">Amazing Savings</b>
                </div>
                <div className="up-to-31">
                  Up to 70% off new Products, you can be sure of the best price.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="group-footer-container">
        <div className="group-footer">
          {/* <div className="newsletter">
            <div className="newsletter-heading">
              <h1 className="sign-up-to1">Sign Up To Our Newsletter.</h1>
              <p className="newsletter-subtitle">
                Be the first to hear about the latest offers.
              </p>
            </div>
            <div className="email-button-container">
              <input
                type="text"
                className="email-input"
                placeholder="Your Email"
              />
              <button className="newsletter-button">Subscribe</button>
            </div>
          </div> */}
          <div className="footer">
            <div className="footer-info">
              <div className="footer-column">
                <p className="footer-head">Info & Policy</p>
                <ul>
                  <li className="liHover">
                    <span onClick={() => EventTriggerAndNavigation("AboutUs")}>About Us</span>
                  </li>
                  {/* <li className="liHover">Contact Us</li> */}
                  <li className="liHover">
                    <span onClick={() => EventTriggerAndNavigation('privacyPolicy')}>
                      Privacy Policy
                    </span>
                  </li>
                  <li className="liHover">
                    <span
                      onClick={() => EventTriggerAndNavigation('RefundOrderCancellationPolicy')}
                    >
                      Refund & Cancellation Policies
                    </span>
                  </li>
                  <li className="liHover">
                    <span onClick={() => EventTriggerAndNavigation("ReturnPolicy")}>
                      Return Policy
                    </span>
                  </li>
                  <li className="liHover">
                    <span onClick={() => EventTriggerAndNavigation("SellerAppPolicy")}>
                      Seller App Policy
                    </span>
                  </li>
                  <li className="liHover">
                    <span
                      onClick={() => EventTriggerAndNavigation("ShippingAndDeliveryPolicies")}
                    >
                      Shipping & Delivery Policies
                    </span>
                  </li>
                  <li className="liHover">
                    <span onClick={() => EventTriggerAndNavigation("TermsAndCondition")}>
                      Terms & Conditions
                    </span>
                  </li>

                  {/* <li>About Us</li>
                  <li>About Zip</li>
                  <li>Privacy Policy</li>
                  <li>Search</li>
                  <li>Terms</li>
                  <li>Orders and Returns</li>
                  <li>Contact Us</li>
                  <li>Advanced Search</li>
                  <li>Newsletter Subscription</li> */}
                </ul>
              </div>
              <div className="footer-column">
                <p className="footer-head">ONDC Network</p>
                <ul>
                  <li>
                    <Link to="/AboutUs#about-ondc" className="footer-titles">
                      About ONDC Network
                    </Link>
                  </li>
                  <li>
                    <Link to="/AboutUs#sell-on-ondc" className="footer-titles">
                      Sell on ONDC Network
                    </Link>
                  </li>
                  <li>
                    <Link to="/AboutUs#seller-work" className="footer-titles">
                      How it works?
                    </Link>
                  </li>
                  <li>
                    <Link to="/AboutUs#sms-adv" className="footer-titles">
                      Seller Management Solutions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-column">
                <p className="footer-head">Top Categories</p>
                <ul>
                  {categorySearchOptions
                    ?.filter((categoryData) => categoryData.name !== "Fashion")
                    .map((category) => (
                      <li key={category.id}>
                        <span
                          className="footer-titles"
                          onClick={() => navigateToCategoryList(category)}
                        >
                          {category.name}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="footer-column">
                <p className="footer-head">Address</p>
                <ul>
                  <li>
                    Address: 5030, 5th Floor, Core B, Solitaire Business Hub,
                    Clover Park, Viman Nagar, Pune, Maharashtra 411014
                  </li>
                  {/* <li>
                    Phones: <span className="phone-number">74868 74868</span>
                  </li> */}
                  <li>We are open: Monday - Saturday 7:00 AM to 9:00 PM</li>
                  {/* <li>Friday: 9:00 AM - 6:00 PM</li>
                <li>Saturday: 11:00 AM - 5:00 PM</li> */}
                  <li>
                    E-mail: <span className="email">contact@openkart.co.in</span>
                  </li>
                </ul>

                <div className="store-icons-parent">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.easypay.openkart&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      alt="playStore"
                      src="/playStore.png"
                    />
                  </a>

                  <a
                    href="https://apps.apple.com/in/app/openkart/id6544790254"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      alt="appStore"
                      src="/AppStore.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div className="social-icons-parent">
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/share/1EdFQx1Luu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-icon"
                    alt="Facebook"
                    src="/Facebook.png"
                  />
                </a>
                <a
                  href="https://www.instagram.com/openkartindia?igsh=MTJoOWoxcXlxemRreA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-icon"
                    alt="Instagram"
                    src="/Instagram.png"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/open-kart-india/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-icon"
                    alt="LinkedIn"
                    src="/LinkedIn.png"
                  />
                </a>{" "}
                {/* <a
                  href="https://www.youtube.com/c/EasyPayIndia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-icon"
                    alt="YouTube"
                    src="/YouTube.png"
                  />
                </a> */}
                {/* <a href="#" target="_blank" rel="noopener noreferrer">
    <img
      className="social-icon"
      alt="Twitter"
      src="/twitter-icon.svg"
    />
  </a> */}
              </div>
              <div className="payment-cards">
                {/* <img
                  className="payment-card"
                  alt=""
                  src="/paymentmethodslightpaypal-1.svg"
                />
                <img
                  className="payment-card"
                  alt=""
                  src="/paymentmethodslightvisa-1.svg"
                />
                <img
                  className="payment-card"
                  alt=""
                  src="/paymentmethodslightmaestro-1.svg"
                />
                <img
                  className="payment-card"
                  alt=""
                  src="/paymentmethodslightdiscover-1.svg"
                />
                <img
                  className="payment-card"
                  alt=""
                  src="/paymentmethodslightamericanexpress-1.svg"
                /> */}
                <p>Powered By ONDC Protocol</p>
              </div>
              <div className="copyright-20201">
                © OpenKart®. All Rights Reserved. Built on Easy Pay platform
                {/* Copyright © {new Date().getFullYear()} Easy Pay Limited. */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
