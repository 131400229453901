import React from 'react'
import './Profile.scss'
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { Outlet } from 'react-router-dom';

function Profile() {
    return (
        <div className='row-container profile'>
                <ProfileMenu />
            <div className="profile-page">
                <Outlet />
            </div>

        </div>
    )
}

export default Profile