import React from 'react';
import './TermsAndCondition.scss';

const TermsAndCondition = () => {
  return (
    <div className="Terms-Condition-container">
      <h1>Terms & Condition</h1>

      <section>
        <h2>Welcome to OpenKart!</h2>
        <p>These Terms and Conditions ("T&C") govern your use of the OpenKart ONDC application ("OpenKart") for purchasing goods and services from various sellers ("Sellers") through the Open Network for Digital Commerce (ONDC). OpenKart is operated by Easy Pay Limited ("Easy Pay"). By accessing this website, installing, or using any of the applications available on this website, you agree to these T&C, forming a binding agreement ("the Agreement") between you and Easy Pay.</p>
        <p>The term "Platform" refers to any platform owned, subscribed to, or used by Easy Pay Limited, including websites, mobile applications, devices, URLs/links, notifications, chatbots, or any other communication medium used to provide services to users.</p>
        <p>Easy Pay Limited, a company incorporated under the Companies Act, 2013, with its registered office at 1, Rangin Park, Sarkhej Gandhinagar Highway, Bodakdev, <strong>Ahmedabad</strong> - 380054 (Opposite Rajpath Club), owns and operates OpenKart. In these T&C, "Buyer(s)", "you", "your", "user", or "End User" refers to any individual or legal entity transacting on OpenKart  by registering as a user using any computer system, phone, or handheld device. " OpenKart ", "we", "us", or "our" refers to Easy Pay  Limited and the OpenKart Platform.</p>
        <p>By installing, downloading, or using OpenKart, you agree to these T&C and other OpenKart policies (including but not limited to the Cancellation & Refund Policy and Privacy Policy) as posted on OpenKart and amended from time to time. This agreement takes effect on the date you use OpenKart, creating a legally binding arrangement to abide by the same.</p>
        <p>OpenKart facilitates transactions between Buyers and Sellers dealing in various categories of goods and services ("OpenKart Services"). You can place orders ("Orders") on OpenKart for a variety of products ("Products") listed by various Sellers, including but not limited to restaurants, kiranas, retail stores, electronic stores, supermarkets, and grocery stores ("Seller(s)") through the Seller application ("Seller App"), which is a platform where various Sellers are available. The goods and services are sold on a business-to-consumer basis only for personal consumption and not for resale.</p>
        <p>OpenKart may facilitate the delivery of such Orders or completion of tasks at select localities of serviceable cities across India ("Delivery Services") provided by Sellers or by connecting third-party service providers, i.e., pick-up and delivery partners ("Logistic Partner") who will be responsible for providing the pick-up and delivery services initiated by the users of OpenKart (Buyers or Sellers). For both OpenKart Services and Delivery Services, OpenKart acts as an intermediary between the Sellers and Buyers and/or Logistic Partners and Buyers/Sellers.</p>
        <p>OpenKart may facilitate the delivery of such Orders or completion of tasks at select localities of serviceable cities across India ("Delivery Services") provided by Sellers or by connecting third-party service providers, i.e., pick-up and delivery partners ("Logistic Partner") who will be responsible for providing the pick-up and delivery services initiated by the users of OpenKart (Buyers or Sellers). For both OpenKart Services and Delivery Services, OpenKart acts as an intermediary between the Sellers and Buyers and/or Logistic Partners and Buyers/Sellers.</p>
        <p>For the Delivery Services, OpenKart may charge the Buyer a delivery fee (inclusive of applicable taxes whenever not expressly mentioned) on behalf of the Logistic Partner/Seller App/party appointing the Logistic Partner, determined based on various factors including but not limited to distance covered, time taken, demand for Delivery Services/tasks, real-time analysis of traffic and weather conditions, seasonal peaks, or other parameters as may be determined from time to time.</p>
        <p>Use of and access to OpenKart is conditioned upon acceptance of all the terms, conditions, and notices contained in these T&C and Privacy Policies, along with any amendments made by OpenKart at its sole discretion and posted from time to time. Subject to the above, OpenKart grants Users a personal, non-exclusive, non-transferable, limited privilege to enter and use OpenKart and the OpenKart Services.</p>
        <p>OpenKart retains the right to modify or amend these T&C. You can access the latest version of these T&C at any given time on OpenKart. You should regularly review the T&C on OpenKart. Your continued use of and access to OpenKart constitutes your consent to such changes. If the modified T&C are not acceptable to you, you should discontinue accessing OpenKart.</p>
      </section>

      <section>
        <h2>Privacy</h2>
        <p>Please review our Privacy Policy, available at Privacy Policy, which governs your visit to OpenKart. This document helps you understand our practices regarding your personal information. Any data you provide to us during your use of OpenKart will be handled in accordance with our Privacy Policy and applicable laws and regulations. If you do not agree with the transfer or use of your information as described, please refrain from using OpenKart.</p>
      </section>

      <section>
        <h2>Use of OpenKart Services</h2>
        <p>All commercial and contractual terms related to the Products offered by Sellers on OpenKart are solely between the Buyers and Sellers or the Seller App, as applicable. These terms include, but are not limited to, pricing, applicable taxes, shipping costs, payment terms, delivery dates, periods and modes, product warranties, and after-sales services. Easy Pay does not control, determine, advise, or involve itself in the offering or acceptance of these commercial or contractual terms between Buyers and Sellers. The prices of products and services are set by the Sellers through the Seller App, and Easy Pay has no role in determining these prices.</p>
        <p>Easy Pay does not make any representations or warranties regarding the specifics (such as quality, value, saleability, etc.) of the Products listed on OpenKart. Easy Pay neither implicitly nor explicitly supports or endorses the sale or purchase of any Products on OpenKart and accepts no liability for any errors or omissions on behalf of itself or third parties.</p>
        <p>Easy Pay makes no representations or warranties concerning any aspect of the services provided by the Logistic Partner through OpenKart, including but not limited to pick-up and delivery services for Sellers or Buyers.</p>
        <p>Easy Pay acts solely as a facilitator and does not at any time during a transaction between Buyer and Seller, or Buyer and Logistic Partner, take possession of any Products offered by the Seller or Logistic Partner. Easy Pay does not hold any rights, titles, or interests over the Products and has no obligations or liabilities concerning any contract entered into between Buyer and Seller and/or Buyer and Logistic Partner.</p>
        <p>OpenKart serves only as a communication platform, and the contract for the sale of any Products is strictly between the Seller and the Buyer. In the event of complaints from the Buyer regarding the efficacy, quality, or other issues related to the Products, Easy Pay will notify the Seller App. The Seller App, in coordination with the Seller, will be responsible for addressing Buyer complaints. If you raise a complaint against any Seller, we will assist you to the best of our abilities by providing relevant information, such as the details of the Seller and the specific order to which the complaint relates, to enable a satisfactory resolution.</p>
      </section>

      <section>
        <h2>User Obligations and Prohibited Activities</h2>
        <p><strong>Your use of OpenKart must adhere to the following binding principles:</strong></p>
        <p>You agree not to host, display, upload, modify, publish, transmit, update, or share any information that belongs to another person without the right to do so; is defamatory, obscene, pornographic, paedophilic, invasive of privacy, insulting, harassing based on gender, libelous, racially or ethnically objectionable, encouraging money laundering or gambling, or otherwise contrary to applicable laws; is harmful to children; infringes any patent, trademark, copyright, or other proprietary rights; violates any law in force; impersonates another person; threatens the unity, integrity, defence, security, or sovereignty of India, friendly relations with foreign states, or public order; contains software viruses or other harmful code; is false and intended to mislead or harass for financial gain or cause injury; disrupts or interferes with the security of OpenKart or any systems connected to it; violates the T&C; or involves reverse engineering, copying, distributing, transmitting, displaying, performing, reproducing, publishing, licensing, creating derivative works from, transferring, or selling any information or software obtained from OpenKart.</p>
        <p>You agree not to use automated systems to access, acquire, copy, or monitor any part of OpenKart, or to bypass the navigation structure or presentation of the platform. You must not attempt to gain unauthorized access to any portion of OpenKart, its systems, or networks through hacking, password mining, or any other illegitimate means. Probing, scanning, or testing the vulnerability of OpenKart or any connected network, and breaching security or authentication measures, is prohibited. You must not trace any information of another user or exploit the platform to reveal any information other than your own.</p>
        <p>You must not make any disparaging or defamatory statements about Easy Pay or the brand names and domain names used by Easy Pay, nor engage in any conduct that could tarnish the reputation of Easy Pay or Sellers on OpenKart. You must not interfere with the proper functioning of OpenKart, the Open Network for Digital Commerce, or any transactions conducted on the platform.</p>
        <p>You agree to comply with all applicable laws, including the Information Technology Act, 2000, and its rules, domestic and international laws, foreign exchange laws, and other relevant regulations regarding your use of our services and your transactions on OpenKart.</p>
        <p>To enable us to use the information you supply, you grant us a non-exclusive, worldwide, perpetual, revocable, royalty-free, sub-licensable right to exercise any rights you have in your information, in any media now known or hereafter developed. This includes sharing your information with our affiliates, subsidiaries, and third parties, in accordance with the T&C and Privacy Policy.</p>
        <p>Easy Pay reserves the right to monitor and remove any content that violates or is alleged to violate applicable laws or these T&C. Easy Pay assumes no responsibility or liability for any content posted by users or for any claims, damages, or losses resulting from the use of such content.</p>
        <p>By using OpenKart, you acknowledge that you may encounter content that some users find offensive, indecent, or objectionable. You agree to use the platform at your own risk, and to the fullest extent permitted by law, Easy Pay will not be liable for content that you may find offensive, indecent, or objectionable.</p>
      </section>

      <section>
        <h2>Account Registration</h2>
        <p>To access OpenKart, you must register to create an account ("Account") and become a User. You are responsible for maintaining the confidentiality of your display name and password and for all activities that occur under them. If you provide information that is untrue, inaccurate, not current, or incomplete, or if we suspect such information, we reserve the right to suspend, terminate, or block your access to OpenKart.</p>
        <p>Your mobile phone number and email address serve as your primary identifiers on OpenKart. It is your responsibility to keep this information up to date. If your mobile number or email address changes, you must promptly update it on OpenKart through a one-time password verification .</p>
        <p>You are solely liable for all activities under your Account and any resulting consequences.</p>
        <h2>Order Flow, Booking, and Payments</h2>
        <p>Order Flow, Booking, and Payments When you place an order on OpenKart, the process involves several steps:</p>
        <ol className='listItemStyles'>
          <li>You select a product or service on OpenKart.</li>
          <li>OpenKart sends the selection request to the relevant Seller Apps.</li>
          <li>Seller Apps notify their Sellers, who confirm availability and terms.</li>
          <li>Seller Apps relay the offer back to OpenKart.</li>
          <li>OpenKart displays the offer to you. If you accept, OpenKart places a purchase order with the Seller via the Seller App, which confirms with the Seller.</li>
        </ol>
        <p>OpenKart facilitates the placement and acceptance of orders by Sellers but does not own, sell, or control the products or services. Orders are subject to product availability, delivery location serviceability, and Seller acceptance.</p>
        <p>OpenKart facilitates the placement and acceptance of orders by Sellers but does not own, sell, or control the products or services. Orders are subject to product availability, delivery location serviceability, and Seller acceptance. All payments must be made in Indian Rupees. Payment methods include credit/debit card, UPI, net banking, or other approved methods. OpenKart acts as a facilitator for payments, not as a banking or financial service. Payments made to OpenKart are deemed completed upon receipt by the Seller. You are responsible for providing accurate billing information and securing your payment details.</p>
        <p>Prices on OpenKart, including any additional charges, are determined by the Seller apps. Warranties or guarantees for products are the Seller's responsibility. Transactions between Buyers and Sellers are bilateral, and Easy Pay is not liable for any applicable taxes on these transactions.</p>
      </section>

      <section>
        <h2>Returns, Cancellations, Exchanges, and Refunds</h2>
        <p>OpenKart aims to assist Buyers with genuine disputes regarding products provided by Sellers. Return policies and periods vary based on product category, Seller's refund policy, and the Seller.</p>
        <h3>Replacement:</h3>
        <p>If a defect is found at delivery or within the return policy period, the Buyer can request a replacement, subject to the following conditions:</p>
        <ul className='ulListItemStyles'>
          <li>Notify the Seller of defects at delivery or within the return policy period.</li>
          <li>Replacements depend on product availability with the Seller.</li>
          <li>Replacement can be for the entire product or parts of it.</li>
        </ul>
        <p>You may return a product if:</p>
        <ul className='ulListItemStyles'>
          <li>A wrong item is delivered.</li>
          <li>Items are substantially damaged or deteriorated in quality at delivery. Proof, including images, may be required.</li>
          <li>Products not eligible for return or replacement include.</li>
          <li>Damages due to misuse.</li>
          <li>Used or installed consumable items.</li>
          <li>Products with tampered or missing serial/UPC numbers.</li>
          <li>Damages not covered under manufacturer's warranty.</li>
          <li>Products returned without original packaging and accessories.</li>
        </ul>

        <h3>Cancellations:</h3>
        <p>For food and perishable items, you can cancel an order without charge before the Seller accepts it. Orders cannot be cancelled post-acceptance unless:</p>
        <ul className='ulListItemStyles'>
          <li>The Order couldn’t be delivered within the estimated time.</li>
          <li>The Seller cancels the Order due to reasons beyond the Buyer’s control, like non-availability of items or store closure.</li>
          <li>OpenKart reserves the right to evaluate cancellation requests and process them if they meet the above conditions. The Seller may cancel orders due to product unavailability or force majeure events, in which case refunds will be provided.</li>
        </ul>
        <p>OpenKart may cancel Orders and issue refunds in cases like:</p>
        <ul className='ulListItemStyles'>
          <li>Logistic services are unavailable.</li>
          <li>Products are out of stock.</li>
          <li>Uncontrollable transaction issues.</li>
        </ul>

        <p>You may request returns for sealed, unopened, unused products in original condition within the return period specified by the Seller. Refunds may be granted if an Order is not delivered due to reasons attributable to the Seller App, OpenKart, or damage at the time of delivery, subject to the Seller's acknowledgment. Refunds will be processed within seven business days of verifying and confirming the request. Shipping charges may be deducted from the refund amount. Refunds will be credited to the source account or other provided options.</p>
        <p>OpenKart reserves the right to charge for cancellations not attributable to the platform, such as:</p>
        <ul className='ulListItemStyles'>
          <li>Incorrect address provided.</li>
          <li>Buyer unavailable at delivery time.</li>
          <li>Lack of information or authorization at delivery.</li>
          <li>Decisions regarding refunds are at Easy Pay’s sole discretion and will follow internal policies. These terms are subject to reasonable additional conditions set by Sellers, Seller Apps, and Logistic Partners, communicated as necessary.</li>
          <li>For claims of returns, refunds, or cancellations, raise a ticket with customer support. Easy Pay may take legal action against Users filing false claims and may suspend or block such Users.</li>
        </ul>
      </section>

      <section>
        <h2>Terms of service</h2>
        <p>The Buyer agrees and acknowledges that Easy Pay shall not be responsible for:</p>
        <ul className='ulListItemStyles'>
          <li>The services or goods provided by the Sellers.</li>
          <li>The services provided by Logistic Partners not meeting Buyer expectations or causing any loss, harm, or damage.</li>
          <li>The availability or unavailability of certain products.</li>
          <li>The Seller delivering incomplete or incorrect Orders.</li>
          <li>Product liability of goods provided by Sellers.</li>
          <li>Buyers and Sellers agree and acknowledge that Easy Pay is not responsible for any liability arising out of Delivery Services provided by the Logistic Partner to them.</li>
        </ul>
        <p>Buyers may not be able to avail OpenKart Services if their delivery location is outside OpenKart's current serviceable area. OpenKart will inform the Buyer of this at the time of confirming his/her Order booking.</p>
        <p>The Buyer understands that the delivery time quoted at the time of confirming the Order is an approximate estimate and may vary based on information obtained from Logistic Partners and Sellers. Easy Pay will not be responsible for any delay in the delivery of a product or service.</p>
        <p>The Buyer's Order will be delivered only to the address specified at the time of placing the Order on OpenKart. If there is a change of address as informed by the Logistic Partner, the Order will be cancelled. Delivery of goods and services to a new address will be subject to acceptance by the Logistic Partner or at the sole discretion of OpenKart.</p>
        <p>The Buyer understands that the responsibility for order fulfilment and quality lies with the Seller. OpenKart does not offer any refunds for Products purchased from a Seller or Logistic Partner unless an error directly attributable to OpenKart occurred during the purchase. Buyers can raise a ticket on OpenKart for issues faced, highlighting concerns to the Seller and/or Logistic Partner. OpenKart will communicate these issues to the respective Seller and/or Logistic Partner, who will then provide responses, actions, or alternatives to the Buyer.</p>
        <p>We strive to provide accurate information on OpenKart. However, in the event of an error, we may, at our sole discretion, contact you with further instructions.</p>
      </section>
      <section>
        <h2>Third party content and links</h2>
        <p>OpenKart Services may include or link to third-party materials. Your use of these links and services is governed by the third party's terms of service and privacy policy. We have no control over and do not endorse the accuracy, relevance, legality, completeness, or quality of any third-party content, products, services, or advertisements linked from OpenKart Services. We do not investigate third-party material before or after its inclusion.</p>
        <p>OpenKart is not responsible for the content of third-party sites and makes no representations regarding their accuracy. Accessing and using these third-party websites is entirely at your own risk.</p>
        <p>Third-party content does not reflect the views of OpenKart or its affiliates. None of the third-party content is endorsed or certified by its providers or licensors. We are not responsible for any content you share or publish on third-party sites.</p>
        <p>Easy Pay is not liable for any loss or damage incurred from the availability of external sites or resources or any reliance on their content. We disclaim any liability for offensive, defamatory, illegal, or infringing content provided by third parties.</p>
      </section>

      <section>
        <h2>User Content</h2>
        <p>After a transaction on OpenKart, you may be prompted to provide ratings, comments, or other feedback about the Logistics Partner and Sellers ("User Content"). By submitting User Content, you consent to OpenKart's right to use, share, and display it as part of its business operations, including on OpenKart, without further approval from you. Easy Pay and its affiliates can edit or remove User Content if it violates these T&C, is obscene, includes personal information, or breaches privacy laws or policies.</p>
        <p>All User Content is subject to moderation by our content team. OpenKart reserves the right to accept, reject, or modify User Content. You grant OpenKart permission to use your name in connection with your User Content and agree not to submit misleading or false information. You are responsible for your User Content and agree to indemnify Easy Pay and its affiliates for any claims arising from it.</p>
        <p>User reviews on OpenKart reflect individual opinions and do not represent Easy Pay’s views.</p>
      </section>

      <section>
        <h2>No Endorsement</h2>
        <p>Although Easy Pay may occasionally market or promote Sellers or Logistic Partners via OpenKart, we do not endorse any Seller or Logistic Partner. By using OpenKart Services, you agree that any legal remedy or liability you seek for actions or omissions by Sellers, Logistic Partners, or any other third parties will be limited to claims against those parties alone, without involving or imposing liability on Easy Pay.</p>
      </section>

      <section>
        <h2>Access to OpenKart, Accuracy, and Security</h2>
        <p>We do not guarantee that OpenKart will be compatible with all hardware and software you may use. We are not liable for any damage to, or viruses or other harmful code that may affect, your equipment (including but not limited to your mobile device), software, data, or other property due to your download, installation, access to, or use of OpenKart, or from obtaining any material from using OpenKart. Additionally, we are not responsible for the actions of third parties.</p>
        <p>We do not guarantee that the information available on OpenKart will be correct, accurate, or reliable.</p>
        <p>We reserve the right to suspend or withdraw access to OpenKart for you personally, or for all users, temporarily or permanently, at any time without notice. We may, at our sole discretion, reinstate suspended users at any time. A suspended user may not register or attempt to register with us or use OpenKart in any manner until such time that we reinstate the user. However, any Order placed by you that has been accepted by the Seller and/or Logistic Partner before your suspension/termination from OpenKart will be completed. Additionally, any pending refunds, grievances, or complaints will be addressed even if you are unable to access OpenKart for communication with us, the Seller, and/or the Logistic Partner. Advertisements on OpenKart are independent of user reviews.</p>
      </section>

      <section>
        <h2>Terms for Buying Pharmaceutical Products on OpenKart</h2>
        <p>The Buyer understands and agrees that OpenKart is merely a technology platform, and the medicines and pharmaceutical products ("Pharmaceutical Products") are sold by the Seller, who is a pharmacy ("Seller Pharmacy"). Easy Pay is not responsible for any errors, omissions, or actions committed by the Seller Pharmacy. All items offered for sale on OpenKart, and the content provided by the Seller Pharmacy, are generated by third parties. OpenKart has no control over such third-party content. The authenticity and genuineness of the Pharmaceutical Products are ensured by the Seller Pharmacy. Easy Pay has no liability regarding the authenticity of the Pharmaceutical Products available through OpenKart.</p>
        <p>The Buyer agrees that all commercial and contractual terms related to the sale, purchase, delivery, and consumption of the Pharmaceutical Products are strictly between the Buyer and the Seller Pharmacy. Easy Pay is not responsible for any unsatisfactory performance, delays, non-performance, or breaches of the contract between the Buyer and the Seller Pharmacy. Easy Pay is not liable if the Seller Pharmacy fails to fulfil an order for Pharmaceutical Products that were shown as available on OpenKart at the time of order placement but were later found to be out of stock, back-ordered, or otherwise unavailable.</p>
        <p>The Buyer can use OpenKart to purchase various medicines and Pharmaceutical Products from pharmacy stores that require a valid medical prescription issued by a medical expert/doctor. To purchase Prescription Drugs from the Seller Pharmacy through OpenKart, the Buyer must upload a scanned copy of a valid prescription on OpenKart. The Seller Pharmacy will not process the order until a valid prescription is uploaded. The Seller Pharmacy will verify the prescription, and if any discrepancies are found, the Seller may reject the order. The Buyer must also present the original prescription at the time of delivery of the Prescription Drugs.</p>
        <p>The Buyer agrees not to reuse a prescription for which drugs have already been dispensed. If a Buyer is found reusing a prescription, the order will be immediately canceled. Easy Pay is not responsible for any adverse effects or harm caused to the Buyer. Orders for substitute Prescription Drugs will only be processed if the medical expert/doctor has permitted an equivalent generic drug to be dispensed or if the prescription lists the salt names instead of a specific brand name. Otherwise, the Seller Pharmacy will not dispense a substitute drug.</p>
        <p>The Buyer agrees and confirms that they are fully aware of the indications, side effects, drug interactions, and effects of missed doses or overdoses of the medicines purchased through OpenKart. It is essential to seek professional advice from a medical practitioner before purchasing or consuming any medicine.</p>
        <p>The Seller/ Easy Pay may maintain a record of the prescriptions uploaded by the Buyers.</p>
      </section>

      <section>
        <h2>Intellectual property rights</h2>
        <p>OpenKart owns all intellectual property, including but not limited to, all text, videos, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (and any combinations thereof) (collectively, the "Content") on OpenKart. All such Content is licensed to Easy Pay and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.</p>
        <p>The trademarks, logos and service marks displayed on OpenKart ("Marks") are the licensed property of Easy Pay or owned by third parties. You are not permitted to use the Marks without the prior consent of Easy Pay or the relevant third party (which is the owner of the Marks) respectively. Access or use of OpenKart does not authorise any party to use trademarks, logo, or any other mark in any manner.</p>
        <p>Easy Pay disclaims to hold any right, title, or interest in and to the intellectual property rights arising out of or associated with the Products.</p>
        <p>There could be references on OpenKart of any name, mark, services or products of third parties for your convenience, and in no way constitutes OpenKart's endorsement, sponsorship or recommendation of such third party name, mark, services or products, whether express or implied.</p>
      </section>

      <section>
        <h2>Disclaimer of Warranties & Liability</h2>
        <p><strong>To the fullest extent permitted by applicable law, you expressly understand and agree that:</strong></p>
        <p>OpenKart and its content are provided on an "as is" basis without any warranties of any kind, whether express, implied, statutory, or otherwise. This includes implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Easy Pay does not guarantee that OpenKart or its services will meet your requirements, or that your use of OpenKart will be uninterrupted, timely, secure, or error-free. No advice or information, whether oral or written, obtained by you from Easy Pay shall create any warranty not expressly stated in the Terms and Conditions (T&C).</p>
        <p>Easy Pay will not be liable for any loss you may incur due to unauthorized use of your account or account information in connection with OpenKart, whether or not you are aware of such use.</p>
        <p>Easy Pay has made efforts to ensure the accuracy of the information on OpenKart, but it does not warrant or make any representations regarding the quality, accuracy, or completeness of any data or information regarding the OpenKart services or otherwise. Easy Pay shall not be responsible for any delay or inability to use OpenKart or its related functionalities, nor for any information, software, functionalities, and related graphics obtained through OpenKart, whether based on contract, tort, negligence, strict liability, or otherwise. Easy Pay shall not be held responsible for the non-availability of OpenKart during periodic maintenance or any unplanned suspension of access due to technical reasons or any reason beyond Easy Pay 's reasonable control.</p>
        <p>Easy Pay has made its best efforts to display the colors of the product images on OpenKart as accurately as possible. However, the actual colors of the products you see will depend on your device. OpenKart does not guarantee the accuracy of such displays and will not be responsible or liable for any discrepancies.</p>
      </section>

      <section>
        <h2>Indemnification and Limitation of Liability</h2>
        <p>You agree to indemnify and hold harmless Easy Pay, its entities, owners, licensees, affiliates, subsidiaries, group companies (as applicable), and their respective officers, directors, agents, and employees ("Indemnitees") from any claims, demands, or actions, including reasonable attorneys' fees, made by any third party or penalties imposed due to or arising out of your breach or non-performance of any representation, warranty, covenant, or agreement made or obligation to be performed by you pursuant to these Terms and Conditions (T&C) and/or the Privacy Policy.</p>
        <p>Additionally, you agree to indemnify the Indemnitees against any claims made by third parties arising from or in connection with your use of OpenKart, any misrepresentation of data or information provided by you, your violation of the T&C and/or the Privacy Policy, your violation of applicable laws, or your infringement of any rights of another, including intellectual property rights.</p>
        <p>Easy Pay and its directors, officers, partners, consultants, agents, employees, and third-party partners shall not be liable to you or any third party for any special, incidental, indirect, consequential, or punitive damages arising out of or in connection with your use of or access to OpenKart or its content.</p>
        <p>These limitations and exclusions apply to the maximum extent permitted by applicable laws.</p>
      </section>

      <section>
        <h2>Violation of These T&C</h2>
        <p>Violation of These T&C You acknowledge that any violation of these T&C by you will likely cause irreparable harm to Easy Pay, for which monetary damages would be inadequate. Therefore, you consent to Easy Pay obtaining any injunctive or equitable relief they deem necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Easy Pay may have at law or in equity.</p>
      </section>

      <section>
        <h2>Suspension and Termination</h2>
        <p>These T&C will remain in effect until terminated by either you or Easy Pay as outlined below.</p>
        <p>If you disagree with the T&C or are dissatisfied with OpenKart, you may (i) close your account on OpenKart, and/or (ii) stop accessing OpenKart.</p>
        <p>Easy Pay reserves the right to delist you, block your future access, or suspend or terminate your account if it believes, at its sole discretion, that you have violated any term of these T&C or the Privacy Policy, or have otherwise acted unethically. Despite anything stated in this clause, all terms intended to survive such termination will continue indefinitely unless Easy Pay decides to terminate them.</p>
        <p>Any termination as stated above will not cancel your obligation to pay for any product purchased on OpenKart, or any other obligation that has accrued or remains unfulfilled and relates to the period prior to termination.</p>
        <p>You are liable to pay any applicable fees or charges for the OpenKart services until the date of termination by either party.</p>
      </section>

      <section>
        <h2>Governing Law and Jurisdiction</h2>
        <p>These T&C will be governed by and interpreted in accordance with the laws of India, excluding its conflict of laws principles. Any disputes arising under or in connection with these T&C shall be subject to the exclusive jurisdiction of the courts located in Ahmedabad, India.</p>
      </section>

      <section>
        <h2>Grievance Redressal Mechanism</h2>
        <p>For any issues related to orders, you should first contact us via chat support on OpenKart for real-time resolution. Alternatively, you may file a complaint by emailing contact@openkart.co.in, and we will aim to address your grievance within the timeframes prescribed by applicable laws.</p>
        <p>If you still have concerns, complaints, or are dissatisfied with the resolution, content, or services provided by OpenKart, you may reach out to the designated Grievance cum Nodal Officer of the Company. Contact details for the Grievance cum Nodal Officer are provided below, in compliance with the Information Technology Act, 2000 and its associated rules, as well as the Consumer Protection (E-Commerce) Rules, 2020.</p>
        <p>We recommend reviewing these T&C and any additional information available on OpenKart to fully understand the terms of use before placing any orders.</p>
      </section>
    </div>
  );
};

export default TermsAndCondition;
