import React, { useState, useEffect, useContext, useRef } from 'react'
import SelectElement from '../SelectElement/SelectElement';
import { Context } from '../../contexts/AppContext';
import searchLens from "../../assets/images/homepage/search-lens.svg";
import './SearchBar.scss';
import { AppConstants } from '../../constants/AppContants';
import { get_search_results } from '../../services/services';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchSuggestionStore from '../SearchSuggestionStore/SearchSuggestionStore';
import SearchSuggestionItem from '../SearchSuggestionItem/SearchSuggestionItem';
import { SmallLoader } from '../Loader/Spinner';
import { toast } from 'react-toastify';
import cross from "../../assets/icons/search-cross.svg";
import { getQueryParamURIComponent } from '../../utils/encryption';
import { googleAnalyticsFunc } from '../../utils/commonFunctions';

function SearchBar() {
  const {
    userLocation,
    categoriesInfo,
    searchCategory,
    setSearchCategory,
    isSearchBarActive, 
    setIsSearchBarActive

  } = useContext(Context);
  const [categorySearchOptions, setCategorySearchOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Search product or store");
  const [searchText, setSearchText] = useState("");
  const [searchListItems, setSearchListItems] = useState([]);
  const [searchListStores, setSearchListStores] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  let timer = useRef(null);
  const searchDropdownRef = useRef(null);
  const inputRef = useRef(null);
  // const [isActive, setIsActive] = useState(false);
  const uniqueStores = new Set();
  const uniqueItems = new Set();
  const location = useLocation();
  useEffect(() => {
    setSearchText("");
  }, [location]);


  // 1. Get category options for search
  useEffect(() => {
    if (Object.keys(categoriesInfo).length !== 0) {
      const firstItem = {
        id: Math.floor(new Date().getTime() / 1000.0),
        key: 'All',
        name: 'All Categories',
      }
      const list = categoriesInfo?.map((categoryData, index) => ({
        id: index,
        key: categoryData.category,
        name: categoryData.category,
      }));
      setCategorySearchOptions([firstItem, ...list]);
    }
  }, [categoriesInfo]);

  // 2. Update placeholder according to resize
  // const updatePlaceholder = () => {
  //     const screenWidth = window.innerWidth;
  //     if (screenWidth > 1024) {
  //         setPlaceholder("Search business, category or place");
  //     } else {
  //         setPlaceholder("Search here");
  //     }
  // };

  // useEffect(() => {
  //     updatePlaceholder();
  //     window.addEventListener("resize", updatePlaceholder);
  //     return () => window.removeEventListener("resize", updatePlaceholder);
  // }, []);

  // Search API call
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);
    return () => {
      clearTimeout(timer);
    }
  }, [searchText])

  const getSearchResults = async () => {
    if (searchText.length >= 3) {
      googleAnalyticsFunc(
        `getSearchResults(${searchText})_Searched`,
        `SearchBar_getSearchResults(${searchText})_Searched`,
        `SearchBar`
      );
      try {
        setShowSearchList(true);
        setLoading(true);
        setSearchListItems([]);
        setSearchListStores([]);
        const payload = {
          DATA: {
            page_no: 1,
            item_name: searchText,
            city: "IND",
            gps: `${userLocation.longitude},${userLocation.latitude}`,
            domainName: searchCategory,
            previous_city: userLocation.city
          },
          HEADER: {
            AGENT_ID: AppConstants.AID,
            AID: AppConstants.AID,
            UDID: AppConstants.UDID,
            epAgentId: AppConstants.AID,
            epPartnerId: "",
            userId: AppConstants.mobile,
            versioNo: "",
            REQUEST_ID: Math.floor(new Date().getTime() / 1000.0)
          }
        };

        const res = await get_search_results(payload);


        if (res.RESP_CODE === 300) {
          const { itemsData, storeData } = res.DATA;
          setSearchListItems(itemsData);
          setSearchListStores(storeData);
        } else {
          // toast.error(res.RESP_MSG);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setSearchListItems([]);
      setSearchListStores([]);
      setShowSearchList(false);
    }
  }

  useEffect(() => {
    getSearchResults();
  }, [debouncedSearchText, searchCategory])

  // close search Dropdown on other place
  const closeSearchDropdown = (e) => {
    if (!searchDropdownRef.current.contains(e.target)) {
      setShowSearchList(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeSearchDropdown);
    return () => {
      document.removeEventListener('mousedown', closeSearchDropdown)
    };
  }, []);

  const navigateToStore = (store) => {
    setShowSearchList(false);

    const storeDetails = {
      bpp_id: store.bpp_id,
      city: store.city,
      domain: store.domain
    }
    const queryParamValue = getQueryParamURIComponent(storeDetails);

    navigate("/store-products/" + store.store_id + `?sd=${queryParamValue}`);
  }


  const navigateToItem = (productDtl) => {
    setShowSearchList(false);
    const dataForQuery = {
      domain: productDtl.domain.toLowerCase().replace(/\s+/g, ""),
      city: productDtl.storDetails.city.toLowerCase().replace(/\s+/g, ""),
    };
    const queryParamValue = getQueryParamURIComponent(dataForQuery);

    navigate(
      `/product/${productDtl.itemId}/seller/${productDtl.storDetails.store_id}?pd=${queryParamValue}`,
    );
  }

  return (
    <div className={`home-search-wrapper ${isSearchBarActive ? 'active' : ''}`}
      ref={searchDropdownRef}
      onMouseEnter={() => setIsSearchBarActive(true)}
      onMouseLeave={() => setIsSearchBarActive(false)}>
      {/* Category list for search */}
      <SelectElement
        id="category-search"
        selectElementOptionList={categorySearchOptions}
        handleOptionSelect={(option) => {
          googleAnalyticsFunc(
            `selectCategory(${option.name})_Selected`,
            `SearchBar_selectCategory(${option.name})_Selected`,
            `SearchBar`
          );
          setSearchCategory(option.key);
          setPlaceholder(`Search in '${option.name}'`)
        }}
        selectedValue={searchCategory}
        placeholder={"select category"}
      />
      <div className="search-container"
      >
        <input
          className="search-input"
          type="text"
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          ref={inputRef}
          onFocus={
            () => {
              if (debouncedSearchText.length >= 3) {
                setShowSearchList(true);
              }
            }}
        />
        <div className="search-lens" onClick={() => inputRef.current.focus()}>
          {searchText.trim() === "" ? (
            <img
              src={searchLens}
              className="search-lens"
              alt="search-lens"
            />
          ) : (
            <img
              src={cross}
              className="search-lens"
              alt="clear-search"
              onClick={() => setSearchText("")}
            />
          )}
        </div>

        <div className={`search-dropdown ${showSearchList ? "active" : ""}`}>
          <ul className="search-list">
            {
              loading && <div className='searching-container'>
                <SmallLoader />
                <div className="searching-text">Searching for '{debouncedSearchText}'</div>
              </div>
            }
            {
              (searchListItems.length === 0 && searchListStores.length === 0 && !loading) && <div className='no-items-text'>
                No items found for search '{debouncedSearchText}'
              </div>
            }
            {
              searchListStores.map((store) => {
                if (!uniqueStores.has(store.store_id)) {
                  uniqueStores.add(store.store_id); // Add to Set to mark as seen
                  return (
                    <li className='search-item search-item-product'
                      key={store.store_id}
                      onClick={() => navigateToStore(store)}>
                      <SearchSuggestionStore store={store} />
                    </li >
                  );
                }
                return null; // Skip rendering if store is already added
              })}
            {
              searchListItems.flatMap((itemWithStore) =>
                itemWithStore.store[0].items.filter((item) => {
                  if (!uniqueItems.has(item.itemId)) {
                    uniqueItems.add(item.itemId); // Add to Set to mark as seen
                    return true;
                  }
                  return false; // Skip duplicates
                }).map((uniqueItem) => (
                  <li className='search-item search-item-product'
                    key={uniqueItem.itemId}
                    onClick={() => navigateToItem(uniqueItem)}>
                    <SearchSuggestionItem item={uniqueItem} />
                  </li>
                ))
              )
            }
          </ul >
        </div >
      </div >
    </div >
  )
}

export default SearchBar