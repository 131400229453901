import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./OrderDetails.scss";
import {
  AppConstants,
  generateId,
  generateIdWithoutHyphens,
} from "../../constants/AppContants";
import { get_order_details } from "../../services/services";
import { formatOrderDate } from "../../utils/encryption";
import { Context } from "../../contexts/AppContext";
import { useDispatch } from "react-redux";
import {
  sendRequestForCancel,
  sendRequestForStatus,
} from "../../redux/actions/Action";
import { toast } from "react-toastify";
import CancelOrder from "../../components/CancelOrder/CancelOrder";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import Spinner from "../../components/Loader/Spinner";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

function OrderDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedOrder, setSelectedOrder] = useState({});
  const [orderStatuslDtls, setorderStatuslDtls] = useState({});

  const [status, setStatus] = useState("Pending");
  const { setLoading } = useContext(Context);
  const dispatch = useDispatch();
  const [bppId, setBppId] = useState(null);
  const [bppUrl, setBppUrl] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [city, setCity] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [textHeading, setTextHeading] = useState("Order has been placed"); // why?? this initialisation
  const [currentStep, setCurrentStep] = useState(0);
  const intervalId = useRef(null);
  const [returnId, setReturnId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderState, setorderState] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [showCancelButton, setshowCancelButton] = useState(false);


  const handleImageError = () => {
    setImageError(true); // Set the error state to true when the image fails to load
  };

  // 1. Order Details
  // 1.1 Getting order details from orderId
  useEffect(() => {
    getOrderDetails();
  }, [searchParams]); // why search params??

  const getOrderDetails = async () => {
    try {
      setIsLoading(true);

      let payload = {
        DATA: {
          screenName: AppConstants.screenName,
          ORDER_ID: id,
        },
        HEADER: {
          AGENT_ID: "",
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      const data = await get_order_details(payload);
      if (data.RESP_CODE === 300) {
        setSelectedOrder(data.DATA.orderDetails[0]);
      } else {
        // handle error
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  };

  // 1.2 setting variables why??
  useEffect(() => {
    if (Object.keys(selectedOrder).length !== 0) {
      setStatus(selectedOrder?.fulfillmentStatus);
      setBppId(selectedOrder?.itemArray[0].bppId);
      setBppUrl(selectedOrder?.itemArray[0].bppUrl);
      setTransactionId(selectedOrder?.itemArray[0].transactionId);
      setCity(selectedOrder?.billingAddress.city);
      setOrderId(selectedOrder?.orderReferenceNumber);
      setorderState(selectedOrder?.orderState);
    }
  }, [selectedOrder]);

  // 2. Track bar
  // 2.1 status update for track bar
  const updateUI = (newStatus) => {
    switch (newStatus) {
      case "Pending": // 1
        setTextHeading("Order is pending");
        setCurrentStep(0);
        setshowCancelButton(true)
        break;
      // Why 'Accepted' case is not here.
      case "Packed": // 2
        setTextHeading("Order has been packed");
        setCurrentStep(1);
        setshowCancelButton(false)

        break;
      case "Agent-assigned": // 3
        setTextHeading("Rider assigned");
        setCurrentStep(1);
        setshowCancelButton(false)

        break;
      case "Order-picked-up": // 4
        setTextHeading("Order has been picked");
        setCurrentStep(2);
        setshowCancelButton(false)

        break;
      case "Out-for-delivery": // 5
        setTextHeading("Out for delivery");
        setCurrentStep(2);
        setshowCancelButton(false)

        break;
      case "Order-delivered": // 6
        setTextHeading("Wohoo! Order delivered Successfully");
        setCurrentStep(3);
        setshowCancelButton(false)

        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
        break;
      case "Cancelled": // 7
        setTextHeading("Order has been cancelled");
        setCurrentStep(4);
        setshowCancelButton(false)

        break;
      default:
        setTextHeading("Order is Pending");
        setCurrentStep(0);
    }
  };

  // 2.2 on status change update UI
  useEffect(() => {
    if (status) {
      // not neeeded
      updateUI(status);
    }
    return () => clearInterval(intervalId.current); // dekhana padega ??
  }, [status]);

  // 3. Raise ticket if problem with order
  const handleNeedhelp = () => {
    navigate(`/profile/tickets/raise-ticket/${id}`);
  };

  // 4. Order status
  // 4.1 check order status call
  const checkOrderStatus = async () => {
    try {
      setLoading(true);
      const res = await dispatch(
        sendRequestForStatus({
          bppId,
          bppUrl,
          transactionId,
          city,
          orderId,
        })
      );
      if (res?.payload?.RESP_CODE === 300) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
      });        
      setLoading(false);
        const newStatus = res?.payload?.DATA?.fulfillmentStatus; // why?? fulfilmentStatus
        setStatus(newStatus);
        setorderStatuslDtls(res?.payload?.DATA)
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error || "Error in fetching status,try after sometime!");
      console.error("Error sending status:", error);
    }
  };

  // 5. Order Cancellation screen
  const handleCancelOrder = () => {
    navigate(`/profile/order-history/${id}?useCase=cancel`);
  };

  // 6. handle invoice download
  const handleInvoiceDownLoad = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Fragment>
      {isLoading && <Spinner />}
      {searchParams.get("useCase") === "cancel" ? (
        <CancelOrder orderDetails={selectedOrder} />
      ) : (
        Object.keys(selectedOrder).length !== 0 && (
          <section className="order-details">
            <header className="order-header">
              <div className="order-header-left">
                <h3 className="header-title">Order Details</h3>
                <div className="order-head">
                  <p>
                    {/* {formatOrderDate(selectedOrder.orderDate)}*/}
                    {selectedOrder.orderDate}
                  </p>
                  <p>
                    {selectedOrder.itemArray.length}&nbsp;{selectedOrder.itemArray.length === 1
                      ? "Product"
                      : "Products"}
                  </p>
                </div>
              </div>
              <div
                className="order-header-right back-to-list"
                onClick={() => {
                  navigate("/profile/order-history");
                  googleAnalyticsFunc(
                    `Back_To_List_Btn_Clicked`,
                    `OrderDetails_Back_To_List_Btn_Clicked`,
                    `OrderDetails`
                  );
                }}>
                Back to List
              </div>
            </header>
            <main className="order-body">
              {currentStep !== 4 ?
                <>
                  {selectedOrder.orderDeliveryDate ?
                    <div className="order-status-delivered">
                      <p className="delivered-label">Delivered</p>
                      <p className="delivered-date">{selectedOrder.orderDeliveryDate}</p>
                    </div>
                    :
                    <div className="order-status-message">
                      Expected delivery in {selectedOrder.expectedDelivery}
                    </div>
                  }
                </>
                :
                <section className="order-track-cancel">
                  <h6 className="cancel-status">{textHeading}</h6>
                  <p className="cancel-date">Date: {selectedOrder?.cancelOrder?.cancellationDate || orderStatuslDtls?.cancelOrder?.cancellationDate || ""}</p>
                  <p className="cancel-reason">Reason: {selectedOrder?.cancelOrder?.cancellationReason || orderStatuslDtls?.cancelOrder?.cancellationReason || ""}</p>
                </section>
              }

              {/* //correct section// */}
              {currentStep !== 4 &&
                <section className="order-track">
                  <div className="progress">
                    <div className="step-container">
                      <button
                        className={`step ${currentStep >= 0 ? "completed" : ""}`}
                        title="Order Confirmed"
                      >
                        <i className="fa-solid fa-check">
                          {currentStep >= 0 ? "✓" : "01"}
                        </i>
                      </button>
                      <span>
                        {currentStep === 0
                          ? textHeading || "Order has been placed"
                          : "Order has been accepted"}
                      </span>
                    </div>
                    <div className="line-container">
                      <div
                        className={`lines ${currentStep >= 1 ? "completed" : ""}`}
                      ></div>
                    </div>
                    <div className="step-container">
                      <button
                        className={`step ${currentStep >= 1 ? "completed" : ""}`}
                        title="Order Shipped"
                      >
                        <i className="fa-solid fa-check">
                          {currentStep >= 1 ? "✓" : "02"}
                        </i>
                      </button>
                      <span>
                        {currentStep === 1
                          ? textHeading || "Order has been accepted"
                          : "Rider assigned"}
                      </span>
                    </div>
                    <div className="line-container">
                      <div
                        className={`lines ${currentStep >= 2 ? "completed" : ""}`}
                      ></div>
                    </div>
                    <div className="step-container">
                      <button
                        className={`step ${currentStep >= 2 ? "completed" : ""}`}
                        title="Out for Delivery"
                      >
                        <i className="fa-solid fa-check">
                          {currentStep >= 2 ? "✓" : "03"}
                        </i>
                      </button>
                      <span>
                        {currentStep === 2
                          ? textHeading || "Rider Assigned"
                          : "Order has been picked"}
                      </span>
                    </div>
                    <div className="line-container">
                      <div
                        className={`lines ${currentStep === 3 ? "completed" : ""}`}
                      ></div>
                    </div>
                    <div className="step-container">
                      <button
                        className={`step ${currentStep === 3 ? "completed" : ""}`}
                        title="Order Delivered"
                      >
                        <i className="fa-solid fa-check">
                          {currentStep === 3 ? "✓" : "04"}
                        </i>
                      </button>
                      <span>
                        {" "}
                        {currentStep === 3
                          ? textHeading || "Delivered"
                          : "Delivered"}
                      </span>
                    </div>
                  </div>
                </section>
              }

              <section className="order-items">
                <table>
                  <thead>
                    <tr>
                      <th>PRODUCT</th>
                      <th>PRICE</th>
                      <th>QUANTITY</th>
                      <th>SUBTOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.itemArray.map((item) => (
                      <tr key={item.itemId}>
                        <td className="product_img">
                          <img
                            src={
                              imageError || !item.itemImage
                                ? itemImageLoader
                                : item.itemImage
                            }
                            alt={item.itemName}
                            onError={handleImageError}
                          />
                          <div>
                            <span>{item.itemName}</span>
                            <span className="product-qty">
                              x {item.itemQuantity}
                            </span>
                          </div>
                        </td>
                        <td className="item-price-mob">₹{item.itemPrice}</td>
                        <td className="item-price-mob">x {item.itemQuantity}</td>
                        <td>₹{item.itemQuantity * item.itemPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>

              <section className="order-info">
                <div className="address-section">
                  <div className="address-box">
                    <h4 className="address-box-title">BILLING ADDRESS</h4>
                    <div className="address-box-content">
                      <p className="add-name">{selectedOrder.name}</p>
                      <p className="ord-address">{selectedOrder.deliveryAddress}</p>
                      <div className="contact-div">
                        {/* <div>
                      <p>EMAIL:</p>
                      <span>{selectedOrder?.name}</span>
                    </div> */}
                        <div>
                          <p className="ord-label">PHONE:</p>
                          <span>{selectedOrder?.mobileNo}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="address-box">
                    <h4 className="address-box-title">SHIPPING ADDRESS</h4>
                    <div className="address-box-content">
                      <p className="add-name">{selectedOrder.name}</p>
                      <p className="ord-address">{selectedOrder.deliveryAddress}</p>
                      <div className="contact-div">
                        {/* <div>
                      <p>NAME:</p>
                      <span>{selectedOrder?.shippingAddress.name}</span>
                    </div> */}
                        <div>
                          <p className="ord-label">PHONE:</p>
                          <span>{selectedOrder?.mobileNo}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-summary">
                  <div className="order-summary-sub">
                    <div className="div">
                      <p className="order-payment">ORDER ID:</p>
                      <span>{selectedOrder.orderReferenceNumber}</span>
                    </div>
                    <div className="div">
                      <p className="order-payment">PAYMENT METHOD:</p>
                      <span>{selectedOrder.paymentType}</span>
                    </div>
                  </div>
                  <div className="price-summary">
                    <div className="price-details">
                      <p>Subtotal:</p>{" "}
                      <span>₹{selectedOrder.quote.amountExcludingTax}</span>
                    </div>
                    <div className="price-details">
                      <p>Tax:</p>{" "}
                      <span>
                        ₹{selectedOrder?.quote.breakupArray
                          .filter((item) => item.titleType === "tax")
                          .reduce((total, item) => total + (item.price || 0), 0)}
                      </span>
                    </div>
                    <div className="price-details">
                      <p>Shipping:</p>{" "}
                      {
                        <span>
                          {selectedOrder.quote.breakupArray
                            .filter((item) => item.titleType === "delivery")
                            .map((item) => item.price).length > 0
                            ? `₹${selectedOrder.quote.breakupArray.filter(
                              (item) => item.titleType === "delivery"
                            )[0].price
                            }`
                            : "Free"}
                        </span>
                      }
                    </div>
                    <div className="price-details total">
                      <p>Total:</p>{" "}
                      <span>₹{selectedOrder.quote.totalPrice}</span>
                    </div>
                  </div>
                </div>
              </section>

              <section className="store-info">
                <p className="store-info-label">Seller Information</p>
                <p className="store-name">{selectedOrder.storeName}</p>
                <p className="store-address">{selectedOrder.sellerAddress}</p>
              </section>

              {currentStep !== 4 &&
                <div className="order-actions">
                  <button className="help-btn" onClick={() => {
                    handleNeedhelp();
                        googleAnalyticsFunc(
                          `Need_Help_Btn_Clicked`,
                          `OrderDetails_Need_Help_Btn_Clicked`,
                          `OrderDetails`
                        );
                  }}>
                    Need Help?
                  </button>
                  <button className="cancel-btn" onClick={() => {
                    checkOrderStatus();
                        googleAnalyticsFunc(
                          `Check_Order_Status_Btn_Clicked`,
                          `OrderDetails_Check_Order_Status_Btn_Clicked`,
                          `OrderDetails`
                        );
                  }}>
                    Check Order Status
                  </button>
                  {selectedOrder?.itemArray.some((item) => item.cancellable === true) && showCancelButton &&
                    // {selectedOrder?.itemArray[0].cancellable &&
                    selectedOrder?.orderState !== "Cancelled" && (
                      <button className="cancel-btn" onClick={() => {
                        handleCancelOrder();
                        googleAnalyticsFunc(
                          `Cancel_Order_Btn_Clicked`,
                          `OrderDetails_Cancel_Order_Btn_Clicked`,
                          `OrderDetails`
                        );
                      }}>
                        Cancel Order
                      </button>
                    )}
                  {selectedOrder?.invoiceUrl && (
                    <button
                      className="cancel-btn"
                      onClick={() => {
                        handleInvoiceDownLoad(selectedOrder?.invoiceUrl);
                        googleAnalyticsFunc(
                          `Download_Invoice_Btn_Clicked`,
                          `OrderDetails_Download_Invoice_Btn_Clicked`,
                          `OrderDetails`
                        );
                      }}
                    >
                      Download Invoice
                    </button>
                  )}
                </div>
              }
            </main>
          </section>
        )
      )}
    </Fragment>
  );
}

export default OrderDetails;
