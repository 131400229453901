import { useEffect, createContext, useState, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Loader/Spinner";
import { toast } from "react-toastify";
import {
  add_to_cart,
  get_cart,
  get_categories,
  remove_cart,
} from "../services/services";
import {
  AppConstants,
  generateIdWithoutHyphens,
  getUserInfoAndSetConstants,
} from "../constants/AppContants";
import LocationInstruction from "../components/LocationInstruction/LocationInstruction";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
  setLocalStorageData,
} from "../utilities/Storage";
import SelectLocation from "../components/SelectLocation/SelectLocation";

export const Context = createContext();

const AppContext = ({ children }) => {
  const navigate = useNavigate();
  // const [popupOpen, setPopupOpen] = useState(false);

  const loadFromSessionStorage = (key, defaultValue) => {
    const saved = sessionStorage.getItem(key);
    return saved ? JSON.parse(saved) : defaultValue;
  };

  const loadStringFromSessionStorage = (key, defaultValue) => {
    const saved = sessionStorage.getItem(key);
    return saved ? saved : defaultValue;
  };

  // const [categoriesInfo, setCategoriesInfo] = useState(() =>
  //   loadFromLocalStorage("categoriesInfo", [])
  // );
  // const [userLocation, setUserLocation] = useState(() =>
  //   loadFromSessionStorage("userLocation", {
  //     latitude: null,
  //     longitude: null,
  //     city: null,
  //   })
  // );
  const [userLocation, setUserLocation] = useState(() =>
    loadFromLocalStorage("userLocation", {
      latitude: null,
      longitude: null,
      city: null,
    })
  );

  const [orders, setOrders] = useState(() =>
    loadFromSessionStorage("orders", [])
  );
  const [products, setProducts] = useState(() =>
    loadFromSessionStorage("products", [])
  );
  const [storeProducts, setStoreProducts] = useState(() =>
    loadFromSessionStorage("storeProducts", [])
  );
  const [showCart, setShowCart] = useState(() =>
    loadFromSessionStorage("showCart", false)
  );
  const [cartData, setCartData] = useState({});
  const [cartCount, setCartCount] = useState(() =>
    loadFromSessionStorage("cartCount", 0)
  );
  const [cartSubTotal, setCartSubTotal] = useState(() =>
    loadFromSessionStorage("cartSubTotal", 0)
  );
  const [selectedCategory, setSelectedCategory] = useState(() =>
    loadStringFromSessionStorage("selectedCategory", "")
  );
  const [eachCartItemCount, setEachCartItemCount] = useState(() =>
    loadFromSessionStorage("eachCartItemCount", {})
  );
  const [eachCartItemSeller, setEachCartItemSeller] = useState(() =>
    loadFromSessionStorage("eachCartItemSeller", {})
  );
  const [sellers, setSellers] = useState(() =>
    loadFromSessionStorage("sellers", [])
  );
  const [nearestSeller, setNearestSeller] = useState(() =>
    loadFromSessionStorage("nearestSeller", {})
  );
  const [currentPage, setCurrentPage] = useState(() =>
    loadFromSessionStorage("currentPage", 1)
  );
  const [totalPages, setTotalPages] = useState(() =>
    loadFromSessionStorage("totalPages", 1)
  );
  const location = useLocation();
  const [userAddress, setUserAddress] = useState("");
  const [userLocationError, setUserLocationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectLocationPopUp, setSelectLocationPopUp] = useState(false);
  const [locationPermissonPopUp, setlocationPermissonPopUp] = useState(false);
  const [searchCategory, setSearchCategory] = useState("All");
  const [categoriesInfo, setCategoriesInfo] = useState([]);
  const [bestDealProducts, setbestDealProducts] = useState([]);
  const [isSearchBarActive, setIsSearchBarActive] = useState(false);

  // Error message Handling functions
  const handleSuccessMsg = (msg) => {
    toast.success(msg);
  };
  const handleErrorMsg = (msg) => {
    toast.error(msg);
  };

  // 1. Category API Call
  useEffect(() => {
    // getcategoriesInfo();
    const userLocation = JSON.parse(localStorage.getItem("userLocation"));
    if (!userLocation || !userLocation.city) {
      getGeolocation();
    }
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      const cachedCategories = await getCachedData("/categories-info");
      const cachedBestDeals = await getCachedData("/best-deal-products");

      // Checking new day
      const currentDate = new Date();
      const lastFetchedDate = cachedCategories?.timestamp
        ? new Date(cachedCategories.timestamp)
        : null;
      const isNewDay =
        !lastFetchedDate ||
        currentDate.toDateString() !== lastFetchedDate.toDateString(); // Check if it's a new day

      if (isNewDay || !cachedCategories || !cachedBestDeals) {
        await getcategoriesInfo(); // Fetch new data and update cache
      } else {
        setCategoriesInfo(cachedCategories.data); // Use cached categories data
        setbestDealProducts(cachedBestDeals.data);
      }

      scheduleNextFetch(); // Schedule the next fetch for 12 AM
    };

    const scheduleNextFetch = async () => {
      const currentDate = new Date();
      const nextMidnight = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1,
        0,
        0,
        0
      );

      const timeUntilNextMidnight = nextMidnight - currentDate;
      // next schedule call midnight
      setTimeout(async () => {
        await loadCategories(); // Trigger loadCategories at 12 AM
      }, timeUntilNextMidnight);
    };

    loadCategories(); // Initial load
  }, []);

  const cacheCategoriesInfo = async (key, data) => {
    const cache = await caches.open("categories-cache"); // Open cache
    const response = new Response(
      JSON.stringify({ data, timestamp: new Date().getTime() })
    ); // Prepare response with timestamp
    await cache.put(key, response);
  };
  const getCachedData = async (key) => {
    const cache = await caches.open("categories-cache");
    const cachedResponse = await cache.match(key); // Retrieve cached response for the specific key
    if (cachedResponse) {
      const result = await cachedResponse.json(); // Parse JSON from response
      return result; // Return parsed data
    }
    return null; // Return null if no cache
  };

  const getcategoriesInfo = async () => {
    try {
      const payload = {
        DATA: {
          userId: AppConstants.mobile,
          screenName: "test",
          UDID: AppConstants.UDID,
          agentCode: AppConstants.AID,
          gps: `${userLocation.longitude || 18},${userLocation.latitude || 73}`,
          pincode: "",
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: "3.0.0.68",
          CUSTOMER_CHARGE: 0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
          TXN_AMOUNT: 0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };

      setLoading(true);
      // const backendData = await fetchDataFromStrapi("/categories?");
      const backendData = await get_categories(payload);

      if (backendData.RESP_CODE === 300) {
        setCategoriesInfo(backendData.DATA.allProductSubCategories);

        setbestDealProducts(backendData.DATA.highestDiscountedItems);

        // localStorage.setItem('categoriesInfo', JSON.stringify(backendData.DATA.allProductSubCategories));
        // localStorage.setItem('categoriesLastFetched', new Date().getTime().toString());

        await cacheCategoriesInfo(
          "/categories-info",
          backendData.DATA.allProductSubCategories
        );
        await cacheCategoriesInfo(
          "/best-deal-products",
          backendData.DATA.highestDiscountedItems
        );
      }
    } catch (error) {
      handleErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  // 2. getting lat long
  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude: lat, longitude: lng } = position.coords;
          if (!useLocation.city) {
            setUserLocation({ latitude: lat, longitude: lng });
            await fetchUserAddress(lat, lng);
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setUserLocation((prev) => ({
              ...prev,
              latitude: null,
              longitude: null,
              city: null,
            }));
          }
          setUserLocationError(error.message);
        }
      );
    } else {
      setUserLocationError("Geolocation is not supported by this browser.");
      alert("Geolocation is not supported by this browser.");
    }
  };

  // 3. City or address using lat long
  const fetchUserAddress = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const data = response.data;

      if (data.results && data.results.length > 0) {
        // const formattedAddress = data.results[0].formatted_address;
        // setUserAddress(formattedAddress);

        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find((component) =>
          component.types.includes("locality")
        );
        const userCity_local = cityComponent?.long_name;

        if (userCity_local) {
          setUserLocation((prev) => ({ ...prev, city: userCity_local }));
        } else {
          console.warn("User city not found in address components.");
        }
      } else {
        console.warn("No results found in API response.");
      }
    } catch (error) {
      console.error("Error fetching address", error);
    }
  };

  // Scroll to top on location change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Save state to session storage when it changes
  const saveToSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  // useEffect(() => {
  //     saveToSessionStorage('categoriesInfo', categoriesInfo);
  // }, [categoriesInfo]);

  // useEffect(() => {
  //     saveToSessionStorage('userCity', userCity);
  //     // toast.success("user city");
  // }, [userCity]);

  useEffect(() => {
    saveToSessionStorage("orders", orders);
  }, [orders]);

  useEffect(() => {
    saveToSessionStorage("products", products);
  }, [products]);

  useEffect(() => {
    saveToSessionStorage("storeProducts", storeProducts);
  }, [products]);

  useEffect(() => {
    saveToSessionStorage("showCart", showCart);
  }, [showCart]);

  useEffect(() => {
    saveToSessionStorage("cartData", cartData);
    saveToSessionStorage("cartCount", cartCount);
    saveToSessionStorage("cartSubTotal", cartSubTotal);
  }, [cartData, cartCount, cartSubTotal]);

  useEffect(() => {
    // saveToSessionStorage('selectedCategory', selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    saveToSessionStorage("eachCartItemCount", eachCartItemCount);
  }, [eachCartItemCount]);

  useEffect(() => {
    saveToSessionStorage("eachCartItemSeller", eachCartItemSeller);
  }, [eachCartItemSeller]);

  // useEffect(() => {
  //   saveToSessionStorage('userLocation', userLocation);
  // }, [userLocation]);
  useEffect(() => {
    saveToLocalStorage("userLocation", userLocation);
  }, [userLocation]);

  useEffect(() => {
    saveToSessionStorage("sellers", sellers);
  }, [sellers]);

  // useEffect(() => {
  //     saveToSessionStorage('nearestSeller', nearestSeller);
  // }, [nearestSeller]);

  useEffect(() => {
    saveToSessionStorage("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    saveToSessionStorage("totalPages", totalPages);
  }, [totalPages]);

  // 4. CART
  // 4.1 Add to Cart for first time
  const handleAddToCart = async (payload) => {
    setLoading(true);
    const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

    if (!isLogin || !isLogin.isLogin) {
      navigate("/login");
      return;
    }

    const res = await add_to_cart(payload);
    if (res.RESP_CODE === 300) {
      const cartDataFromBackend = res.DATA[0];
      setCartData(cartDataFromBackend);
      // toast.success(res.RESP_MSG);
      setLoading(false);
    } else {
      setLoading(false);

      toast.error(res.RESP_MSG);
    }
  };

  const reduceCartItems = async (removeItem, id = "", transactionId = "") => {
    const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

    if (!isLogin || !isLogin.isLogin) {
      navigate("/login");
      return;
    }

    const transactionIdToUse = transactionId || cartData?.transactionId;

    try {
      const payload = {
        DATA: {
          userId: AppConstants.mobile,
          screenName: AppConstants.screenName,
          UDID: "",
          agentCode: AppConstants.AID,
          AID: AppConstants.AID,
          ...(id && { itemId: id }),
          transactionId: transactionIdToUse,
          removeItemCount: removeItem,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: "",
          CUSTOMER_CHARGE: 0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
          TXN_AMOUNT: 0,
          TXN_DT: "",
          UDID: "",
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: "",
          sellerCode: "",
          totalPrice: 0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      setLoading(true);
      const res = await remove_cart(payload);

      if (res.RESP_CODE === 300) {
        const cartDataFromBackend = res.DATA.length !== 0 ? res.DATA[0] : {};
        setCartData(cartDataFromBackend);
      } else {
        // error handling remained
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // 4.2 Update cartCount and cartSubTotal when cartItems change
  useEffect(() => {
    let count = 0;
    let subTotal = 0;
    if (Object.keys(cartData).length !== 0) {
      cartData.items.forEach((item) => {
        count += parseInt(item.count);
        // subTotal += item.price * (eachCartItemCount[item.product_id] || 1);
        subTotal += item.price * item.count;
      });
    }
    setCartCount(count);
    // setCartSubTotal(subTotal);
    setCartSubTotal(subTotal.toFixed(2));
  }, [
    cartData,
    // eachCartItemCount
  ]);

  // 4.3 Handle Cart item quantity increment or decrement
  // const handleCartProductQuantity = (type, product) => {
  //     let items = [...cartItems];
  //     let index = items.findIndex((p) => p.product_id === product.product_id);

  //     if (index !== -1) {
  //         let newCount = eachCartItemCount[product.product_id] || 1;

  //         if (type === "inc") {
  //             newCount += 1;
  //         } else if (type === "dec") {
  //             if (newCount === 1) return; // Ensure quantity doesn't go below 1
  //             newCount -= 1;
  //         }
  //         setEachCartItemCount({
  //             ...eachCartItemCount,
  //             [product.product_id]: newCount
  //         });
  //     }
  //     const updatedCart = cartItems.map((cartProduct) => {
  //         if (cartProduct.product_id === product.product_id) {
  //             if (type === "inc") {
  //                 return { ...cartProduct, quantity: cartProduct.quantity + 1 }
  //             } else if (type === "dec") {
  //                 return { ...cartProduct, quantity: cartProduct.quantity - 1 }
  //             }
  //         } else {
  //             return cartProduct;
  //         }
  //     })

  //     setCartItems(updatedCart);
  // };

  // Combine cart data into a list of JSON objects
  // const combinedCartItems = cartItems.map((item) => ({
  //     count: eachCartItemCount[item.product_id] || 1,
  //     seller: eachCartItemSeller[item.product_id] || null,
  //     cost: item.price * (eachCartItemCount[item.product_id] || 1)
  // }));

  // Function to add product to cart

  // Function to remove product from cart
  // const handleRemoveFromCart = (product) => {
  //     let items = cartItems.filter((p) => p.product_id !== product.product_id);
  //     setCartItems(items);
  //     const updatedItemCount = { ...eachCartItemCount };
  //     delete updatedItemCount[product.product_id];
  //     setEachCartItemCount(updatedItemCount);
  // };

  // 4.4 getCart
  const getCart = async () => {
    if (!AppConstants.AID || isNaN(AppConstants.AID)) {
      console.error("Invalid AID");
      return;
    }

    const payload = {
      DATA: {
        userId: AppConstants.mobile,
        screenName: AppConstants.screenName,
        UDID: AppConstants.UDID,
        AID: AppConstants.AID,
        City: userLocation.city,
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: "",
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: generateIdWithoutHyphens(),
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    const res = await get_cart(payload);
    if (res.RESP_CODE === 300) {
      const cartDataFromBackend = res.DATA.length !== 0 ? res.DATA[0] : {};
      setCartData(cartDataFromBackend);
      // if(Object.keys(cartDataFromBackend).length !== 0) {
      //   if(AppConstants.bppId !== '') {
      //     AppConstants.bppId = cartDataFromBackend.bppId;
      //     AppConstants.bppUrl = cartDataFromBackend.bppUrl;
      //   }
      // }
    }
  };

  useEffect(() => {
    const initializeCart = async () => {
      await getUserInfoAndSetConstants();
      getCart();
    };

    if (AppConstants.AID) {
      initializeCart();
    }
  }, [AppConstants.AID]);

  // encode category to convert it to valide component of URI
  const getUrlFriendlyCategoryName = (name) => {
    let category = name;
    let modifiedName = category
      .split(" ")
      .filter((word) => word !== "and" && word !== "&")
      .map((word) => word.replace(/,/g, "").toLowerCase())
      .join("-");
    return modifiedName;
  };

  const handleAcceptForLocationInstuction = () => {
    setlocationPermissonPopUp(false);
  };
  const handleClosePopUp = () => {
    setSelectLocationPopUp(false);
  };

  return (
    <Context.Provider
      value={{
        products,
        setProducts,
        storeProducts,
        setStoreProducts,
        categoriesInfo,
        setCategoriesInfo,
        orders,
        setOrders,
        cartData,
        setCartData,
        handleAddToCart,
        reduceCartItems,
        cartCount,
        showCart,
        setShowCart,
        cartSubTotal,
        setCartSubTotal,
        selectedCategory,
        setSelectedCategory,
        eachCartItemCount,
        setEachCartItemCount,
        eachCartItemSeller,
        setEachCartItemSeller,
        userLocation,
        setUserLocation,
        sellers,
        setSellers,
        nearestSeller,
        setNearestSeller,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        loading,
        setLoading,
        getGeolocation,
        handleErrorMsg,
        getUrlFriendlyCategoryName,
        getCart,
        selectLocationPopUp,
        setSelectLocationPopUp,
        locationPermissonPopUp,
        setlocationPermissonPopUp,
        searchCategory,
        setSearchCategory,
        bestDealProducts,
        setbestDealProducts,
        isSearchBarActive,
        setIsSearchBarActive,
      }}
    >
      {children}
      {loading ? <Spinner /> : ""}
      <SelectLocation open={selectLocationPopUp} />
      <LocationInstruction
        open={locationPermissonPopUp}
        onAccept={handleAcceptForLocationInstuction}
        title="Location Permission Required"
        content={
          <Fragment>
            <p style={{ marginBottom: "10px" }}>
              To provide personalized content and enhance your experience, we
              need access to your location. Please follow these steps to enable
              location permissions:
            </p>
            <ol style={{ margin: 0, paddingLeft: "1rem" }}>
              <li>
                Click the icon next to the website address in your browser.
              </li>
              <li>Select "Site settings" or "Permissions."</li>
              <li>Find "Location" and set it to "Allow."</li>
              <li>Reload the page if needed.</li>
            </ol>
          </Fragment>
        }
      />
    </Context.Provider>
  );
};

export default AppContext;
