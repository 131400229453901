import axios from 'axios';
// import {ShowingErrMsg} from './ShowingErrMsg';

export async function FetchingAddressUsingLatLong(
  latitude,
  longitude,
) {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    );

    if (response?.data?.results?.length > 0) {
      const fetchedAddr = response.data.results;

      function isEnglishAddress(fetchedAddr) {
        let englishRegex = /^[a-zA-Z0-9,.+!@#$%^&*:;"'<>?(){}/\s-]+$/;
        return englishRegex.test(fetchedAddr);
      }

      let filteredAddresses = fetchedAddr.filter(fetchedAddr =>
        isEnglishAddress(fetchedAddr.formatted_address),
      );

      let addressDetails = {
        googleAddress: '',
        landmark: '',
        area: '',
        city: '',
        pincode: '',
        state: '',
      };

      for (let i = 0; i < filteredAddresses.length; i++) {
        const addressComponents = filteredAddresses[i].address_components;

        if(!addressDetails.googleAddress) {
          addressDetails.googleAddress = filteredAddresses[i]?.formatted_address;
        }

        addressComponents.forEach((component) => {
          if (
            component.types.includes('route') || component.types.includes('establishment') && !addressDetails.pincode
          ) {
            addressDetails.landmark = component.long_name;
          }
          if (
            component.types.includes('sublocality') && !addressDetails.pincode
          ) {
            addressDetails.area = component.long_name;
          }
          if (component.types.includes('locality') && !addressDetails.city) {
            addressDetails.city = component.long_name;
          }
          if (component.types.includes('administrative_area_level_1') && !addressDetails.state
          ) {
            addressDetails.state = component.long_name;
          }
          if (
            component.types.includes('postal_code') && !addressDetails.pincode
          ) {
            addressDetails.pincode = component.long_name;
          }
        });

        if (
          addressDetails.pincode &&
          addressDetails.city &&
          addressDetails.state
        ) {
          break;
        }
      }
      return addressDetails;

    } else {
      // ShowingErrMsg('No results found');
    }
  } catch (error) {
    // ShowingErrMsg('Failed to fetch details');
  }
}

