import { useEffect, useRef } from "react";

const GoogleMapComponent = ({ propLat = 18.55673, propLng = 73.921764, onDragEnd, mapContainerStyle }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    const initMap = async () => {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps API not loaded.");
        return;
      }

      const { Map } = await window.google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

      const map = new Map(mapRef.current, {
        center: { lat: propLat, lng: propLng },
        zoom: 14,
        mapId: "4504f8b37365c3d0",
      });

      const marker = new AdvancedMarkerElement({
        map,
        position: new window.google.maps.LatLng(propLat, propLng),
        gmpDraggable: true,
      });

      marker.addListener("dragend", () => {
        const newPos = marker.position;
        if (newPos) {
          // const latLng =
          //   newPos instanceof window.google.maps.LatLng
          //     ? newPos
          //     : new window.google.maps.LatLng(newPos.lat, newPos.lng);
          // const latLng = new window.google.maps.LatLng(newPos.lat, newPos.lng);

          onDragEnd({ lat: newPos.lat, lng: newPos.lng });
          // console.log("Updated Position:", latLng.lat(), latLng.lng());
          // onDragEnd({ lat: latLng.lat(), lng: latLng.lng() });
        } else {
          console.error("Invalid position:", newPos);
        }
      });

      markerRef.current = marker;
    };

    const loadGoogleMapsScript = () => {
      if (window.google && window.google.maps) {
        initMap();
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=marker`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);

      return () => {
        script.remove();
        delete window.google;
      };
    };

    loadGoogleMapsScript();

    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
    };
  }, [propLat, propLng]);

  return <div id="map" ref={mapRef} style={mapContainerStyle}></div>;
};

export default GoogleMapComponent;
