import React, { Fragment, useContext, useEffect, useState } from "react";
import Banner from "../Home/Banner/Banner";
import { get_festive_data } from "../../services/services";
import { Context } from "../../contexts/AppContext";
import { AppConstants } from "../../constants/AppContants";
import { toast } from "react-toastify";
import "./FestivalOffers.scss";
import ProductCard from "../../components/ProductCard/ProductCard";
import BestDealItemCard from "../../components/BestDealItemCard/BestDealItemCard";
import CardSlider from "../../components/CardSlider/CardSlider";
import {  useNavigate, useParams, useSearchParams } from "react-router-dom";
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";
import Spinner from "../../components/Loader/Spinner";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
// import { generateFormattedItemArray } from "../../utils/commonFunctions";

const FestivalOffers = () => {
  const { userLocation } = useContext(Context);
  const navigate = useNavigate();
  const { getUrlFriendlyCategoryName } = useContext(Context);

  // const { festivName, bannerUrl } = useParams();

  const [loading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [festiveseasonDataResponse, setfestiveseasonDataResponse] = useState(
    []
  );
  const [searchParams] = useSearchParams();
  const redirectFestiveId = searchParams.get("festData");


  useEffect(() => {
    getFestiveseasonData();
  }, []);

  const getFestiveseasonData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        DATA: {
          gps: `${userLocation.latitude}, ${userLocation.longitude}`,
          festival_name: redirectFestiveId,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          userId: AppConstants.mobile,
          versioNo: "",
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        },
      };

      const res = await get_festive_data(payload);

      if (res.RESP_CODE === 300) {
        setfestiveseasonDataResponse(res.DATA);
        const responseData = res.DATA.items;
        const groupedData = {};

        responseData.forEach((item) => {
          const { category_id, category_displayname, priority } = item;
          if (!groupedData[category_id]) {
            groupedData[category_id] = {
              category_id,
              category_displayname,
              priority,
              items: [],
            };
          }
          if (!groupedData[category_id].items.find(i => i.itemId === item.itemId)) {
            groupedData[category_id].items.push(item);
          }        
        });

        const customizedObject = Object.values(groupedData).sort(
          (a, b) => a.priority - b.priority
        );

        // let catArr = customizedObject?.map(x => {
        //   let formattedData = generateFormattedItemArray({items: x.items})
        //   return {
        //     ...x,
        //     items: formattedData[0].items
        //   }
        // })
        setCategories(customizedObject);
        const activityRedirectionInfoforCategory = JSON.parse(
          sessionStorage.getItem("activityRedirectionInfo")
        )
        // added below if condition to check if category_id is present in session storage befor Login if try to add product to cart from particular category
        if (activityRedirectionInfoforCategory?.category_id) {
          const categoryFromSession = customizedObject.find(
            (category) => category.category_id === activityRedirectionInfoforCategory.category_id
          );
          if (categoryFromSession) {
            handleCategoryClick(categoryFromSession);
          } else {
            handleCategoryClick(customizedObject[0]);
          }
        } else {
          handleCategoryClick(customizedObject[0]);
        }
      } else {
        toast.error(res.RESP_MSG);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setFilteredProducts(category.items);
  };

  const handleExploreAll = (category) => {
    const encodedParams = encodeURIComponent(
      JSON.stringify({
        catid: category.category_id,
        festiveId: redirectFestiveId,
      })
    );

    navigate(
      `/event/${getUrlFriendlyCategoryName(
        redirectFestiveId
      )}/products/?dataWithcatgid=${encodedParams}`
    );
  };

  return (
    <Fragment>
      {loading && <Spinner />}
      <div className=" festival-offers">
        <div className="row-container festive-banner">
          {festiveseasonDataResponse.festive_banner_web && (
           <picture>
           <source media="(max-width: 650px)" srcSet={festiveseasonDataResponse.festive_banner_mobile} className="festival-main-banner" />
           <source media="(min-width: 651px)" srcSet={festiveseasonDataResponse.festive_banner_web} className="festival-main-banner" />
         
           <img src={festiveseasonDataResponse.festive_banner_web} className="festival-main-banner" alt="Festival Banner" />
         </picture>
         
          )}
        </div>
        


        <div className="row-container festive-content">
          <div className="left-category">
            <div className="categories">
              {categories.map((category) => (
                <div
                  key={category.category_id}
                  className={`festive-category ${
                    activeCategory?.category_id === category.category_id
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    handleCategoryClick(category);
                    googleAnalyticsFunc(
                      `Category_${category.category_displayname}_Clicked`,
                      `FestivalOffers_Category_${category.category_displayname}_Clicked`,
                      `FestivalOffers`
                    );
                  }}>
                  {category.category_displayname}
                </div>
              ))}
            </div>
          </div>

          <div className="right-festive-products">
            <div className="productheader">
              <div className="shopFor">
                <p className="shop-text">
                  Shop for <br />{" "}
                  <span className="title-text">
                    {" "}
                    {festiveseasonDataResponse.title}
                  </span>
                </p>
              </div>
              <a
                className="explore-btn"
                onClick={() => {
                  handleExploreAll(activeCategory);
                  googleAnalyticsFunc(
                    `ExploreAll_Btn_Clicked`,
                    `FestivalOffers_ExploreAll_Btn_Clicked`,
                    `FestivalOffers`
                  );
                }}>
                {" "}
                Explore All
              </a>
            </div>

            {filteredProducts.length > 0 ? (
              <CardSlider
                cards={filteredProducts.map((product) => (
                  <BestDealItemCard key={product.itemId} product={product} />
                ))}
              />
            ) : (
              <ErrorScreen
                message="No Products found"
                isShowAddAddress={false}
              />
            )}
          </div>
        </div>
        <div className="row-container festive-content-mobile">
          {categories.map((category) => (
            <div key={category.category_id} className="category-block">
              <div className="category-header">
                <h3>{category.category_displayname}</h3>
                <a
                  className="explore-btn"
                  onClick={() => {
                    handleExploreAll(category);
                    googleAnalyticsFunc(
                      `ExploreAll_Btn_Clicked`,
                      `FestivalOffers_ExploreAll_Btn_Clicked`,
                      `FestivalOffers`
                    );
                  }}>All
                  Explore All
                </a>
              </div>

              {category.items.length > 0 ? (
                <CardSlider
                  cards={category.items.map((product) => (
                    <BestDealItemCard key={product.itemId} product={product} />
                  ))}
                />
              ) : (
                <ErrorScreen
                  message="No Products found"
                  isShowAddAddress={false}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default FestivalOffers;
