import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./ShoppingCart.scss";
import { Context } from "../../contexts/AppContext";
import { AppConstants, generateId } from "../../constants/AppContants";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { Lotties } from "../../constants/LottieJson";
import { toast } from "react-toastify";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { getQueryParamURIComponent } from "../../utils/encryption";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const ShoppingCart = () => {
  const {
    userLocation,
    handleAddToCart,
    reduceCartItems,
    cartData,
    cartSubTotal,
    cartCount,
  } = useContext(Context);
  const [selectedSeller, setSelectedSeller] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (itemId) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [itemId]: true,
    }));
  };  

  // 1. Add to Cart
  const addToCartOrIncrement = (product) => {
    const isLogin = JSON.parse(localStorage.getItem("IsLogin"));
    if (!isLogin || !isLogin.isLogin) {
      navigate("/login");
      return;
    }
    if (product.count === product.availableQuantity) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "You have this item in your cart with the maximum available quantity.",
        );
      }
      return;
    }

    if (product.count === product.maxQuantity) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "You have reached maximum item quantity limit for this order.",
        );
      }
      return;
    }

    const payload = {
      DATA: {
        userId: AppConstants.mobile,
        screenName: AppConstants.screenName,
        UDID: AppConstants.UDID,
        agentCode: AppConstants.AID,
        DATA: [
          {
            areaCode: "",
            bppId: cartData?.bppId,
            bppUrl: cartData?.bppUrl,
            categories: [{}],
            city: cartData.city,
            gps: `${userLocation.latitude}, ${userLocation.longitude}`,
            locationId: cartData.locationId,
            messageId: Math.floor(new Date().getTime() / 1000.0),
            transactionId: cartData?.transactionId || generateId(),
            store_area_code: cartData.store_area_code,
            store_city: cartData.store_city,
            store_email: cartData.store_email,
            store_end: cartData.store_end,
            storeId: cartData.storeId,
            store_image: cartData.store_image,
            store_name: cartData.store_name,
            store_phone: cartData.store_phone,
            store_start: cartData.store_start,
            store_state: cartData.store_state,
            store_street: cartData.store_street,
            domainName: cartData.domain,
            items: [
              {
                availableQuantity: product.availableQuantity,
                bppId: cartData.bpp_id,
                bppUrl: cartData.bpp_uri,
                cancellable: product.cancellable,
                contact_details_consumer_care:
                  product.contact_details_consumer_care,
                count: "1",
                itemId: product.itemId,
                item_image: product.item_image,
                itemImagesArray: product.itemImagesArray,
                item_long_desc: product.item_long_desc,
                item_name: product.item_name,
                item_short_desc: product.item_short_desc,
                item_symbol: product.item_symbol,
                categoryName: product.categoryName,
                max_price: product.max_price,
                unitized: product.unitized,
                offer: {
                  item_capacity: [],
                },
                maxQuantity: product.maxQuantity,
                ondcreturnwindow: product.ondcreturnwindow,
                return_window: product.return_window,
                price: product.price,
                returnable: product.returnable,
                parent_item_id: product?.parent_item_id,
                // messageId: Math.floor(new Date().getTime() / 1000.0),
                // transactionId: Math.floor(new Date().getTime() / 1000.0),
              },
            ],
          },
        ],
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: "",
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };
    handleAddToCart(payload);
  };
  // const { data, error } = useFetch(
  //   `https://strapibackend.easypay.co.in/api/products?populate=*&[filters][product_id]=${"a53fbf771cd711efa82ec35e3e14d068"}`,
  // );
  // let no_items = true;
  // const product = data?.data?.[0]?.attributes;
  // const sellers = product?.productseller?.data || [];
  // const fetchSellerData = async (product, sellerName) => {
  //   const productTitle = product?.product_id;
  //   try {
  //     const res = await fetchDataFromApi(
  //       `https://strapibackend.easypay.co.in/api/productsellers?populate=*&[filters][product_id][product_id]=${productTitle}&[filters][store_details][store_name]=${"MAYA AGROVET"}`,
  //     );
  //     if (res?.data?.[0]?.attributes?.seller?.data[0]) {
  //       setEachCartItemSeller((prev) => ({
  //         ...prev,
  //         [product.product_id]: res?.data?.[0]?.attributes?.seller?.data[0],
  //       }));
  //     }
  //   } catch (error) {
  //     //  console.error("Error fetching seller data:", error);
  //   }
  // };
  // useEffect(() => {
  //   if (data) {
  //     const product = data?.data?.[0]?.attributes;
  //     const sellers = product?.productseller?.data;
  //     const initialSelectedSellerName = sellers?.[0]?.attributes?.store_details.store_name;
  //     setSelectedSeller(initialSelectedSellerName);
  //     fetchSellerData(product, initialSelectedSellerName);
  //   }
  // }, [data]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Lotties.emptyCartLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSellerClick = (productDetails) => { 
    const storeDetails = {
      bpp_id: productDetails.bppId,
      city: productDetails.city,
      domain: productDetails.domain
    }
     const queryParamValue = getQueryParamURIComponent(storeDetails);

    navigate("/store-products/" + productDetails.storeId + `?sd=${queryParamValue}`);
    googleAnalyticsFunc(
      `storeName_Clicked`,
      `ShoppingCart_storeName_Clicked`,
      `ShoppingCart`
    );
  };

  return (
    <div>
      <div className="HeaderLinears">
        <h2>Shopping Cart</h2>
      </div>
      <div className="row-container">
      <div className="headerTag">
              <p onClick={() => {
                navigate("/");
                googleAnalyticsFunc(
                  `Home_Clicked`,
                  `ShoppingCart_Home_Clicked`,
                  `ShoppingCart`
                );
              }} className="headerText">
            <u>Home</u>
          </p>
          <img className="rightArrow" src="/right-arrow.png" alt="" />
          
          <p>Cart</p>
          </div>
          {/* <button className="clear-cart" onClick={() => reduceCartItems(true) }>Clear Cart</button> */}
                  {/* <button className="clear-cart">Clear Cart</button> */}
        <div className="headerChildDiv">
          {Object.keys(cartData)?.length === 0 ? (
            <>
              <div className="empty-cart-container">
                <Lottie options={defaultOptions} height={350} width={350} />
                <h1 className="empty-cart-text">Your cart is empty</h1>
              </div>
            </>
          ) : (
            <Fragment>
              <div className="responsiveTables">
               
                <div className="responsiveTableWrapper">
                  <table className="responsiveTable">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData.items.map((product) => {
                        return (
                          <tr key={product.itemId}>
                            <td className="tableTd">
                              <div className="itemClass">
                                <div>
                                  <img
                                    src={
                                      imageErrors[product.itemId] || !product.item_image
                                        ? itemImageLoader
                                        : product.item_image
                                    }
                                    className="itemImage"
                                    alt=""
                                    onError={() => handleImageError(product.itemId)}
                                  />
                                </div>
                                <div className="itemsDetails">
                                  <h5>{product.item_name}</h5>
                                  <p className="priceKg">{product.unitized}</p>
                                  {/* <div className="discount1">0% off</div> */}
                                  <div className="storeDetails">
                                    <img
                                      className="storeImage"
                                      src={cartData.store_image}
                                      alt=""
                                    />
                                    <span
                                    className="storeName"
                                    onClick={() => handleSellerClick(cartData)}>
                                      {cartData.store_name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="tableTd">
                              <div className="itemClass1">
                                {/* <p className="strikethrough">
                                ₹{product.max_price}
                              </p> */}
                                <p>₹{product.price}</p>
                              </div>
                            </td>
                            <td className="tableTd">
                              <div
                                className='countDiv'>
                                <button
                                  className="sign-button reduce-btn"
                                  onClick={() => {
                                    reduceCartItems(false, product.itemId);
                                    googleAnalyticsFunc(
                                      `decrement_Clicked`,
                                      `ShoppingCart_decrement_Clicked`,
                                      `ShoppingCart`
                                    );
                                  }}>
                                  <img
                                    alt=""
                                    className="vectorIcon"
                                    src="/minus-sign.png"
                                  />
                                </button>
                                <p className="countNumber">{product.count}</p>
                                {/* {product.maxQuantity === product.count &&
                                  <p className="out-of-stock">0 left</p>
                                } */}
                                <button
                                  className="sign-button increment-btn"
                                  onClick={() => {
                                    addToCartOrIncrement(product);
                                    googleAnalyticsFunc(
                                      `increment_Clicked`,
                                      `ShoppingCart_increment_Clicked`,
                                      `ShoppingCart`
                                    );
                                  }}
                                  // disabled={product.maxQuantity === product.count}
                                >
                                  <img
                                    alt=""
                                    className="vectorIcon"
                                    src="/Vector.png"
                                  />
                                </button>
                              </div>

                            </td>
                            <td className="tableTd">
                              <div className="itemClass1 flexChild">
                                <div className="itemClass1">
                                  {/* <p className="strikethrough"> ₹{product.max_price * product.count}</p> */}
                                  <p>₹{parseFloat(product.price * product.count).toFixed(2)}</p>
                                </div>
                                <div className="vectorIcons">
                                  <img
                                    alt=""
                                    className="vectorIcon"
                                    src="/vector_icon.png"
                                    onClick={() => {
                                      reduceCartItems(true, product.itemId);
                                      googleAnalyticsFunc(
                                        `vectorIcon_Clicked`,
                                        `ShoppingCart_vectorIcon_Clicked`,
                                        `ShoppingCart`
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className='buttonMainDiv'>
                <div className='conClearButton'>
                  <Link to='/' className='ContinueButton'>Continue Shopping</Link>
                  {/* <button className='ContinueButton'>Continue Shopping</button> */}
                  <button className='ClearButton' onClick={() => {
                    reduceCartItems(true);
                    googleAnalyticsFunc(
                      `ClearButton_Clicked`,
                      `ShoppingCart_ClearButton_Clicked`,
                      `ShoppingCart`
                    );
                  }}>Clear Shopping Cart</button>
                </div>
                {/* <div className='ClearButtons'>
                  <button className='ClearButton'>Update Shopping Cart</button>
                </div> */}
              </div>
              </div>
              <div className="mobile-cart">
              {/* <button className="clear-cart" onClick={() => reduceCartItems(true) }>Clear Cart</button> */}

                {cartData.items.map((product) => (
                  <div key={product.itemId} className="itemClass-mobile">
                    <div className="itemClass1-mob">
                      <img
                        src={product.item_image}
                        alt=""
                        className="itemImage-mob"
                      />
                    </div>

                    <div className="itemsDetails-mob">
                      <div className="row1">
                        <div className="productInfo-mob">
                          <h5 className="itemName-mob">{product.item_name}</h5>
                          <p className="priceKg-mob">{product.unitized}</p>
                        </div>
                        <button
                          className="vectorIcon-mob"
                          onClick={() => {
                            reduceCartItems(true, product.itemId);
                            googleAnalyticsFunc(
                              `vectorIcon_mob_Clicked`,
                              `ShoppingCart_vectorIcon_mob_Clicked`,
                              `ShoppingCart`
                            );
                          }}
                        >
                          <img
                            alt=""
                            className="vectorIcon"
                            src="/vector_icon.png"
                          />
                        </button>
                      </div>

                      {/* Second Row: Counter and Price */}
                      <div className="row2">
                        <div className="countDiv">
                          <button
                            className="sign-button"
                            onClick={() => {
                              reduceCartItems(false, product.itemId);
                              googleAnalyticsFunc(
                                `sign_button_Clicked`,
                                `ShoppingCart_sign_button_Clicked`,
                                `ShoppingCart`
                              );
                            }}>
                            <img
                              alt=""
                              className="vectorIcon"
                              src="/minus-sign.png"
                            />
                          </button>
                          <p className="countNumber">{product.count}</p>
                          {/* {product.maxQuantity === product.count &&
                            <p className="out-of-stock">0 left</p>
                          } */}
                          <button
                            className="sign-button increment-btn"
                            onClick={() => {
                              addToCartOrIncrement(product);
                              googleAnalyticsFunc(
                                `increment_Btn_Clicked`,
                                `ShoppingCart_increment_Btn_Clicked`,
                                `ShoppingCart`
                              );
                            }}
                            // disabled={product.maxQuantity === product.count}
                          >
                            <img
                              alt=""
                              className="vectorIcon"
                              src="/Vector.png"
                            />
                          </button>
                        </div>
                        <div className="priceRow">
                          <p className="discountedPrice-mob">₹{product.price}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className='buttonMainDiv'>
                <div className='conClearButton'>
                  <Link to='/' className='ContinueButton'>Continue Shopping</Link>
                  {/* <button className='ContinueButton'>Continue Shopping</button> */}
                  <button className='ClearButton' onClick={() => {
                    reduceCartItems(true);
                    googleAnalyticsFunc(
                      `Clear_Shopping_Cart_Clicked`,
                      `ShoppingCart_Clear_Shopping_Cart_Clicked`,
                      `ShoppingCart`
                    );
                    }}>Clear Shopping Cart</button>
                </div>
                {/* <div className='ClearButtons'>
                  <button className='ClearButton'>Update Shopping Cart</button>
                </div> */}
              </div>
              </div>

            </Fragment>
          )}
          {Object.keys(cartData)?.length !== 0 && (
            <div className="summryDiv">
              <div>
                <h3 className="summryTitle">Summary</h3>
              </div>
              <div>
                {/* <div className='summryChild'>
                <p className='childColor'>Subtotal</p>
                <p>₹100</p>
              </div>
              <div className='summryChild'>
                <p className='childColor'>Shipping Cost </p>
                <p>₹8.00</p>
              </div>
              <div className='summryChild'>
                <p className='childColor'>Tax</p>
                <p>₹0.00</p>
              </div> */}
                <div className="summryChild">
                  <p>Total ({cartCount + " " + "items"})</p>
                  <p>₹{cartSubTotal}</p>
                </div>
              </div>
              <div className="buttonMainDivs">
                {cartData?.items?.length > 0 && (
                  <button
                    className="Proceed-button"
                    onClick={() => {
                      navigate("/checkout");
                      googleAnalyticsFunc(
                        `Proceed_to_Checkout_Clicked`,
                        `ShoppingCart_Proceed_to_Checkout_Clicked`,
                        `ShoppingCart`
                      );
                    }}
                  >
                    Proceed to Checkout
                  </button>
                )}
                {/* <button className="Check-button">
                Check out with
                <img src="/paypal.png" className='pyImage' alt="" />
              </button>
              <button className="address-button">
                Check Out with Multiple Addresses
              </button> */}
              </div>
              {/* <div className="zipImgMain">
                <div>
                  <img className="zipImage" src="/zip.png" />
                </div>
                <p className="ownTexts">
                  <span className="ownText">own</span> it now, up to 6 months
                  interest free learn more
                </p>
              </div> */}
            </div>
          )}
        </div>
        {/* <div className="similar-products">
          <h4 className="similar-products-title">
            Similar Items You Might Also Like
          </h4>
          <div className="product-row">
            {sellers?.map((seller) => {
              if (seller.attributes.store_details.store_name !== selectedSeller) {
                no_items = false;
                return (
                  <ProductCard
                    key={product.product_id}
                    data={product}
                    sellerName={seller.attributes.store_details.store_name}
                    id={product.product_id}
                  />
                );
              }
            })}
            {no_items ? <p>No similar items available</p> : ''}
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default ShoppingCart;
