import "./ProductCard.scss";
import { useLocation, useNavigate } from "react-router-dom";
import React, {
  useContext,
  useState,
  useEffect,
  forwardRef,
  useRef,
} from "react";
import { Context } from "../../contexts/AppContext";
import { AppConstants, generateId } from "../../constants/AppContants";
import ReplaceProductPopup from "../ReplaceProductPopup/ReplaceProductPopup";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { toast } from "react-toastify";
import { getQueryParamURIComponent } from "../../utils/encryption";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
// import { getItemCount } from "../../utils/commonFunctions";

const ProductCard = forwardRef(
  ({ data, isFromCatrgory, isStoreClosed }, ref) => {
    const { reduceCartItems, handleAddToCart, cartData, userLocation } =
      useContext(Context);
    const [isInCart, setIsInCart] = useState(false);
    const [replaceCartModalVisible, setReplaceCartModalVisible] =
      useState(false);
    const navigate = useNavigate();
    const [imageError, setImageError] = useState(false);
    const toastId = useRef(null);
    const location = useLocation();
    // const [outOfStock, setOutOfStock] = useState(false);

    // 1. check whether item is already in cart
    useEffect(() => {
      if (Object.keys(cartData).length !== 0) {
        const inCart = cartData.items.some((item) => item.itemId === data.itemId);
        setIsInCart(inCart);
      } else {
        setIsInCart(false);
      }
    }, [cartData]);

    // 2. Replace modal close and item added to recently emptied cart
    useEffect(() => {
      if (replaceCartModalVisible && Object.keys(cartData).length === 0) {
        addToCartOrIncrement();
        setReplaceCartModalVisible(false);
      }
    }, [cartData]);
    console.log(data.available, "data");

    // 3. Add to cart
    const addToCartOrIncrement = () => {
      googleAnalyticsFunc(
        `addToCartOrIncrement_btn_clicked`,
        `ProductCard_addToCartOrIncrement_btn_clicked`,
        `ProductCard`
      );
      const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

      if (!isLogin || !isLogin.isLogin) {
        sessionStorage.setItem(
          "activityRedirectionInfo",
          JSON.stringify({
            path: location.pathname + location.search,
            activity: "addToCart",
            productId: data.itemId,
          })
        );
        navigate("/login");
        return;
      }

      if (Object.keys(cartData).length !== 0) {
        if (data.storDetails.store_id !== cartData.storeId) {
          setReplaceCartModalVisible(true);
          return;
        }
        const item = cartData?.items?.find(
          (product) => product.itemId === data.itemId
        );

        if (item) {
          if (item.count === item.availableQuantity) {
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(
                "You have this item in your cart with the maximum available quantity."
              );
            }
            return;
          }

          if (item.count === item.maxQuantity) {
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(
                "You have reached maximum item quantity limit for this order."
              );
            }
            return;
          }
        }
      }

      const payload = {
        DATA: {
          userId: AppConstants.mobile,
          screenName: AppConstants.screenName,
          UDID: AppConstants.UDID,
          agentCode: AppConstants.AID,
          DATA: [
            {
              areaCode: "",
              bppId: data.storDetails.bpp_id,
              bppUrl: data.storDetails?.bpp_uri,
              domainName: data.domain,
              categories: [{}],
              city: data.storDetails.city,
              gps: `${userLocation.latitude}, ${userLocation.longitude}`,
              locationId: data.storDetails.store_location_id,
              messageId: Math.floor(new Date().getTime() / 1000.0),
              transactionId: cartData?.transactionId || generateId(),
              store_area_code: data.storDetails.store_area_code,
              store_city: data.storDetails.store_city,
              store_email: data.storDetails.store_email,
              store_end: data.storDetails.store_end,
              storeId: data.storDetails.store_id,
              store_image: data.storDetails.store_image,
              store_name: data.storDetails.store_name,
              store_phone: data.storDetails.store_phone,
              store_start: data.storDetails.store_start,
              store_state: data.storDetails.store_state,
              store_street: data.storDetails.store_street,
              items: [
                {
                  availableQuantity: data.available,
                  bppId: data.storDetails.bpp_id,
                  bppUrl: data.storDetails?.bpp_uri,
                  cancellable: data.cancellable,
                  contact_details_consumer_care:
                    data.contact_details_consumer_care,
                  count: "1",
                  itemId: data.itemId,
                  item_image: data.item_image,
                  itemImagesArray: data.itemImagesArray,
                  item_long_desc: data.item_long_desc,
                  item_name: data.item_name,
                  item_short_desc: data.item_short_desc,
                  item_symbol: data.item_symbol,
                  categoryName: data.category_id,
                  max_price: data.max_price,
                  unitized: data.unitized,
                  offer: {
                    item_capacity: [],
                  },
                  maxQuantity: data.max_available,
                  ondcreturnwindow: data.ondc_return_window,
                  return_window: data.return_window,
                  price: data.price,
                  returnable: data.returnable,
                  parent_item_id: data?.parent_item_id,
                },
              ],
            },
          ],
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: "",
          CUSTOMER_CHARGE: 0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
          TXN_AMOUNT: 0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: "",
          sellerCode: "",
          totalPrice: 0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };

      handleAddToCart(payload);
    };

    // 4. navigate to store on store name click
    const handleSellerClick = (productDetails) => {
      googleAnalyticsFunc(
        `storeDetails_btn_clicked`,
        `ProductCard_storeDetails_btn_clicked`,
        `ProductCard`
      );
      const storeDetails = {
        bpp_id: productDetails.storDetails.bpp_id,
        city: productDetails.storDetails.city,
        domain: productDetails.domain,
      };
      const queryParamValue = getQueryParamURIComponent(storeDetails);

      navigate(
        "/store-products/" +
          productDetails.storDetails.store_id +
          `?sd=${queryParamValue}`
      );
    };

    const calculateDiscountPercentage = (sellingPrice, maxPrice) => {
      const discountPercentage = Math.round(
        ((Number(maxPrice) - Number(sellingPrice)) / Number(maxPrice)) * 100
      );
      return discountPercentage;
    };

    // 5. Navigate to product details page
    const handleProductDetailsPage = () => {
      if (
        process.env.REACT_APP_ENV !== "production" &&
        data.storDetails.store_id === "164"
      ) {
        return;
      } else {
        const dataForQuery = {
          domain: data.domain.toLowerCase().replace(/\s+/g, ""),
          city: data.storDetails.city.toLowerCase().replace(/\s+/g, ""),
        };
        const queryParamValue = getQueryParamURIComponent(dataForQuery);

        navigate(
          `/product/${data.itemId}/seller/${data.storDetails.store_id}?pd=${queryParamValue}`
        );
      }
      googleAnalyticsFunc(
        `product_details_btn_clicked`,
        `ProductCard_product_details_btn_clicked`,
        `ProductCard`
      );
    };
    // const isValidImageType = (src) => {
    //   const validExtensions = ["jpg", "jpeg", "png"];
    //   const extension = src.split(".").pop().toLowerCase();
    //   return validExtensions.includes(extension);
    // };

    const handleImageError = () => {
      setImageError(true);
    };

    // 6. handle add to cart when deep linking us used
    useEffect(() => {
      if (Object.keys(data).length !== 0) {
        const activityRedirectionInfo = JSON.parse(
          sessionStorage.getItem("activityRedirectionInfo")
        );
        // shorten this code
        if (activityRedirectionInfo) {
          if (
            activityRedirectionInfo.productId === data.itemId &&
            activityRedirectionInfo.activity === "addToCart"
          ) {
            addToCartOrIncrement();
            sessionStorage.removeItem("activityRedirectionInfo");
          }
        }
      }
    }, [data]);

    return (
      <div className="card" ref={isFromCatrgory ? ref : null}>
        <div className="cardHeader">
          <img src="/veg.svg" alt="Veg Icon" className="vegIcon" />
          {calculateDiscountPercentage(data.price, data.max_price) > 0 && (
            <div className="discount">
              {calculateDiscountPercentage(data.price, data.max_price)}% off
            </div>
          )}{" "}
        </div>
        <div
          className="cardproductImage-container"
          onClick={handleProductDetailsPage}
        >
          <img
            src={
              imageError || !data.item_image ? itemImageLoader : data.item_image
            }
            alt={data.item_name}
            className="cardproductImage"
            onError={handleImageError}
          />
        </div>
        <div className="productDetails">
          <div className="rating">
            {/* <div className="stars">★★★★★</div> */}
            {parseInt(data.available) === 0 && (
              <div className="outOfStock">Out of Stock</div>
            )}
          </div>
          <div className="productName" onClick={handleProductDetailsPage}>
            {data.item_name}
          </div>
          {isFromCatrgory && (
            <div
              className="productFlex"
              onClick={() => handleSellerClick(data)}
            >
              <div className="productWeight">
                <img
                  src={data.storDetails.store_image}
                  className="shopIcon"
                  alt=""
                />
              </div>
              <div className="productWeights">
                {data.storDetails.store_name}
              </div>
            </div>
          )}
          <div className="price">
            <span className="rupee">₹{data.price}</span>
            {data.price !== data.max_price && (
              <>
                <span className="strikethrough">₹{data.max_price}</span>
              </>
            )}
          </div>
          {isStoreClosed || parseInt(data.available) === 0 ? (
            <div className="addButton">
              <button className="addonlyButton" disabled>
                Add
              </button>
            </div>
          ) : (
            data.available > 0 && (
              <div className="addButton">
                {isInCart ? (
                  <div className="quantityControls">
                    <button
                      className="decrement"
                      onClick={() => {
                        googleAnalyticsFunc(
                          `reduceCartItems_btn_clicked`,
                          `ProductCard_reduceCartItems_btn_clicked`,
                          `ProductCard`
                        );
                        reduceCartItems(false, data.itemId);
                      }}
                    >
                      -
                    </button>
                    <span className="quantity">
                    {cartData?.items?.find((item) => item.itemId === data.itemId)
                    ?.count || 0}
                    </span>
                    <button
                      className="increment"
                      onClick={() => addToCartOrIncrement()}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    className="addonlyButton"
                    onClick={() => addToCartOrIncrement()}
                  >
                    Add
                  </button>
                )}
              </div>
            )
          )}
        </div>
        <ReplaceProductPopup
          isVisible={replaceCartModalVisible}
          onClose={() => setReplaceCartModalVisible(false)}
          cartStoreName={cartData?.store_name}
          newItemName={data?.item_name}
          newStoreName={data?.storDetails?.store_name}
          onReplace={() => reduceCartItems(true)}
        />
      </div>
    );
  }
);

export default ProductCard;

// Old ProductCard.jsx

// const ProductCard = ({ data, sellerName }) => {
//   const {
//     reduceCartItems,
//     handleAddToCart,
//     eachCartItemCount,
//     handleCartProductQuantity,
//     cartData,
//     setCartData,
//     userLocation,
//   } = useContext(Context);
//   const [productSeller, setProductSeller] = useState(null);
//   const [quantity, setQuantity] = useState(
//     eachCartItemCount[data.product_id] || 1
//   );
//   const [isInCart, setIsInCart] = useState(false);
//   const [replaceCartModalVisible, setReplaceCartModalVisible] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState({});
//   const navigate = useNavigate();

//   // 1. Check any product in Cart and yes remove "Add to Cart" button
//   useEffect(() => {
//     if (Object.keys(cartData).length !== 0) {
//       const inCart = cartData.items.some(
//         (item) =>
//           item.itemId ===
//           data.productseller.data[0].attributes.item_details.itemId
//       );
//       setIsInCart(inCart);
//     } else {
//       setIsInCart(false);
//     }
//   }, [cartData]);

//   // 2. "Add to Cart"
//   const addToCartOrIncrement = () => {
//     // if (cartItems.some((item) => item.product_id === data.product_id)) {
//     //   handleIncrement();
//     // } else {

//     const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

//     if (!isLogin || !isLogin.isLogin) {
//       navigate("/login");
//       return;
//     }

//     const product = data;
//     const sellerData = data.productseller.data[0];
//     setSelectedProduct(product);
//     if (Object.keys(cartData).length !== 0) {
//       if (sellerData.attributes.store_details.store_id !== cartData.storeId) {
//         setReplaceCartModalVisible(true);
//         return;
//       }
//     }
//     const payload = {
//       DATA: {
//         userId: AppConstants.mobile,
//         screenName: AppConstants.screenName,
//         UDID: AppConstants.UDID,
//         agentCode: AppConstants.AID,
//         DATA: [
//           {
//             areaCode: "",
//             bppId: sellerData.attributes.item_details.storDetails.bpp_id,
//             bppUrl: sellerData.attributes.item_details?.storDetails.bpp_uri,
//             categories: [{}],
//             city: userLocation.city,
//             gps: `${userLocation.latitude}, ${userLocation.longitude}`,
//             locationId:
//               sellerData.attributes.item_details?.storDetails.store_location_id,
//             messageId: Math.floor(new Date().getTime() / 1000.0),
//             transactionId: cartData?.transactionId || generateId(),
//             store_area_code:
//               sellerData.attributes.store_details.store_area_code,
//             store_city: sellerData.attributes.store_details.store_city,
//             store_email: sellerData.attributes.store_details.store_email,
//             store_end: sellerData.attributes.store_details.store_end,
//             storeId: sellerData.attributes.store_details.store_id,
//             store_image: sellerData.attributes.store_details.store_image,
//             store_name: sellerData.attributes.store_details.store_name,
//             store_phone: sellerData.attributes.store_details.store_phone,
//             store_start: sellerData.attributes.store_details.store_start,
//             store_state: sellerData.attributes.store_details.store_state,
//             store_street: sellerData.attributes.store_details.store_street,
//             items: [
//               {
//                 availableQuantity: sellerData.attributes.item_details.available,
//                 bppId: sellerData.attributes.item_details.storDetails.bpp_id,
//                 bppUrl: sellerData.attributes.item_details?.storDetails.bpp_uri,
//                 cancellable: sellerData.attributes.item_details.cancellable,
//                 contact_details_consumer_care:
//                   sellerData.attributes.item_details
//                     .contact_details_consumer_care,
//                 count: "1",
//                 itemId: sellerData.attributes.item_details.itemId,
//                 item_image: sellerData.attributes.item_details.item_image,
//                 itemImagesArray:
//                   sellerData.attributes.item_details.itemImagesArray,
//                 item_long_desc:
//                   sellerData.attributes.item_details.item_long_desc,
//                 item_name: sellerData.attributes.item_details.item_name,
//                 item_short_desc:
//                   sellerData.attributes.item_details.item_short_desc,
//                 item_symbol: sellerData.attributes.item_details.item_symbol,
//                 categoryName: sellerData.attributes.item_details.category_id,
//                 max_price: sellerData.attributes.item_details.max_price,
//                 unitized: sellerData.attributes.item_details.unitized,
//                 offer: {
//                   item_capacity: [],
//                 },
//                 maxQuantity: sellerData.attributes.item_details.max_available,
//                 ondcreturnwindow:
//                   sellerData.attributes.item_details.ondc_return_window,
//                 return_window: sellerData.attributes.item_details.return_window,
//                 price: sellerData.attributes.item_details.price,
//                 returnable: sellerData.attributes.item_details.returnable,
//                 // messageId: Math.floor(new Date().getTime() / 1000.0),
//                 // transactionId: Math.floor(new Date().getTime() / 1000.0),
//               },
//             ],
//           },
//         ],
//       },
//       HEADER: {
//         AGENT_ID: AppConstants.AID,
//         AID: AppConstants.AID,
//         APP_VERSION: "",
//         CUSTOMER_CHARGE: 0,
//         ORDER_ID: "",
//         PAYABLE_AMOUNT: 0,
//         REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
//         TXN_AMOUNT: 0,
//         TXN_DT: "",
//         UDID: AppConstants.UDID,
//         epAgentId: AppConstants.AID,
//         epPartnerId: "",
//         mobile: AppConstants.mobile,
//         orderId: "",
//         sellerCode: "",
//         totalPrice: 0,
//         userId: AppConstants.mobile,
//         versioNo: "",
//       },
//     };

//     handleAddToCart(payload);
//   };

//   // 3. Clear cart
//   useEffect(() => {
//     if (replaceCartModalVisible && Object.keys(cartData).length === 0) {
//       addToCartOrIncrement();
//       setReplaceCartModalVisible(false);
//     }
//   }, [cartData]);

//   // 3. Handle Increment in Cart item quantity
//   // const handleIncrement = () => {
//   //   if (productSeller?.item_details?.max_available > quantity) {
//   //     setQuantity(quantity + 1);
//   //     handleCartProductQuantity("inc", data);
//   //   }
//   // };

//   // 4. Handle Decrement in Cart item quantity
//   const handleDecrement = () => {
//     if (quantity > 1) {
//       setQuantity(quantity - 1);
//       handleCartProductQuantity("dec", data);
//     }
//   };

//   useEffect(() => {
//     setQuantity(eachCartItemCount[data.product_id] || 1);
//   }, [eachCartItemCount, data.product_id]);

//   useEffect(() => {
//     const fetchProductSeller = async () => {
//       const res = await fetchDataFromStrapi(
//         `/productsellers?&[filters][product_id][product_id]=${data.product_id}&[filters][store_details][store_name]=${sellerName}&fields[0]=description&fields[1]=image_url&fields[2]=price&fields[3]=item_details&fields[4]=store_details`
//       );
//       setProductSeller(res?.data[0]?.attributes);
//     };

//     fetchProductSeller();
//   }, [data.product_id, sellerName]);

//   if (!productSeller) {
//     return null; // Or some loading indicator
//   }

//   return (
//     <>
//       {/* <div className="card">
//         <div className="cardHeader">
//           <img src="/veg.svg" alt="Veg Icon" className="vegIcon" />
//           <div className="discount">50% off</div>
//         </div>
//         <div
//           className="cardproductImage-container"
//           onClick={() =>
//             navigate(`/product/${data.product_id}/seller/${sellerName}`)
//           }
//         >
//           <img
//             src={data.image_url}
//             alt={data.title}
//             className="cardproductImage"
//           />
//         </div>
//         <div className="productDetails">
//           <div className="rating">
//             <div className="stars">★★★★★</div>
//             <div className="inStock">In Stock</div>
//           </div>
//           <div
//             className="productName"
//             onClick={() =>
//               navigate(`/product/${data.product_id}/seller/${sellerName}`)
//             }
//           >
//             {data.title}
//           </div>
//           <div className="productWeight">0</div>
//           <div className="productWeight">{sellerName}</div>
//           <div className="price">
//             <span className="rupee">₹{productSeller.price}</span>
//             <span className="strikethrough">MRP ₹0</span>
//           </div>
//           <div className="addButton">
//             {isInCart ? (
//               <div className="quantityControls">
//                 <button className="decrement" onClick={handleDecrement}>
//                   -
//                 </button>
//                 <span className="quantity">{quantity}</span>
//                 <button className="increment" onClick={handleIncrement}>
//                   +
//                 </button>
//               </div>
//             ) : (
//               <button className="addonlyButton" onClick={addToCartOrIncrement}>
//                 Add
//               </button>
//             )}
//           </div>
//         </div>
//       </div> */}
//       <div className="card">
//         {/* <div className="cardHeader">
//           <img src="/veg.svg" alt="Veg Icon" className="vegIcon" />
//           <div className="discount">50% off</div>
//         </div> */}
//         <div
//           className="cardproductImage-container"
//           onClick={() =>
//             navigate(`/product/${data.product_id}/seller/${sellerName}`)
//           }
//         >
//           <img
//             src={data.image_url}
//             alt={data.title}
//             className="cardproductImage"
//           />
//         </div>
//         <div className="productDetails">
//           {/* <div className="rating">
//             <div className="stars">★★★★★</div>
//             <div className="inStock">In Stock</div>
//           </div> */}
//           <div
//             className="productName"
//             onClick={() =>
//               navigate(`/product/${data.product_id}/seller/${sellerName}`)
//             }
//           >
//             {data.title}
//           </div>
//           <div className="productFlex">
//             <div className="productWeight">
//               <img
//                 src={productSeller.store_details.store_image}
//                 className="shopIcon"
//                 alt=""
//               />
//             </div>
//             <div className="productWeights">
//               {productSeller.store_details.store_name}
//             </div>
//           </div>
//           <div className="price">
//             <span className="rupee">₹{productSeller.price}</span>
//             <span className="strikethrough">
//               ₹{productSeller.item_details.max_price}
//             </span>
//           </div>
//           <div className="addButton">
//             {isInCart ? (
//               <div className="quantityControls">
//                 <button
//                   className="decrement"
//                   onClick={() =>
//                     reduceCartItems(false, productSeller.item_details.itemId)
//                   }
//                 >
//                   -
//                 </button>
//                 <span className="quantity">
//                   {
//                     cartData?.items?.find(
//                       (item) =>
//                         item.itemId ===
//                         data.productseller.data[0].attributes.item_details
//                           .itemId
//                     ).count
//                   }
//                 </span>
//                 <button className="increment" onClick={addToCartOrIncrement}>
//                   +
//                 </button>
//               </div>
//             ) : (
//               <button className="addonlyButton" onClick={addToCartOrIncrement}>
//                 Add
//               </button>
//             )}
//           </div>
//         </div>
//       </div>

//       <Modal
//         open={replaceCartModalVisible}
//         onClose={() => setReplaceCartModalVisible(true)}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <div className="popup-box">
//           <p>close popup</p>
//           <h4>Replace cart items?</h4>
//           {/* <p>
//              Your cart contains products from {cartData.store_name}. Do you want
//              to discard the selection and add {selectedProduct?.title} from{" "}
//              {selectedProduct?.productseller.data[0]}?
//            </p> */}
//           <div className="button-group">
//             <button
//               className="button reject"
//               onClick={() => setReplaceCartModalVisible(false)}
//             >
//               No
//             </button>
//             <button
//               className="button accept"
//               onClick={() => reduceCartItems(true)}
//             >
//               Replace
//             </button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default ProductCard;
