import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import customerProfile from "../../assets/images/Ellipse 5.png";
import { GetAddressBuyer, GetOrderHistory } from "../../redux/actions/Action";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { formatOrderDate } from "../../utils/encryption";
import { useSelector } from "react-redux";
import { AppConstants } from "../../constants/AppContants";
import { useNavigate } from "react-router-dom";
import { getLocalStorageData } from "../../utilities/Storage";
import OrderHistory from "../OrderHistory/OrderHistory";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { data: orderHistoryList } = useSelector(
    (state) => state.GetOrderHistory,
  );

  const addressRequest = useAppSelector((state) => state.GetAddressBuyerSlice);
  const lastAddress = addressRequest.data[addressRequest.data.length - 1];

  const isLoggedIn = JSON.parse(getLocalStorageData("IsLogin"))?.isLogin;

  const userEmail =
    JSON.parse(getLocalStorageData("userInfo"))?.userInfo?.EMAIL || "";

  const userName =
    JSON.parse(getLocalStorageData("userInfo"))?.userInfo?.NAME || "";

  const userInitials = userName
    .split(" ")
    .map((name) => name.charAt(0).toUpperCase())
    .join("")
    .slice(0, 2);

  const initials =
    userInitials.length === 1 && userName.split(" ").length === 1
      ? userName.charAt(0).toUpperCase() + userName.charAt(1).toUpperCase()
      : userInitials;

  useEffect(() => {
    dispatch(GetAddressBuyer());
    if (!orderHistoryList?.orderDetails?.length) {
      dispatch(GetOrderHistory(1));
    }
  }, [dispatch, orderHistoryList]);

  return (
    <div className="dashboard">
      <div className="profile-section">
        <div className="profile-card">
          <div className="profile-card-content">
            {/* <img src={customerProfile} alt="Profile" className="profile-image" /> */}
            {isLoggedIn ? (
              <div className="profile-text">{initials}</div>
            ) : (
              <img
                src={customerProfile}
                alt="login icon"
                className="profile-image"
              />
            )}
            <h4>
              {AppConstants.NAME.charAt(0).toUpperCase() +
                AppConstants.NAME.slice(1)}
            </h4>
          </div>
        </div>

        <div className="billing-address">
          <h5>BILLING ADDRESS</h5>
          {lastAddress ? (
            <div className="address-content">
              <p className="bill-name">{lastAddress?.name}</p>
              <p className="bill-address">
                {[
                  lastAddress?.houseNo,
                  lastAddress?.addressOne,
                  lastAddress?.landmark,
                  lastAddress?.city,
                  lastAddress?.state && lastAddress?.pincode
                    ? `${lastAddress.state}\u00A0-\u00A0${lastAddress.pincode}`
                    : lastAddress?.state || lastAddress?.pincode
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>
              <p className="bill-mobile">{lastAddress?.mobile}</p>
              <p
                href="#"
                className="edit-address"
                onClick={() => {
                  navigate("/profile/add-address", { state: lastAddress });
                  googleAnalyticsFunc(
                    `Edit_Address_Clicked`,
                    `Dashboard_Edit_Address_Clicked`,
                    `Dashboard`
                  );
                }}>
                Edit Address
              </p>
            </div>
          ) : (
            <p className="loading-text">Loading address...</p>
          )}
        </div>
      </div>

      <OrderHistory usedAt="dashboard" />
    </div>
  );
};

export default Dashboard;
