import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CardSlider.scss";
import buttonnext from "../../assets/icons/button-next.svg";
import buttoneback from "../../assets/icons/buttone-back.svg";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const CardSlider = ({ cards }) => {
  const navigate = useNavigate();
  const slideViewRef = useRef(null);
  const [visibleForButton, setVisibleForButton] = useState(false);
  const [visibleBackButton, setVisibleBackButton] = useState(false);

  const createForwardScroll = () => {
    googleAnalyticsFunc(
      `createForwardScroll_Tab_clicked`,
      `CardSlider_createForwardScroll_Tab_clicked`,
      `CardSlider`
    );
    if (cards?.length > 0) {
      return function () {
        const slideViewWidth = slideViewRef.current.offsetWidth;
        const slideItemWidth = slideViewRef.current.children[0].offsetWidth;
        const currentScroll = slideViewRef.current.scrollLeft;

        const newScrollLeft = Math.min(
          currentScroll + slideItemWidth,
          slideViewRef.current.scrollWidth - slideViewWidth
        );

        slideViewRef.current.scrollBy({
          left: newScrollLeft,
          behavior: "smooth",
        });
      };
    }
  };

  const createBackwardScroll = () => {
    googleAnalyticsFunc(
      `createBackwardScroll_Tab_clicked`,
      `CardSlider_createBackwardScroll_Tab_clicked`,
      `CardSlider`
    );
    if (cards?.length > 0) {
      return function () {
        const slideItemWidth = slideViewRef.current.children[0].offsetWidth;
        const currentScroll = slideViewRef.current.scrollLeft;

        const newScrollLeft = Math.max(currentScroll - slideItemWidth, 0);
        slideViewRef.current.scrollTo({
          left: -newScrollLeft,
          behavior: "smooth",
        });
      };
    }
  };

  const updateButtonVisibility = () => {
    const slideViewWidth = slideViewRef.current.offsetWidth;
    const slideItemsWidth = slideViewRef.current.scrollWidth;
    const scrollLeft = slideViewRef.current.scrollLeft;

    setVisibleForButton(scrollLeft + slideViewWidth < slideItemsWidth - 1);
    setVisibleBackButton(scrollLeft > 1);
  };

  useEffect(() => {
    if (cards?.length > 0) {
      const slideViewWidth = slideViewRef.current.offsetWidth;
      const slideItemsWidth = slideViewRef.current.scrollWidth;

      setVisibleForButton(slideItemsWidth > slideViewWidth);
    }

    const ref = slideViewRef.current;
    ref.addEventListener("scroll", updateButtonVisibility);

    return () => ref.removeEventListener("scroll", updateButtonVisibility);
  }, [cards]);

  const moveFor = createForwardScroll();
  const moveBack = createBackwardScroll();

  return (
    <div className="slider-container">
      <div className="slider-view" ref={slideViewRef}>
        {cards}
      </div>

      {visibleBackButton && (
        <button
          className={`slide-back ${!visibleBackButton ? "hidden" : ""}`}
          onClick={moveBack}
        >
        <img src={buttoneback} alt="back" className="arrowIcon" />  
        </button>
      )}
      {visibleForButton && (
        <button
          className={`slide-for ${!visibleForButton ? "hidden" : ""}`}
          onClick={moveFor}
        >
        <img src={buttonnext} alt="next" />
        </button>
      )}
    </div>
  );
};

export default CardSlider;
