import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./BuyerCarousel.scss";
import { useNavigate } from "react-router-dom";
import { getQueryParamURIComponent } from "../../utils/encryption";
import { Context } from "../../contexts/AppContext";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const BuyerCarousel = ({ imagesData }) => {
  const deskImagesData = imagesData?.filter(
    (imageData) => imageData.CATEGORY === "WEB"
  );
  const mobImagesData = imagesData?.filter(
    (imageData) => imageData.CATEGORY === "MOBILE"
  );
  const [slideIndex, setSlideIndex] = useState(0);
  const slidesRef = useRef([]);
  const dotsRef = useRef([]);
  const navigate = useNavigate();
  const { getUrlFriendlyCategoryName } = useContext(Context);

  const showSlides = (index) => {
    slidesRef.current.forEach((slide, i) => {
      if (slide) {
        slide.style.display = i === index ? "block" : "none";
      }
    });
    dotsRef.current.forEach((dot, i) => {
      if (dot) {
        dot.className = i === index ? "dot active" : "dot";
      }
    });
  };

  useEffect(() => {
    if (imagesData.length > 0) {
      showSlides(slideIndex);
    }
  }, [slideIndex, imagesData]);

  useEffect(() => {
    let indexForInterval = slideIndex;
    const setIntervalId = setInterval(() => {
      indexForInterval = (indexForInterval + 1) % slidesRef.current.length;
      setSlideIndex(indexForInterval);
    }, 5000);
    return () => clearInterval(setIntervalId);
  }, [slideIndex]);

  const handleDotClick = (index) => {
    googleAnalyticsFunc(
      `handleDotClick_Tab_clicked`,
      `BuyerCarousel_handleDotClick_Tab_clicked`,
      `BuyerCarousel`
    );
    setSlideIndex(index);
  };

  const changeSlide = (direction) => {
    googleAnalyticsFunc(
      `changeSlide_Tab_clicked`,
      `BuyerCarousel_changeSlide_Tab_clicked`,
      `BuyerCarousel`
    );
    const nextIndex =
      direction === "next"
        ? (slideIndex + 1) % slidesRef.current.length
        : (slideIndex - 1 + slidesRef.current.length) %
          slidesRef.current.length;
    setSlideIndex(nextIndex);
  };

  const redirectToStore = (imageData) => {
    googleAnalyticsFunc(
      `redirectToStore_Tab_clicked`,
      `BuyerCarousel_redirectToStore_Tab_clicked`,
      `BuyerCarousel`
    );

    if (imageData.ACTION === "festive") {
      const encodedRedirectUrl = encodeURIComponent(imageData.REDIRECT_URL);
      navigate(
        `/event/${getUrlFriendlyCategoryName(
          imageData.REDIRECT_URL
        )}?festData=${encodedRedirectUrl}`
      );
    } else {
      const storeDetails =
        process.env.REACT_APP_ENV === "production"
          ? {
              bpp_id: imageData.ACTION,
              city: "IND",
              domain: "All",
            }
          : {
              bpp_id: "sellerpreprod.easypay.co.in",
              city: "Pune",
              domain: "grocery",
            };
      const queryParamValue = getQueryParamURIComponent(storeDetails);
      const store_id =
        process.env.REACT_APP_ENV === "production"
          ? imageData.REDIRECT_URL
          : 101;
      navigate("/store-products/" + store_id + `?sd=${queryParamValue}`);
    }
  };

  return (
    <Fragment>
      {window.matchMedia("(min-width: 651px)").matches
        ? // If screen width is 651px or more, render and iterate over deskImagesData
          deskImagesData.length > 0 && (
            <div className="row-container slideshow-container">
              {deskImagesData.map((imageData, index) => (
                <div
                  key={index}
                  className="mySlides fade"
                  ref={(ele) => (slidesRef.current[index] = ele)}
                >
                  <div className="numbertext">
                    {index + 1} / {deskImagesData.length}
                  </div>
                  <picture
                    onClick={() => redirectToStore(deskImagesData[index])}
                  >
                    <source
                      media="(min-width: 651px)"
                      srcSet={deskImagesData[index]?.URL}
                      className="carousel-img"
                    />
                    <img
                      src={deskImagesData[index]?.URL}
                      className="carousel-img"
                      alt={`Slide ${index + 1}`}
                    />
                  </picture>
                  <div className="text">Caption Text</div>
                </div>
              ))}
              <button className="prev" onClick={() => changeSlide("prev")}>
                &#10094;
              </button>
              <button className="next" onClick={() => changeSlide("next")}>
                &#10095;
              </button>
              <div className="carousel-dots">
                {deskImagesData.map((_, index) => (
                  <span
                    key={index}
                    className="dot"
                    ref={(ele) => (dotsRef.current[index] = ele)}
                    onClick={() => handleDotClick(index)}
                  ></span>
                ))}
              </div>
            </div>
          )
        : // If screen width is less than or equal to 650px, render and iterate over mobImagesData
          mobImagesData.length > 0 && (
            <div className="row-container slideshow-container">
              {mobImagesData.map((imageData, index) => (
                <div
                  key={index}
                  className="mySlides fade"
                  ref={(ele) => (slidesRef.current[index] = ele)}
                >
                  <div className="numbertext">
                    {index + 1} / {mobImagesData.length}
                  </div>
                  <picture
                    onClick={() => redirectToStore(mobImagesData[index])}
                  >
                    <source
                      media="(max-width: 650px)"
                      srcSet={mobImagesData[index]?.URL}
                      className="carousel-img"
                    />
                    <img
                      src={mobImagesData[index]?.URL}
                      className="carousel-img"
                      alt={`Slide ${index + 1}`}
                    />
                  </picture>
                  <div className="text">Caption Text</div>
                </div>
              ))}
              <button className="prev" onClick={() => changeSlide("prev")}>
                &#10094;
              </button>
              <button className="next" onClick={() => changeSlide("next")}>
                &#10095;
              </button>
              <div className="carousel-dots">
                {mobImagesData.map((_, index) => (
                  <span
                    key={index}
                    className="dot"
                    ref={(ele) => (dotsRef.current[index] = ele)}
                    onClick={() => handleDotClick(index)}
                  ></span>
                ))}
              </div>
            </div>
          )}
    </Fragment>
  );
};

export default BuyerCarousel;
