import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../Loader/Spinner";
import IssueProductCard from "../IssueProductCard/IssueProductCard";
import {
  getCancellationReasons,
  sendRequestForCancel,
} from "../../redux/actions/Action";
import { useDispatch } from "react-redux";
import "./CancelOrder.scss";
import { generateId } from "../../constants/AppContants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const CancelOrder = ({ orderDetails }) => {
  const [loading, setIsLoading] = useState();
  const dispatch = useDispatch();
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [bppId, setBppId] = useState("");
  const [bppUrl, setBppUrl] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [messageId, setMessageId] = useState("");
  const [city, setCity] = useState("");
  const [orderId, setOrderId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  // 1. setting variables for API call
  useEffect(() => {
    if (orderDetails) {
      const {
        itemArray,
        shippingAddress,
        orderReferenceNumber,
        transactionId: txnId,
        messageId: msgId,
      } = orderDetails;

      if (itemArray && itemArray.length > 0) {
        setBppId(itemArray[0]?.bppId);
        setBppUrl(itemArray[0]?.bppUrl);
      }

      setCity(shippingAddress?.city);
      setOrderId(orderReferenceNumber);
      setTransactionId(txnId);
      setMessageId(msgId);
    }
  }, [orderDetails]);

  // 2. Getting to know cancelReasonsWhen
  useEffect(() => {
    const timer = setTimeout(() => {
      if (orderDetails?.deliveryStatus) {
        const normalizedStatus = orderDetails.deliveryStatus.toLowerCase();

        const cancelReasonsWhen =
          normalizedStatus === "pending" || normalizedStatus === "accepted"
            ? "CANCEL_BEFORE_SHIP_BY_BUYER"
            : normalizedStatus === "order-delivered"
            ? "UPDATE"
            : "CANCEL_BEFORE_SHIP_BY_BUYER";
        if (cancelReasonsWhen) {
          getOrderCancelReasons(cancelReasonsWhen);
        }
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [orderDetails?.deliveryStatus]);

  // 3. Getting cancellation reasons
  const getOrderCancelReasons = (reasonsWhen) => {
    try {
      dispatch(getCancellationReasons(reasonsWhen))
        .then((res) => {
          if (res.payload.RESP_CODE === 300) {
            setReasons(res.payload.DATA);
          } else {
            console.error(
              `Failed Cancellation Reasons Server Call with err code: ${res.payload.RESP_CODE}`
            );
          }
        })
        .catch((err) =>
          console.error(`Failed Cancellation Reasons Server Call, ${err}`)
        );
    } catch (err) {
      console.error(`Cancel Order Failled.`);
    }
  };

  // 4. check product
  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) =>
      prev.find((selectedItem) => selectedItem.itemId === item.itemId)
        ? prev.filter((selectedItem) => selectedItem.itemId !== item.itemId)
        : [...prev, item]
    );
  };
  // 5. Cancel Order
  // 5.1 cancel order validation
  const handleSubmitButton = () => {
    googleAnalyticsFunc(
      `SubmitButton_clicked`,
      `CancelOrder_SubmitButton_clicked`,
      `CancelOrder`
    );
    if (!selectedReason) {
      toast.error("Please select a reason for cancellation.");
      return;
    }
    if (selectedItems.length === 0) {
      toast.error("Please select at least one product to cancel.");
      return;
    }
    cancelOrder(selectedReason?.reasonCode);
  };

  const cancellableItems =
    orderDetails?.itemArray?.filter((item) => item.cancellable) || [];

  // 5.2 cancel order API
  const cancelOrder = (code) => {
    setIsLoading(true);
    dispatch(
      sendRequestForCancel({
        bppId,
        bppUrl,
        transactionId,
        messageId: generateId(),
        city,
        orderId,
        cancellation_reason_id: code,
      })
    )
      .then((res) => {
        if (res.payload.RESP_CODE === 300) {
          toast.success(res.payload.RESP_MSG);
          navigate(-1, { replace: true });
        } else {
          toast.error(res.payload.RESP_MSG);
        }
      })
      .catch((err) => {
        console.error("Failed to cancel order:", err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <main className="content-cancel">
            <header className="order-header-cancel">
              <h4>Problem with the Order</h4>
              <div
                className="back-to-list-cancel"
                onClick={() => {
                  navigate(-1, { replace: true });
                  googleAnalyticsFunc(
                    `Back_to_Order_Tab_clicked`,
                    `CancelOrder_Back_to_Order_Tab_clicked`,
                    `CancelOrder`
                  );
                }}
              >
                Back to Order
              </div>
            </header>
            <div className="tell-us-problem-cancel">
              Tell us about the problem you are facing with the order
            </div>

            <h5 className="select-products-cancel">Select Products</h5>

            <section className="order-form-cancel">
              <IssueProductCard
                orderDetails={{ ...orderDetails, itemArray: cancellableItems }}
                handleCheckboxChange={handleCheckboxChange}
                selectedItems={selectedItems}
              />
            </section>
            <h5 className="describe-issue-cancel">
              Select the appropriate issue*
            </h5>
            <div className="reason-options-cancel">
              {reasons.map((reason) => (
                <div key={reason.reasonCode} className="reason-item-cancel">
                  <input
                    type="radio"
                    id={reason.reasonCode}
                    name="cancellationReason"
                    value={reason.reasonCode}
                    onChange={() => setSelectedReason(reason)}
                    checked={selectedReason?.reasonCode === reason.reasonCode}
                  />
                  <label htmlFor={reason.reasonCode}>{reason.reason}</label>
                </div>
              ))}
            </div>
            <div
              className="submit-issue-btn-cancel"
              onClick={handleSubmitButton}
            >
              Submit
            </div>
          </main>
        </>
      )}
    </Fragment>
  );
};

export default CancelOrder;
