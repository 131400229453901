import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Context } from "../contexts/AppContext";

const ProtectedRoutes = ({ children }) => {
  const { cartData } = useContext(Context);
  const { pathname } = useLocation();
  const isLogin = JSON.parse(localStorage.getItem("IsLogin"));
  if (
    isLogin &&
    pathname === "/checkout" &&
    Object.keys(cartData).length === 0
  ) {
    return <Navigate to="/cart" replace />;
  }
  return isLogin ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;
