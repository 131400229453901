import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AddAddressFun,
  requestForUpdateAndDeleteAddress,
} from "../../redux/actions/Action";
import { toast } from "react-toastify";
import { useAppSelector } from "../../hooks/useReduxHooks";
import { FetchingAddressUsingLatLong } from "../../utilities/FetchingAddressUsingLatLong";
import { useLocation, useNavigate } from "react-router-dom";
import searchLens from "../../assets/images/homepage/search-lens.svg";
import currentlocationIcon from "../../assets/icons/Group.png";
import Basket from "../../assets/icons/basket.png";
import Navigate from "../../assets/icons/navigate.png";
import CheckGreen from "../../assets/icons/check_green.png";
import Home1 from "../../assets/icons/home1.png";
import { AppConstants, validateForm } from "../../constants/AppContants";
import "./AddAddress.scss";
import {
  get_google_address_from_place_id,
  get_google_address_predictions,
} from "../../services/services";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Context } from "../../contexts/AppContext";
import GoogleMapComponent from "../../utilities/GoogleMapComponent";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const librariesArray = ["places", "geometry"];

function AddAddress({ usedFor, callFromParent, showDiscardBtn }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUseCurrentLocationClick, setisUseCurrentLocationClick] = useState(false);
   const {setlocationPermissonPopUp,} = useContext(Context);

  const [suggestions, setSuggestions] = useState([]);
  const wrapperRef = useRef(null);
  const [marker, setMarker] = useState({
    lat: 0,
    lng: 0,
  });
  const [query, setQuery] = useState("");
  const initialAddressData = {
    addressOne: "",
    addressTwo: "",
    addressType: "",
    area: "",
    city: "",
    houseNo: "",
    latitude: "",
    longitude: "",
    pincode: "",
    state: "",
    landmark: "",
    isDefault: false,
    addressId: "",
    updateFlag: "N",
    name: "",
    mobile: "",
  };
  const [addressData, setAddressData] = useState(initialAddressData);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const addressFromRoute = location?.state;
  const mapStyles = {
    height: "300px",
    width: "100%",
    borderRadius: "10px",
  };
  const [confirmLocation, setConfirmLocation] = useState(false);

  const addressRequest = useAppSelector((state) => state.GetAddressBuyerSlice);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSuggestions([]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (location?.state) {
      const { latitude, longitude } = addressFromRoute;
      const addressDataForEdit = {
        ...addressFromRoute,
        updateFlag: "Y",
        addressType: addressFromRoute.addressTypeName, // Need to remove field "addressTypeName"
      };
      setAddressData(addressDataForEdit);
      setMarker({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
      setConfirmLocation(true);
    } else {
      getCurrentPosition();
    }
  }, []);

  // 1. On search Text
  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length !== 0) {
      getgooglePredictionsOnSearch(value);
    } else {
      setSuggestions([]);
    }
  };

  // 2. Get Predictions on search
  const getgooglePredictionsOnSearch = async (searchText) => {
    const payload = {
      DATA: {
        input: searchText,
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: AppConstants.APP_VERSION,
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: AppConstants.REQUEST_ID,
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    const res = await get_google_address_predictions(payload);

    if (res.RESP_CODE === 300) {
      // toast.success(res.RESP_MSG);
      const predictionArray = res.DATA?.predictions;
      setSuggestions(predictionArray);
    } else {
      toast.error(res.RESP_MSG);
    }
  };

  // 3. Get Lat Long from Place Id
  // 3.1 Receive place Id
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.description);
    setSuggestions([]);
    getGoogleLatLongFromPlaceId(suggestion.place_id);
  };

  // 3.2 use place Id to get google lat long
  const getGoogleLatLongFromPlaceId = async (place_id) => {
    const payload = {
      DATA: {
        input: place_id,
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: AppConstants.APP_VERSION,
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: AppConstants.REQUEST_ID,
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    const res = await get_google_address_from_place_id(payload);


    if (res.RESP_CODE === 300) {
      // toast.success(res.RESP_MSG);
      const { lat, lng } = res.DATA.result.geometry.location;
      getAddressFromLatLong(Number(lat), Number(lng));
      setMarker({
        lat: lat,
        lng: lng,
      });
    } else {
      toast.error(res.RESP_MSG);
    }
  };

  // 4. Get address from Lat Long
  const getAddressFromLatLong = useCallback(
    async (latitude, longitude) => {
      try {
        const approxAddress = await FetchingAddressUsingLatLong(
          latitude,
          longitude
        );
        setAddressData({
          ...addressData,
          addressTwo: approxAddress?.googleAddress,
          area: approxAddress?.area,
          landmark: approxAddress?.landmark,
          pincode: approxAddress?.pincode,
          city: approxAddress?.city,
          latitude: latitude,
          longitude: longitude,
          state: approxAddress?.state,
        });
        setConfirmLocation(false);
        if(isUseCurrentLocationClick){
        setQuery(approxAddress?.googleAddress);
        }

      } catch (error) {
        console.error("Error fetching address details:", error);
      }
    },
    [addressData]
  );

  // 5. Get current Location lat long
  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setMarker({
          lat: latitude,
          lng: longitude,
        });
        getAddressFromLatLong(latitude, longitude);
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  };

  // 6. Handle Marker drag
  const handleMarkerDragEnd = (markerVal) => {
    // const newLat = event.latLng.lat();
    // const newLng = event.latLng.lng();
    const newLat = markerVal.lat;
    const newLng = markerVal.lng;
    setMarker({ lat: newLat, lng: newLng });
    getAddressFromLatLong(newLat, newLng);
  };

  // 7. receiving address inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setAddressData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const onAddressTypeChange = (type) => {
    googleAnalyticsFunc(
      `AddressTypeChange(${type})_clicked`,
      `AddAddress_AddressTypeChange(${type})_clicked`,
      `AddAddress`
    );
    setAddressData((prevData) => ({
      ...prevData,
      addressType: type,
    }));
  };

  // 8. Save address
  const saveAddress = async (e) => {
    e.preventDefault();
    const errors = validateForm(addressData);
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      setIsLoading(true);
      const res = await dispatch(
        location?.state
          ? requestForUpdateAndDeleteAddress({ ...addressData }) // addressone not required to backend
          : AddAddressFun({ ...addressData })
      );
      if (res?.payload?.RESP_CODE === 300) {
        setIsLoading(false);
        setConfirmLocation(false);
        toast.success(res.payload?.RESP_MSG);
        setAddressData(initialAddressData);
        usedFor === "checkout"
          ? callFromParent("checkout")
          : navigate("/profile/address-list");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding address" || error);
    }
  };
  const handleUseCurrentLocation = () => {
    googleAnalyticsFunc(
      `UseCurrentLocation_clicked`,
      `AddAddress_UseCurrentLocation_clicked`,
      `AddAddress`
    );
    // getCurrentPosition()
    // // navigator.permissions.query({ name: 'geolocation' }).then((result) => {
    // //   if (result.state === 'granted') {
    // //     setSelectLocationPopUp(true);
    // //   } else {
    // //     setlocationPermissonPopUp(true);
    // //   }
    // setQuery("")
    // // setisUseCurrentLocationClick(true)
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        getCurrentPosition();
        setQuery("")
      } else {
        setlocationPermissonPopUp(true);
      }
    }).catch((err) => {
      console.error(err);
      setlocationPermissonPopUp(true);
    });

  };

  return (
    <div className="address-container">
      <div className="add-header">
        <h2>{location?.state ? "Edit Address" : "Add New Address"}</h2>
        {showDiscardBtn ? (
          <div onClick={() => {
            callFromParent();
            googleAnalyticsFunc(
              `Discard_Address_clicked`,
              `AddAddress_Discard_Address_clicked`,
              `AddAddress`
            );
          }} className="add-address">
            Discard Address
          </div>
        ) : (
          <div
            onClick={() => {
              navigate("/profile/address-list");
              googleAnalyticsFunc(
                `Back_To_Addresses_clicked`,
                `AddAddress_Back_To_Addresses_clicked`,
                `AddAddress`
              );
            }}
            className="add-address"
          >
            Back to Addresses
          </div>
        )}
      </div>
      <div className="add-address-content">
        <div className="google-content">
          <div className="google-fields">
            <div className="g-search-wrapper" ref={wrapperRef}>
              <input
                className={`g-search-input ${
                  query.trim() && suggestions.length > 0
                    ? "with-suggestions"
                    : ""
                }`}
                type="text"
                placeholder="Search for your location"
                value={query}
                maxLength={100}
                onChange={handleSearchInputChange}
              />
              <img
                src={searchLens}
                className="g-search-lens"
                alt="search-lens"
              />
              {query.trim() && suggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="suggestion-item"
                      onClick={() => {
                        handleSuggestionClick(suggestion);
                        googleAnalyticsFunc(
                          `suggestions_clicked`,
                          `AddAddress_suggestions_clicked`,
                          `AddAddress`
                        );
                      }}>
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="g-current-location-btn">
              <img
                src={currentlocationIcon}
                className="g-current-location-icon"
                width={15}
                alt=""
              />
              <p
                onClick={handleUseCurrentLocation}
                style={{ cursor: "pointer" }}
              >
                Use your current location
              </p>
            </div>
          </div>

          {/* <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={librariesArray}
          > */}
            <GoogleMapComponent
            mapContainerStyle={mapStyles}
            propLat={marker.lat} 
            propLng={marker.lng} 
            onDragEnd={handleMarkerDragEnd}
            />
            {/* <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={marker}>
              <Marker
                position={marker}
                draggable={true} // Enable dragging
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap> */}
          {/* </LoadScript> */}
          {addressData?.addressTwo && (
          <div className="google-add">
            <img src={Navigate} alt="" className="google-add-icon" />
            <p>{addressData?.addressTwo}</p>
          </div>
          )}

          {!confirmLocation && addressData?.addressTwo && (
            <button
              className="add-save-btn"
              onClick={() => {
                setConfirmLocation(true);
                googleAnalyticsFunc(
                  `ConfirmLocation_clicked`,
                  `AddAddress_ConfirmLocation_clicked`,
                  `AddAddress`
                );
              }}>
              Confirm Location
            </button>
          )}
        </div>

        {confirmLocation && (
          <form action="" className="add-address-form">
            <div className="inputFlex">
              <div className="newDic">
                <label className="inputLable" htmlFor="fullName">
                  Full name
                </label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  className="inputBorder"
                  id="name"
                  maxLength={64}
                  value={addressData?.name}
                  onChange={handleInputChange}
                />
                {errors.name && <p className="error-text">{errors?.name}</p>}
              </div>
              <div className="newDic">
                <label className="inputLable" htmlFor="mobile">
                  Mobile Number
                </label>
                <input
                  type="text"
                  placeholder="Enter mobile number"
                  className="inputBorder"
                  id="mobile"
                  maxLength={10}
                  value={addressData?.mobile}
                  onChange={handleInputChange}
                />
                {errors?.mobile && (
                  <p className="error-text">{errors?.mobile}</p>
                )}
              </div>
            </div>

            <div className="inputFlex">
              <div className="newDic">
                <label className="inputLable" htmlFor="flatNumber">
                  House / Flat / Block Number
                </label>
                <input
                  type="text"
                  placeholder="Enter house / flat / block number"
                  className="inputBorder"
                  id="houseNo"
                  maxLength={35}
                  value={addressData?.houseNo}
                  onChange={handleInputChange}
                />
                {errors.houseNo && (
                  <p className="error-text">{errors.houseNo}</p>
                )}
              </div>
              <div className="newDic">
                <label className="inputLable" htmlFor="address">
                  Complete Address
                </label>
                <input
                  type="text"
                  placeholder="Enter complete address"
                  className="inputBorder"
                  id="addressOne"
                  maxLength={70}
                  value={addressData?.addressOne}
                  onChange={handleInputChange}
                />
                {errors.addressOne && (
                  <p className="error-text">{errors.addressOne}</p>
                )}
              </div>
            </div>

            <div className="inputFlex">
              {/* <div className="newDic">
                        <label className="inputLable" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email Address"
                          className="inputBorder"
                          id="email"
                          value={addressData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <p className="error-text">{errors.email}</p>
                        )}
                      </div> */}
              <div className="newDic">
                <label className="inputLable" htmlFor="addressType">
                  Save as
                </label>
                <div className="iconWithTexts">
                  <div
                    className="iconWithText gap-4"
                    onClick={() => onAddressTypeChange("Home")}
                  >
                    <img
                      src={
                        addressData?.addressType === "Home" ? CheckGreen : Home1
                      }
                      className="saveIcon"
                      alt=""
                    />
                    <span
                      style={
                        addressData?.addressType === "Home"
                          ? { color: "#53b175" }
                          : {}
                      }
                    >
                      Home
                    </span>
                  </div>
                  <div
                    className="iconWithText gap-4"
                    onClick={() => onAddressTypeChange("Office")}
                  >
                    <img
                      src={
                        addressData?.addressType === "Office"
                          ? CheckGreen
                          : Basket
                      }
                      className="saveIcon"
                      alt=""
                    />
                    <span
                      style={
                        addressData?.addressType === "Office"
                          ? { color: "#53b175" }
                          : {}
                      }
                    >
                      Office
                    </span>
                  </div>
                  <div
                    className="iconWithText gap-4"
                    onClick={() => onAddressTypeChange("Others")}
                  >
                    <img
                      src={
                        addressData?.addressType === "Others"
                          ? CheckGreen
                          : Navigate
                      }
                      className="saveIcon"
                      alt=""
                    />
                    <span
                      style={
                        addressData?.addressType === "Others"
                          ? { color: "#53b175" }
                          : {}
                      }
                    >
                      Others
                    </span>
                  </div>
                </div>
                {errors.addressType && (
                  <p className="error-text">{errors.addressType}</p>
                )}
              </div>
            </div>
            <div className="add-adrs-btns">
              <button className="add-save-btn" onClick={(e) => {
                saveAddress(e);
                googleAnalyticsFunc(
                  `${location?.state ? "Update_Address" : "Save_Address"}_clicked`,
                  `AddAddress_${location?.state ? "Update_Address" : "Save_Address"}_clicked`,
                  `AddAddress`
                );
                }}>
                {location?.state ? "Update Address" : "Save Address"}
              </button>
              {showDiscardBtn && (
                <button className="add-save-btn" onClick={() => {
                  callFromParent();
                  googleAnalyticsFunc(
                    `Discard_Btn_clicked`,
                    `AddAddress_Discard_Btn_clicked`,
                    `AddAddress`
                  );
                  }}>
                  Discard
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default AddAddress;
