import React, { useContext, useEffect, useRef, useState } from "react";
import "../StoreItemCard/StoreItemCard.scss";
import itemImageLoader from "../../assets/images/itemImageLoader.png";
import { Context } from "../../contexts/AppContext";
import { AppConstants, generateId } from "../../constants/AppContants";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReplaceProductPopup from "../ReplaceProductPopup/ReplaceProductPopup";
import { getQueryParamURIComponent } from "../../utils/encryption";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";
// import { getItemCount } from "../../utils/commonFunctions";

const BestDealItemCard = ({ product }) => {
  const [imageError, setImageError] = useState(false);
  const {
    reduceCartItems,
    handleAddToCart,
    eachCartItemCount,
    handleCartProductQuantity,
    cartData,
    userLocation,
  } = useContext(Context);
  const [isInCart, setIsInCart] = useState(false);
  const navigate = useNavigate();
  const toastId = useRef(null);

  const [replaceCartModalVisible, setReplaceCartModalVisible] = useState(false);
  const location = useLocation();

  const EventTriggerAndNavigation = (screenName) => {
    navigate(`/${screenName}`);
    googleAnalyticsFunc(
      `${screenName}_Tab_clicked`,
      `BestDealItemCard_${screenName}_Tab_clicked`,
      `BestDealItemCard`
    );
  };

  // 1. check whether item is already in cart
  useEffect(() => {
    if (Object.keys(cartData).length !== 0) {
      const inCart = cartData.items.some(
        (item) => item.itemId === product.itemId
      );
      setIsInCart(inCart);
    } else {
      setIsInCart(false);
    }
  }, [cartData]);

  // 2. Replace modal close and item added to recently emptied cart
  useEffect(() => {
    if (replaceCartModalVisible && Object.keys(cartData).length === 0) {
      addToCartOrIncrement();
      setReplaceCartModalVisible(false);
    }
  }, [cartData]);
  const addToCartOrIncrement = () => {
    googleAnalyticsFunc(
      `addToCartOrIncrement_clicked`,
      `BestDealItemCard_addToCartOrIncrement_clicked`,
      `BestDealItemCard`
    );
    const isLogin = JSON.parse(localStorage.getItem("IsLogin"));

    if (!isLogin || !isLogin.isLogin) {
      sessionStorage.setItem(
        "activityRedirectionInfo",
        JSON.stringify({
          path: location.pathname + location.search,
          activity: "addToCart",
          productId: product.itemId,
          category_id: product.category_id,
        })
      );
      EventTriggerAndNavigation("login");
      return;
    }

    if (Object.keys(cartData).length !== 0) {
      if (product.storDetails.store_id !== cartData.storeId) {
        setReplaceCartModalVisible(true);
        return;
      }
      const item = cartData?.items?.find(
        (cartItem) => cartItem.itemId === product.itemId
      );

      if (item) {
        if (item.count === item.availableQuantity) {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(
              "You have this item in your cart with the maximum available quantity."
            );
          }
          return;
        }

        if (item.count === item.maxQuantity) {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(
              "You have reached maximum item quantity limit for this order."
            );
          }
          return;
        }
      }
    }

    const payload = {
      DATA: {
        userId: AppConstants.mobile,
        screenName: AppConstants.screenName,
        UDID: AppConstants.UDID,
        agentCode: AppConstants.AID,
        DATA: [
          {
            areaCode: "",
            bppId: product.storDetails.bpp_id,
            bppUrl: product.storDetails?.bpp_uri,
            domainName: product.domain,
            categories: [{}],
            city: product.storDetails.city,
            gps: `${userLocation.latitude}, ${userLocation.longitude}`,
            locationId: product.storDetails.store_location_id,
            messageId: Math.floor(new Date().getTime() / 1000.0),
            transactionId: cartData?.transactionId || generateId(),
            store_area_code: product.storDetails.store_area_code,
            store_city: product.storDetails.store_city,
            store_email: product.storDetails.store_email,
            store_end: product.storDetails.store_end,
            storeId: product.storDetails.store_id,
            store_image: product.storDetails.store_image,
            store_name: product.storDetails.store_name,
            store_phone: product.storDetails.store_phone,
            store_start: product.storDetails.store_start,
            store_state: product.storDetails.store_state,
            store_street: product.storDetails.store_street,
            items: [
              {
                availableQuantity: product.available,
                bppId: product.storDetails.bpp_id,
                bppUrl: product.storDetails?.bpp_uri,
                cancellable: product.cancellable,
                contact_details_consumer_care:
                  product.contact_details_consumer_care,
                count: "1",
                itemId: product.itemId,
                item_image: product.item_image,
                itemImagesArray: product.itemImagesArray,
                item_long_desc: product.item_long_desc,
                item_name: product.item_name,
                item_short_desc: product.item_short_desc,
                item_symbol: product.item_symbol,
                categoryName: product.category_id,
                max_price: product.max_price,
                unitized: product.unitized,
                offer: {
                  item_capacity: [],
                },
                maxQuantity: product.max_available,
                ondcreturnwindow: product.ondc_return_window,
                return_window: product.return_window,
                price: product.price,
                returnable: product.returnable,
                parent_item_id: product?.parent_item_id,
              },
            ],
          },
        ],
      },
      HEADER: {
        AGENT_ID: AppConstants.AID,
        AID: AppConstants.AID,
        APP_VERSION: "",
        CUSTOMER_CHARGE: 0,
        ORDER_ID: "",
        PAYABLE_AMOUNT: 0,
        REQUEST_ID: Math.floor(new Date().getTime() / 1000.0),
        TXN_AMOUNT: 0,
        TXN_DT: "",
        UDID: AppConstants.UDID,
        epAgentId: AppConstants.AID,
        epPartnerId: "",
        mobile: AppConstants.mobile,
        orderId: "",
        sellerCode: "",
        totalPrice: 0,
        userId: AppConstants.mobile,
        versioNo: "",
      },
    };

    handleAddToCart(payload);
  };

  const handleImageError = () => {
    setImageError(true);
  };
  const calculateDiscountPercentage = (sellingPrice, maxPrice) => {
    const discountPercentage = Math.round(
      ((Number(maxPrice) - Number(sellingPrice)) / Number(maxPrice)) * 100
    );
    return discountPercentage;
  };
  const handleproductClick = (productDtl) => {
    const storeDetails = {
      city: productDtl.storDetails.city.toLowerCase().replace(/\s+/g, ""),
      domain: productDtl.domain.toLowerCase().replace(/\s+/g, ""),
    };
    const queryParamValue = getQueryParamURIComponent(storeDetails);

    navigate(
      `/product/${productDtl.itemId}/seller/${productDtl.storDetails.store_id}?pd=${queryParamValue}`
    );
    googleAnalyticsFunc(
      `handleproduct_clicked`,
      `BestDealItemCard_handleproduct_Tab_clicked`,
      `BestDealItemCard`
    );
  };

  // 6. handle add to cart when deep linking us used
  useEffect(() => {
    if (Object.keys(product).length !== 0) {
      const activityRedirectionInfo = JSON.parse(
        sessionStorage.getItem("activityRedirectionInfo")
      );
      // shorten this code
      if (activityRedirectionInfo) {
        if (
          activityRedirectionInfo.productId === product.itemId &&
          activityRedirectionInfo.activity === "addToCart"
        ) {
          addToCartOrIncrement();
          sessionStorage.removeItem("activityRedirectionInfo");
        }
      }
    }
  }, [product]);

  // let variationsData = product?.variations;
  return (
    <div
      className="store-item-card"
      // onClick={() => handleProductClick(product.itemId, product.storDetails)}
    >
      <div
        className="product-img-container"
        onClick={() => handleproductClick(product)}
      >
        <img
          src={
            imageError || !product?.item_image
              ? itemImageLoader
              : product?.item_image
          }
          alt={product?.item_name}
          className="deal-img"
          onError={handleImageError}
        />
        {calculateDiscountPercentage(product?.price, product?.max_price) >
          0 && (
          <div className="deal-discount">
            <h2>
              {calculateDiscountPercentage(product?.price, product?.max_price)}{" "}
              %
            </h2>
          </div>
        )}
      </div>
      <div className="product-content">
        <div onClick={() => handleproductClick(product)}>
          <p className="product-name">{product?.item_name}</p>
          {/* <p className="product-desc" data-full-text={product?.item_short_desc}>
            {product?.item_short_desc}
          </p> */}
          <div className="price-container-home">
            <p className="price-home">₹{product?.price}</p>
            {product?.price !== product?.max_price && (
              <p className="max-price-home">₹{product?.max_price}</p>
            )}
          </div>
        </div>
        {product.available > 0 && (
          <div className="addButton">
            {isInCart ? (
              <div className="quantityControls">
                <button
                  className="decrement"
                  onClick={() => {
                    reduceCartItems(false, product.itemId);
                    googleAnalyticsFunc(
                      `reduceCartItems_Tab_clicked`,
                      `BestDealItemCard_reduceCartItems_Tab_clicked`,
                      `BestDealItemCard`
                    );
                  }}
                >
                  -
                </button>
                <span className="quantity">
                {cartData?.items?.find(
                    (item) => item.itemId === product.itemId
                  )?.count || 0}
                </span>
                <button
                  className="increment"
                  onClick={() => addToCartOrIncrement()}
                >
                  +
                </button>
              </div>
            ) : (
              <button
                className="addonlyButton"
                onClick={() => addToCartOrIncrement()}
              >
                Add
              </button>
            )}
          </div>
        )}
      </div>
      <ReplaceProductPopup
        isVisible={replaceCartModalVisible}
        onClose={() => setReplaceCartModalVisible(false)}
        cartStoreName={cartData?.store_name}
        newItemName={product?.item_name}
        newStoreName={product?.storDetails?.store_name}
        onReplace={() => reduceCartItems(true)}
      />
    </div>
  );
};

export default BestDealItemCard;
