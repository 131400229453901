import React from 'react';
import './ReturnPolicy.scss';

const ReturnPolicy = () => {
  return (
    <div className="return-policy-container">
      <h1>Return Policy</h1>

      <section>
        <p>Refund policy on the orders placed on OpenKart will vary from seller to seller.</p>
          <p>For ONDC Network sellers, certain products are not returnable. The information will be indicated on the product page.</p>
          <p>For cancellable orders, customers can cancel the orders anytime before it is shipped. Orders cannot be cancelled after they are shipped.</p>
          <p>For returnable orders, customers can initiate the return from the My Account section. Products must be in original condition for the return to be successful. The seller will arrange a pickup to collect the product from the customer location. The refund will be processed once the seller has received the products.</p>
      </section>

      <section>
        <h2>Return Policy for a Seller on OpenKart</h2>
        <p>Returns Policy dictates the terms in case post-delivery support is required by the customer. It allows the sellers to offer the choice of exchange, replacement, or refund for their products.</p>
        <p>A well-crafted return policy demonstrates a seller's commitment to customer satisfaction and helps build trust with their audience. It can lead to increased sales, improved customer loyalty, and a positive brand reputation, ultimately benefiting the seller's business in multiple ways.</p>
        <p>A Proof of Dispatch (POD) clause is applicable for a seller in the case a request for post-delivery support is raised. A seller must have POD in the form of a picture or a video of the product, both packaged and unpackaged, to verify the state of the items delivered.</p>
        <h3>1. Defect, Damage Product, or Wrong Item Delivered for All Categories</h3>
        <p>
          After a ticket for the defective, damaged, or wrong item is raised, a seller needs to provide proof of dispatch i.e., a picture or a video of the item in its package, unpacked. Similarly, the pictures/videos received from the customers will be shared with the seller. After a brief investigation, if the seller is found to be at fault, the settlements of such orders will be marked disputed. Additionally, a seller is required to either provide a replacement for the delivered product or provide a confirmation for the refund and pickup for the product.</p>
        <p>The logistics charges are to be borne by the seller for both the backward and forward shipments of the item. The Estimated Time of Completion (ETC) for such orders is 7 days.
        </p>

        <h3>2. Unsatisfactory Quality, Incorrect Size or Color for Apparel</h3>
        <p>
          In case an item delivered is of unsatisfactory quality, incorrect size, or color, the seller is required to either provide a replacement for the delivered product or provide a confirmation for the refund and pickup for the product.</p>
        <p> The logistics charges are to be borne by the seller for both the backward and forward shipments of the item. The ETC for such orders is 7 days.
        </p>

        <h3>3. Orders Belonging to the Electronics Category</h3>
        <p>
          In case a ticket is raised for the order belonging to the electronics section, it's the seller's responsibility to raise the same with the manufacturing brand. The settlements of such orders will be marked disputed. Additionally, the seller must ensure that the service request is communicated and provided to the customer in all cases.</p>
        <p> A seller must maintain an open chain of communication with the customer, OpenKart, and the manufacturing brand to ensure that the customer’s issue is resolved. It may include providing a replacement and/or a full refund to the customer for the item delivered.
        </p>

        <h3>4. Unsatisfactory Quality, Incorrect Size, or Color for Automobile Parts and Accessories</h3>
        <p>
          If the automobile part or accessory delivered is of unsatisfactory quality, incorrect size, or color, the seller must provide a replacement for the delivered product or confirm the refund and pickup for the product.</p>
        <p> The logistics charges are to be borne by the seller for both the backward and forward shipments of the item. The ETC for such orders is 7 days.
        </p>
        <p><strong>If the seller fails to cooperate or respond promptly to escalations, their settlements will be suspended.</strong></p>

        <h4>A seller must confirm replacement/refund within 2 Days if:</h4>
        <ul>
          <li>It is determined that the product was damaged before it was delivered to the customer and did not suffer any damage during the transit.</li>
          <li>A seller has not followed the packaging guidelines, and the product is damaged during transit.</li>
          <li>A product is not different from the description provided on the OpenKart product page.</li>
          <li>If a product is expired or is unfit for its intended use.</li>
          <li>If the product is missing essential parts or accessories, the seller should provide the missing items or offer a replacement or refund.</li>
        </ul>

        <p>
          OpenKart ensures that the delivered product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card, and all the accessories therein) back to the seller.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          For any return-related queries or assistance, feel free to reach out to our customer support team via email at contact@openkart.co.in.
        </p>
      </section>
    </div>
  );
};

export default ReturnPolicy;
