import "./Header.scss";
import logo from "../../assets/images/loantaplogo.svg";
import easypaylogo from "../../assets/images/oklogo_full.png";
import React, { useEffect, useContext, useState, Fragment } from "react";
import { Context } from "../../contexts/AppContext";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import SelectElement from "./../SelectElement/SelectElement";
import home from "../../assets/icons/home.svg";
import explore from "../../assets/icons/explore.svg";
import menuCart from "../../assets/icons/menu-cart.svg";
import favourite from "../../assets/icons/favourite.svg";
import user from "../../assets/icons/user.svg";
import { getLocalStorageData } from "../../utilities/Storage";
import LocationInstruction from "../LocationInstruction/LocationInstruction";
import SelectLocation from "../SelectLocation/SelectLocation";
import SearchBar from "../SearchBar/SearchBar";
import orderHisIcon from "../../assets/icons/orderHisIcon.png";
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const Header = () => {
  const {
    userLocation,
    cartCount,
    categoriesInfo,
    selectedCategory,
    setSelectedCategory,
    getUrlFriendlyCategoryName,
    selectLocationPopUp,
    setSelectLocationPopUp,
    locationPermissonPopUp,
    setlocationPermissonPopUp,
    setSearchCategory,
    categoryActiveTab,
    setcategoryActiveTab,
    isSearchBarActive, 
    setIsSearchBarActive
  } = useContext(Context);
  const [categorySearchOptions, setCategorySearchOptions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState("Search");

  const handleLocationClick = () => {
    googleAnalyticsFunc(
      `handleLocation_Tab_clicked`,
      `Header_handleLocation_Tab_clicked`,
      `Header`
    );
    navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if (result.state === "granted") {
          setSelectLocationPopUp(true);
        } else {
          setlocationPermissonPopUp(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setlocationPermissonPopUp(true);
      });
  };

  // 1. Get category options for category strip
  useEffect(() => {
    const list = categoriesInfo?.map((categoryData, index) => ({
      id: index,
      key: categoryData.category,
      name: categoryData.categoryDisplayName,
      // image: categoryData.categoryImage
    }));
    setCategorySearchOptions(list);
  }, [categoriesInfo]);

  useEffect(() => {
    // setSelectedCategory("");
  }, [location]);

  const setOneValue = () => {
    sessionStorage.setItem("name", "Ajinkya");
  };

  const isLoggedIn = JSON.parse(getLocalStorageData("IsLogin"))?.isLogin;

  const userName =
    JSON.parse(getLocalStorageData("userInfo"))?.userInfo?.NAME || "";

  const userInitials = userName
    .split(" ")
    .map((name) => name.charAt(0).toUpperCase())
    .join("")
    .slice(0, 2);

  const initials =
    userInitials.length === 1 && userName.split(" ").length === 1
      ? userName.charAt(0).toUpperCase() + userName.charAt(1).toUpperCase()
      : userInitials;

  const navigateToCategoryList = (categoryObj) => {
    setSelectedCategory(categoryObj.name);
    setSearchCategory(categoryObj.name);
    // setcategoryActiveTab(name);
    const friendlyCategoryName = getUrlFriendlyCategoryName(categoryObj.name);
    navigate(`/category/${friendlyCategoryName}?cat=${encodeURIComponent(JSON.stringify(categoryObj))}`);
  };

  return (
    <div className="sticky-header">
      <header className="visit-showroom-strip">
        <div style={{ textAlign: "center" }}>
          Visit our showroom in 1234 Street Adress City Address, 1234&nbsp;
          <a className="contact-us" href="">
            Contact Us
            <div className="contact-underline"></div>
          </a>
        </div>
      </header>
      <section className="header-menus-wrapper">
        <div className="header-menus header-menus-desktop">
          <div className="nav-left">
            <div className="ham-button">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className="logo-container">
              <NavLink to={`/`} className="logo-link">
                <img src={easypaylogo} alt="buyer_logo" className="logo" />
              </NavLink>
            </div>

            <div className="location-wrapper" onClick={handleLocationClick}>
              <img className="group-icon" alt="" src="/group.svg" />
              <div className="search-location-dropdown no-border">
                <div className="location-name">
                  {userLocation.city ? userLocation.city : "My Location"}
                </div>
              </div>
            </div>
            {/* {locationPermissonPopUp && (
        <LocationInstruction
        open={locationPermissonPopUp}
        onAccept={handleAcceptForLocationInstuction}
        title="Location Permission Required"
        content={
          <Fragment>
            <p style={{marginBottom:"10px"}}>
              To provide personalized content and enhance your experience, we
              need access to your location. Please follow these steps to enable
              location permissions:
            </p>
            <ol style={{ margin: 0, paddingLeft: "1rem" }}>
              <li>
                Click the icon next to the website address in your browser.
              </li>
              <li>Select "Site settings" or "Permissions."</li>
              <li>Find "Location" and set it to "Allow."</li>
              <li>Reload the page if needed.</li>
            </ol>
          </Fragment>
        }
      />
      )} */}
            {/* {selectLocationPopUp && (
        <SelectLocation
          open={selectLocationPopUp}
          onClose={handleClosePopUp}
        />
      )} */}
          </div>
          <SearchBar />
          <div className="nav-right">
            <button className="sell-on-ondc-button">
              <a href="https://business.easypay.in/" target="_blank">
                Sell on ONDC
              </a>
            </button>
            <img className="ondc-logo" alt="" src="/ondcLogo.png" />

            <div
              className="cart-wrapper"
              onClick={() => {
                googleAnalyticsFunc(
                  `${isLoggedIn ? "cart" : "login"}_Tab_clicked`,
                  `Header_${isLoggedIn ? "cart" : "login"}_Tab_clicked`,
                  `Header`
                );
                isLoggedIn ? navigate("/cart") : navigate("/login");
              }}
            >
              <img className="cart-icon" alt="" src="/jamshoppingcart.svg" />
              <span className="item-count">{cartCount}</span>
            </div>

            <div className="profile-wrapper">
              {isLoggedIn ? (
                <div
                  className="profile-initials"
                  onClick={() => {
                    googleAnalyticsFunc(
                      `profile_initials_Tab_clicked`,
                      `Header_profile_initials_Tab_clicked`,
                      `Header`
                    );
                    navigate("/profile/dashboard")
                  }}
                >
                  {initials}
                </div>
              ) : (
                <img
                  src="/login_icon.jpg"
                  alt="login icon"
                  className="profile-pic"
                  onClick={() => {
                    googleAnalyticsFunc(
                      `profile_pic_Tab_clicked`,
                      `Header_profile_pic_Tab_clicked`,
                      `Header`
                    );
                    navigate("/login")
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className="header-menus header-menus-mobile">
          <div className="logo-container">
            <NavLink to="/">
              <img src={logo} alt="buyer_logo" className="logo" />
            </NavLink>
          </div>
          <div className="location-wrapper" onClick={handleLocationClick}>
            <img className="group-icon" alt="" src="/group.svg" />
            <div className="search-location-dropdown no-border">
              <div className="location-name">
                {userLocation.city ? userLocation.city : "My Location"}
              </div>
            </div>
          </div>
          <div className="ham-button">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div> */}
      </section>
      <header className="category-strip">
        <div className="ham-menu">
          <div className="ham-button">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>Category Menu</div>
        </div>

        <ul className="category-list">
          <li>
            <Link
              className={`tab ${selectedCategory === "All" ? "active" : ""}`}
              to="/"
              // onClick={() => {
              //   setSearchCategory("All");
              //   // setSelectedCategory("");
              // }}
            >
              Home
            </Link>
          </li>
          {categorySearchOptions?.map((category) => (
            <li key={category.id}>
              <span
                className={`tab ${
                  selectedCategory === category.name ? "active" : ""
                }`}
                onClick={() => {
                  googleAnalyticsFunc(
                    `navigateToCategoryList_Tab_clicked`,
                    `Header_navigateToCategoryList(${category})_Tab_clicked`,
                    `Header`
                  );
                  navigateToCategoryList(category);
                }}
              >
                {category.name}
              </span>
            </li>
          ))}
        </ul>
      </header>
      <div className="mobile-bottom-menu">
        <NavLink className="menu" to={`/`}>
          <img src={home} alt="" className="menu-icon" />
          <p className="name">Home</p>
        </NavLink>
        <NavLink className="menu" to={`/`} onClick={() => {
            googleAnalyticsFunc(
              `menu_Tab_clicked`,
              `Header_menu_Tab_clicked`,
              `Header`
            );
          setIsSearchBarActive(true);
          }}>
          <img src={explore} alt="" className="menu-icon" />
          <p className="name">Explore</p>
        </NavLink>
        <NavLink
          className="menu cart-sphere"
          to={isLoggedIn ? `/cart` : `/login`}
        >
          <img src={menuCart} alt="" className="menu-icon" />
          <p className="name">Cart</p>
          <span className="item-count">{cartCount}</span>
        </NavLink>
        <NavLink className="menu" to={`/profile/order-history`}>
          <img src={orderHisIcon} alt="" className="menu-icon" />
          <p className="name">History</p>
        </NavLink>
        <NavLink
          className="menu"
          to={isLoggedIn ? `/profile/dashboard` : `/login`}
        >
          <img src={user} alt="" className="menu-icon" />
          <p className="name">Account</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
