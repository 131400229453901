import React from 'react';
import './ShippingAndDeliveryPolicies.scss';

const ShippingAndDeliveryPolicies = () => {
  return (
    <div className="shipping-policies-container">
      <h1>Shipping and Delivery Policies</h1>

      <section>
        <h2>What are the delivery charges?</h2>
        <p>Delivery charges vary depending on the seller.</p>
          <p>Sellers may incur higher shipping costs on low-value items. In such cases, a nominal delivery charge may be applied to offset these logistics costs. Please check your order summary to understand the delivery charges for individual products.
        </p>
      </section>

      <section>
        <h2>Why does the delivery date not correspond to the delivery timeline of X-Y business days?</h2>
        <p>
          Occasionally, a holiday may fall between the date you place your order and the expected delivery date, as shown on the product page. In such cases, the estimated delivery date may be extended by one day. Some courier partners and sellers may not operate on Sundays, which is also factored into the delivery dates.
        </p>
      </section>

      <section>
        <h2>What is the estimated delivery time?</h2>
        <p>
          Sellers generally procure and ship the items within the time specified while placing an order. Business days exclude public holidays and Sundays.
        </p>
        <p>Estimated delivery time depends on several factors:</p>
        <ul className='factorsList'>
          <li>The seller offering the product</li>
          <li>Product availability with the seller</li>
          <li>The destination to which the order is being shipped and the seller's location</li>
        </ul>
      </section>

      <section>
        <h2>Are there any hidden costs (sales tax, etc.) on items sold by sellers on OpenKart?</h2>
        <p>
          There are no hidden charges when you make a purchase on OpenKart. List prices are final and all-inclusive. The price displayed on the product page is exactly what you pay at checkout.</p>
        <p>Delivery & Handling/Packaging charges are not hidden charges and may be added separately, depending on the seller's shipping policy.
        </p>
      </section>

      <section>
        <h2>Why does the estimated delivery time vary for each seller?</h2>
        <p>
          The estimated delivery time may vary for different sellers due to factors such as product availability, the geographic location of the seller, the shipping destination, and the courier partner's time-to-deliver to your location.
        </p>
        <p>
          Please enter your default postal code on the product page to see more accurate delivery times for your area.
        </p>
      </section>

      <section>
        <h2>The seller does not/cannot ship to my area. Why?</h2>
        <p>To check if a product can be delivered to your location, please enter your postal code on the product page.</p>
        <p>Whether your location can be serviced depends on:</p>
        <ul className='factorsList'>
          <li>The seller’s ability to ship to your location</li>
          <li>Any legal restrictions on shipping particular products to your location</li>
          <li>The availability of reliable courier partners in your area</li>
        </ul>
        <p>Some sellers may choose not to ship to certain locations at their discretion.</p>
      </section>

      <section>
        <h2>Why is the Cash on Delivery (CoD) option not offered on OpenKart?</h2>
        <p>
          The availability of CoD depends on whether logistics partners can process cash payments. Currently, since the logistics partners do not support this option, CoD is not available on OpenKart.
        </p>
      </section>

      <section>
        <h2>I need to return an item. How do I arrange for a pick-up?</h2>
        <p>
          Returns are easy on OpenKart. Contact our support team to initiate a return, and you will receive instructions on the process.
        </p>
        <p>
          Where possible, logistics partners will facilitate the pick-up of the item. If a pick-up cannot be arranged through logistics partners, you may return the item using a third-party courier service. Return fees are typically borne by the seller.
        </p>
      </section>

      <section>
        <h2>I did not receive my order, but I got a delivery confirmation via SMS/Email.</h2>
        <p>
          If you did not receive your order but received a delivery confirmation, please report the issue within 24 Hours from the date of the confirmation for the seller to investigate.
        </p>
      </section>

      <section>
        <h2>What do the different stock status tags mean?</h2>
        <ul className='factorsList'>
          <li>
            <strong>In Stock:</strong> For items listed as "In Stock", Sellers will mention the delivery time based on your location pin code (usually 2-3 business days, 4-5 business days, or 4-6 business days in areas where standard courier service is available). For other areas, orders will be sent by Registered Parcel or Speed Post through the Indian Postal Service which may take 1-2 weeks depending on the location.
          </li>
          <li>
            <strong>Out of Stock:</strong> The item is currently unavailable for purchase. It will become available once the seller updates the stock.
          </li>
        </ul>
      </section>

      <section>
        <h2>Does OpenKart deliver internationally?</h2>
        <p>Currently, OpenKart does not offer international delivery.</p>
      </section>
    </div>
  );
};

export default ShippingAndDeliveryPolicies;
