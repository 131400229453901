import axios from "axios";

const strapiBaseURL = process.env.REACT_APP_STRIPE_APP_URL

const params = {
  headers: {
    Authorization: "bearer " + process.env.REACT_APP_STRIPE_APP_KEY
  },
};

// 1
export const fetchDataFromStrapi = async (url) => {
  try {
    const response = await axios.get(
      strapiBaseURL + url,
      params
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Service is temporarily unavailable. Please try again in a few moments.")
    }
  } catch (error) {
    throw error;
  }

};

// export const postDataToApi = async (url, postData) => {
//   try {
//     const { data } = await axios.post(
//       process.env.REACT_APP_STRIPE_APP_DEV_URL + url,
//       postData,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: "bearer " + process.env.REACT_APP_STRIPE_DEV_APP_KEY,
//         }
//       }
//     );
//     return data;
//   } catch (err) {
//     return err;
//   }
// };

// export const makePaymentRequest = axios.create({
//   strapiBaseURL: process.env.REACT_APP_STRIPE_APP_DEV_URL,
//   headers: {
//     Authorization: "bearer " + process.env.REACT_APP_STRIPE_DEV_APP_KEY,
//   },
// });
