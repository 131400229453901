export const setLocalStorageData = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.log(error);
    }
};

export const getLocalStorageData = (key) => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.log(error);
    }
};
export const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  export const loadFromLocalStorage = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };