import { createSlice } from "@reduxjs/toolkit";
import { GetOrderHistory } from "../actions/Action";

const initialState = {
  RESPONSE: false,
  RESP_MSG: "",
  AUTH_RESP: false,
  data: {},
  error: "false",
};

const GetOrderHistorySlice = createSlice({
  name: "GetOrderHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOrderHistory.pending, (state) => {
        state.RESPONSE = true;
      })
      .addCase(GetOrderHistory.fulfilled, (state, { payload }) => {
        state.RESPONSE = false;
        state.RESP_MSG = payload.RESP_MSG;
        state.data = payload.DATA;
      })
      .addCase(GetOrderHistory.rejected, (state, { payload }) => {
        state.RESPONSE = false;
        state.RESP_MSG = payload.RESP_MSG;
      });
  },
});

export default GetOrderHistorySlice.reducer;
