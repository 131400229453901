import React from 'react'
import './SearchSuggestionItem.scss';

function SearchSuggestionItem({item}) {
  return (
    <div className="search-sugg-item">
            <img src={item.item_image} alt="item-image" className="item-img" />
            <div className="item-info">
                <div className="item-name-row">
                    <h6 className="item-name">{item.item_name}</h6>
                    <div className="item-distance"></div>
                </div>
                <div className="item-profile">Product</div>
            </div>
        </div>
  )
}

export default SearchSuggestionItem