import Modal from "@mui/material/Modal";
import React from 'react'
import { googleAnalyticsFunc } from "../../utils/commonFunctions";

const ReplaceProductPopup = ({
    isVisible,
    onClose,
    cartStoreName,
    newItemName,
    newStoreName,
    onReplace
  }) => {

    const ReplaceProductPopupEvents = (title) => {
      title == 'Replace' ? onReplace() : onClose();
      googleAnalyticsFunc(
        `${title}_Btn_clicked`,
        `ReplaceProductPopup_${title}_Btn_clicked`,
        `ReplaceProductPopup`
      );
    }

    return (
      <Modal
        open={isVisible}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="popup-box">
          <img
            src={"/path/to/close-icon.svg"} // replace with appropriate path or prop
            alt="close"
            className="close-cross"
            onClick={() => ReplaceProductPopupEvents('closeCross')}
          />
          <h3 className="popup-title">Replace cart item?</h3>
          <p className="popup-content">
            Your cart contains products from <b>{cartStoreName}</b>. Do you want to discard the selection and add
            {newItemName && newStoreName && (
              <>
                {' '}
                <b>{newItemName}</b> from <b>{newStoreName}</b>?
              </>
            )}
          </p>
  
          <div className="popup-button-group">
            <button
              className="popup-button popup-reject"
              onClick={() => ReplaceProductPopupEvents('No')}
            >
              No
            </button>
            <button
              className="popup-button popup-accept"
              onClick={() => ReplaceProductPopupEvents('Replace')}
            >
              Replace
            </button>
          </div>
        </div>
      </Modal>
    );
  };

export default ReplaceProductPopup
