import React, { useState } from "react";
import "./IssueProductCard.scss";
import itemImageLoader from "../../assets/images/itemImageLoader.png";


const IssueProductCard = ({ orderDetails, handleCheckboxChange, selectedItems }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const isChecked = (item) => {
    return selectedItems.some((selectedItem) => selectedItem.itemId === item.itemId);
  };

  return (
    <section className="issue-product-details">
    {orderDetails?.itemArray?.map((item) => (
      <div className="issue-product-row" key={item.itemId}>
        {/* Checkbox */}
        <input
          type="checkbox"
          id={`product-${item.itemId}`}
          checked={isChecked(item)}
          onChange={() => handleCheckboxChange(item)}
        />
        
        {/* Product Image */}
        <label htmlFor={`product-${item.itemId}`}>
        <img
      src={imageError || !item.itemImage ? itemImageLoader : item.itemImage}
      alt={item.itemName}
      className="issue-product-image"
      onError={handleImageError} // Trigger handleImageError when image fails to load
    />
        </label>
        
        {/* Product Details */}
        <div className="issue-product-details">
          <span className="issue-product-name">{item.itemName}</span>
          <span className="issue-product-quantity">Qty: {item.itemQuantity}</span>
        </div>
        
        {/* Product Price */}
        <span className="issue-product-price">₹{item.itemPrice}</span>
      </div>
    ))}
  </section>
  
  );
};

export default IssueProductCard;
