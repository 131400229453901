import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AppConstants,
  generateId,
  generateIdWithoutHyphens,
  getUserInfoAndSetConstants,
} from "../../constants/AppContants";
import {
  check_payment_status,
  get_address_buyer,
  get_cancellation_reasons,
  get_issue_category,
  get_issue_list,
  get_order_history,
  request_for_update_and_deleteaddress,
  save_details,
  send_otp,
  send_request_for_cancel,
  send_request_for_confirm,
  send_request_for_init,
  send_request_for_select,
  send_request_for_status,
  send_Request_For_Submit_Issue,
  add_address,
  verify_otp,
  provide_feedback_api,
  search_store_by_provider_id,
} from "../../services/services";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../utilities/Storage";
import { useContext } from "react";
import { Context } from "../../contexts/AppContext";

export const sendOtp = createAsyncThunk("sendOtp", async (value) => {
  try {
    let PAYLOAD = {
      HEADER: {
        mobile: value,
        REQUEST_ID: AppConstants.REQUEST_ID,
        UDID: AppConstants.UDID,
      },
    };
    const data = await send_otp(PAYLOAD);
    return data;
  } catch (error) {
    return error;
  }
});

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async ({ code, mobileNumber }) => {
    try {
      let lat = "";
      let long = "";
      if (await getLocalStorageData("location")) {
        const { latitude, longitude } = await getLocalStorageData("location");
        lat = latitude;
        long = longitude;
      }
      let fcm_token = await getLocalStorageData("FCM");
      if (fcm_token === null || fcm_token === "null") {
        // fcm_token = await messaging().getToken();
        setLocalStorageData("FCM", fcm_token);
      }
      let PAYLOAD = {
        HEADER: {
          mobile: mobileNumber,
          REQUEST_ID: AppConstants.REQUEST_ID,
          UDID: AppConstants.UDID,
          deviceDtl: AppConstants.devicedtl,
          // OS: Platform.OS,
          IP: AppConstants.IP_ADDRESS,
          ...(lat ? { latitude: lat } : {}),
          ...(long ? { longitude: long } : {}),
          imei: "2c432ce827a94f9b",
          fcmToken: fcm_token,
        },
        DATA: {
          otp: code,
        },
      };
      const data = await verify_otp(PAYLOAD);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const saveDetails = createAsyncThunk(
  "saveDetails",
  async ({ mobile, fullName, email }) => {
    try {
      let PAYLOAD = {
        HEADER: {
          mobile,
          REQUEST_ID: AppConstants.REQUEST_ID,
          UDID: AppConstants.UDID,
        },
        DATA: {
          email,
          fullName,
        },
      };
      const data = await save_details(PAYLOAD);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const GetAddressBuyer = createAsyncThunk(
  "GetAddressBuyer",
  async (value) => {
    try {
      let det = await getUserInfoAndSetConstants();
      
      let PAYLOAD = {
        DATA: {
          screenName: AppConstants.screenName,
          email: AppConstants.EMAIL,
          fullName: AppConstants.NAME,
          isEasyPayAgent: "true",
          isOtpVerified: "true",
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          totalPrice: 0.0,
          userId: AppConstants.mobile,
        },
      };

      const data = await get_address_buyer(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const GetOrderHistory = createAsyncThunk(
  "GetOrderHistory",
  async (value) => {
    let det = getUserInfoAndSetConstants();
    try {
      let PAYLOAD = {
        DATA: {
          screenName: AppConstants.screenName,
          PAGE_NO: value,
        },
        HEADER: {
          AGENT_ID: "",
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };

      const data = await get_order_history(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const AddAddressFun = createAsyncThunk(
  "AddAddressFun",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: value,
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0,
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0,
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          totalPrice: 0,
          userId: AppConstants.mobile,
        },
      };
      const data = await add_address(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const requestForUpdateAndDeleteAddress = createAsyncThunk(
  'requestForUpdateAndDeleteAddress',
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: value,
        HEADER: {
          AGENT_ID: '',
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: '',
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: '',
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: '',
          mobile: AppConstants.mobile,
          sellerCode: '',
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: '',
        },
      };
      const data = await request_for_update_and_deleteaddress(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  },
);

export const sendRequestForStatus = createAsyncThunk(
  "sendRequestForStatus",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          bppId: value?.bppId,
          bppUrl: value?.bppUrl,
          transactionId: value?.transactionId,
          messageId: generateId(),
          city: value?.city,
          order_id: value?.orderId,
        },
        HEADER: {
          AGENT_ID: "",
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      const data = await send_request_for_status(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const sendRequestForCancel = createAsyncThunk(
  "sendRequestForCancel",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: value,
        HEADER: {
          AGENT_ID: "",
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      const data = await send_request_for_cancel(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const sendRequestForSelect = createAsyncThunk(
  "sendRequestForSelect",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          itemArray: [
            {
              transactionId: generateId(), //value?.transactionId,
              messageId: generateId(),
              bppId: value?.bppId,
              bppUrl: value?.bppUrl,
              city: value?.city,
              storeId: value?.storeId,
              locationId: value?.locationId,
              gps: value?.gps,
              areaCode: value?.store_area_code,
              items: value?.items,
              domain: value?.domain,
            },
          ],
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      const data = await send_request_for_select(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const sendRequestForInit = createAsyncThunk(
  "sendRequestForInit",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          itemArray: [
            {
              transactionId: value?.transactionId,
              messageId: generateId(),
              bppId: value?.bppId,
              bppUrl: value?.bppUrl,
              city: value?.city,
              storeId: value?.storeId,
              provider_id: value?.providerId,
              location_id: value?.locationId,
              gps: value?.gps,
              customer_name: value?.customer_name, //AppConstants.NAME,
              customer_email: AppConstants.EMAIL,
              customer_phone: value?.customer_phone,
              fulfillment_id: value?.fulfillment_id,
              fulfillment_type: value?.fulfillment_type,
              fulfillment_tracking: value?.fulfillment_tracking,
              fulfillment_gps: value?.gps,
              items: value?.items,
              customer_billing_address: value?.customerAddress,
              customer_shipping_address: value?.customerAddress,
              domain: value?.domain,
            },
          ],
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: "",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: "",
        },
      };
      const data = await send_request_for_init(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const sendRequestForConfirm = createAsyncThunk(
  "sendRequestForConfirm",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: value,
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          // AGENT_ID: "9033891671",
          // AID: "9033891671",
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          OP: "ONDC",
          ORDER_ID: value?.ORDER_ID,
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          ST: "ONDC",
          TXN_AMOUNT: 4.12,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: AppConstants.AID,
          // epAgentId: AppConstants.AID,
          // epPartnerId: "9033891671",
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: "",
          totalPrice: 4.12,
          userId: AppConstants.mobile,
          versioNo: AppConstants.APP_VERSION,
          surl: value.surl, 
          furl: value.furl 
        },
      };
      const data = await send_request_for_confirm(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const checkPaymentStatus = createAsyncThunk(
  "payu_status_check",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          orderRefId: value,
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: AppConstants?.REQUEST_ID,
          TXN_AMOUNT: 0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: "",
          epPartnerId: "",
          mobile: AppConstants.mobile,
          sellerCode: "",
          totalPrice: 0,
          userId: AppConstants.mobile,
          versioNo: AppConstants.APP_VERSION,
        },
        // DATA: {
        //   orderRefId: "03e2b8abb2064faab43b0c7aba67a4ed",
        // },
        // HEADER: {
        //   AGENT_ID: "7276613070",
        //   APP_VERSION: "3.0.0.68",
        //   CUSTOMER_CHARGE: 0,
        //   ORDER_ID: "",
        //   PAYABLE_AMOUNT: 0,
        //   REQUEST_ID: 1730810312,
        //   TXN_AMOUNT: 0,
        //   TXN_DT: "",
        //   UDID: "3cc3398e38945fdd",
        //   epAgentId: "",
        //   epPartnerId: "",
        //   mobile: "7276613070",
        //   sellerCode: "",
        //   totalPrice: 0,
        //   userId: "7276613070",
        //   versioNo: "",
        // },
      };
      const data = await check_payment_status(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getIssueCategory = createAsyncThunk(
  'issues/getIssueCategory',
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {  
          screenName: "test",
          userId: ""
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: '3.0.0.68',
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: '',
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: 1715674423225,
          TXN_AMOUNT: 0.0,
          TXN_DT: '',
          UDID: 'e52af7df0c76a2c2',
          epAgentId: '',
          epPartnerId: '',
          mobile: '8087187104',
          sellerCode: '',
          totalPrice: 0.0,
          userId: '915575',
          versioNo: '',
          orderId: "b7a0352e10524d25b0aaf8fa0bab5d6c",
        },
      };
      const data= await get_issue_category(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  },
);

// export const SendRequestForSubmitIssue = createAsyncThunk(
//   "issue/sendRequestForIssueV2",
 
//   async (value) => {
//     try {
//       let PAYLOAD = value;
//       const data = await send_Request_For_Submit_Issue(PAYLOAD);
//       return data;
//     } catch (error) {}
//   }
// );

export const submitIssueDetails = async (issueDetails, issueImg) => {
  const formData = new FormData();

  // Append JSON data for request
  formData.append('request', JSON.stringify(issueDetails));

  // Append images if available
  if (issueImg) {
    formData.append('file', issueImg);
  }

  try {
    const response = await send_Request_For_Submit_Issue(formData);
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const getIssueList = createAsyncThunk(
  'issues/getIssueList',
  async () => {
    try {
      let PAYLOAD = {
        DATA: {  
          screenName: "test",
          userId: ""
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          AID: AppConstants.AID,
          APP_VERSION: '3.0.0.68',
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: '',
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: 1715674423225,
          TXN_AMOUNT: 0.0,
          TXN_DT: '',
          UDID: 'e52af7df0c76a2c2',
          epAgentId: '',
          epPartnerId: '',
          mobile: '8087187104',
          sellerCode: '',
          totalPrice: 0.0,
          userId: '915575',
          versioNo: '',
        },
      };
      const data= await get_issue_list(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  },
);

export const getCancellationReasons = createAsyncThunk(
  'getCancellationReasons',
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          screenName: AppConstants.screenName,
          CATEGORY_NAME: value,
        },
        HEADER: {
          AGENT_ID: '',
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: '',
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: '',
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: '',
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: '',
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: '',
        },
      };
      const data = await get_cancellation_reasons(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  },
);

export const provideFeedbackApi = createAsyncThunk(
  'provideFeedbackApi',
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          issue_id: value.id,
          rating: value.rating,
        },
        HEADER: {
          AGENT_ID: '',
          AID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0.0,
          ORDER_ID: '',
          PAYABLE_AMOUNT: 0.0,
          REQUEST_ID: AppConstants.REQUEST_ID,
          TXN_AMOUNT: 0.0,
          TXN_DT: '',
          UDID: AppConstants.UDID,
          epAgentId: AppConstants.AID,
          epPartnerId: '',
          mobile: AppConstants.mobile,
          orderId: generateIdWithoutHyphens(),
          sellerCode: '',
          totalPrice: 0.0,
          userId: AppConstants.mobile,
          versioNo: '',
        },
      };
      const data = await provide_feedback_api(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  },
);

export const searchStoreByProviderId = createAsyncThunk(
  "searchStoreByProviderId",
  async (value) => {
    try {
      let PAYLOAD = {
        DATA: {
          city: value.city,
          bppId: value.bppId,
          providerId: value.providerId,
          domainName: value.domainName
        },
        HEADER: {
          AGENT_ID: AppConstants.AID,
          APP_VERSION: AppConstants.APP_VERSION,
          CUSTOMER_CHARGE: 0,
          ORDER_ID: "",
          PAYABLE_AMOUNT: 0,
          REQUEST_ID: AppConstants?.REQUEST_ID,
          TXN_AMOUNT: 0,
          TXN_DT: "",
          UDID: AppConstants.UDID,
          epAgentId: "",
          epPartnerId: "",
          mobile: AppConstants.mobile,
          sellerCode: "",
          totalPrice: 0,
          userId: AppConstants.mobile,
          versioNo: AppConstants.APP_VERSION,
        },
       
      };

      const data = await search_store_by_provider_id(PAYLOAD);
      if (data.RESP_CODE === 300) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      return error;
    }
  }
);

