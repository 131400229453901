import React from 'react'
import './CategoryCard.scss'
import { googleAnalyticsFunc } from '../../utils/commonFunctions';

const CategoryCard = ({cardData, onCardClick }) => {
    const EventTriggerAndNavigation = () => {
        googleAnalyticsFunc(
          `category_item_Tab_clicked`,
          `CategoryCard_category_item_Tab_clicked`,
          `CategoryCard`
        );
        onCardClick();
      }
    return (
        <div
            key={cardData.id}
            className="category-item"
            style={{ cursor: "pointer" }}
            onClick={EventTriggerAndNavigation}
        >
            <img
                className="category-icon"
                loading="lazy"
                alt={cardData.categoryDisplayName
                }
                src={cardData.categoryImage
                }
            />
            <div className="shop-by-category-labels">
                <p>{cardData.categoryDisplayName}</p>
            </div>
        </div>
    )
}

export default CategoryCard