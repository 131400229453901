// DealsProductList
import React, {
    useState,
    useEffect,
    Fragment,
    useContext,
    useRef,
  } from "react";
  import { useLocation } from "react-router-dom";
  import "./DealsProductList.scss";
  import CardSlider from "../../components/CardSlider/CardSlider";
  import ProductCard from "../../components/ProductCard/ProductCard";
  import Spinner, { SmallLoader } from "../../components/Loader/Spinner";
  import { Context } from "../../contexts/AppContext";
  import { useNavigate } from "react-router-dom";
  import ErrorMessage from "../../components/ErrorScreen/ErrorScreen";
  import SelectElement from "../../components/SelectElement/SelectElement";
  import IconsContainer from "../../components/IconsContainer/IconsContainer";
  import searchLens from "../../assets/images/homepage/search-lens.svg";
  import locationIcon from "../../assets/icons/locationIcon.png";
  import currentlocationIcon from "../../assets/icons/Group.png";
  import locationIcon2 from "../../assets/icons/navigate.png";
// import { generateFormattedItemArray } from "../../utils/commonFunctions";
  // import Filter from "../CategoryProductList/Filter/Filter";
  const DealsProductList = () => {
    const location = useLocation();
    const [loading, setIsLoading] = useState(false);
    const { bestDealProducts } = useContext(Context);
    const [dealProdutList, setDealProdutList] = useState([]);
    const [dealProductFiltered, setDealProductFiltered] = useState([]);
    const [renderContent, setRenderContent] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
      const loadData = async () => {
        if (bestDealProducts && bestDealProducts.store) {
          // let dealData = {
          //   ...bestDealProducts,
          //   items: bestDealProducts.store
          // }
          // let formattedItems = generateFormattedItemArray(dealData)
          setDealProdutList(bestDealProducts.store);
          setDealProductFiltered(bestDealProducts.store);
          setIsLoading(false); 
          setRenderContent(true); 
        } else {
          setIsLoading(true); 
        }
      };
      loadData();
    }, [bestDealProducts]); 
    const handleSearchChange = (e) => {
      const value = e.target.value;
      setSearchText(value);
      if (value.length >= 1) {
        const filtered = dealProdutList.filter((product) =>
          product.item_name.toLowerCase().includes(value.toLowerCase())
        );
        setDealProductFiltered(filtered);
      } else {
        setDealProductFiltered(dealProdutList);
      }
    };
    return (
      <Fragment>
        {loading && <Spinner />}
        <div className="deals-product-page">
          <div className="deals-banner">
            <div className="deals-banner-content">
              <h1>{bestDealProducts?.title}</h1>
              <p className="banner-breadcrumb">
                Home{" "}
                <span>
                  <img src="/right-arrow.png" className="rightArrow" alt="" />
                </span>{" "}
                {bestDealProducts?.title}{" "}
              </p>
            </div>
          </div>
          {renderContent ? (
            <section className="product-list-section">
              <div className="search-sort-row">
                <IconsContainer handleSearchChange={handleSearchChange} />
              </div>
              <hr className="hracpl" />
              <div className="filter-and-product-list">
                <div className="left-content">
                  {/* <Filter /> */}
                </div>
                {dealProductFiltered && dealProductFiltered.length !== 0 ? (
                <div className="right-content">
                  <div className="selected-filters-row">
                    {["Ober-ear headphones", "headphones"].map(
                      (selectedFilter, index) => (
                        <div className="selected-filter" key={index}>
                          {selectedFilter}
                        </div>
                      )
                    )}
                  </div>
                  <div className="cardsContainer">
                    {dealProductFiltered.map((product, index) => (
                      <ProductCard
                        key={null}
                        data={product}
                        isFromCatrgory={false}
                        ref={null}
                      />
                    ))}
                  </div>
                </div>
                 ) : (
                  <ErrorMessage message="No products found" />
                )}
              </div>
              {/* <Offers /> */}
            </section>
          ) : (
            <div style={{ minHeight: "100vh" }}></div>
          )}
        </div>
        {/* Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <img src={locationIcon} width={100} height={100} alt="" />
              <h3>Select a location</h3>
              <div className="search-wrapper ">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search for your location"
                />
                <img src={searchLens} className="search-lens" alt="search-lens" />
              </div>
              <div className="currentLocation">
                <div className="currentIcon">
                  <img src={currentlocationIcon} width={15} alt="" />
                  <p>Use your current location</p>
                </div>
                <div className="currentIcon">
                  <img src={locationIcon2} width={20} alt="" />
                  <p>Locate on Map</p>
                </div>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26924.34774929709!2d72.809635638464!3d21.23000080350025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04eeb5c1077c1%3A0xf3db03f8cc74c359!2sKatargam%2C%20Surat%2C%20Gujarat!5e1!3m2!1sen!2sin!4v1730125490968!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ borderRadius: "10px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        )}
      </Fragment>
    );
  };
  export default DealsProductList;